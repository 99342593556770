import { Stack, styled, Typography } from '@mui/material'
import * as React from 'react'
import { NewRateMapButton } from './NewRateMapButton'
import i18n, { keys } from '../../../i18n'
import { ReactComponent as TractorIcon } from '../assets/tractor.svg'

const Container = styled(Stack)({
  backgroundColor: 'rgb(58, 58, 58)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '300px',
  fontSize: 24,
  rowGap: '16px',
})

const EmptyViewText = styled(Typography)({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: '0.5px',
})

export const RateMapEmptyView = () => {
  return (
    <Container direction="column">
      <TractorIcon />
      <EmptyViewText>{i18n.t(keys.rateMapEmptyLabel)}</EmptyViewText>
      <NewRateMapButton variant="outlined" />
    </Container>
  )
}
