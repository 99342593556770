import { createSelector } from 'reselect'
import { selectOrganization } from '../../data/selectOrganization'
import { selectSelectedLegendProductId } from '../../data/selectSelectedLegendProductId'
import {
  HistoricDeliveryParcelStats,
  selectHistoricParcelData,
} from './selectHistoricParcelData'
import { selectHistoricalCalculation } from './selectHistoricalCalculation'
import { selectParcels } from '../../data/selectOrgMapData'
import { selectMapLayerDefsById } from '../../data/selectListMapSourceDefs'
import { DownloadTableOptions } from '../../util/table'
import { keys } from '../../i18n/keys'
import i18n from '../../i18n'
import {
  selectHistoricalCalculationProperties,
  selectHistoricalCalculationType,
} from './selectHistoricalTabCalculationProperties'

export const selectHistoricStatsData = createSelector(
  [
    selectOrganization,
    selectHistoricParcelData,
    selectHistoricalCalculation,
    selectParcels,
    selectSelectedLegendProductId,
    selectMapLayerDefsById,
    selectHistoricalCalculationType,
    selectHistoricalCalculationProperties,
  ],
  (
    organization,
    historicChartData,
    historicCalculation,
    parcels,
    productId,
    mapLayerDefsById,
    historicStatsType,
    historicStatsProperties
  ) => {
    if (
      !organization ||
      !historicChartData.data ||
      historicChartData.data.length === 0 ||
      !parcels ||
      !productId ||
      !mapLayerDefsById ||
      !historicStatsType ||
      !historicStatsProperties
    ) {
      return
    }

    const product = mapLayerDefsById[productId]

    const tableData = historicChartData.data
      .map((d) => {
        const matchingParcel = parcels.find(
          (p) => p.id.toString() === d.parcelId.toString()
        )
        return {
          organization: organization.name,
          group: matchingParcel?.group.name ?? '',
          parcel: matchingParcel?.name ?? '',
          date: i18n.toDateShortName(d.flightDate),
          ...getHistoricCellData(historicStatsType, d),
        }
      })
      .sort((a, b) => a.parcel.localeCompare(b.parcel))
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

    const result: DownloadTableOptions = {
      data: tableData,
      filename: `${organization?.name}_${product.name}_${i18n.t(
        keys.stats.historic
      )}_stats`,
      headers: [
        {
          key: 'organization',
          label: i18n.t(keys.stats.organization),
        },
        {
          key: 'group',
          label: i18n.t(keys.stats.group),
        },
        {
          key: 'parcel',
          label: `${i18n.t(keys.stats.block)}`,
        },
        {
          key: 'date',
          label: i18n.t(keys.stats.date),
        },
        ...getHistoricHeaders(historicStatsType),
      ],
    }

    return result
  }
)

const getHistoricHeaders = (historicStatsType: string) => {
  switch (historicStatsType) {
    case 'weightedAvgWithError':
      return [
        {
          key: 'mean',
          label: i18n.t(keys.stats.mean),
        },
        {
          key: 'stdev',
          label: i18n.t(keys.stats.stDev),
        },
      ]
    default:
      return []
  }
}

const getHistoricCellData = (
  historicStatsType: string,
  d: HistoricDeliveryParcelStats
) => {
  switch (historicStatsType) {
    case 'weightedAvgWithError':
      return {
        mean: roundNum(d.EVImean),
        stdev: roundNum(d.EVIstdev),
      }
    default:
      return {}
  }
}

function roundNum(num: number, isInteger?: boolean, maximumFractionDigits = 3) {
  if (isInteger) {
    return Math.round(num)
  }

  const multiplier = Math.pow(10, maximumFractionDigits)
  let result = num * multiplier
  result = Math.round(result)
  result = result / multiplier

  return Number(result)
}
