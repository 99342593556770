import { rateMapSelectedProductIds } from '../constants'
import { createDeepEqualitySelector } from './createDeepEqualitySelector'
import { selectRateMapAdditionalLayerIds } from './selectRateMapAdditionalLayers'

export const selectRateMapSelectedProductIds = createDeepEqualitySelector(
  selectRateMapAdditionalLayerIds,
  (additionalLayers) => {
    const selectedProductIds = [...rateMapSelectedProductIds]

    if (additionalLayers && additionalLayers.length > 0) {
      selectedProductIds.push(...additionalLayers)
    }

    return selectedProductIds
  }
)
