import {
  ChartData,
  ChartOptions,
  ChartType,
} from 'chart.js'
import { Chart }  from 'react-chartjs-2'
import * as React from 'react'
import './ChartJS.scss'

export interface ChartJSProps {
  type: ChartType
  options: ChartOptions
  data: ChartData
}

export default function ChartJS({ type, options, data }: ChartJSProps) {
  const ref = React.useRef<any>()

  return (
    <div className="chartjs-container">
      <Chart type={type} ref={ref} options={options} data={data}/>
    </div>
  )
}
