import './SamplePlanNew.scss'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  Stack,
  Typography,
} from '@mui/material'
import * as React from 'react'
import i18n, { keys } from '../../../../i18n'
import { StageStepper, StepData } from '../../../../UI/Stages/StageStepper'
import SamplePlanNameForm from './forms/SamplePlanNameForm'
import SamplePlanFormTemplateForm from './forms/SamplePlanFormTemplateForm'
import SamplePlanBlockSelectionForm from './forms/SamplePlanBlockSelectionForm'
import SamplePlanSetUpForm from './forms/SamplePlanSetUpForm/SamplePlanSetUpForm'
import SamplePlanProtocolForm from './forms/SamplePlanProtocolForm/SamplePlanProtocolForm'
import SamplePlanConfirmationForm from './forms/SamplePlanConfirmationForm'
import { Close } from '@mui/icons-material'
import { useStageValidation } from '../../hooks/useStageValidation'
import {
  MapSource,
  SamplePlan,
  SamplePlanCreateDataSet,
} from '../../../../graphql/types'
import { commitNewSamplingPlan, deleteStaleSamplePlan } from '../../queries'
import { useConfirmBrowserExit } from '../../../../hooks/useConfirmBrowserExit'

interface Props {
  open: boolean
  onClose: (samplePlanId?: string) => void
}

const slideTiming = {
  appear: 210,
  enter: 500,
  exit: 500,
}

export const SamplePlanNew = ({ open, onClose }: Props) => {
  const [currentNewSamplingPlan, setCurrentNewSamplingPlan] =
    React.useState<SamplePlanCreateDataSet>({})
  const [completeSamplingPlan, setCompletedSamplingPlan] =
    React.useState<SamplePlan>()
  const [dataSources, setDataSources] = React.useState<MapSource[]>()
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [currentStage, setCurrentStage] = React.useState(0)
  const { registerStage, resolveStatusForStage } = useStageValidation()

  const stageFormStyles: React.CSSProperties[] = [
    {
      minWidth: 600,
      maxWidth: 660,
      minHeight: 100,
      maxHeight: 100,
      height: 100,
    },
    {
      minWidth: 600,
      maxWidth: 660,
      minHeight: 310,
      maxHeight: 310,
      height: 310,
    },
    {
      minWidth: 1540,
      maxWidth: 1600,
      minHeight: 543,
      maxHeight: 543,
      height: 543,
    },
    { minWidth: 760, maxWidth: 800, minHeight: 140, maxHeight: 1000 },
    {
      minWidth: 600,
      maxWidth: 660,
      minHeight: 350,
      maxHeight: 350,
      height: 350,
    },
    {
      minWidth: 960,
      maxWidth: 1000,
      minHeight: 350,
      maxHeight: 350,
      height: 350,
    },
  ]

  const stages: StepData[] = [
    {
      content: i18n.t(keys.samplePlanNewStep1Label),
    },
    {
      content: i18n.t(keys.samplePlanNewStep2Label),
    },
    {
      content: i18n.t(keys.samplePlanNewStep3Label),
    },
    {
      content: i18n.t(keys.samplePlanNewStep4Label),
    },
    {
      content: i18n.t(keys.samplePlanNewStep5Label),
    },
    {
      content: i18n.t(keys.samplePlanNewStep6Label),
    },
  ]

  const back = () => {
    if (currentStage === 0) {
      return
    }
    setCurrentStage(currentStage - 1)
  }

  const next = async () => {
    if (currentStage === stages.length - 1) {
      await complete()
      return
    }
    setCurrentStage(currentStage + 1)
  }

  const complete = async () => {
    if (!completeSamplingPlan?.id) {
      return
    }
    // set the sample plan to in progress.
    await commitNewSamplingPlan([completeSamplingPlan?.id])
    // clear out the sample plan.
    setCompletedSamplingPlan(undefined)
    setCurrentNewSamplingPlan({})
    setCurrentStage(0)
    // close
    onClose(completeSamplingPlan.id)
  }

  const close = async () => {
    // clear out the sample plan.
    setCurrentNewSamplingPlan({})
    // invalidate any completed sample plan
    await invalidateCompletedSamplingPlan()
    // set the stage back to the first.
    setCurrentStage(0)
    // close
    onClose()
  }

  const invalidateCompletedSamplingPlan = async () => {
    if (completeSamplingPlan) {
      await deleteStaleSamplePlan([
        completeSamplingPlan?.id,
        completeSamplingPlan?.projectId,
      ])
      setCompletedSamplingPlan(undefined)
    }
  }

  useConfirmBrowserExit(
    currentStage > 0 || !!currentNewSamplingPlan?.samplePlanName
  )

  return (
    <Dialog open={open} ref={containerRef} maxWidth="xl" disablePortal>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6" fontWeight={700} fontSize={20}>
            {i18n.t(keys.samplePlanNewLabel)}
          </Typography>
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2} sx={{ overflow: 'hidden' }}>
          <Paper
            sx={{ padding: '16px', backgroundColor: 'var(--background3)' }}
          >
            <StageStepper
              stages={stages}
              activeStages={currentStage}
            ></StageStepper>
          </Paper>

          {/* Name form */}
          <div
            className="sample-plan-new-stage-form-container"
            style={stageFormStyles[currentStage]}
          >
            <Slide
              direction="up"
              in={currentStage === 0}
              mountOnEnter
              unmountOnExit
              container={containerRef.current}
              timeout={slideTiming}
            >
              <SamplePlanNameForm
                currentNewSamplingPlan={currentNewSamplingPlan}
                setCurrentNewSamplingPlan={setCurrentNewSamplingPlan}
                registerValidation={registerStage}
                invalidateCompletedSamplingPlan={
                  invalidateCompletedSamplingPlan
                }
                stage={0}
              />
            </Slide>

            {/* Note form template form */}
            <Slide
              direction="up"
              in={currentStage === 1}
              mountOnEnter
              unmountOnExit
              container={containerRef.current}
              timeout={slideTiming}
            >
              <SamplePlanFormTemplateForm
                currentNewSamplingPlan={currentNewSamplingPlan}
                setCurrentNewSamplingPlan={setCurrentNewSamplingPlan}
                registerValidation={registerStage}
                invalidateCompletedSamplingPlan={
                  invalidateCompletedSamplingPlan
                }
                stage={1}
              />
            </Slide>

            {/* Block selection form */}
            <Slide
              direction="up"
              in={currentStage === 2}
              mountOnEnter
              unmountOnExit // - Note: unmounting appears to cause aberrant behaviour with map.
              container={containerRef.current}
              timeout={slideTiming}
            >
              <SamplePlanBlockSelectionForm
                currentNewSamplingPlan={currentNewSamplingPlan}
                setCurrentNewSamplingPlan={setCurrentNewSamplingPlan}
                registerValidation={registerStage}
                invalidateCompletedSamplingPlan={
                  invalidateCompletedSamplingPlan
                }
                stage={2}
              />
            </Slide>

            {/* Set up form */}
            <Slide
              direction="up"
              in={currentStage === 3}
              mountOnEnter
              unmountOnExit
              container={containerRef.current}
              timeout={slideTiming}
            >
              <SamplePlanSetUpForm
                currentNewSamplingPlan={currentNewSamplingPlan}
                setCurrentNewSamplingPlan={setCurrentNewSamplingPlan}
                registerValidation={registerStage}
                invalidateCompletedSamplingPlan={
                  invalidateCompletedSamplingPlan
                }
                setDataSources={setDataSources}
                stage={3}
              />
            </Slide>

            {/* Protocol form */}
            <Slide
              direction="up"
              in={currentStage === 4}
              mountOnEnter
              unmountOnExit
              container={containerRef.current}
              timeout={slideTiming}
            >
              <SamplePlanProtocolForm
                currentNewSamplingPlan={currentNewSamplingPlan}
                setCurrentNewSamplingPlan={setCurrentNewSamplingPlan}
                registerValidation={registerStage}
                invalidateCompletedSamplingPlan={
                  invalidateCompletedSamplingPlan
                }
                stage={4}
              />
            </Slide>

            {/* Confirmation form */}
            <Slide
              direction="up"
              in={currentStage === 5}
              mountOnEnter
              unmountOnExit
              container={containerRef.current}
              timeout={slideTiming}
            >
              <SamplePlanConfirmationForm
                currentNewSamplingPlan={currentNewSamplingPlan}
                completedSamplingPlan={completeSamplingPlan}
                setCompletedSamplingPlan={setCompletedSamplingPlan}
                registerValidation={registerStage}
                stage={5}
                dataSources={dataSources}
              />
            </Slide>
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={back} disabled={currentStage === 0}>
          {i18n.t(keys.back)}
        </Button>
        <Button onClick={next} disabled={!resolveStatusForStage(currentStage)}>
          {currentStage !== stages.length - 1
            ? i18n.t(keys.next)
            : i18n.t(keys.confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
