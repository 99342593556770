import { Box, BoxProps, styled, useMediaQuery, useTheme } from '@mui/material'
import * as React from 'react'
import Draggable from 'react-draggable'
import { MapOption } from './types'
import { useRedux } from '../hooks/useRedux'
import { selectMapOption } from './redux/selectors/selectMapOptions'
import { MapEditorToolType } from '../redux/map-editor-pages/redux'
import { selectDrawerStatus } from './redux/selectors/selectDrawerStatus'
import { selectDrawerWidth } from './redux/selectors/selectDrawerWidth'
import { selectDrawerOptions } from './redux/selectors/selectDrawerOptions'

interface MapOverlayProps<MapConfig> extends Pick<MapOption<MapConfig>, 'id'> {
  pageType: MapEditorToolType
  children?: React.ReactNode
  mapPosition?: string
}

interface MapOptionProps extends BoxProps {
  rightDrawerOpen: boolean
  leftDrawerOpen: boolean
  rightDrawerTabWidth: number
  leftDrawerTabWidth: number
  isMobile: boolean
  stickTo?: 'left-side-bar' | 'right-side-bar'
  isMultiTab?: boolean
  mapPosition?: string
}

const MapOverlayContainer = styled(Box)<MapOptionProps>(
  ({
    theme,
    rightDrawerOpen,
    leftDrawerOpen,
    rightDrawerTabWidth,
    leftDrawerTabWidth,
    isMobile,
    stickTo,
    isMultiTab,
    mapPosition,
  }) => {
    if (mapPosition === 'fill') {
      return {
        height: '100%',
        width: '100%',
        display: 'flex',
      }
    }
    return {
      transform: `translateX(${
        rightDrawerOpen && !isMobile && stickTo === 'right-side-bar'
          ? -rightDrawerTabWidth
          : leftDrawerOpen && !isMobile && stickTo === 'left-side-bar'
          ? leftDrawerTabWidth
          : 0
      }px)`,
      paddingLeft: stickTo === 'left-side-bar' && isMultiTab ? '16px' : 0,
      paddingRight: stickTo === 'right-side-bar' && isMultiTab ? '16px' : 0,
      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }
  }
)

export const MapOverlay = <MapConfig,>({
  id,
  children,
  pageType,
  mapPosition,
}: MapOverlayProps<MapConfig>) => {
  const [state] = useRedux()
  const theme = useTheme()
  const option = selectMapOption(state, pageType, id)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { rightDrawerOpen, leftDrawerOpen } = selectDrawerStatus(
    state,
    pageType
  )
  const { rightDrawerWidth, leftDrawerWidth } = selectDrawerWidth(
    state,
    pageType
  )
  const DrawerOptions = selectDrawerOptions(state, pageType)
  const isMultiTab = React.useMemo(() => {
    const activeLeftDrawerOptionCount = Object.values(DrawerOptions).filter(
      (option) => option.active && option.position === 'left-side-bar'
    ).length
    const activeRightDrawerOptionCount = Object.values(DrawerOptions).filter(
      (option) => option.active && option.position === 'right-side-bar'
    ).length
    return activeLeftDrawerOptionCount > 1 || activeRightDrawerOptionCount > 1
  }, [DrawerOptions])

  // if the config does not exist, just render normally.
  if (option === undefined) {
    return (
      <MapOverlayContainer
        rightDrawerOpen={!!rightDrawerOpen}
        leftDrawerOpen={!!leftDrawerOpen}
        rightDrawerTabWidth={rightDrawerWidth}
        leftDrawerTabWidth={leftDrawerWidth}
        isMobile={isMobile}
        isMultiTab={isMultiTab}
        mapPosition={mapPosition}
      >
        {children}
      </MapOverlayContainer>
    )
  }

  if (!option.active) {
    return null
  }

  if (option.draggable) {
    return <Draggable>{children}</Draggable>
  }

  return (
    <MapOverlayContainer
      rightDrawerOpen={!!rightDrawerOpen}
      leftDrawerOpen={!!leftDrawerOpen}
      rightDrawerTabWidth={rightDrawerWidth}
      leftDrawerTabWidth={leftDrawerWidth}
      isMobile={isMobile}
      stickTo={option.stickTo}
      isMultiTab={isMultiTab}
      mapPosition={mapPosition}
    >
      {children}
    </MapOverlayContainer>
  )
}
