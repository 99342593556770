import { Box, Stack, styled, Tab, Tabs } from '@mui/material'
import AsyncSelectorStatusOverlay from '../../../../../AsyncSelector/AsyncSelectorStatusOverlay'
import { useRedux } from '../../../../../hooks/useRedux'
import * as React from 'react'
import i18n, { keys } from '../../../../../i18n'
import { selectOrgMapData } from '../../../../../data/selectOrgMapData'
import { AmendmentConfig } from './AmendmentConfig'
import { VigorZoneConfig } from './VigorZoneConfig'
import { FinalOutput } from './FinalOutput'
import { SupplementalDataLayers } from './SupplementalDataLayers'
import { useRateMapContext } from '../../RateMapContext'
import { useGenerateAmendmentZones } from '../../../hooks/useGenerateAmendmentZones'

export interface ZoneRow {
  amount: number
  rateOfApplication: number
  stopIndex: number | undefined
  index: number
  zoneId: string
  size: number
  color: string
  value: string | null | undefined
  isCustomZone?: boolean
}

const MainContainer = styled(Stack)(() => ({
  width: '100%',
  height: '100%',
}))

const AmendmentContentContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: 0,
  overflow: 'auto',
}))

const ConfigSectionContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#00000033',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
}))

export const RateMapConfig = () => {
  const [state] = useRedux()
  const [zones, zonesLoading] = useGenerateAmendmentZones()

  const OrgMapDataRequest = React.useMemo(
    () => selectOrgMapData(state),
    [state]
  )
  const { loading, isCustomZoneMode } = useRateMapContext()

  const customModeStyle = React.useMemo(
    () => ({
      pointerEvents: isCustomZoneMode ? 'none' : ('auto' as 'none' | 'auto'),
      opacity: isCustomZoneMode ? 0.3 : 1,
    }),
    [isCustomZoneMode]
  )

  return (
    <MainContainer direction="column">
      <div style={customModeStyle}>
        <Tabs
          value={'RateMapConfig'}
          textColor="primary"
          indicatorColor="primary"
          variant="fullWidth"
          centered
        >
          <Tab
            style={{ minWidth: 0 }}
            value="RateMapConfig"
            label={i18n.t(keys.rateMapTab)}
          />
        </Tabs>
      </div>
      <AmendmentContentContainer>
        <AsyncSelectorStatusOverlay
          hideChildrenWhenLoading
          requests={[OrgMapDataRequest]}
          isLoading={loading}
          style={{ width: '100%' }}
        >
          <Stack
            sx={{
              width: '100%',
              overflow: 'hidden',
              ...customModeStyle,
              padding: 1,
            }}
            spacing={3}
          >
            <ConfigSectionContainer>
              <VigorZoneConfig />
            </ConfigSectionContainer>
            <ConfigSectionContainer>
              <AmendmentConfig zones={zones} zonesLoading={zonesLoading} />
            </ConfigSectionContainer>
            <ConfigSectionContainer>
              <FinalOutput zones={zones} zonesLoading={zonesLoading} />
            </ConfigSectionContainer>
          </Stack>
          <Stack
            sx={{
              width: '100%',
              overflow: 'hidden',
              padding: 1,
              paddingTop: 2,
            }}
            spacing={3}
          >
            <ConfigSectionContainer>
              <SupplementalDataLayers />
            </ConfigSectionContainer>
          </Stack>
        </AsyncSelectorStatusOverlay>
      </AmendmentContentContainer>
    </MainContainer>
  )
}
