import * as Sentry from '@sentry/browser'
import * as React from 'react'
import { mapDataUpdated } from '../postgis/actions'
import { dispatch } from '../redux/store'
import { classnames } from '../util/classnames'
import handleError from '../util/handleError'
import { sendToLogin } from '../util/sendToLogin'
import { debounce } from '../util/throttle-debounce'
import { getApiUrl, getAuthToken } from '../vvapi/apiResource/createApiResource'
import './Map.scss'
import { MapboxGL } from './MapboxGL'
import { generateFill } from './paint/generateFill'
import { MapContext } from './withMap'
import marker from './assets/images/marker.png'

interface Props {
  classNames?: string[]
  overlayStyles?: React.CSSProperties
  fitBounds?: MapboxGL.LngLatBoundsLike
  fitBoundsOptions?: MapboxGL.FitBoundsOptions
  style?: MapboxGL.Style | string
}

const addImageToMap = (
  map: MapboxGL.Map,
  imageUrl: string,
  name: string,
  sdf = false
) => {
  map.loadImage(imageUrl, (err, image) => {
    if (err) {
      console.error(err)
      return
    }

    if (!image) {
      return
    }

    // Add the image to the map style.
    map.addImage(name, image, { sdf })
  })
}

const mapId = () =>
  `MAP-${(Date.now() + Math.random()).toString(16).replace(/[.]+/g, '')}`

const mapUpdatedDebounce = debounce({ delay: 1000 }, (map?: MapboxGL.Map) => {
  dispatch(mapDataUpdated(map))
})

export function Map(props: React.PropsWithChildren<Props>) {
  const [containerId] = React.useState(mapId())
  const { map, setMap } = React.useContext(MapContext)

  const componentDidUpdate = (map?: MapboxGL.Map) => {
    if (!map) {
      return
    }

    const { style } = props

    if (!!style) {
      map.setStyle(style)
    }

    map.on('sourcedata', (e: mapboxgl.MapSourceDataEvent) => {
      if (
        e.sourceId.startsWith('mapbox-gl-draw-') ||
        e.sourceId.startsWith('guide') ||
        e.sourceId.startsWith('SAMPLE-PLAN')
      ) {
        return
      }

      if (e.isSourceLoaded) {
        mapUpdatedDebounce(map)
      }
    })

    map.on('load', () => {
      map.addImage('pattern', generateFill())
      addImageToMap(map, marker, 'marker', true)
    })
  }

  const fitBounds = (map: MapboxGL.Map) => {
    const { fitBounds, fitBoundsOptions } = props

    if (map && fitBounds) {
      try {
        map.fitBounds(fitBounds, fitBoundsOptions)
      } catch (error) {
        Sentry.addBreadcrumb({
          category: 'map-ui',
          message: `Map.fitBounds fitBounds=${JSON.stringify(
            fitBounds
          )} fitBoundsOptions=${JSON.stringify(fitBoundsOptions)}`,
          level: Sentry.Severity.Error,
        })
        handleError(error)
      }
    }
  }

  React.useEffect(() => {
    // return a function to execute at unmount
    return () => {
      if (!map) {
        return
      }

      map.remove()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!map) {
      const map = new MapboxGL.Map({
        container: containerId,
        style: props.style,
        attributionControl: false,
        logoPosition: 'bottom-left',
        transformRequest: (url: string) => {
          if (url.startsWith(getApiUrl() ?? '')) {
            return {
              url: url,
              headers: {
                authorization: `${getAuthToken()}`,
              },
            }
          }
          return {
            url,
          }
        },
      } as any)
      ;(map as any).painter.context.extTextureFilterAnisotropic = undefined

      map.on('error', ({ error }: any) => {
        if (error?.url?.startsWith(getApiUrl()) && error.status === 401) {
          sendToLogin()
        }
      })

      map.addControl(
        new MapboxGL.AttributionControl({ compact: true }),
        'bottom-right'
      )

      setMap(map)

      componentDidUpdate(map)
    }
  }, [map])

  React.useEffect(() => {
    componentDidUpdate(map)
  }, [map, props.style])

  React.useEffect(() => {
    if (!map) {
      return
    }

    fitBounds(map)
  }, [map, props.fitBounds, props.fitBoundsOptions])

  return (
    <div className={classnames('Map', ...(props.classNames ?? []))}>
      <div id={containerId} className="Map__container" />
      <div className="Map__overlay" style={props.overlayStyles}>
        {props.children}
      </div>
    </div>
  )
}
