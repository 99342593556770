import { createSelector } from 'reselect'

import { MapboxGL } from '../../map/MapboxGL'
import mapboxSatelliteJson from '../../map/styles/mapbox-satellite.json'
import { getElevationLayer } from '../ElevationLayer/selectors'
import { getSoilLayers } from '../SoilLayer/selectors'
import { getFieldsLayer } from './FieldsLayerSelector'
import { getNotesLayer } from './NotesLayerSelector'
import { selectedProductLayers } from './SelectedProductLayerSelector'
import { selectSelectedPolygonLayer } from './SelectSelectedPolygonLayer'
import { selectStatsMask } from './selectStatsMask'
import { getUserPositionLayer } from './UserPositionLayerSelector'
import { RootStore } from '../../redux/types'
import { ExtraLayersStyle } from '../../data/types'
import { Layer } from 'mapbox-gl'

const EMPTY_EXTRA_LAYERS: ExtraLayersStyle = {
  sources: {},
  layers: [],
}

const getExtraLayers = (state: RootStore) =>
  state.userSelection?.extraLayers ?? EMPTY_EXTRA_LAYERS

const addExtraLayers = (
  layers: MapboxGL.Layer[],
  extraLayers?: ExtraLayersStyle['layers']
) => {
  if (!extraLayers || extraLayers.length === 0) {
    return layers
  }

  const result: MapboxGL.Layer[] = [...layers]

  for (const { afterLayerGroup, beforeLayer, ...layer } of extraLayers) {
    let index = (result as any).findLastIndex(
      (l: Layer) => l.metadata?.mapLayerDefId === afterLayerGroup
    )
    if (index !== -1) {
      result.splice(index + 1, 0, layer)
    } else {
      if (index !== -1) {
        result.splice(index, 0, layer)
      } else {
        if (beforeLayer) {
          index = result.findIndex((l: Layer) => l.id === beforeLayer)
          if (index !== -1) {
            result.splice(index, 0, layer)
          } else {
            result.push(layer)
          }
        }
      }
    }
  }

  return result
}

/**
 * it merges all of our mapbox style json:
 * default, products, fields, etc
 */
export const selectAllMapLayers = createSelector(
  selectedProductLayers,
  getFieldsLayer,
  getUserPositionLayer,
  getNotesLayer,
  getElevationLayer,
  getSoilLayers,
  selectSelectedPolygonLayer,
  selectStatsMask,
  getExtraLayers,
  (
    productsLayers,
    fields,
    userPosition,
    notes,
    elevationLayer,
    soilLayers,
    selectedPolygonLayers,
    statsMask,
    extraLayers
  ): MapboxGL.Style => {
    return {
      ...mapboxSatelliteJson,
      sources: {
        ...mapboxSatelliteJson.sources,
        ...productsLayers.sources,
        ...fields.sources,
        ...userPosition.sources,
        ...notes.sources,
        ...elevationLayer.sources,
        ...soilLayers.sources,
        ...selectedPolygonLayers.sources,
        ...statsMask.sources,
        ...(extraLayers?.sources ?? {}),
      },
      layers: addExtraLayers(
        [
          ...mapboxSatelliteJson.layers,
          ...productsLayers.layers,
          ...fields.layers,
          ...userPosition.layers,
          ...notes.layers,
          ...elevationLayer.layers,
          ...soilLayers.layers,
          ...selectedPolygonLayers.layers,
          ...statsMask.layers,
        ],
        extraLayers?.layers
      ),
    }
  }
)
