import { createSelector } from 'reselect'
import { RateMapContextState } from '../editor/RateMapContext'
import { indexArray } from '../../../util/indexArray'
import { RateMapCustomZoneRate } from '../../../graphql/types'

const getRateMapCustomZoneRates = (state: RateMapContextState) =>
  state.rateMap?.RateMapCustomZoneRates

const EMPTY_CUSTOM_ZONE_RATES: Record<string, RateMapCustomZoneRate> = {}

export const selectRateMapCustomZoneRatesByZoneId = createSelector(
  getRateMapCustomZoneRates,
  (customZoneRates) => {
    if (!customZoneRates || customZoneRates.length === 0) {
      return EMPTY_CUSTOM_ZONE_RATES
    }
    return indexArray(customZoneRates, 'customZoneId')
  }
)
