import { Chart } from 'chart.js'

export const shiftByDataPoint = (
  chart: Chart,
  direction: 'left' | 'right',
  span: number = 8
): { min: number; max: number } => {
  const {
    data: { labels },
  } = chart

  // Get the current x min and max of the chart.
  const currentMin = Number(chart.config.options?.scales?.x?.min)
  const currentMax = Number(chart.config.options?.scales?.x?.max)

  if (!labels || labels.length === 0) {
    return {
      min: 0,
      max: 0,
    }
  }

  const maxSpan = span <= labels.length ? span : labels.length

  // If either min of max is undefined. Set the default span.
  if (
    chart.config.options?.scales?.x?.min === undefined ||
    !chart.config.options?.scales?.x?.max === undefined
  ) {
    return {
      min: 0,
      max: maxSpan - 1,
    }
  }

  // If the max is already in the final index and the user scrolls right, return current.
  if (direction === 'right' && currentMax + 1 >= labels.length) {
    return {
      min: currentMin,
      max: currentMax,
    }
  }

  // If the min is already in the first index and the user scrolls left, return current values.
  if (direction === 'left' && currentMin - 1 < 0) {
    return {
      min: currentMin,
      max: currentMax,
    }
  }

  // Shift the min of the window one block depending on the direction.
  const min = direction === 'right' ? currentMin + 1 : currentMin - 1

  // Shift the max of the window one block depending on the direction.
  const max = direction === 'right' ? currentMax + 1 : currentMax - 1

  return { min, max }
}

export const shiftByYear = (
  chart: Chart,
  direction: 'left' | 'right',
  span: number = 2
): { min: number; max: number } => {
  if (!chart) {
    return {
      min: 0,
      max: 0,
    }
  }
  const {
    data: { labels },
  } = chart
  // Get the current x min and max of the chart.
  const currentMin = Number(chart.config.options?.scales?.x?.min)
  const currentMax = Number(chart.config.options?.scales?.x?.max)

  if (!labels || labels.length === 0) {
    return {
      min: 0,
      max: 0,
    }
  }

  if (!labels.every((l) => l instanceof Date)) {
    throw new Error('One or more of the labels is not a date.')
  }

  const dateLabels = labels as string[]

  const { indexByYearBlock, yearBlockByIndex } = getYearIndexMapping(dateLabels)

  const maxSpan =
    span <= indexByYearBlock.length ? span : indexByYearBlock.length

  // If either min of max is undefined. Set the default span.
  if (
    chart.config.options?.scales?.x?.min === undefined ||
    !chart.config.options?.scales?.x?.max === undefined
  ) {
    return {
      min: 0,
      max: indexByYearBlock[maxSpan - 1][
        indexByYearBlock[maxSpan - 1].length - 1
      ],
    }
  }

  // If the max is already in the final index and the user scrolls right, return current.
  if (direction === 'right' && currentMax + 1 >= labels.length) {
    return {
      min: currentMin,
      max: currentMax,
    }
  }

  // If the min is already in the first index and the user scrolls left, return current values.
  if (direction === 'left' && currentMin - 1 < 0) {
    return {
      min: currentMin,
      max: currentMax,
    }
  }

  const currentMinBlockIndex = yearBlockByIndex[currentMin]
  const currentMaxBlockIndex = yearBlockByIndex[currentMax]

  // Shift the min of the window one block depending on the direction.
  const min =
    direction === 'right'
      ? indexByYearBlock[currentMinBlockIndex + 1][0]
      : indexByYearBlock[currentMinBlockIndex - 1][0]

  // Shift the max of the window one block depending on the direction.
  const max =
    direction === 'right'
      ? indexByYearBlock[currentMaxBlockIndex + 1][
          indexByYearBlock[currentMaxBlockIndex + 1].length - 1
        ]
      : indexByYearBlock[currentMaxBlockIndex - 1][
          indexByYearBlock[currentMaxBlockIndex - 1].length - 1
        ]

  return { min, max }
}

export const updateSpanByYears = (chart: Chart, span: number) => {
  if (!chart) {
    return {
      min: 0,
      max: 0,
    }
  }

  const {
    data: { labels },
  } = chart

  if (!labels || labels.length === 0) {
    return {
      min: 0,
      max: 0,
    }
  }

  if (!labels.every((l) => l instanceof Date)) {
    throw new Error('One or more of the labels is not a date.')
  }

  const dateLabels = labels as string[]

  const { indexByYearBlock } = getYearIndexMapping(dateLabels)

  const maxSpan =
    span <= indexByYearBlock.length ? span : indexByYearBlock.length

  return {
    min: 0,
    max: indexByYearBlock[maxSpan - 1][
      indexByYearBlock[maxSpan - 1].length - 1
    ],
  }
}

/**
 * Updates the chart span by data points
 *
 * @param chart - The chart object
 * @param span - The new chart span
 * @param startIndex - The starting index of the chart data
 *
 * @returns The updated chart span object with min and max values
 */
export const updateSpanByDataPoints = (
  chart: Chart,
  span: number,
  startIndex: number = 0
): { min: number; max: number } => {
  if (!chart) {
    return {
      min: 0,
      max: 0,
    }
  }

  const {
    data: { labels },
  } = chart

  // If span is less than 0, return a default object
  if (span < 0) {
    return { min: 0, max: 0 }
  }

  // If there are no labels, return a default object
  if (!labels || labels.length === 0) {
    return { min: 0, max: 0 }
  }

  // Find the maximum available span and return an object with the updated span
  const maxStartIndex = labels.length - span
  const actualStartIndex = Math.min(startIndex, maxStartIndex)
  const maxSpan = Math.min(span + actualStartIndex, labels.length)

  return {
    min: actualStartIndex,
    max: maxSpan - 1,
  }
}

export const getIndexOfSelectedFlightDate = (
  chart: Chart,
  selectedFlightDate: string
): number => {
  if (!chart) {
    return 0
  }

  const { labels } = chart.data

  if (!chart || !labels || labels.length === 0) {
    return 0
  }

  const dateLabels = labels as Date[]

  const flightDate = new Date(selectedFlightDate)
  const index = dateLabels.findIndex(
    (label) => new Date(label).getTime() === flightDate.getTime()
  )

  return index !== -1 ? index : 0
}

export const updateChartMinMax = (chart: Chart, min: number, max: number) => {
  chart.config.options = {
    ...chart.config.options,
    scales: {
      ...chart.config.options?.scales,
      x: {
        ...chart.config.options?.scales?.x,
        min: min,
        max: max,
      },
    },
  }
}

export const updateSelectedFlightIndex = (
  chart: Chart,
  selectedIndex: number
) => {
  chart.config.options = {
    ...chart.config.options,
    plugins: {
      ...chart.config.options?.plugins,
      historicChart: {
        ...chart.config.options?.plugins?.historicChart,
        barIndex: selectedIndex,
      },
    },
  }
}

export const updateSpan = (chart: Chart, span: number) => {
  chart.config.options = {
    ...chart.config.options,
    plugins: {
      ...chart.config.options?.plugins,
      historicChart: {
        ...chart.config.options?.plugins?.historicChart,
        chartSpan: span,
      },
    },
  }
}

export const getYearIndexMapping = (dateLabels: string[]) => {
  // Prepare index sets by grouping the labels by their year.
  const indexByYear = dateLabels.reduce<Map<number, number[]>>(
    (groups, currentValue, index) => {
      const year = new Date(currentValue).getFullYear()
      const mappedIndexes = groups.get(year)
      if (mappedIndexes) {
        groups.set(year, [...mappedIndexes, index])
      } else {
        groups.set(year, [index])
      }

      return groups
    },
    new Map<number, number[]>()
  )

  // Convert the mapping to blocks of indexes bases on the year.
  const indexByYearBlock = Array.from(indexByYear.values())

  // Inverse that structure to give an block based on an index.
  const yearBlockByIndex = indexByYearBlock.reduce<number[]>(
    (flattened, block, index) => {
      block.forEach(() => flattened.push(index))
      return flattened
    },
    []
  )

  return { indexByYearBlock, yearBlockByIndex }
}
