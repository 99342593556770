import * as React from 'react'
import { useRateMapContext } from '../editor/RateMapContext'
import { useMapDrawContext } from '../../../map/draw/MapDrawContext'
import {
  Feature,
  feature,
  featureCollection,
  MultiPolygon,
  Polygon,
} from '@turf/helpers'
import area from '@turf/area'
import * as drawHelpers from '../../../map/draw/util/drawHelpers'
import kinks from '@turf/kinks'
import { indexArray } from '../../../util/indexArray'
import {
  RateMapBlockSelection,
  RateMapCustomZone,
} from '../../../graphql/types'
import { useMap } from '../../../map/withMap'
import { useRateMapStopData } from './useRateMapStopData'
import { useRateMapVigorZoneFeatures } from './useRateMapVigorZoneFeatures'
import { ADJUSTED_VIGOR_ZONE_LAYER_ID } from '../editor/map-options/custom-zones/CustomZoneStyles'
import { FIELD_BOUNDS_UNSELECTED_LAYER_ID } from '../../../postgis/selectors/FieldsLayerSelector'
import { GeoJSONSource } from 'mapbox-gl'

const MIN_AREA_M2 = 0.25

const getParcelIntersections = (
  blockSelections: RateMapBlockSelection[],
  parcelGeometries: any,
  features: Feature<Polygon | MultiPolygon>[]
) => {
  return blockSelections
    .map((selection: any) => {
      const parcelFeature = parcelGeometries?.[selection?.parcelId]

      if (!parcelFeature) {
        return {
          parcelId: selection.parcelId,
          parcel: selection.Parcel,
          intersection: undefined,
          intersectionArea: 0,
        }
      }
      const parcelIntersection = drawHelpers.intersection(
        parcelFeature,
        ...features
      )
      return {
        parcelId: selection.parcelId,
        parcel: selection.Parcel,
        intersection: parcelIntersection ?? undefined,
        intersectionArea: parcelIntersection ? area(parcelIntersection) : 0,
      }
    })
    .sort((a, b) => b.intersectionArea - a.intersectionArea)
}

const getCustomZoneDifferences = (
  customZones: RateMapCustomZone[],
  newFeature: Feature<Polygon> | Feature<MultiPolygon>
) => {
  return customZones.map((zone) => {
    if (zone.id === newFeature.id || !zone.geometry) {
      return {
        zoneId: zone.id,
        difference: newFeature,
      }
    }

    const zoneGeometry = zone.geometry
    const zoneFeatureGeometry = newFeature.geometry

    const difference = drawHelpers.difference(
      feature(zoneGeometry),
      feature(zoneFeatureGeometry)
    )

    return {
      zoneId: zone.id,
      difference: difference ?? newFeature,
    }
  })
}

const clipPreviousRateMapCustomZones = (
  targetCustomZone: RateMapCustomZone,
  customZones: RateMapCustomZone[]
) => {
  return customZones.reduce((acc, zone) => {
    if (zone.id === targetCustomZone.id) {
      return [...acc, targetCustomZone]
    }

    if (!zone.geometry || !targetCustomZone.geometry) {
      return [...acc, zone]
    }

    const difference = drawHelpers.difference(
      feature(zone.geometry),
      feature(targetCustomZone.geometry)
    )

    if (!difference || area(difference) < MIN_AREA_M2) {
      return [
        ...acc,
        {
          ...zone,
          deletedAt: new Date().toISOString(),
        },
      ]
    }

    return [
      ...acc,
      {
        ...zone,
        geometry: difference?.geometry ?? zone.geometry,
      },
    ]
  }, [] as RateMapCustomZone[])
}

const clipPreviouslyDrawnFeatures = (
  allFeatures: Feature<Polygon | MultiPolygon>[],
  rateMapCustomZoneDifferencesById: Record<
    string,
    {
      zoneId: string
      difference: Feature<Polygon> | Feature<MultiPolygon>
    }
  >,
  originalDrawId?: string
) => {
  const adjustedFeatures = allFeatures.reduce((acc, feature) => {
    const zone = rateMapCustomZoneDifferencesById?.[feature.id ?? '']

    // if the feature is not a custom zone, return it as is
    if (!zone) {
      return [...acc, feature]
    }

    // if the feature is the original draw, remove since it will be replaced
    if (feature.id === originalDrawId) {
      return acc
    }

    // if the feature is a custom zone, adjust the geometry to reflect the clipped zone
    acc.push({
      ...feature,
      geometry: zone?.difference?.geometry ?? feature.geometry,
    } as Feature<Polygon>)
    return acc
  }, [] as Feature<Polygon>[])

  return adjustedFeatures
}

export const useDrawCustomRateMapZones = (enabled: boolean) => {
  const {
    snappingEnabled,
    selectedZoneType,
    isCustomZoneMode,
    selectedCustomZone,
    setSelectedCustomZone,
    setModifiedRateMap,
    rateMap,
    limitToBounds,
    setHasInvalidGeometry,
    setSelectedZoneIndex,
    selectedZoneIndex,
  } = useRateMapContext()

  const { map } = useMap()

  const [pendingZones, setPendingZones] = React.useState<
    RateMapCustomZone[] | null
  >(null)

  const {
    getCustomZoneAdjustedVigorZonesByStopIndex,
    parcelGeometries,
    rateMapMapSources,
  } = useRateMapVigorZoneFeatures()

  const adjustedVigorZones = React.useMemo(() => {
    return getCustomZoneAdjustedVigorZonesByStopIndex(
      rateMap?.RateMapCustomZones ?? []
    )
  }, [getCustomZoneAdjustedVigorZonesByStopIndex, rateMap?.RateMapCustomZones])

  const customZones = React.useMemo(() => {
    return pendingZones ?? rateMap?.RateMapCustomZones
  }, [pendingZones, rateMap?.RateMapCustomZones])

  const {
    draw,
    setOnCreate,
    setOnDelete,
    setOnRender,
    setOnUpdate,
    setSnapping,
    setIsEnabled,
    attachedToMap,
  } = useMapDrawContext()

  const [invalidGeometryIds, setInvalidGeometryIds] = React.useState<string[]>(
    []
  )

  const stops = useRateMapStopData()

  React.useEffect(() => {
    if (!enabled) {
      setIsEnabled(false)
      if (map?.getLayer('rate-map-custom-zones')) {
        map?.setLayoutProperty('rate-map-custom-zones', 'visibility', 'visible')
      }
    } else {
      setIsEnabled(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled])

  const onCreate = React.useCallback(
    (e: any) => {
      if (
        !draw ||
        !selectedCustomZone ||
        !setSelectedCustomZone ||
        !setModifiedRateMap ||
        !rateMap
      ) {
        return
      }

      const originalDrawId = e.features[0].id
      const undeletedZones =
        customZones?.filter((zone) => !zone?.deletedAt) ?? []

      const alreadyExists = undeletedZones.some(
        (zone) => zone.id === selectedCustomZone.id
      )

      if (alreadyExists) {
        return
      }

      const selectedParcelIntersections = getParcelIntersections(
        rateMap.RateMapBlockSelections,
        parcelGeometries,
        e.features as Feature<Polygon | MultiPolygon>[]
      )

      const parcelIntersectionGeom =
        selectedParcelIntersections[0]?.intersection?.geometry

      const intersectingParcelId = selectedParcelIntersections[0]?.parcelId

      // update the feature to reflect changes needed to limit the zone to the parcel bounds
      const updatedCreatedFeature = {
        ...e.features[0],
        id: selectedCustomZone.id, // set the id to the custom zone id
        geometry: limitToBounds
          ? parcelIntersectionGeom
          : e.features[0].geometry,
        properties: {
          ...e.features[0].properties,
          'parcel-id': intersectingParcelId,
          'zone-id': selectedCustomZone.id,
          'zone-color': selectedCustomZone.color,
          'zone-created-at': new Date().toISOString(),
        },
      } as Feature<Polygon>

      // update the custom zone data attached to the rate map to reflect limits to parcel bounds
      const newCustomZone = {
        ...selectedCustomZone,
        parcelId: selectedParcelIntersections[0]?.parcelId,
        Parcel: selectedParcelIntersections[0]?.parcel,
        geometry: updatedCreatedFeature.geometry,
      }

      const customZoneDifferences = getCustomZoneDifferences(
        undeletedZones,
        updatedCreatedFeature
      )

      const rateMapCustomZoneDifferencesById = indexArray(
        customZoneDifferences,
        'zoneId'
      )

      const updatedRateMapCustomZones = clipPreviousRateMapCustomZones(
        newCustomZone,
        undeletedZones
      )

      const allFeatures = draw?.getAll?.() ?? featureCollection([])

      const adjustedFeatures = clipPreviouslyDrawnFeatures(
        allFeatures.features as Feature<Polygon | MultiPolygon>[],
        rateMapCustomZoneDifferencesById,
        originalDrawId
      )

      draw?.set?.(
        featureCollection([...adjustedFeatures, updatedCreatedFeature])
      )

      if (kinks(updatedCreatedFeature).features.length > 0) {
        setInvalidGeometryIds((prev) => [
          ...prev,
          updatedCreatedFeature?.id ?? e.features[0].id,
        ])
      }

      setPendingZones([...updatedRateMapCustomZones, newCustomZone])
      setSelectedCustomZone(undefined)
    },
    [
      draw,
      selectedCustomZone,
      setSelectedCustomZone,
      setModifiedRateMap,
      rateMap,
      limitToBounds,
      parcelGeometries,
      customZones,
    ]
  )

  const onDelete = React.useCallback(
    (e: any) => {
      if (!setSelectedCustomZone || !setModifiedRateMap || !rateMap) {
        return
      }

      const zoneIds = e.features.map((f: any) => f.properties['zone-id'])
      if (!zoneIds || zoneIds.length === 0) {
        return
      }

      setInvalidGeometryIds((prev) =>
        prev.filter((id) => !e.features.map((f: any) => f.id).includes(id))
      )

      setPendingZones(
        customZones?.map((zone) => {
          if (zoneIds.includes(zone.id)) {
            return {
              ...zone,
              deletedAt: new Date().toISOString(),
            }
          }
          return zone
        }) ?? []
      )
      setSelectedCustomZone(undefined)
    },
    [rateMap, setModifiedRateMap, setSelectedCustomZone, customZones]
  )

  const onUpdate = React.useCallback(
    (e: any) => {
      if (!rateMap) {
        return
      }
      // get the zone id from the feature properties
      const zoneId = e.features[0].properties['zone-id']
      if (!zoneId) {
        return
      }

      const updatedCustomZone = customZones?.find((zone) => zone.id === zoneId)

      if (!updatedCustomZone) {
        return
      }

      const undeletedZones =
        customZones?.filter((zone) => !zone?.deletedAt) ?? []

      const selectedParcelIntersections = getParcelIntersections(
        rateMap.RateMapBlockSelections,
        parcelGeometries,
        e.features as Feature<Polygon | MultiPolygon>[]
      )

      const parcelIntersectionGeom =
        selectedParcelIntersections[0]?.intersection?.geometry

      const intersectingParcelId = selectedParcelIntersections[0]?.parcelId

      // update the feature to reflect changes needed to limit the zone to the parcel bounds
      const updatedFeature = {
        ...e.features[0],
        geometry: limitToBounds
          ? parcelIntersectionGeom
          : e.features[0].geometry,
        properties: {
          ...e.features[0].properties,
          'parcel-id': intersectingParcelId,
          'zone-updated-at': new Date().toISOString(),
        },
      } as Feature<Polygon>

      // update the custom zone data attached to the rate map to reflect limits to parcel bounds
      const newUpdatedCustomZone = {
        ...updatedCustomZone,
        parcelId: selectedParcelIntersections[0]?.parcelId,
        Parcel: selectedParcelIntersections[0]?.parcel,
        geometry: updatedFeature.geometry,
      }

      const customZoneDifferences = getCustomZoneDifferences(
        undeletedZones,
        updatedFeature
      )

      const rateMapCustomZoneDifferencesById = indexArray(
        customZoneDifferences,
        'zoneId'
      )

      const updatedRateMapCustomZones = clipPreviousRateMapCustomZones(
        newUpdatedCustomZone,
        undeletedZones
      )

      const allFeatures = draw?.getAll?.() ?? featureCollection([])

      const adjustedFeatures = clipPreviouslyDrawnFeatures(
        allFeatures.features as Feature<Polygon | MultiPolygon>[],
        rateMapCustomZoneDifferencesById
      )

      draw?.set?.(featureCollection(adjustedFeatures))

      if (kinks(updatedFeature).features.length > 0) {
        setInvalidGeometryIds((prev) => [
          ...prev,
          updatedFeature?.id ?? e.features[0].id,
        ])
      }

      setPendingZones(updatedRateMapCustomZones)
    },
    [
      setPendingZones,
      rateMap,
      limitToBounds,
      draw,
      parcelGeometries,
      customZones,
    ]
  )

  React.useEffect(() => {
    if (!onCreate || !setOnCreate || !attachedToMap) {
      return
    }
    const off = setOnCreate(onCreate)

    return () => {
      off()
    }
  }, [onCreate, draw, attachedToMap, setOnCreate])

  React.useEffect(() => {
    if (!onDelete || !setOnDelete || !attachedToMap) {
      return
    }
    const off = setOnDelete(onDelete)

    return () => {
      off()
    }
  }, [onDelete, draw, attachedToMap, setOnDelete])

  React.useEffect(() => {
    if (!onUpdate || !setOnUpdate || !attachedToMap) {
      return
    }
    const off = setOnUpdate(onUpdate)

    return () => {
      off()
    }
  }, [onUpdate, draw, attachedToMap, setOnUpdate])

  React.useEffect(() => {
    if (!attachedToMap || !draw?.changeMode) {
      return
    }

    if (!isCustomZoneMode) {
      const invalidGeom =
        draw
          ?.getAll?.()
          ?.features.filter(
            (f) =>
              kinks(f.geometry as Polygon | MultiPolygon).features.length > 0 &&
              !!f.id
          )
          .map((f) => f?.id as string) ?? []
      setInvalidGeometryIds(invalidGeom)

      draw?.changeMode('static')
      return
    }

    if (!selectedCustomZone) {
      const currentMode = draw?.getMode?.() ?? 'simple_select'
      if (currentMode !== 'simple_select') {
        draw.changeMode('simple_select')
      }

      return
    }

    if (selectedZoneType === 'rectangle') {
      draw?.changeMode('draw_rectangle')
    } else {
      draw?.changeMode('draw_polygon')
    }
  }, [
    draw,
    selectedZoneType,
    isCustomZoneMode,
    selectedCustomZone,
    setHasInvalidGeometry,
    attachedToMap,
  ])

  React.useEffect(() => {
    if (!setSnapping) {
      return
    }
    setSnapping({
      snap: snappingEnabled,
    })
  }, [snappingEnabled, draw, setSnapping])

  React.useEffect(() => {
    if (!attachedToMap) {
      return
    }

    // when the draw initializes, set the drawings saved to the rate map
    const features =
      customZones
        ?.filter((zone) => !zone.deletedAt)
        .map((zone) =>
          feature(
            zone.geometry as Polygon,
            {
              'zone-id': zone.id,
              'zone-color': zone.color,
              'parcel-id': zone.parcelId,
            },
            { id: zone.id }
          )
        ) ?? []

    if (features.length > 0) {
      const clearOnRender = setOnRender(() => {
        clearOnRender()
        requestAnimationFrame(() => {
          if (map?.getLayer('rate-map-custom-zones')) {
            map?.setLayoutProperty(
              'rate-map-custom-zones',
              'visibility',
              'none'
            )
          }
        })
      })

      const fc = featureCollection(features)
      draw?.set?.(fc)
    }
    const invalidGeom =
      features
        .filter((f) => kinks(f).features.length > 0 && !!f.id)
        .map((f) => f?.id as string) ?? []
    setInvalidGeometryIds(invalidGeom)
  }, [attachedToMap, customZones, draw, map, setOnRender])

  React.useEffect(() => {
    setHasInvalidGeometry(invalidGeometryIds.length > 0)
  }, [invalidGeometryIds, setHasInvalidGeometry])

  // when the user taps the escape key while in draw mode, cancel the drawing
  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (
        !attachedToMap ||
        !draw?.changeMode ||
        !draw?.getAll ||
        !draw?.delete ||
        !selectedCustomZone
      ) {
        return
      }
      if (e.key === 'Escape') {
        setSelectedCustomZone(undefined)
        draw.changeMode('simple_select')

        // find all the features that don't have a zone id and remove them
        const featureIds = draw
          .getAll()
          .features.filter((f: any) => !f.properties['zone-id'])
          .map((f: any) => f.id)
        if (featureIds.length > 0) {
          draw.delete(featureIds)
        }
      }
    }

    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [attachedToMap, draw, selectedCustomZone, setSelectedCustomZone])

  React.useEffect(() => {
    if (isCustomZoneMode) {
      setSelectedZoneIndex?.(undefined)
    }
  }, [isCustomZoneMode, setSelectedZoneIndex])

  React.useEffect(() => {
    const loadLayerAndSource = () => {
      try {
        const newFeatures = Object.entries(adjustedVigorZones ?? {})
          .map(([stopIndex, zoneFeatures]) => {
            return zoneFeatures.map(
              (zone) =>
                ({
                  type: 'Feature',
                  properties: {
                    color:
                      (stops?.[Number(stopIndex)]?.color as string) ?? '#000',
                    hidden:
                      selectedZoneIndex === undefined
                        ? false
                        : Number(stopIndex) !== selectedZoneIndex,
                  },
                  geometry: zone.geometry,
                } as Feature<Polygon>)
            )
          })
          .flat()

        const beforeLayerId =
          map
            ?.getStyle()
            .layers?.find(
              (l) => (l as any).metadata?.mapLayerDefId !== undefined
            )?.id ?? FIELD_BOUNDS_UNSELECTED_LAYER_ID

        const source = map?.getSource(`${ADJUSTED_VIGOR_ZONE_LAYER_ID}_source`)

        if (source) {
          ;(source as GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: newFeatures,
          })

          map?.moveLayer(ADJUSTED_VIGOR_ZONE_LAYER_ID, beforeLayerId)
        } else {
          map?.addSource?.(`${ADJUSTED_VIGOR_ZONE_LAYER_ID}_source`, {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: newFeatures,
            },
          })
          map?.addLayer?.(
            {
              id: ADJUSTED_VIGOR_ZONE_LAYER_ID,
              source: `${ADJUSTED_VIGOR_ZONE_LAYER_ID}_source`,
              type: 'fill',
              filter: [
                'all',
                ['==', '$type', 'Polygon'],
                ['any', ['!has', 'hidden'], ['==', 'hidden', false]],
              ],
              paint: {
                'fill-color': ['get', 'color'],
              },
            },
            beforeLayerId
          )
        }
      } catch (e) {
        console.error(e)
      }
    }

    // loadLayerAndSource()

    map?.once('styledata', loadLayerAndSource)

    // return () => {
    //   map?.off('styledata', loadLayerAndSource)
    // }
  }, [adjustedVigorZones, stops, map, rateMapMapSources, selectedZoneIndex])

  const confirmAllZones = React.useCallback(() => {
    if (!rateMap || !setModifiedRateMap || !pendingZones) {
      return
    }

    setModifiedRateMap({
      ...rateMap,
      RateMapCustomZones: pendingZones,
    })
  }, [pendingZones, rateMap, setModifiedRateMap])

  const cancel = () => setPendingZones(null)

  return [draw, pendingZones, confirmAllZones, cancel] as const
}
