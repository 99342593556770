import * as React from 'react'
import ItemSelectionForm from '../../../../../UI/ItemSelection/ItemSelectionForm'
import i18n, { keys } from '../../../../../i18n'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useRateMapContext } from '../../RateMapContext'
import { Parcel } from '../../../../../graphql/types'
import { useRateMapExportable } from '../../../hooks/useRateMapExportable'

type DownloadRateMapFileTypes = 'csv' | 'geojson' | 'shapefile'

interface Props {
  open: boolean
  onClose: () => void
  onCommit?: () => void
}

export const ExportSelector = ({ open, onClose, onCommit }: Props) => {
  const { rateMap } = useRateMapContext()
  const [download] = useRateMapExportable()

  const [fileType, setFileType] =
    React.useState<DownloadRateMapFileTypes>('geojson')

  const onDownload = React.useCallback(
    (selectedItems: Parcel[]) => {
      download(fileType, rateMap?.name ?? 'rate-map', selectedItems)
      onCommit?.()
      onClose()
    },
    [download, fileType, rateMap?.name, onCommit, onClose]
  )

  const parcelOptions = React.useMemo(
    () =>
      rateMap?.RateMapBlockSelections?.filter(
        (block) => !!block?.Parcel && block.enabled
      ).map((block) => block?.Parcel as Parcel) ?? [],
    [rateMap]
  )

  return (
    <ItemSelectionForm
      open={open}
      title={i18n.t(keys.rateMapDownloadLabel)}
      sectionTitle={i18n.t(keys.rateMapDownloadBlockSelectionLabel)}
      items={parcelOptions ?? []}
      onClose={onClose}
      onCommit={onDownload}
      validate={() =>
        (!!rateMap?.RateMapCustomZones?.length &&
          rateMap.RateMapCustomZones.length > 0) ||
        (!!rateMap?.amendmentZoneRates && rateMap.amendmentZoneRates.length > 0)
      }
      additionalSections={{
        [i18n.t(keys.generic.downloadFormat)]: (
          <FormControl>
            <RadioGroup
              value={fileType}
              onChange={(e) =>
                setFileType(e.target.value as DownloadRateMapFileTypes)
              }
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="geojson"
                control={<Radio />}
                label={i18n.t(keys.generic.geojson)}
              />
              <FormControlLabel
                value="csv"
                control={<Radio />}
                label={i18n.t(keys.generic.csv)}
              />
              <FormControlLabel
                value="shapefile"
                control={<Radio />}
                label={i18n.t(keys.generic.shapefile)}
              />
            </RadioGroup>
          </FormControl>
        ),
      }}
    />
  )
}
