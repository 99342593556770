import { Chart } from 'chart.js'
import { getYearIndexMapping } from '../util/pluginHelpers'
import { Theme } from '@mui/material'

const labelAreaHeight = 40
const chevron_size = 30
const chevron_padding = 5

export interface HistoricChartOptions {
  chartSpan: number
  barIndex: number
  bloomRadius: number
  bloomIntensity: number
  fontSize: number
  theme: Theme
  focusLabel: () => string
  scrollingLabelFormat: (label: string) => string
  onArrowClicked: (
    chart: Chart,
    direction: 'left' | 'right',
    span: number
  ) => void
}

// Assumes that the labels are a date and they are already sorted.
export const historicChartPlugin = {
  id: 'historicChart',
  beforeEvent: (chart: Chart, args: any, pluginOptions: any) => {
    const event = args.event
    if (event.type === 'click') {
      onArrowClicked(chart, pluginOptions, event)
    }
  },
  afterUpdate: (chart: Chart) => {
    if (chart.options.layout) {
      chart.options.layout.padding = {
        top: 32,
        right: 16,
        bottom: labelAreaHeight + 9,
      }
    }
  },
  beforeDraw: (chart: Chart, _args: any, pluginOptions: any) => {
    drawFocusedHighlight(chart, pluginOptions)
  },
  afterDraw: (chart: Chart, args: any, pluginOptions: any) => {
    drawScrollArrows(chart, pluginOptions)
  },
  afterDatasetsDraw: (chart: Chart, _args: any, pluginOptions: any) => {
    drawLabelsAndSubLabels(chart, pluginOptions)
  },
}

const drawScrollArrows = (
  chart: Chart,
  pluginOptions: HistoricChartOptions
) => {
  const { theme, chartSpan } = pluginOptions
  const {
    ctx,
    chartArea: { width, height, left, top },
    options: { scales },
    data: { labels },
  } = chart

  // If there are less data points than span, don't render a scroll bar.
  if (!labels?.length || labels.length <= chartSpan) {
    return
  }

  const centerY = height / 2 + top
  const xLeft = left + chevron_padding
  const xRight = width + left - chevron_padding
  const minX = Number(scales?.x?.min ?? 0)
  const maxX = Number(scales?.x?.max ?? 0)

  // Draw arrow on the left.
  if (minX !== 0) {
    ctx.save()
    ctx.translate(xLeft, 0)
    drawChevrons(ctx, centerY, theme.palette.text.primary)
    ctx.restore()
  }

  if (maxX !== labels.length - 1) {
    ctx.save()
    ctx.translate(xRight, 0)
    ctx.scale(-1, 1)
    // Draw arrow on the right
    drawChevrons(ctx, centerY, theme.palette.text.primary)
    ctx.restore()
  }
}

const onArrowClicked = (
  chart: Chart,
  pluginOptions: HistoricChartOptions,
  event: MouseEvent
) => {
  const { onArrowClicked, chartSpan } = pluginOptions
  const {
    chartArea: { width, height, left, top },
  } = chart

  const x = event.x
  const y = event.y

  const centerY = height / 2 + top
  const xLeft = left + chevron_padding
  const xRight = width + left - chevron_padding

  const isLeftChevronClicked =
    x > xLeft - chevron_size / 2 &&
    x < xLeft + chevron_size / 2 &&
    y > centerY - chevron_size / 2 &&
    y < centerY + chevron_size / 2
  const isRightChevronClicked =
    x > xRight - chevron_size / 2 &&
    x < xRight + chevron_size / 2 &&
    y > centerY - chevron_size / 2 &&
    y < centerY + chevron_size / 2
  if (isLeftChevronClicked) {
    onArrowClicked(chart, 'left', chartSpan)
  }
  if (isRightChevronClicked) {
    onArrowClicked(chart, 'right', chartSpan)
  }
}

const drawChevrons = (
  ctx: CanvasRenderingContext2D,
  centerY: number,
  color: string
) => {
  ctx.beginPath()
  ctx.moveTo(5, centerY)
  ctx.lineTo(15, centerY - 10)
  ctx.lineTo(15, centerY - 5)
  ctx.lineTo(10, centerY)
  ctx.lineTo(15, centerY + 5)
  ctx.lineTo(15, centerY + 10)
  ctx.closePath()

  ctx.globalAlpha = 0.35
  ctx.fillStyle = color
  ctx.fill()
}

const drawFocusedHighlight = (
  chart: Chart,
  pluginOptions: HistoricChartOptions
) => {
  const { barIndex, fontSize, bloomRadius, focusLabel, theme } = pluginOptions
  const {
    ctx,
    chartArea: { top, bottom, right, left },
  } = chart
  const bar = chart.getDatasetMeta(0).data[barIndex] as any

  if (!bar) {
    return
  }
  // Save the current canvas state
  ctx.save()

  // Create bar frame for the bloom effect
  // Apply the gradient as a shadow on the canvas
  ctx.fillStyle = bar.options.backgroundColor
  ctx.shadowBlur = bloomRadius
  ctx.shadowColor = theme.palette.text.primary

  ctx.fillRect(bar.x - bar.width / 2, bar.y, bar.width, bar.height)

  ctx.font = `${fontSize}px sans-serif`
  ctx.fillStyle = theme.palette.text.secondary
  ctx.textAlign = 'center'
  ctx.shadowBlur = 0
  const focusLabelText = focusLabel()
  var lineheight = 15
  var lines = focusLabelText.split('\n')

  for (var i = 0; i < lines.length; i++) {
    ctx.fillText(lines[i], bar.x, bar.yMax - 30 + i * lineheight)
  }

  ctx.clearRect(0, top - 50, left, bottom + 50)
  ctx.clearRect(right, top - 50, 50, bottom + 50)

  // Restore the canvas state
  ctx.restore()
}

const drawLabelsAndSubLabels = (
  chart: Chart,
  pluginOptions: HistoricChartOptions
) => {
  const { scrollingLabelFormat, theme } = pluginOptions
  const {
    ctx,
    chartArea: { top, bottom, right, left },
    data: { labels },
  } = chart

  if (!labels) {
    throw new Error(
      'There are no labels and thus sub-labels cannot be created.'
    )
  }

  const dateLabels = labels as string[]

  // Prepare index sets by grouping the labels by their year.
  const { indexByYearBlock } = getYearIndexMapping(dateLabels)

  if (!indexByYearBlock) {
    throw new Error('Could not group the labels.')
  }

  const boxHeight = labelAreaHeight / 2
  const boxBottom = bottom
  const fontSize = 12

  for (const indexes of indexByYearBlock) {
    // get the first and last index for the key.
    if (!indexes) continue
    const subLabelMin = indexes[0]
    const subLabelMax = indexes?.slice(-1)[0]

    // get the x position of the canvas for each of the index.
    const barThickness = (chart.getDatasetMeta(0).data[subLabelMin] as any)
      .width
    const positionXMin = chart.getDatasetMeta(0).data[subLabelMin].x
    const positionXMax = chart.getDatasetMeta(0).data[subLabelMax].x
    const boxWidth = positionXMax - positionXMin
    const centerPositionX = positionXMin + boxWidth / 2

    const formattedDatapointLabels = dateLabels.map(scrollingLabelFormat)

    ctx.save()

    ctx.globalAlpha = 0.35
    ctx.font = `${fontSize}px sans-serif`
    ctx.fillStyle = theme.palette.text.secondary
    ctx.textAlign = 'center'
    // Move the labels into the chart so it scrolls.
    for (var i = 0; i < formattedDatapointLabels.length; i++) {
      const positionX = chart.getDatasetMeta(0).data[i].x
      ctx.fillText(
        formattedDatapointLabels[i],
        positionX,
        boxBottom + (boxHeight - fontSize / 2)
      )
    }

    ctx.restore()

    // Build spanning box.
    ctx.fillStyle = 'rgba(102, 102, 102, 0.8)'
    ctx.fillRect(
      positionXMin - barThickness / 2,
      boxBottom + boxHeight,
      boxWidth + barThickness,
      boxHeight
    )

    ctx.restore()

    // Add year to center of box.
    ctx.globalAlpha = 0.8
    ctx.font = `${fontSize}px sans-serif`
    ctx.fillStyle = theme.palette.text.secondary
    ctx.textAlign = 'center'
    ctx.fillText(
      new Date(dateLabels[indexes[0]]).getFullYear().toString(),
      centerPositionX,
      boxBottom + (boxHeight * 2 - fontSize / 2)
    )

    ctx.restore()
  }

  // Prevents the sublabels from appearing to be off of the chart.
  ctx.clearRect(0, bottom + 5, left, top + 5)
  ctx.clearRect(right, bottom + 5, 50, top + 5)

  ctx.restore()
}
