import { createSelector } from 'reselect'
import { selectSelectedLegendProductId } from '../../data/selectSelectedLegendProductId'
import { selectSelectedMapLayersByLayerDefId } from '../../data/selectSelectedMapLayersByLayerDefId'

export const selectHistoricalCalculationProperties = createSelector(
  [selectSelectedMapLayersByLayerDefId, selectSelectedLegendProductId],
  (selectedMapLayersById, selectedLegendProductId) =>
    selectedMapLayersById[selectedLegendProductId]?.mapLayerDef
      ?.historicalChartCalculationProperties
)

export const selectHistoricalCalculationType = createSelector(
  [selectSelectedMapLayersByLayerDefId, selectSelectedLegendProductId],
  (selectedMapLayersById, selectedLegendProductId) =>
    selectedMapLayersById[selectedLegendProductId]?.mapLayerDef
      ?.historicalChartCalculationType
)
