import { RateMapContextState } from '../editor/RateMapContext'
import { createSelector } from 'reselect'

const getRateMapFlightDate = (state: RateMapContextState) =>
  state.rateMap?.flightDate

export const selectRateMapFlightDate = createSelector(
  getRateMapFlightDate,
  (rateMapFlightDate) => rateMapFlightDate
)
