const hexToRgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return { r, g, b }
}

const rgbToHex = (r: number, g: number, b: number) => {
  const toHex = (c: number) => {
    const hex = c.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

type Interpolation = 'linear' | 'sigmoid'

const linearInterpolation = (start: number, end: number, t: number) =>
  Math.ceil(start + t * (end - start))

const sigmoidInterpolation = (start: number, end: number, t: number) =>
  Math.ceil(start + (1 / (1 + Math.exp(-t))) * (end - start))

const generateGradient = (
  colors: [string, string],
  steps: number,
  interpolationFn: Interpolation = 'linear'
) => {
  const gradient = []
  const start = colors[0]
  const end = colors[1]
  const startColor = hexToRgb(start)
  const endColor = hexToRgb(end)
  switch (interpolationFn) {
    case 'linear':
      for (let i = 0; i < steps; i++) {
        const t = i / steps
        const r = linearInterpolation(startColor.r, endColor.r, t)
        const g = linearInterpolation(startColor.g, endColor.g, t)
        const b = linearInterpolation(startColor.b, endColor.b, t)
        gradient.push(rgbToHex(r, g, b))
      }
      return gradient
    case 'sigmoid':
      for (let i = 0; i < steps; i++) {
        const t = i / steps
        const r = sigmoidInterpolation(startColor.r, endColor.r, t)
        const g = sigmoidInterpolation(startColor.g, endColor.g, t)
        const b = sigmoidInterpolation(startColor.b, endColor.b, t)
        gradient.push(rgbToHex(r, g, b))
      }
      return gradient
  }
}

const generateMultiGradient = (
  colors: string[],
  stepsBetweenColors: number,
  interpolationFn: Interpolation = 'linear'
): string[] => {
  const gradient = []
  for (let i = 0; i < colors.length - 1; i++) {
    const colorGradient = generateGradient(
      [colors[i], colors[i + 1]],
      stepsBetweenColors,
      interpolationFn
    )
    gradient.push(...colorGradient)
  }
  return gradient
}

// resample the gradient, adjusting the colors to fit the new size
const resampleGradient = (gradient: string[], size: number): string[] => {
  const newGradient = []
  const step = gradient.length / size
  for (let i = 0; i < size; i++) {
    if (i === 0) {
      newGradient.push(gradient[0])
      continue
    }
    if (i === size - 1) {
      newGradient.push(gradient[gradient.length - 1])
      continue
    }
    const index = Math.round(i * step)
    newGradient.push(gradient[index])
  }
  return newGradient
}

export const color = {
  hexToRgb,
  rgbToHex,
  generateGradient,
  generateMultiGradient,
  reduceGradient: resampleGradient,
}
