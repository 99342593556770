import { Info, KeyboardArrowDown } from '@mui/icons-material'
import {
  Collapse,
  List,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import * as React from 'react'
import i18n, { keys } from '../../../../../i18n'
import DataLayerMenuItem from '../../../../../legend/DataLayerMenuItem'
import { selectAvailableProductsByGroup } from '../../../../../data/selectAvailableProducts'
import { useRedux } from '../../../../../hooks/useRedux'
import { MapLayerDefData } from '../../../../../data/types'
import { useRateMapContext } from '../../RateMapContext'
import { pureVineZonesLayerID, vigorZonesLayerID } from '../../../constants'

import SoilLayerToggle from '../../../../../postgis/SoilLayer/SoilLayerToggle'
import ElevationLayerToggle from '../../../../../postgis/ElevationLayer/ElevationLayerToggle'

export const SupplementalDataLayers = () => {
  const [state] = useRedux()
  const [expanded, setExpanded] = React.useState(false)
  const { rateMap, setModifiedRateMap } = useRateMapContext()
  const availableProducts = selectAvailableProductsByGroup(state)

  const handleExpand = React.useCallback(() => {
    setExpanded((prev) => !prev)
  }, [])

  const handleLayerToggle = React.useCallback(
    (product: MapLayerDefData) => {
      if (rateMap) {
        const newAdditionalLayers = [...(rateMap.RateMapAdditionalLayers ?? [])]
        const index = newAdditionalLayers.findIndex(
          (l) => l.layerId === product.id
        )

        if (index > -1) {
          newAdditionalLayers[index] = {
            ...newAdditionalLayers[index],
            enabled: !newAdditionalLayers[index].enabled,
          }
        } else {
          newAdditionalLayers.push({
            rateMapId: rateMap.id,
            layerId: product.id,
            enabled: true,
          })
        }

        setModifiedRateMap?.({
          ...rateMap,
          RateMapAdditionalLayers: newAdditionalLayers,
        })
      }
    },
    [rateMap?.RateMapAdditionalLayers, setModifiedRateMap, rateMap]
  )

  return (
    <Stack gap={1} sx={{ width: '100%' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={handleExpand}
        sx={{ cursor: 'pointer' }}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '16px',
              letterSpacing: '-3.5%',
              lineHeight: '19.2px',
            }}
          >
            {i18n.t(keys.rateMapSupplementalDataLayersTitle)}
          </Typography>
          <Tooltip
            title={i18n.t(keys.rateMapSupplementalDataLayersDescription)}
          >
            <Info sx={{ fontSize: '14px' }} />
          </Tooltip>
        </Stack>

        <KeyboardArrowDown />
      </Stack>

      <Collapse in={expanded}>
        {Object.values(availableProducts).length > 0 ? (
          <List dense>
            <MenuItem divider disabled>
              <ListItemText
                style={{ paddingLeft: 0 }}
                primary={i18n.t(keys.legend.selectActiveLayerMenuTitle)}
              />
            </MenuItem>
            {Object.keys(availableProducts).map((groupId) => {
              const [product] = availableProducts[groupId]
              if (
                product.id === pureVineZonesLayerID ||
                product.id === vigorZonesLayerID
              ) {
                return null
              }
              return product ? (
                <DataLayerMenuItem
                  product={product}
                  key={product.id}
                  onLayerToggled={handleLayerToggle}
                  onExclusiveLayerSelect={handleLayerToggle}
                />
              ) : null
            })}
            <hr />
            <SoilLayerToggle />
            <ElevationLayerToggle />
            <hr />
          </List>
        ) : (
          <Typography>
            {i18n.t(keys.rateMapNoSupplementalDataLayers)}
          </Typography>
        )}
      </Collapse>
    </Stack>
  )
}
