import * as React from 'react'
import { useRedux } from '../../../hooks/useRedux'

import { selectMapLayerDefsById } from '../../../data/selectListMapSourceDefs'
import { selectPreferredLanguage } from '../../../data/selectPreferredLanguage'
import { selectProductStats } from '../../../ProductStats/selectors/selectProductStats'
import {
  selectBestUnitsByProductId,
  selectConvertedSelectedColorProfiles,
} from '../../../ProductStats/selectors/stats'
import { useRateMapContext } from '../editor/RateMapContext'
import { selectRateMapVigorZoneProductId } from '../selectors/selectRateMapVigorZoneProductId'
import { toStopData } from '../../../ProductStats/toStopData'
import { VisualizationMode } from '../../../ProductSettings/store/types'

export const useRateMapStopData = () => {
  const [state] = useRedux()
  const rateMapState = useRateMapContext()

  const { rateMap } = rateMapState
  const lang = selectPreferredLanguage(state)
  const productId = selectRateMapVigorZoneProductId(state)
  const productStats = selectProductStats(state)[productId]
  const selectedColorProfiles = selectConvertedSelectedColorProfiles(state)
  const productSelectedColorProfiles = selectedColorProfiles?.[productId]
  const productDef = selectMapLayerDefsById(state)[productId]
  const preferredUnitSystem = state.preferences.preferredUnitSystem
  const unit = selectBestUnitsByProductId(state)[productId]
  const stops = React.useMemo(() => {
    const colorProfile =
      productSelectedColorProfiles &&
      (productSelectedColorProfiles[
        (rateMap?.mode ?? 'absolute') as VisualizationMode
      ] ||
        productSelectedColorProfiles.absolute ||
        productSelectedColorProfiles.relative ||
        productSelectedColorProfiles.threshold)

    const { stops } = toStopData({
      lang,
      unit,
      stats: productStats,
      preferredUnitSystem,
      colorProfile: colorProfile as any,
      layerDef: productDef,
    })

    return stops
  }, [
    productStats,
    lang,
    preferredUnitSystem,
    unit,
    productDef,
    productSelectedColorProfiles,
    rateMap?.mode,
  ])

  return stops
}
