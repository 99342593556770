import { MRT_TableInstance } from 'material-react-table'
import * as React from 'react'
import { Stack, styled } from '@mui/material'
import { SamplePlanRow } from './types/SamplePlanRow'
import { NewSamplePlanButton } from './NewSamplePlanButton'

interface Props {
  table: MRT_TableInstance<SamplePlanRow>
}

const OptionContainer = styled(Stack)({
  paddingTop: 10,
  paddingBottom: 25,
  paddingLeft: 10,
})

export const SamplePlanToolBarOptions = (_props: Props) => {
  return (
    <OptionContainer>
      <NewSamplePlanButton variant="outlined" />
    </OptionContainer>
  )
}
