import { Translations } from './keys'

export const fr: Translations = {
  notificationsTitle: 'Notifications par email',
  notificationsManage: 'Gérer les notifications par e-mail',
  notifications: {
    'order|confirmation': {
      subscribeText: 'Recevoir des e-mails de confirmation de commande.',
      subscriptionDescription:
        'Ces e-mails seront envoyés une fois que nous aurons traité votre commande.',
    },
    'target-delivery|flight-notice': {
      subscribeText: "Recevez des e-mails d'avis de vol.",
      subscriptionDescription:
        "Ces e-mails seront envoyés deux semaines avant la semaine d'un vol prévu.",
    },
    'delivery|flight-complete': {
      subscribeText: 'Recevez des e-mails de fin de vol.',
      subscriptionDescription:
        "Ces e-mails seront envoyés après la fin d'un vol, vous informant que vos données seront disponibles dans les prochains jours.",
    },
    'delivery|data-available': {
      subscribeText: 'Recevoir les données disponibles par e-mail.',
      subscriptionDescription:
        "Ces e-mails seront envoyés lorsque vous pourrez afficher les données dans l'application.",
    },
  },

  theme: { light: 'Clair', dark: 'Sombre', themePicker: 'Choisissez le thème' },
  selectDate: 'Sélectionner une date',
  forSelectedParcels: 'Pour les parcelles sélectionnées',
  forOtherParcels: 'Pour les autres parcelles',
  productsText: 'Des produits',
  beta: 'Bêta',
  betaMessage:
    "Veuillez noter que vous travaillez maintenant dans la version bêta de l'application Vineview",
  errors: {
    somethingWentWrong: `Une erreur s'est produite`,
    tapToReload: 'Cliquer pour recharger',
    reportAnIssue: 'Signaler un problème',
  },

  user: {
    user: 'Utilisateur',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    password: 'Mot de passe',
    organization: 'Équipe',
    confirmPassword: 'Confirmez le mot de passe',
    email: 'Email',
    switchOrganization: `Changer d'équipe`,
    role: 'Rôle',
  },

  focusedBlockDialog: {
    fieldInformation: 'Informations sur la parcelle',
    name: 'Nom',
    area: 'Surface',
    varietals: 'Cépage',
    rootstock: 'Porte-greffe',
    trellisType: 'palissage',
    EVImean: 'EVI signifie',
    EVIstdev: 'EVI Ecart Type',
    canopyArea: 'Canopy Area',
    CWCmean: 'CWC moyenne',
    undetectedVines: 'Pieds non détectés',
    totalVines: 'Nombre total de pieds',
    rowSpacing: 'Écartement des rangs',
    vineSpacing: 'Espacement des vignes',
  },

  soilLayer: {
    soilInformation: 'Informations sur le sol',
    layerToggle: 'Couche sol',
  },
  elevationLayer: {
    layerToggle: 'Couche topographique',
  },
  forms: {
    notNull: 'Champs obligatoires',
    confirmation: '{{field}} devrait correspondre à {{otherField}}',
    isUnique: 'déjà utilisé',
    login: {
      login: `S'identifier`,
      loginError: 'Erreur lors de la connexion',
      passwordRequired: `Veuillez entrez votre mot de passe`,
      emailRequired: `Veuillez saisir votre adresse e-mail`,
      notChromeWarning:
        'REMARQUE: VineView est optimisé pour les navigateurs basés sur Chrome. Si vous rencontrez des problèmes',
      emailOrPasswordIncorrect: `La connexion a échoué. Votre nom d'utilisateur ou votre mot de passe ne correspondent pas.`,
    },
    password: {
      lowercase: 'Le mot de passe doit contenir au moins une lettre minuscule',
      uppercase: 'Le mot de passe doit contenir au moins une lettre majuscule',
      number: 'Le mot de passe doit contenir au moins un numéro',
      symbol: 'Le mot de passe doit contenir au moins un symbole',
      length: 'Le mot de passe doit contenir au moins 6 caractères',
    },
    resetPassword: {
      title: 'Réinitialiser le mot de passe',
      submit: 'Réinitialiser mon mot de passe',
    },
    forgotPassword: {
      title: 'Mot de passe oublié',
      submit: `Envoyer l'e-mail de réinitialisation du mot de passe`,
      forgotMessage: `Entrez votre adresse e-mail`,
      requestedMessage: `Vous allez recevoir un lien par mail pour réinitialiser
        votre mot de passe. Si vous ne le recevez pas immédiatement`,
    },
    invalidToken: 'Jeton non valide',
    done: 'Effectué',
    parcelFilter: {
      advancedFilters: 'Filtres avancés',
      deliveriesFor: 'Filtrer par emplacement:',
      selectDelivery: 'Filtrer par date:',
      filter: 'Filtrer',
      search: 'Filtrer par nom',
      varietal: 'Cépage',
      fromDate: 'Date début',
      toDate: 'Date fin',
      noDate: 'Aucun',
      today: `Aujourd'hui`,
      sort: 'Trier les groupes par',
      sortAlpha: 'Alphabétique',
      sortTime: 'Heure de saisie des données',
      resetSelection: 'Réinitialiser la sélection',
      rootstock: 'Porte-greffe',
      trellisType: 'Palissage',
      noVarietals: 'Cépage non renseigné',
      noRootstocks: 'Porte Greffe non renseigné',
      noTrellises: 'Palissage non renseigné',
    },
  },
  colorScheme: {
    chooseColorSchemeButton: 'Choisissez les couleurs',
    chooseColorScheme: 'Choisissez le Profil des couleurs',
    revertToDefaults: 'Revenir aux profils de couleur par défaut',
    redYellowGreen: 'Rouge jaune vert',
    recommended: 'Recommandé par VineView',
    recommendedEVI: 'Légende EVI recommandée par VineView',
    allBlues: 'Tous les bleus',
    redYellowBlue: 'Bleu jaune rouge',
    cyanToRed: 'Cyan au rouge',
    showHealthyAsGreen: 'Afficher les pieds sains en vert',
    showSymptomaticAsRed: 'Afficher les pieds symptômatiques en rouge',
    showPresentAsGreen: 'Afficher les pieds présents en vert',
    relativeThermal: 'Thermique Relative',
    missingVines: 'Pieds manquants',
    pureVineDefault: 'Par défaut',
    ceviDefault: 'CEVI par défaut',
    legacy: 'Héritage',
    redleafDefault: 'RedLeaf par défaut',
  },
  legend: {
    selectActiveLayerMenuTitle: 'Données Cartographiques',
    changeActiveProductTooltip: 'Sélectionner des données cartographiques',
    changeColorSchemeTooltip: 'Modifier les profils de couleur',
    scaleSwitcherTooltip:
      'Basculer entre les profils de couleurs absolus et relatifs',
    isolateColorTooltip: "Appuyez sur une couleur pour l'isoler sur la carte.",
    filterTooltip: 'Option de Filtrage des Données',
    clearFilter: 'Effacer Filtre',
    colorLegend: 'Légende',
    noLegend: 'Pas de Légende',
    noDataSelected: 'Aucune Donnée Sélectionnée',
    scale: {
      absolute: 'absolu',
      relative: 'relatif',
    },
  },
  logout: 'Se déconnecter',
  logoutConfirm: 'Êtes-vous sûr de vouloir vous déconnecter?',
  serverError: 'Erreur de serveur',
  cursorLon: 'lng: {{lng}}',
  cursorLat: 'lat: {{lat}}',
  generic: {
    any: 'Tout',
    alertConst: 'Alerte',
    back: 'Précédent',
    cancel: 'Annuler',
    clear: 'Effacer',
    close: 'Fermer',
    confirm: 'Confirmer',
    continue: 'Continuer',
    copied: 'Copié!',
    copy: 'Copier',
    create: 'Créer',
    created: 'Créé',
    csv: 'CSV',
    shapefile: 'Shapefile',
    geojson: 'GeoJSON',
    delete: 'Supprimer',
    duplicate: 'Dupliquer',
    download: 'Télécharger',
    downloadFormat: 'Format de téléchargement',
    edit: 'Modifier',
    expires: 'Expire',
    export: 'Exporter',
    help: 'Aider',
    high: 'Haute',
    loading: 'Chargement',
    low: 'Faible',
    na: 'Non Disponible',
    next: 'Suivant',
    no: 'non',
    noThanks: 'Non merci',
    noData: 'Aucune donnée disponible.',
    none: 'Aucun',
    ok: "D'accord",
    refresh: 'recharger',
    remove: 'Supprimer',
    reset: 'réinitialiser',
    retry: 'Réessayer',
    save: 'Enregistrer',
    selectAll: 'Sélectionner Tout',
    update: 'Réactualiser',
    view: 'Afficher',
    xlsx: 'XLSX',
    pdf: 'PDF',
    yes: 'oui',
    unavailable: 'Indisponible',
    name: 'Nom',
    observations: 'Observations',
    average: 'Moyenne',
    successLevels: {
      none: '-',
      low: 'Médiocre',
      fair: 'Moyen',
      good: 'Bon',
      excellent: 'Excellent',
    },
    learnMore: 'En savoir plus',
    noItems: 'Aucun élément',
    on: 'Sur',
    off: 'Éteint',
    move: 'Déplacer',
  },
  attachments: {
    attachments: 'Pièces jointes',
    chooseFile: 'Choisir un fichier',
    fileLimitWarning: 'Limite de 10 fichiers atteinte',
  },
  gis: {
    area: 'Superficie',
    zone: 'Zone',
    canopyArea: 'CA',
    undetectedVine: 'Pied non détecté',
  },
  toggleStats: 'Basculer les statistiques pour {{product}}',
  products: {
    leafroll_high: 'Hautement symptomatique',
    leafroll_low: 'Aucun symptôme',
  },
  productNames: {
    GRAY_SCALE: 'Niveaux de gris',
    TRUE_COLOR: 'Couleurs Réelles',
    CIR: 'Infrarouge',
    EVI_LEGACY: 'EVI calibré - Héritage (Absolue)',
    EVI_LEGACY_RELATIVE: 'EVI calibré - Héritage (Relative)',
    RELATIVE_THERMAL_RASTER: 'Thermique Relative',

    CANOPY_GAP: 'Trous de Végétation',

    VIGOR_ZONE: 'Zones de Vigueur Calibrées',
    PURE_VIGOR_ZONE: 'Zones Pro',
    RELATIVE_THERMAL_ZONE: 'Zones Thermiques Relatives',

    EVI_POINT: 'Pied par Pied',
    WATER_INDEX: 'Teneur en Eau Calibrée',
    LEAFROLL: 'PureVine Leafroll',
    LEAFROLL_RASTER: 'Leafroll Relative',
    MISSING_VINE: 'Pieds Manquant',
    FD: '',
    RED_LEAF: 'PureVine RedLeaf',
    RED_LEAF_RASTER: 'Image RedLeaf',
  },
  productDescriptions: {
    EVI_POINT: `Couche d'Indice de Végétation Améliorée (EVI) à l'échelle du pied. Toutes les données de l'interrang
    ont été supprimées`,
    VIGOR_ZONE: `Zones générées pour mettre en évidence les tendances générales
    de la vigueur sur le terrain. Les données de l'interrang sont extraites`,
    PURE_VIGOR_ZONE: `Zones générées pour mettre en évidence les tendances de
    vigueur sur le terrain. Toutes les données relatives au sol et aux cultures
    de couverture ont été retirées des calculs.`,
    EVI_LEGACY_RELATIVE: `Couche de l'indice de végétation amélioré montrant
    les tendances générales de la vigueur`,
    EVI_LEGACY: `Couche de l'indice de végétation amélioré montrant
    les tendances générales de la vigueur`,
    CANOPY_GAP: `Mesures de la superficie de la canopée à intervalles de 50 cm
    le long de chaque rang de vignes.`,
    TRUE_COLOR: `Image aérienne haute résolution.
    Format couleurs vraies`,
    CIR: `Image aérienne haute résolution.
    Format infrarouge couleur`,
    GRAY_SCALE: 'Niveaux de gris',
    LEAFROLL: 'Leafroll',
    LEAFROLL_RASTER: 'Raster Leafroll',
    WATER_INDEX: `Mesures de la teneur en eau de la canopée (CWC) représentée à l'échelle du pied`,
    TEMPERATURE_ZONE: `Zones générées pour mettre en évidence les tendances de
    la température sur le terrain`,
    RELATIVE_THERMAL_ZONE: `Zones générées pour mettre en évidence les
    tendances relatives de la température sur le terrain`,
    RELATIVE_THERMAL_RASTER: `Image aérienne thermique à haute résolution. Format relatif pour mettre en
    évidence les tendances de température`,
    MISSING_VINE: `Une classification précise des pieds manquants`,
    FD: '',
    RED_LEAF: `Valeurs RedLeaf visualisées dans un format pied par pied.
      Pour chaque cépage`,
    RED_LEAF_RASTER: `Représentation visuelle de l'endroit où la canopée
      a été détectée plus rouge que vert. L'objectif est de localiser la présence des feuilles zouges`,
  },
  valueNames: {
    GRAY_SCALE: '',
    TRUE_COLOR: '',
    CIR: 'Couleur Infrarouge',
    EVI: 'Indice de Végétation Amélioré',
    Thermal: 'Thermique Relatif',
    CanopyGap: 'Trous de végétation',
    CWC: 'Teneur en Eau de la Canopée',
    LR_Score: "Probabilté de présence de Virose de l'Enroulement",
    MV_Class: 'Pied Manquant',
    FD: '',
    RedMean: 'Indice Redleaf',
    PercentRed: 'Couverture',
    Area: 'Surface',
    PercentLR: 'Couverture',
    RowID: 'Rang VineView',
    PlantID: 'Pied',
  },
  valueAcronyms: {
    GRAY_SCALE: '',
    TRUE_COLOR: '',
    CIR: 'CIR',
    EVI: 'EVI',
    Thermal: '',

    CanopyGap: 'CG',

    CWC: 'CWC',
    LR_Score: 'LR',
    MV_Class: 'MV',
    FD: 'FD',
    RedMean: 'RLI',
    PercentRed: '',
    PercentLR: 'LR',
  },
  map: {
    map: 'Carte',
    selectAField: 'Sélectionnez une parcelle',
    allParcels: 'toutes les {{totalParcels}} parcelles',
    someParcels: '{{selectedParcels}} sur {{totalParcels}} parcelles',
    resetBearing: 'Réinitialiser la Vue',
    mapSettings: 'Paramètres de la Carte',
  },
  customLayers: {
    editLayers: 'Modifier les calques',
  },
  parcelDrawer: {
    ungrouped: 'Dégroupé',
    unknownList: 'Inconnu',
    mostRecentDate: 'Date des données les plus récentes',
    noDataForCurrentDate: 'Aucune donnée disponible pour la date actuelle',
    selectParcels: 'Sélectionnez parcelles',
    noResults: 'Aucun résultat',
    unavailableForSelectedDate: 'Indisponible pour la date sélectionnées',
  },
  notes: {
    failedToSave: 'La sauvegarde de la note a échoué',
    failedToLoad: 'Le chargement de la note a échoué',
    failedToDelete: 'La suppression de la note a échoué',
    showOnMap: 'Afficher sur la carte',
    pinColor: `Épingle de couleur`,
    filter: {
      search: 'Remarques de recherche',
      fromDate: 'Partir de la date',
      toDate: 'À ce jour',
      allTemplates: 'Tous les modèles',
      allProjects: 'Tous les projets',
    },
    general: {
      placeholder: 'Ajouter un commentaire...',
    },
    editLocationSnackbar: 'Modifier la forme. Puis cliquez',
    noteSaved: 'Note enregistrée',
    noteDelete: 'Note déplacée et archivée',
    undo: 'Annuler',
    notesPopup: {
      save: 'Sauvegarder',
      note: 'Remarques',
      location: {
        location: 'Emplacement',
        currentLocation: 'Localisation actuelle',
        pointOnMap: 'Point sur la carte',
        areaOnMap: 'Zone sur la carte',
      },

      deleteConfirmTitle: `Confirmer l'effacement`,
      deleteConfirmMessage:
        'Effacer cette note effacera définitivement toutes données associées',
      saveLocation: 'Enregistrer la position',
      updateLocation: `Mettre à jour l'emplacement maintenant`,
      noteType: 'Type de note',
      createAnother: 'Créer un autre',
      editLocation: `Modifier l'emplacement`,
      currentLocationAgo: {
        unavailable: 'Indisponible',
        lastUpdated: 'Dernière mise à jour',
      },
      newNote: 'Nouvelle Note',
      editNoteType: 'Modifier {{noteType}} Remarque',
      selectLocation: `Sélectionnez l'emplacement`,
      pointInstruction:
        "Veuillez activer l'accès à la localisation dans vos paramètres pour afficher votre position actuelle",
      polygonInstruction: `Cliquez sur la carte pour placer des points. Cliquez sur un point existant pour terminer le polygone. Double-cliquez sur un point pour modifier la forme.`,
      jumpToCurrentLocation: `Aller à l'emplacement actuel`,
    },
    createdBy: `Créé par: {{firstName}}`,
    updatedBy: `Mis à jour par: {{firstName}}`,
    export: 'Exporter',
    fileName: 'Nom de fichier',
    fileNameValidation: 'Veuillez entrer un nom de fichier valide',
    exportBoxTitle: 'Enregistrer les Notes',
    noNotes: 'Pas de notes disponibles dans le projet',
    downloadImagesTitle: 'Télécharger des images',
    downloadImagesDescription: 'Etes-vous sûr de vouloir télécharger',
    deleteImageTitle: `Supprimer l'image`,
    deleteImageDescription: 'Êtes-vous sûr de vouloir supprimer 1 fichier?',
    deleteImagesTitle: 'Supprimer des Images',
    deleteImagesDescription: 'Etes-vous sûr de vouloir supprimer',
    file: 'ficher',
    files: 'fichiers',
    Files: 'Fichiers',
    projects: 'Projets',
  },
  layerDrawer: {
    fieldBoundaries: 'Limites de parcelles',
    fieldLabels: 'Noms des parcelles',
    notes: 'Afficher les Notes',
    toggleScaleBar: `Échelle`,
    toggleCursorLocation: 'Emplacement du curseur',
    backgroundRaster: 'Arrière-plan',
    numberOfGroups: 'Groupes',
    value: 'plages de valeurs',
    area: 'zones égales',
    downloadDialog: {
      title: 'Télécharger la carte',
      description: 'Choisissez une option de téléchargement',
      downloadPNG: 'Télécharger PNG',
      printMap: 'Imprimer',
      exportMapHeading: 'Télécharger la carte',
    },
  },
  userPosition: {
    mapToolbarTooltip: 'Suivez ma position',
    error: {
      missingPermission: 'Autorisations manquantes pour accéder à la position',
      positionNotAvailable: `Votre position n'est pas disponible`,
      unknown: `Impossible de récupérer votre position`,
    },
  },
  preferences: 'Préférences',
  clearSettings: 'Effacer les données',
  clearSettingsDescription:
    'Cela réinitialise tous les paramètres à leurs valeurs par défaut.',
  darkMode: 'Mode Sombre',
  fieldsTab: 'Parcelles',
  rateMapTab: 'Paramétrage de la Carte de Modulation',
  notesTab: 'Remarques',
  colorsTab: 'Couleurs',
  layersTab: 'Couches',
  settingsTab: 'Réglages',
  zoomToSelection: 'Zoomer sur la sélection',
  demo: 'Démo',
  noteForm: {
    failedToLoad: 'Le chargement des modèles a échoué',
    template: 'Modèle de note',
    templateTitle: 'Modèle',
    reservedNames: {
      pinpoint: {
        title: 'Capture du bouton PinPoint',
        button: 'Bouton',
        double: 'Double-clic',
        horizontalAccuracy: 'Précision horizontale',
        numSV: 'Nombre de satellites',
        altitude: 'Altitude',
        hmsl: 'Hauteur au-dessus du niveau de la mer moyenne',
        fixType: 'Type de fixation',
        date: 'Date / heure',
        label: 'Étiquette',
      },
      note: 'Remarque',
      __VV__GENERAL__TEMPLATE__: 'Générale',
      __VV_PINPOINT_CAPTURE__: 'Capture du bouton PinPoint',
      __VV__VINEYARD_PRODUCTION_INSPECTION__:
        'Inspection de la Production du Vignoble',
      __VV__PRODUCTION_STATUS__: 'Statut de la production',
      __VV__PRODUCING__: 'Fécond',
      __VV__NONPRODUCING__: 'Stérile',
      __VV__ROOTSTOCK__: 'Porte-Greffe',
      __VV__MISSING__: 'Manquant',
      __VV__IRRIGATION_SUPPLY__: `Provision d'irrigation`,
      __VV__PRIMARY__: 'Primaire',
      __VV__SECONDARY__: 'Secondaire',
      __VV_NONE__: 'Aucune',
      __VV__NA__: 'N/A',
      __VV__VIRUS_STATUS__: 'État des infections',
      __VV__POWDERY_MILDEW__: 'Oïdium',
      __VV__DOWNY_MILDEW__: 'Mildiou',
      __VV__BOTRYTIS__: 'Botrytis',
      __VV__PIERCES__: 'Maladie de Pierce',
      __VV__ESCA__: 'Esca',
      __VV__LEAFROLL__: 'Enroulement de la Vigne',
      __VV__RED_BLOTCH__: 'Tache Rouge',
      __VV__DISEASE_INSPECITON__: 'Inspection des Infections',
      __VV__CLUSTER_COUNT__: 'Compte des Grappes',
      __VV__SHATTER_PRESENCE__: 'Présence de Coulure',
      __VV__CLUSTERS_INFECTED__: 'Grappes infectées',
      __VV__DISEASE_OBSERVED__: 'Infection Observée',
      __VV__SAMPLE_TAKEN__: 'Échantillion Relevé',
      __VV__CANOPY_SYMPTOMATIC__: 'Canopée Symptomatique',
      __VV__SHATTER__: 'Coulure',
      __VV_PERCENTAGE__: 'Pourcentage',
      __VV_BLACK_ROT__: 'Pourriture Noire',
      __VV_LEAF_SPOT__: 'Excoriose',
      __VV_CLUSTER_WEIGHT__: 'Poids de la Grappe',
    },
    errors: {
      errorDeleting: 'La suppression du modèle a échoué',
      errorSaving: 'La sauvegarde du modèle a échoué',
      templateHasErrors: `Le modèle contient des erreurs. Veuillez vérifier s'il n'y a pas d'erreur`,
      atLeastOneField: 'Veuillez indiquer au moins un champ pour le modèle',
      mustDefineOptions: 'Le modèle doit définir les options',
      fieldTitleRequired: 'Le nom du champ est requis',
      fieldTitleMustBeUnique: 'Le nom du champ doit être unique',
      formTitleRequired: 'Veuillez indiquer le titre du modèle',
      duplicateFormName: `Un modèle de ce nom existe déjà. Veuillez s'il vous plaît spécifier un autre nom`,
    },
    fieldTypes: {
      text: 'Texte',
      number: 'Nombre',
      dropdown: 'Dérouler',
      checkbox: 'Case à cocher',
      checkboxes: 'Cases à cocher',
    },
    manage: {
      title: 'Modèles',
      info: `Ici vous pouvez créer des modèles personnalisés. Ces modèles peuvent être utilisés.`,
      templateNameFilter: 'Nom du modèle...',
      columns: {
        templateName: 'Nom du modèle',
        createdDate: 'Date de création',
        createdBy: 'Créé par',
      },
      newTemplate: 'Nouveau modèle',
      dialogs: {
        delete: {
          title: 'Supprimer le modèle',
          message: 'Supprimer le modèle?',
        },
      },
    },
    addOption: 'Ajouter une option',
    titlePlaceholder: 'Titre...',
    newTemplate: 'Nouveau modèle',
    addField: 'Nouveau champ',
    addNew: 'Nouveau modèle de formulaire',
    toggleAdmin: 'Modèles',
    formName: 'Nom du modèle de note',
    propertyName: 'Nom du champ',
    propertyType: 'Type de champ',
    propertyEnum: 'Options valides',
    propertyEnumOptions: `Veuillez entrer les options sous forme de valeurs séparées
     par des virgules.`,
    newForm: 'Nouvelle forme',
    newProperty: 'Nouveau champ',
    showAsMenu: 'Afficher en tant que menu',
    addProperty: 'Ajouter un champ',
    deleteProperty: 'Supprimer champ',
    cantDeleteInUse:
      'Ce formulaire est actuellement utilisé et ne peut pas être supprimé.',
    note: 'Remarque',
    date: 'Date',
    text: 'Texte',
    number: 'Nombre',
    truefalse: 'Case à cocher',
    choices: 'Liste',
    formNameError: 'Veuillez saisir le nom du formulaire correctement.',
    propertyNameError: 'Entrez le nom de la propriété correctement.',
    formAndPropertyError:
      'Veuillez saisir le formulaire et le nom de la propriété correctement.',
    createNote: 'Créer une Note',
  },
  preview: 'Aperçu',
  areYouSure: 'Êtes-vous sûr?',
  confirmDelete: 'Êtes-vous sûr? Ça ne peut pas être annulé.',
  save: 'Enregistrer',
  delete: 'Effacer',
  formType: 'Type de note',
  dataDownload: {
    buttonText: 'Télécharger les données',
  },
  reports: {
    singular: 'Rapport',
    plural: 'Rapports',
    chooseDataProducts: 'Choisissez des données',
    includeStats: 'Inclure les statistiques',
    dataProducts: 'Données',
    noProducts: 'Aucune donnée trouvée',
    reportOptions: 'Options de rapport',
    includeBlockReports: 'Inclut rapport individuel pour chaque bloc',
    title: 'Rapport',
    print: 'Impression',
    preparedBy: 'préparé par VineView',
    preparedByOn: 'préparé par VineView le',
    flightDate: 'date de vol',
    whatsInside: `Qu'y a-t-il à l'intérieur`,
    client: 'Client',
    ranch: 'Château',
    vineSpacing: 'Distance entre les pieds',
    dataProduct: 'Produit',
    blockArea: 'Surface de la parcelle',
    detectedVines: 'Pieds détectés',
    undetectedVines: 'Pieds non détectés',
    areaNotInUse: 'Zone non utilisée',
    rows: 'Rangs',
    meanEVI: 'Valeur moyenne EVI',
    includeRelative: 'Inclure Relatif',
    showBlockBoundaries: 'Afficher les limites des parcelles',
    pages: 'Pages',
    flightInformation: 'Informations du Vol',
    blockInformation: 'Informations de la parcelle',
    groupPage: 'Par groupe',
    individualPages: 'Par parcelle',
    EVIcov: 'Coefficient de variation EVI',
    EVIstdev: 'Écart type EVI',
    EVImean: 'Moyenne EVI',
    PercentGaps: `Pourcentage d'écarts`,
  },

  visualization: {
    visualization: 'Visualisation',
    primary: 'Primaire',
    secondary: 'Secondaire',

    absolute: 'Absolu',
    relative: 'Relatif',
    threshold: 'Seuil',
    coverage: 'Sensibilité de',
    coverageMin: 'Couverture minimale',
    coverageMax: 'Couverture maximale',

    numberOfGroups: 'Classes',
    groupBy: 'Classer Par',
    equalAreas: 'Quantiles',
    valueRanges: 'Intervalles Egaux',

    opacity: 'Opacité',
    filter: 'Filtré',
    filterProperty: 'Propriétés du Filtre',
  },

  units: {
    unitSystemPicker: `Choisissez le système d'unités`,
    areaPicker: `Choisissez l'unité de surface`,
    areaLabel: 'Surface',
    unitSystem: {
      imperial: `Impérial`,
      metric: `Métrique`,
    },
    area: {
      hectare: 'Hectare',
      acre: 'Acre',
    },
    lengthPicker: `Choisissez l'unité de longueur`,
    lengthLabel: 'Longueur',
    length: {
      meter: 'Mètre',
      foot: 'Pieds',
    },

    perimiterLabel: `Périmètre`,

    plantCountLabel: 'Pieds',
    countLabel: 'Compter',
  },
  language: {
    languagePicker: 'Choisissez la langue',
  },

  statistics: {
    statisticsPicker: 'Choisir le format de statistiques par défaut',
    values: 'Valeurs',
    percentages: 'Pourcentages',
  },

  stats: {
    historic: 'Historique',
    current: 'Actuelle',
    organization: 'Équipe',
    group: 'Groupe',
    block: 'Bloc',
    date: 'Date',
    mean: 'Moyenne',
    stDev: 'Ecart Type',
    total: 'Total',
    undetected: 'Pieds Non détectés',
    coverage: 'Couverture ≤ {{coverage}}%',
    totalWithUndetected: 'Total comprenant les pieds non détectés',
    missing: 'Manquant',
    present: 'Présent',
    download: 'Télécharger',
    headerTitle: 'Statistiques {{productName}}',
    classes: {
      Missing: 'Manquant',
      0: 'Manquant',
      Present: 'Présent',
      1: 'Présent',
      Dead: 'Mort',
      2: 'Mort',
    },
    usage: 'Usage',
    usageTooltip: 'Pourcentage de champ avec des plantes actives',
    coefficientOfVariation: 'Variation',
    coefficientOfVariationTooltip:
      'Mesure relative de la variabilité dans un champ',
    acres: 'Acres',
    hectares: 'Hectares',
    average: 'Moyenne',
    currentSelection: 'Sélection\nactuelle',
    trendTooltip: 'Changement de {{stat}} depuis la dernière capture',
  },
  measure: 'Mesure',
  statsZones: 'Statistiques zonales',
  drawLine: 'Dessiner une ligne',
  drawPolygon: 'Dessiner un polygone',
  clearAll: 'Tout effacer',
  download: 'Télécharger',
  openInAvenza: 'Ouvrir dans Avenza Maps™',
  copyUrl: 'Copier le lien',
  copyFailed: 'Échec de la copie automatique',
  copyFailedDetail:
    'Utilisez cette boîte de dialogue pour sélectionner le texte suivant :',
  copySuccess: 'Texte copié dans le presse-papiers !',
  reprocessDownload: 'Retraiter le téléchargement',
  reprocessDownloadAll: 'Retraiter Tout télécharger',
  allFiles: 'tous les fichiers',
  downloadPending: 'Téléchargement en attente',
  downloadError: `La demande de téléchargement a échoué.
    Veuillez contacter notre équipe commerciale`,
  requestDownload: 'Demande de téléchargement',
  requestDownloadAll: 'Demander tout télécharger',
  confirmRequestDownload: 'Confirmer la demande de téléchargement',
  confirmRequestDownloadMessage:
    'Voulez-vous vraiment demander le téléchargement de {{filename}} ?',

  confirmReprocessDownload: 'Confirmer le retraitement du téléchargement',
  confirmReprocessDownloadMessage:
    'Voulez-vous vraiment retraiter {{filename}} pour le télécharger ?',
  downloadRequested: 'Téléchargement demandé',
  downloadRequestedCheckBack:
    'Téléchargement de {{filename}} demandé. Veuillez vérifier plus tard',

  downloadRequestError:
    'Erreur lors de la demande de téléchargement. Veuillez réessayer. Si le problème persiste, veuillez contacter notre équipe commerciale',
  downloadRequestErrorTitle: 'Erreur lors de la demande de téléchargement',
  dotSize: 'Taille de point',
  small: 'Petit',
  medium: 'Moyen',
  large: 'Gros',
  extraLarge: 'Extra gros',
  manageOrganization: `Gérer l'équipe`,
  removeUser: `Supprimer l'utilisateur`,
  uninviteUser: `Révoquer l'invitation`,
  resendInvite: `Renvoyer l'invitation`,
  addNewUser: 'Inviter un nouvel utilisateur',
  add: 'Ajouter',
  confirmUpdateUser: `Voulez vous vraiment changer le rôle de cet utilisateur de "{{oldRole}}" à "{{newRole}}" ?`,
  confirmUpdateUserTitle: `Confirmer le changement de rôle d'utilisateur`,
  confirmUpdateUserSuccess: `Rôle utilisateur mis à jour !`,
  confirmRemoveUser:
    'Êtes-vous sûr que vous souhaitez supprimer cet utilisateur ?',
  confirmRemoveUserTitle: "Confirmer supprimer l'utilisateur",
  confirmRemoveUserSuccess: 'Utilisateur supprimé!',
  confirmUpdateSelf: `Vous êtes sur le point de vous retirer en tant que propriétaire de cette équipe. Si vous continuez, vous ne serez plus en mesure de gérer les utilisateurs de cette équipe.`,
  confirmUpdateSelfTitle: 'Confirmer la mise à jour de soi',
  confirmRemoveSelf: `Vous êtes sur le point de vous retirer de cette équipe. Si vous continuez, vous ne serez plus en mesure de gérer les utilisateurs de cette organisation.`,
  confirmRemoveSelfTitle: 'Confirmer enlever Soi',
  manageOrgNotPermitted: `Vous n'êtes pas autorisé à gérer cette équipe. Si cela est incorrect, veuillez contacter le support VineView.`,
  invitedBy: 'Invité par',
  confirmUninviteUser:
    'Êtes-vous sûr que vous souhaitez révoquer cette invitation ?',
  confirmUninviteUserTitle: `Confirmer la révocation d'invitation`,
  confirmUninviteUserSuccess: 'Invitation révoquée !',
  resend: 'Renvoyer',
  revoke: 'Révoquer',
  confirmInviteResend:
    'Êtes-vous sûr que vous souhaitez renvoyer cette invitation ?',
  confirmInviteResendTitle: 'Confirmer Renvoyer une invitation',
  confirmInviteResendSuccess: 'Invitation renvoyée !',
  signup: `S'inscrire`,
  inviteInvalid: 'Cette invitation est invalide',
  continueWith: 'Continuer avec:',
  inviteMessage: `{{invitee}} vous a invité à rejoindre l'équipe {{organizationName}}`,
  join: `Accepter l'invitation`,
  joinWithOther: 'Joindre avec un autre compte',
  emailInUse: `L'email associé à cette invitation est déjà utilisé. Si vous êtes propriétaire de ce compte, veuillez vous connecter pour accepter cette invitation.`,
  emailInvalid: `Veuillez saisir votre adresse e-mail dans ce format << votrename@example.com >>`,
  signupError: `Erreur lors de l'inscription`,
  inviteConflict: `L'invitation existe déjà`,
  inviteForEmailAlreadyExists:
    'Une invitation pour {{userEmail}} existe déjà, ou un utilisateur avec cet e-mail appartient déjà à votre équipe.',
  userAlreadyBelongsToOrg: 'Vous êtes déjà membre de {{organisationName}}',
  invitation: 'Invitation VineView',
  joinedOrgSuccessTitle: `Equipe rejointe avec succès`,
  joinedOrgSuccess: `Vous avez rejoint {{organizationName}}. Souhaitez-vous passer à cette équipe maintenant?`,
  accepted: 'Accepté',
  expired: 'Expiré',
  wrongInviteEmail:
    'On dirait que cette invitation est pour un autre utilisateur.',
  switchAcount: 'Changer de compte',
  toAcceptInvite: 'pour accepter cette invitation.',
  mustBeSignedIn:
    'Cette invitation ne peut être acceptée lors de la déconnexion.',
  signUpToAccept: `Inscrivez-vous pour accepter`,
  invitationPending: 'Invitation en attente',
  invitationExpired: 'Invitation expirée',
  editName: `Modifier le nom`,
  members: 'Membres',
  fields: 'Parcelles',
  groupDownloadByRequest:
    'Les téléchargements de groupe sont générés par demande.',
  groupDownloadToRequest:
    'Pour lancer une demande de téléchargement, cliquez sur ',
  groupDownloadMayTakeAMinute: '',
  groupDownloadOnceGenerated: `Une fois que vous voyez `,
  groupDownloadOnceGeneratedPost:
    ', cliquez dessus pour télécharger les données',
  speedTracker: 'Traqueur de vitesse',
  showSpeedTracker: 'Afficher le suivi de la vitesse',
  gps: 'GPS',
  showLatLon: 'Afficher latitude/longitude',
  prescribedSpeed: 'Vitesse prescrite',
  currentSpeed: 'Vitesse actuelle',
  soundWhenChangingZones: 'Son lors du changement de zone',
  GPSLocation: 'Localisation GPS',
  adjustMapPosition: 'Ajuster la position de la carte',
  adjustCurrentGPSMapPosition: 'Ajuster la position actuelle de la carte GPS',
  moveTheMapToSetCorrectLocation:
    'Déplacez la carte pour définir l\'emplacement correct et appuyez sur "confirmer"',
  offset: 'Décalage',
  userLocation: "Emplacement de l'utilisateur",
  cursorLocation: 'Emplacement du curseur',
  distance: 'Distance',
  geoPdfDownload: 'Télécharger Geopdf',
  geoPdfDownloadByRequest:
    'Les téléchargements de Geopdf sont générés par demande.',
  productGuide: 'Catalogue Produits',
  appGuide: 'Guide d’Utilisation de l’Application',
  faq: 'FAQ',
  contactUs: 'Contactez Nous',
  name: 'Nom',
  requestedBy: 'Demandé par',
  actions: 'Actions',
  status: 'Statut',
  downloads: 'Téléchargements',
  dateRequested: 'Date demandée',
  deleteDownload: 'Supprimer le téléchargement',
  deleteDownloadConfirm: 'Êtes-vous sûr que vous souhaitez supprimer {{name}}?',
  filename: 'Nom de fichier',
  groupName: 'Nom de groupe',
  flightDate: 'Date',
  blockByBlockAverage: 'Moy. de parcelle',
  viewAs: 'Voir comme',
  'manage-subscription': "Gérer l'abonnement",
  paySubscription: "Payer l'abonnement",
  productPriceDescription: 'USD par acre chaque année',
  noSubscriptions: "Aucun abonnement n'existe pour cette équipe",
  renewsOn: 'Renouvelle le',
  invoiceNotSent:
    "La facture n'a pas encore été envoyée. Veuillez revenir bientôt.",
  month: {
    one: 'mois',
    other: '{{count}} mois',
  },
  year: {
    one: 'an',
    other: '{{count}} années',
  },
  groupDownloadsFilename: 'téléchargements-de-groupe.zip',
  multipleDownloads: '{{num}} téléchargements',
  rowsPerPage: 'Taille de la page',
  tableRowsPerPage: 'Lignes par page:',
  nextPage: 'Page suivante',
  previousPage: 'Page précédente',
  filenames: {
    'all-files': {
      zip: 'tous-les-fichiers.zip',
    },
  },
  filters: 'Filtres',
  all: 'Toute',
  readyForDownload: 'Prêt à télécharger',
  inProgress: 'En cours',
  complete: 'Complète',
  pending: 'En attente',
  processing: 'Traitement',
  error: 'Erreur',
  unprocessed: 'Non transformé',
  downloadType: 'Type de téléchargement',
  geoPdf: 'GeoPDF',
  rawData: 'Données brutes',
  request: 'Demande',
  selectType: 'Sélectionner le genre',
  groupsAndDates: 'Groupes et dates',
  settings: 'Réglages',
  reviewAndRequest: 'Examiner et demander',
  selectGroupsAndDates: 'Sélectionnez des groupes et des dates',
  groups: 'Groupes',
  allGroups: 'Tous les groupes',
  editGroupAccess: `Modifier l'accès aux groupes`,
  dates: 'Dates',
  geoPdfSettings: 'Paramètres GeoPDF',
  product: 'Produit',
  duplicatesFoundTitle: 'Doublons trouvés',
  duplicatesFoundDetail:
    'Certains téléchargements demandés existent déjà. Veuillez choisir si vous souhaitez passer ou remplacer les téléchargements dupliqués ci-dessous.',
  skip: 'Passer',
  replace: 'Remplacer',
  skipAll: 'Tout passer',
  replaceAll: 'Remplace tout',
  rawDataDownloadSettings: 'Paramètres de téléchargement des données brutes',
  file: 'Fichier',
  noFilesAffectedTitle: 'Aucun fichier affecté',
  noFilesAffectedDetail:
    "Les paramètres sélectionnés et les combinaisons de groupe / date / nom de fichier qui ont été ignorées n'ont entraîné aucun effet",
  colorProfile: 'Profil des couleurs',
  filesCount: '{{fileCount}} Fichiers',
  downloadTableInstruction:
    'Les téléchargements sont générés par demande. Pour commencer, cliquez << {{request}} >>.',
  numberOfFiles: 'Nombre de fichiers',
  expirationDate: "Date d'expiration",
  filterBy: 'Filtrer par',
  popOutStats: 'Undock statistiques',
  dockStats: 'Dock statistiques',
  range: 'Intervalle',
  downloadChartDataTitle: 'Exporter les données du graphique',
  closeStatWindow: 'Fermer la fenêtre des statistiques',
  weightedAvgWithError: 'Moyenne pondérée avec erreur',
  historicChartConfig: {
    xAxisLabel: "Étiquette de l'axe X",
    yAxisLabel: "Étiquette de l'axe Y",
    meanLabel: 'Étiquette moyenne',
    stdevLabel: "Étiquette d'écart type",
    weightLabel: 'Étiquette de poids',
    mean: 'Étiquette de propriété moyenne',
    stdev: "Étiquette de propriété d'écart type",
    weight: 'Étiquette de propriété de poids',
  },
  downloadOutputTypeLabel: 'Structure de fichier',
  downloadMergeGroupsLabel: 'Fusionné',
  downloadBlockByBlockLabel: 'Parcelle par parcelle',
  downloadBothLabel: 'Les deux',
  pauseAllJobs: 'Suspendre toutes les tâches',
  unpauseAllJobs: 'Réactiver toutes les tâches',
  mobileAdvertisementTitle: 'Sur le mobile?',
  mobileAdvertisementText:
    "L'application VineView est maintenant disponible sur l'App Store et Google Play!",
  samplePlanDashboard: "Plans d'échantillonnage",
  samplePlanNameColumnLabel: 'Nom',
  samplePlanDateColumnLabel: 'Date de création',
  samplePlanFormColumnLabel: 'Type Echantillonnage',
  samplePlanBlocksColumnLabel: 'Parcelles',
  samplePlanSamplesColumnLabel: 'Echantillons',
  samplePlanLastUpdateColumnLabel: 'Dernière mise à jour',
  samplePlanStatusColumnLabel: 'Etat',
  samplePlanStatusPendingLabel: 'En attente',
  samplePlanStatusInProgressLabel: 'En cours',
  samplePlanStatusCompleteLabel: 'Terminé',
  samplePlanCreateNewLabel: 'Nouveau plan d’échantillonnage',
  samplePlanMoreMenuMarkAsCompleteLabel: 'Marquer comme terminé',
  samplePlanBlockIdColumnLabel: 'Bloc',
  samplePlanBlockLocationColumnLabel: 'Emplacement',
  samplePlanBlockSampleCountColumnLabel: 'Échantillons',
  samplePlanDownloadPlanLabel: 'Télécharger Plan',
  samplePlanDownloadPlanFormTitle: "Télécharger le plan d'échantillonnage",
  samplePlanDownloadPlanFormDescLabel:
    'Sélectionnez les blocs que vous souhaitez télécharger',
  samplePlanNewLabel: "Nouveau plan d'échantillonnage",
  samplePlanNewStep1Label: 'Nom',
  samplePlanNewStep2Label: 'Modèle de plan',
  samplePlanNewStep3Label: 'Sélection des Parcelles',
  samplePlanNewStep4Label: 'Méthodologie',
  samplePlanNewStep5Label: 'Protocole',
  samplePlanNewStep6Label: 'Confirmation',
  samplePlanNewNameLabel: "Nommez votre plan d'échantillonnage",
  samplePlanNewNameTipLabel: 'Nom du plan',
  samplePlanNewSampleTypeLabel: "Type d'échantillonnage",
  samplePlanNewStatisticalMethodLabel: 'Méthode statistique',
  samplePlanNewBlockSelectionLabel:
    "Sélectionnez le nombre d'échantillons par bloc",
  samplePlanNewSameForAllLabel: 'Pareil pour tous',
  samplePlanNewZonesPerBlockLabel: 'Zones par parcelle',
  samplePlanNewZonesPerBlockErrorLabel: 'Entrer un nombre',
  samplePlanNewSelectFormTemplateLabel: 'Sélectionnez un modèle de formulaire',
  samplePlanNewCreateFormTemplateLabel:
    'Créer de nouveaux modèles ou modifier des modèles existants',
  samplePlanNewMarginOfErrorLabel: "Marge d'erreur",
  samplePlanNewAvgMarginOfErrorLabel: "Marge d'erreur moyenne",
  samplePlanNewConfidenceLevelLabel: '| Niveau de confiance:',
  samplePlanNewMarginOfErrorTooltip:
    "Plus la marge d’erreur est grande, moins les résultats reflétant l’ensemble de la population sont fiables. Améliorez-le en augmentant la taille de l'échantillon ou en réduisant la variabilité de l'échantillon avec des zones plus pondérées.",
  samplePlanNewDivergenceTooltip:
    "La divergence quantifie la différence entre le nombre d'échantillons que vous avez placés et l'ensemble de la population de vignes du bloc. Les valeurs sont comprises entre 0 et 1, les valeurs les plus faibles indiquant une meilleure précision.",
  samplePlanNewProjectInstructionsLabel:
    'Instructions pour la collecte d’échantillons',
  samplePlanNewFormsLabel: 'Formes',
  samplePlanNewSampleNumberPerBlock:
    "Sélectionnez le nombre d'échantillons par bloc",
  samplePlanNewSameForAll: 'Pareil pour tous',
  samplePlanNewGenerateLabel:
    "S'il vous plaît, attendez. Générer des échantillons...",
  samplePlanNewFormTemplatePlaceholderLabel:
    'Choisissez un modèle de formulaire...',
  samplePlanNewChooseMethod: 'Choisissez votre méthode statistique',
  samplePlanNewSampleTypePlaceholderLabel: "Choisissez un type d'échantillon",
  samplePlanNewSampleFieldPlaceholderLabel: 'Choisissez un champ...',
  samplePlanNewSampleDataSourcePlaceholderLabel:
    'Choisissez une source de données...',
  writeHere: 'Écrivez ici',
  next: 'Suivant',
  confirm: 'Confirmer',
  back: 'Précédent',
  sampleResultBlockLabel: 'Parcelle',
  sampleResultSamplerLabel: `Préléveur`,
  sampleResultCompletionDateLabel: `Date`,
  sampleResultCompletionHourLabel: `Heur`,
  samplePlanResultCheckBoxContentTrue: 'Oui',
  samplePlanResultCheckBoxContentFalse: 'Non',
  samplePlanDownloadTitleLabel: 'Télécharger les résultats',
  samplePlanDownloadDescLabel:
    'Sélectionnez le type de données que vous souhaitez télécharger',
  samplePlanDownloadCsv: 'CSV',
  samplePlanDownloadGeoJson: 'Geojson',
  samplePlanReportTitle: "Aperçu de l'échantillonnage",
  samplePlanNoSamplePlans:
    "Il semble que vous n'ayez pas encore créé de plan d'échantillonnage",
  search: 'Recherche',
  results: 'Résultat',
  seeResults: 'Voir les résultats',
  filterIconTooltip: 'Cette colonne est impactée par les filtres',
  orderIconTooltip: 'Cette colonne peut être triée en cliquant sur cette icône',
  completedBy: 'Terminé par',
  allLocations: 'Tous les emplacements',
  allBlocks: 'Tous les blocs',
  samplePlanMap: 'Exemple de carte',
  projectInstructions: 'Instructions du projet',
  samplePlanProjectInstructionsPlaceholder:
    'Ecrivez ici les instructions pour la collecte d’échantillons… Par exemple : Comptez le nombre de grappes',
  notesProjectInstructionsPlaceholder:
    'Ecrivez ici les instructions pour le projet… Par exemple : Comptez le nombre de grappes',
  marginOfError: 'Marge d’erreur',
  divergence: 'Divergence',
  sample: 'Échantillon',
  moreInfo: 'Plus d’informations',
  unableToPrintMapTitle: `Impossible d'imprimer la carte`,
  unableToPrintMapMessage: `Impossible d'imprimer la carte. Assurez-vous que les fenêtres contextuelles sont activées.`,
  createProjectLabel: 'Créer un projet',
  editProjectLabel: 'Modifier le projet',
  deleteProjectLabel: 'Supprimer le projet',
  deleteProjectPromptMessage1:
    "L'effacement supprimera les {{projectNoteCount}} notes associées du {{projectName}}.",
  deleteProjectPromptMessage2: 'Êtes-vous sûr de vouloir continuer ?',
  deleteSelectedNotesPromptLabel: 'Supprimer les notes sélectionnées',
  deleteProjectSuccessMessage: 'Projet supprimé avec succès',
  deleteProjectFailedMessage: 'Échec de la suppression du projet',
  deleteSelectedNotesPromptMessage:
    'Toutes les notes sélectionnées seront supprimées. Poursuivre ?',
  deleteNotesSuccessMessage: 'Note(s) supprimée(s) avec succès',
  deleteNotesFailedMessage: 'Échec de la suppression de la/des note(s)',
  moveAllProjectNotesLabel: 'Déplacer toutes les notes vers',
  moveAllProjectNotesPromptLabel: 'Déplacer toutes les notes vers',
  moveAllProjectNotesPromptMessage:
    'Toutes les notes seront déplacées dans « {{targetProjectName}} » à partir de « {{currentProjectName}} ». Poursuivre ?',
  moveAllProjectNotesSuccessMessage: 'Note(s) déplacée(s) avec succès',
  moveAllProjectNotesFailedMessage: 'Échec du déplacement de la/des note(s)',
  moveNotesModalTitle: 'Déplacer la note',
  moveNotesModalDescription:
    "Sélectionnez l'endroit où vous souhaitez déplacer votre/vos note(s).",
  selectNotesLabel: 'Sélectionner',
  selectAllNotesLabel: 'Sélectionner toutes les notes',
  makeProjectPrivateLabel: 'Rendre privé',
  projectLabel: 'Projet',
  projectNameLabel: 'Nom du projet',
  projectNamePlaceholder: 'Saisir le nom du projet',
  projectInstructionsLabel: 'Instructions du projet',
  projectInstructionsPlaceholder: 'Saisir les instructions du projet',
  projectCreateNewLabel: 'Ou créer un nouveau projet',
  samplePlanMissingDatasourcesWarning:
    'Avertissement : Certains des blocs que vous avez sélectionnés ne disposent pas de données cohérentes. Revenez à la sélection des blocs et filtrez par date.',
  samplePlanAllFlightDates: 'Toutes les dates de vol',
  samplePlanDuplicate: 'Plan en double',
  samplePlanDuplicateConfirmation:
    'Veuillez saisir un nom pour le plan en double',
  samplePlanRename: 'Renommer',
  samplePlanDelete: 'Supprimer le plan',
  samplePlanDeleteConfirmation:
    'Êtes-vous sûr de vouloir supprimer ce plan d’échantillonnage?',
  sampleTemplate: 'Modèle d’échantillon',
  samplePlanVinesPerRow: 'Vignes par rang',
  projectsToExport: 'Sélectionner les projets à exporter',
  tools: 'Outils',
  rateMap: 'Créer Carte de Modulation',
  rateMapDescription: 'Créer carte de modulation de dose',
  samplePlanDescription:
    "Créer et gérer des plans d'échantillonnage basés sur vos données",
  rateMapNameColumnLabel: 'Nom',
  rateMapDateColumnLabel: 'Date de création',
  rateMapBlockColumnLabel: 'Bloc',
  rateMapAmendmentTypeColumnLabel: 'Produit',
  rateMapEditColumnButtonLabel: 'Modifier',
  newRateMap: 'Nouvelle carte de modulation',
  rateMapEmptyLabel:
    "Il semble que vous n'ayez pas encore créé de carte tarifaire",
  rateMapCreateCustomZones: 'Créer ou modifier des zones personnalisées',
  rateMapDelete: 'Supprimer la carte de modulation',
  rateMapDeleteConfirmation:
    'Êtes-vous sûr de vouloir supprimer la carte de modulation?',
  rateMapDuplicate: 'Carte de modulation en double',
  rateMapDuplicateConfirmation:
    'Veuillez saisir un nom pour la carte de modulation en double',
  rateMapDownloadLabel: 'Télécharger la carte',
  rateMapDownloadBlockSelectionLabel: 'Sélectionnez les blocs à télécharger',
  rateMapDownloadFormatLabel: 'Définir le format de téléchargement',
  noAvailableDates: 'Aucune date disponible',
  rateMapMapColor: 'Couleur de la carte',
  rateMapRateOfApplication: 'Dose',
  rateMapTotalAmountPerZone: 'Total produit par zone',
  rateMapVigorZoneTypeTitle: 'Sélectionnez le type de zonage',
  rateMapVigorZoneTypeDescription:
    'Choisissez le zonage utilisé pour la modulation',
  rateMapSetUpAmendmentTitle: 'Paramétrage de produit',
  rateMapSetUpAmendmentDescription:
    'Choisissez les doses pour chaque classe de vigueur',
  rateMapFinalOutputTitle: 'Rendu Final',
  rateMapFinalOutputDescription:
    'Affiche le poids et le coût total de votre plan de fumure',
  rateMapSupplimentalDataLayersTitle: 'Couches Supplémentaires',
  rateMapSupplimentalDataLayersDescription: '',
  rateMapAmendmentTypeTitle: 'Produit',
  rateMapUnitTypeTitle: 'Unité',
  rateMapUnitCostTitle: 'Coût unitaire',
  rateMapTotalApplicationAmountTitle: 'Total produit appliqué',
  rateMapCostTitle: 'Coût',
  rateMapAddAmendmentModalTitle: 'Ajouter un produit',
  rateMapDeleteAmendmentModalTitle: 'Supprimer un produit',
  rateMapDeleteAmendmentModalDescription: 'Etes-vous sûr de vouloir supprimer ',
  rateMapAddAmendmentNameLabel: 'Nom du produit',
  rateMapSaveModalTitle: 'Sauvegarder la carte de modulation',
  rateMapAbsoluteZones: 'Zones absolues',
  rateMapRelativeZones: 'Zones relatives',
  rateMapRate: 'Dose',
  rateMapAmount: 'Quantité',
  rateMapAmendmentDuplicateError:
    'Un amendement portant ce nom existe déjà dans votre organisation.',
  rateMapDeleteAmendmentModalAffectedMaps: 'Cela affectera {{count}}',
  rateMapDeleteAmendmentModalAffectedMapsSingular:
    'autre Carte de Modulation dans votre organisation.',
  rateMapDeleteAmendmentModalAffectedMapsPlural:
    'autres Cartes de Modulation dans votre organisation.',
  rateMapInvalidGeometryWarning:
    'Vous avez des bords qui se chevauchent dans votre géométrie.',
  rateMapMoreOptions: 'Plus d’Options',
  rateMapRectangle: 'Rectangle',
  rateMapPolygon: 'Polygone',
  rateMapSnapping: `S'enclencher`,
  rateMapLimitToBounds: 'Limiter Aux Limites',
  rateMapCreateNewZone: 'Ajouter Une Nouvelle Zone',
  rateMapConfirmAllZones: 'Confirmer Toutes Les Zones',
  rateMapPropertyName: 'Nom du champ',
  rateMapSupplementalDataLayersTitle: 'Couches de Données Supplémentaires',
  rateMapSupplementalDataLayersDescription:
    'Afficher des couches de données supplémentaires',
  rateMapNoSupplementalDataLayers:
    'Aucun calque disponible pour la date sélectionnée.',
  rateMapLimitToBoundsTooltip:
    'Les nouvelles zones seront tronquées aux limites blanches de la parcelle',
  rateMapSnappingTooltip:
    'Les polygones s’aligneront aux bords des polygones adjacents',
  rateMapPolygonTooltip: 'Dessinez une zone en forme de polygone personnalisé',
  rateMapRectangleTooltip: 'Dessinez une zone rectangulaire',
  filterListByMapView: 'Filtrer la Liste par Vue Cartographique',
}
