import centerOfMass from '@turf/center-of-mass'
import { createSelector } from 'reselect'
import { selectDeliveryParcelsByParcelId } from '../../data/selectDelivery'
import { selectParcels } from '../../data/selectOrgMapData'
import { selectSelectedDeliveryParcels } from '../../data/selectSelectedDeliveryParcels'
import { MapboxGL } from '../../map/MapboxGL'
import { RootStore } from '../../redux/types'
import { OrgFeatureGeometry, ParcelFeature } from '../../vvapi/models'

export const FIELD_BOUNDS_UNSELECTED_LAYER_ID =
  'FIELD-BOUNDS-POLYGONS-UNSELECTED'
export const FIELD_BOUNDS_SELECTED_LAYER_ID = 'FIELD-BOUNDS-POLYGONS-SELECTED'
export const FIELD_BOUNDS_COLOR_NO_DATA = '#ff6347' // tomato
export const FIELD_BOUNDS_COLOR_SELECTED = '#FFCA28' // Material Color Amber 400
export const FIELD_BOUNDS_COLOR_UNSELECTED = '#EEEEEE'
export const FIELD_BOUNDS_LABEL = 'FIELD-BOUNDS-LABEL'
const FIELD_BOUNDS_LINE_OPACITY_STOPS = [
  [13, 1],
  [14, 0.6],
]
const FIELD_BOUNDS_WIDTH = 3

const getShowFieldBounds = (state: RootStore) =>
  state.preferences.showFieldBoundaries
const getShowFieldLabels = (state: RootStore) =>
  state.preferences.showFieldLabels

const getOverriddenParcelBorderColor = (state: RootStore) =>
  state.userSelection.overriddenParcelBorderColor

const getOverriddenParcelBorderOpacity = (state: RootStore) =>
  state.userSelection.overriddenParcelBorderOpacity

const getOverriddenParcelBorderWidth = (state: RootStore) =>
  state.userSelection.overriddenParcelBorderWidth

const selectUnselectedParcels = createSelector(
  selectParcels,
  selectSelectedDeliveryParcels,
  (allParcels, selectedParcels) => {
    const selectedParcelIds = selectedParcels.reduce((acc, parcel) => {
      acc.add(parcel.parcelId)

      return acc
    }, new Set<string>())

    const unselectedParcels = allParcels.filter(
      (parcel) => !selectedParcelIds.has(parcel.id)
    )

    return unselectedParcels
  }
)

interface FieldsLayerResult {
  sources: any
  layers: MapboxGL.Layer[]
}

export const getFieldsLayer = createSelector(
  selectParcels,
  selectDeliveryParcelsByParcelId,
  selectSelectedDeliveryParcels,
  selectUnselectedParcels,
  getShowFieldBounds,
  getShowFieldLabels,
  getOverriddenParcelBorderColor,
  getOverriddenParcelBorderOpacity,
  getOverriddenParcelBorderWidth,
  (
    allParcels,
    deliveryParcelsByParcelId,
    selectedParcels,
    unselectedParcels,
    showFieldBounds,
    showFieldLabels,
    overriddenParcelBorderColor,
    overriddenParcelBorderOpacity,
    overriddenParcelBorderWidth
  ) => {
    const result: FieldsLayerResult = { sources: {}, layers: [] }

    if (showFieldBounds) {
      addFieldBounds(
        unselectedParcels,
        deliveryParcelsByParcelId,
        'FIELD-BOUNDS-POLYGONS-UNSELECTED',
        FIELD_BOUNDS_COLOR_UNSELECTED,
        result
      )

      addFieldBounds(
        selectedParcels,
        deliveryParcelsByParcelId,
        'FIELD-BOUNDS-POLYGONS-SELECTED',
        overriddenParcelBorderColor ?? FIELD_BOUNDS_COLOR_SELECTED,
        result,
        FIELD_BOUNDS_COLOR_NO_DATA,
        overriddenParcelBorderWidth,
        overriddenParcelBorderOpacity
      )
    }

    if (showFieldLabels) {
      addFieldLabels(allParcels, deliveryParcelsByParcelId, result)
    }

    return result
  }
)

type DeliveryParcelData = ReturnType<typeof selectDeliveryParcelsByParcelId>
export const addBoundLayers = (
  parcels: ParcelData[],
  sourceId: string,
  sourcesAndLayers: FieldsLayerResult
) =>
  addFieldBounds(
    parcels,
    {},
    sourceId,
    FIELD_BOUNDS_COLOR_SELECTED,
    sourcesAndLayers
  )

export const addFieldBounds = (
  parcels: ParcelData[],
  deliveryParcelsByParcelId: DeliveryParcelData,
  sourceId: string,
  lineColor: string,
  { sources, layers }: FieldsLayerResult,
  noDataColor?: string,
  lineWidth?: number,
  lineOpacity?: number[][]
) => {
  const fieldBoundsCollection = parcelsToGeoJSON(
    parcels,
    deliveryParcelsByParcelId
  )

  sources[sourceId] = {
    data: fieldBoundsCollection,
    type: 'geojson',
    attribution: '&copy; VineView',
  }
  layers.push({
    paint: {
      'line-width': lineWidth ?? FIELD_BOUNDS_WIDTH,
      'line-color': noDataColor
        ? ['case', ['get', 'hasData'], lineColor, noDataColor]
        : lineColor,
      'line-opacity': {
        stops: lineOpacity ?? FIELD_BOUNDS_LINE_OPACITY_STOPS,
      },
    },
    type: 'line' as 'line',
    id: sourceId,
    source: sourceId,
  })
}

export const addFieldBoundsWithFill = (
  parcels: ParcelData[],
  deliveryParcelsByParcelId: DeliveryParcelData,
  sourceId: string,
  lineColor: string,
  { sources, layers }: FieldsLayerResult,
  noDataColor?: string,
  lineWidth?: number,
  lineOpacity?: number[][],
  fillColor?: string
) => {
  const fieldBoundsCollection = parcelsToGeoJSON(
    parcels,
    deliveryParcelsByParcelId
  )

  if (!!fillColor) {
    const sourceFillId = `${sourceId}-FILL`
    sources[sourceFillId] = {
      data: fieldBoundsCollection,
      type: 'geojson',
      attribution: '&copy; VineView',
    }
    layers.push({
      paint: {
        'fill-color': fillColor,
        'fill-opacity': 0.25,
      },
      type: 'fill',
      id: sourceFillId,
      source: sourceFillId,
    })
  }

  sources[sourceId] = {
    data: fieldBoundsCollection,
    type: 'geojson',
    attribution: '&copy; VineView',
  }
  layers.push({
    paint: {
      'line-width': lineWidth ?? FIELD_BOUNDS_WIDTH,
      'line-color': noDataColor
        ? ['case', ['get', 'hasData'], lineColor, noDataColor]
        : lineColor,
      'line-opacity': {
        stops: lineOpacity ?? FIELD_BOUNDS_LINE_OPACITY_STOPS,
      },
    },
    type: 'line' as 'line',
    id: sourceId,
    source: sourceId,
  })
}

export const addFieldLabels = (
  parcels: ParcelData[],
  deliveryParcelsByParcelId: DeliveryParcelData,
  { sources, layers }: FieldsLayerResult,
  id?: string
) => {
  const fieldLabelsCollection = parcelsToGeoJSON(
    parcels,
    deliveryParcelsByParcelId,
    true
  )

  sources[id ?? FIELD_BOUNDS_LABEL] = {
    data: fieldLabelsCollection,
    type: 'geojson',
    attribution: '&copy; VineView',
  }
  layers.push({
    type: 'symbol' as 'symbol',
    paint: {
      'text-color': 'white',
      'text-halo-color': 'black',
      'text-halo-width': 1.5,
    },
    layout: {
      // uses the `name` field from the geojson properties
      'text-field': '{name}',
    },
    id: id ?? FIELD_BOUNDS_LABEL,
    source: id ?? FIELD_BOUNDS_LABEL,
  })
}

interface ParcelData {
  id?: string
  parcelId?: string
  name: string
  geometry: GeoJSON.Polygon | GeoJSON.MultiPolygon
  meta: object | undefined | null
}

export function parcelsToGeoJSON(
  parcels: ParcelData[],
  deliveryParcelsByParcelId?: DeliveryParcelData,
  replaceGeometryWithCenter = false,
  properties?: Record<string, any>
) {
  const geojson = parcels.map((parcel) => {
    const parcelId = parcel.id! || parcel.parcelId!
    const deliveryParcel = deliveryParcelsByParcelId?.[parcelId]
    const { geometry, name, meta } = deliveryParcel || parcel

    return {
      id: `${parcelId}`,
      geometry: replaceGeometryWithCenter
        ? centerOfMass(geometry).geometry
        : geometry,
      type: 'Feature',
      properties: {
        id: `${parcelId}`,
        parcelId,
        name,
        meta,
        hasData: deliveryParcel?.mapSources.some((mapSource) =>
          mapSource.mapLayers.some((mapLayer) => mapLayer.enabled)
        ),
        ...(properties || {}),
      },
    } as ParcelFeature
  })

  return {
    features: geojson,
    type: 'FeatureCollection',
  } as GeoJSON.FeatureCollection<OrgFeatureGeometry>
}
