import { SnapModeDrawStyles } from 'mapbox-gl-draw-snap-mode'
import { FIELD_BOUNDS_UNSELECTED_LAYER_ID } from '../../../../../postgis/selectors/FieldsLayerSelector'

export const CUSTOM_MAPBOX_DRAW_POLY_FILL_ACTIVE_LAYER_ID =
  'mapbox-gl-draw-polygon-custom-zone-fill-active'
export const CUSTOM_MAPBOX_DRAW_POLY_FILL_INACTIVE_LAYER_ID =
  'mapbox-gl-draw-polygon-custom-zone-fill-inactive'
export const CUSTOM_MAPBOX_DRAW_POLY_LINE_LAYER_ID =
  'mapbox-gl-draw-polygon-custom-zone-stroke'
export const ADJUSTED_VIGOR_ZONE_LAYER_ID = 'adjusted-vigor-zone-layer'

const OverrideStyles = [
  {
    id: CUSTOM_MAPBOX_DRAW_POLY_FILL_ACTIVE_LAYER_ID,
    type: 'fill',
    filter: [
      'all',
      ['==', '$type', 'Polygon'],
      ['==', 'active', 'true'],
      ['has', 'user_zone-color'],
      ['any', ['!has', 'user_hidden'], ['==', 'user_hidden', false]],
    ],
    paint: {
      'fill-color': ['get', 'user_zone-color'],
      'fill-opacity': 0.5,
    },
  },
  {
    id: CUSTOM_MAPBOX_DRAW_POLY_FILL_INACTIVE_LAYER_ID,
    type: 'fill',
    filter: [
      'all',
      ['==', '$type', 'Polygon'],
      ['==', 'active', 'false'],
      ['has', 'user_zone-color'],
      ['any', ['!has', 'user_hidden'], ['==', 'user_hidden', false]],
    ],
    paint: {
      'fill-color': ['get', 'user_zone-color'],
      'fill-opacity': 1,
    },
  },
  {
    id: CUSTOM_MAPBOX_DRAW_POLY_LINE_LAYER_ID,
    type: 'line',
    filter: [
      'all',
      ['==', '$type', 'Polygon'],
      ['has', 'user_zone-color'],
      ['any', ['!has', 'user_hidden'], ['==', 'user_hidden', false]],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ['get', 'user_zone-color'],
      'line-width': 2,
    },
  },
]

const RemovedStyles = [
  'gl-draw-polygon-fill-inactive',
  'gl-draw-polygon-fill-static',
  'gl-draw-polygon-stroke-inactive',
  'gl-draw-polygon-stroke-static',
]

export const adjustZoneLayerPositions = (
  moveLayer: (id: string, beforeId?: string) => void
) => {
  moveLayer(
    `${CUSTOM_MAPBOX_DRAW_POLY_FILL_ACTIVE_LAYER_ID}.cold`,
    FIELD_BOUNDS_UNSELECTED_LAYER_ID
  )
  moveLayer(
    `${CUSTOM_MAPBOX_DRAW_POLY_FILL_INACTIVE_LAYER_ID}.cold`,
    FIELD_BOUNDS_UNSELECTED_LAYER_ID
  )
  moveLayer(
    `${CUSTOM_MAPBOX_DRAW_POLY_LINE_LAYER_ID}.cold`,
    FIELD_BOUNDS_UNSELECTED_LAYER_ID
  )
}

export const CustomZoneStyles = [
  ...SnapModeDrawStyles.filter(
    (style: any) =>
      OverrideStyles.every(
        (overrideStyle: any) => style.id !== overrideStyle.id
      ) && !RemovedStyles.includes(style.id)
  ),
  ...OverrideStyles,
]
