import { postJson } from '../../../../vvapi/apiResource/createApiResource'

export const queueDeliveryProcGroupJob = (
  {
    deliveryId,
    procGroup,
  }: {
    deliveryId: string
    procGroup: string
  },
  jobType:
    | 'final-products'
    | 'reference-setup'
    | 'reference-update'
    | 'row-mask'
    | 'canopy-mfp'
    | 'upload',
  requeueDownstream = false
) =>
  postJson(
    `/api/v3/delivery-procgroup/${deliveryId}/${procGroup}/queue-${jobType}`,
    {
      body: { requeueDownstream },
    }
  )
