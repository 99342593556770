import { FeatureCollection } from '@turf/helpers'
import applyNamespace from 'redux-ts-helpers/lib/applyNamespace'
import createAction from 'redux-ts-helpers/lib/createAction'
import { MapboxGL } from '../map/MapboxGL'
import * as notes from '../notes/redux'
import { AppDispatch, ReduxAsyncAction } from '../redux/types'
import { SerializablePosition } from './SerializablePosition'
import {
  FocusedPoint,
  FocusedPolygon,
  ParcelFilterInfo,
  PostGISState,
} from './types'

export const constants = applyNamespace('postgis', {
  /** timestamp for refreshing the fitSelected zoom level */
  setFitSelectedParcels: 0,
  /** Sets the currently focused EVI Point on the map. */
  setFocusedPoint: 0,
  /** Sets the currently focused Polygon on the map. */
  setFocusedPolygon: 0,
  /** Sets the currently focused Zone Map zone on the map. */
  setFocusedZone: 0,
  /** implementation detail, not to be called by UI */
  replaceParcelFilterInfo: 0,
  setParcelFilterInfo: 0,
  toggleLayerDrawer: 0,

  setBackgroundRaster: 0, // ! TODO
  setUserPosition: 0,
  setUserPositionError: 0,
  setIsPollingUserPosition: 0,
  setPosition: 0,
  setPitch: 0,
  setBearing: 0,

  setFocusedBlockId: 0,

  fetchProducts: 0, // ! TODO
  fetchBackgroundRasterMapLayers: 0, // ! TODO
  setBackgroundRasterMapLayers: 0, // ! TODO

  clickedSoilLayer: 0,
  setFocusedSoilLayer: 0,

  toggleMeasureToolActive: 0,
  toggleStatsZonesToolActive: 0,
  setStatsZonesToolActive: 0,
  toggleShowInactiveLayers: 0,
  toggleGPSOffsetMarkerVisible: 0,

  setStatsCustomZones: 0,

  mapDataUpdated: 0,
})

export const setStatsCustomZones = createAction<FeatureCollection | undefined>(
  constants.setStatsCustomZones
)
export const setFocusedBlockId = createAction<string | number | undefined>(
  constants.setFocusedBlockId
)

export const toggleStatsZonesToolActive = createAction<null>(
  constants.toggleStatsZonesToolActive
)

export const setStatsZonesToolActive = createAction<boolean>(
  constants.setStatsZonesToolActive
)

export const toggleMeasureToolActive = createAction<null>(
  constants.toggleMeasureToolActive
)

export const toggleGPSOffsetMarkerVisible = createAction<null>(
  constants.toggleGPSOffsetMarkerVisible
)

export const setFitSelectedParcels = createAction<boolean>(
  constants.setFitSelectedParcels
)
export const setFocusedPoint = createAction<Writable<FocusedPoint> | null>(
  constants.setFocusedPoint
)
export const setFocusedPolygon = createAction<Writable<FocusedPolygon> | null>(
  constants.setFocusedPolygon
)
export const replaceParcelFilterInfo = createAction<ParcelFilterInfo>(
  constants.replaceParcelFilterInfo
)
export const toggleLayerDrawer = createAction(constants.toggleLayerDrawer)

export const setUserPositionError =
  createAction<GeolocationPositionError | null>(constants.setUserPositionError)
export const setUserPosition = (position: GeolocationPosition | null) => ({
  type: constants.setUserPosition,
  payload: position ? new SerializablePosition(position) : null,
})

export const setParcelFilterInfo = (parcelFilterInfo: ParcelFilterInfo) => ({
  type: constants.setParcelFilterInfo,
  payload(dispatch: AppDispatch) {
    dispatch(replaceParcelFilterInfo(parcelFilterInfo))
  },
})

export const setIsPollingUserPosition = (isPollingUserPosition: boolean) => ({
  type: constants.setIsPollingUserPosition,
  async payload(dispatch: AppDispatch) {
    if (isPollingUserPosition) {
      startWatchingUserPosition(dispatch)
    } else {
      stopWatchingUserPosition()
    }

    return isPollingUserPosition
  },
})

type TimeoutId = number | null

let watchId: TimeoutId = null

function startWatchingUserPosition(dispatch: AppDispatch) {
  stopWatchingUserPosition()
  const TIMEOUT = 1000 * 60 // 1 minute
  function handleLocationResponse(position: GeolocationPosition) {
    dispatch(setUserPositionError(null))
    dispatch(setUserPosition(position))
  }
  function handleLocationError(error: GeolocationPositionError) {
    dispatch(setUserPositionError(error))
  }

  watchId = navigator.geolocation.watchPosition(
    handleLocationResponse,
    handleLocationError,
    {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: TIMEOUT,
    }
  )
}

function stopWatchingUserPosition() {
  if (watchId) {
    navigator.geolocation.clearWatch(watchId)
    watchId = null
  }
}

export const setPosition = createAction<{
  squareBounds: number[][]
  actualBounds: number[][]
}>(constants.setPosition)

export const setPitch = createAction<number>(constants.setPitch)
export const setBearing = createAction<number>(constants.setBearing)

export const clickedSoilLayer = (
  soilLayer: PostGISState['focusedSoilLayer']
): ReduxAsyncAction => ({
  type: constants.clickedSoilLayer,
  payload(dispatch) {
    dispatch(setFocusedSoilLayer(soilLayer))
    dispatch(setFocusedBlockId(undefined))
    dispatch(setFocusedPoint(null))
    dispatch(setFocusedPolygon(null))
    dispatch(notes.actions.setEditingNoteId(undefined))
  },
})

export const setFocusedSoilLayer = createAction<
  PostGISState['focusedSoilLayer']
>(constants.setFocusedSoilLayer)

export const toggleShowInactiveLayers = createAction(
  constants.toggleShowInactiveLayers
)

export const mapDataUpdated = createAction<MapboxGL.Map | undefined>(
  constants.mapDataUpdated
)
