/**
 * tileset id's: uploaded to https://studio.mapbox.com/tilesets/
 */
const tilesets = [
  { id: 'vineview.b5ri05v2', layer: 'WA-Soil_1-d9v0gi' },
  { id: 'vineview.chg4xrcn', layer: 'WA-Soil_2-8i81p7' },
  { id: 'vineview.06q7hb6e', layer: 'WA-Soil_3-00g8vs' },
  { id: 'vineview.2nclhsx7', layer: 'WA-Soil_4-95wpt5' },
  { id: 'vineview.cz64kcpx', layer: 'WA-Soil_5-6zsend' },
  { id: 'vineview.d9a42uzq', layer: 'WA-Soil_6-07cp0c' },
  { id: 'vineview.cf89mhdx', layer: 'WA-Soil_7-2xxf1k' },
  { id: 'vineview.d5m5vysp', layer: 'WA-Soil_8-3b0ygb' },
  { id: 'vineview.0o5q10bs', layer: 'WA-Soil_9-5rexlo' },
  { id: 'vineview.2zf7x01s', layer: 'WA-Soil_10-18qd3r' },
  { id: 'vineview.c4e3eo1g', layer: 'WA-Soil_11a-dwe8ux' },
  { id: 'vineview.9znznaz5', layer: 'WA-Soil_11b-1ajnsx' },
  { id: 'vineview.am7hffui', layer: 'WA-Soil_12-0ympll' },
  { id: 'vineview.4x0zgm2s', layer: 'WA-Soil_13-14-4vs0py' },
  { id: 'vineview.1tg1fkdw', layer: 'WA-Soil_15-crned8' },
  { id: 'vineview.bfhiv91d', layer: 'WA-Soil_16-75w4lm' },
  { id: 'vineview.24xviquy', layer: 'WA-Soil_17-5l2xer' },
  { id: 'vineview.btxe4yzy', layer: 'WA-Soil_18-32cz3i' },
  { id: 'vineview.9hu1okkb', layer: 'WA-Soil_19-2kqxlj' },
  { id: 'vineview.40wcyf4p', layer: 'WA-Soil_20-09u54v' },
]

export const SOIL_WASHINGTON_DATA_LAYER = 'SOIL-WASHINGTON-polygon'
export const SOIL_WASHINGTON_LABEL_LAYER = 'SOIL-WASHINGTON-label'

const COLOR = 'hsl(0, 61%, 26%)'
const TEXT_COLOR = 'hsl(31, 65%, 84%)'
const TEXT_HALO_COLOR = 'hsl(31, 65%, 10%)'
const sources: Record<string, mapboxgl.VectorSource> = {}
const layers: mapboxgl.Layer[] = []

// add layers and sources from tilesets
tilesets.forEach(({ id, layer }, index) => {
  const sourceId = `washington-soil-${index}`

  sources[sourceId] = {
    url: `mapbox://${id}`,
    type: 'vector',
    maxzoom: 16,
    minzoom: 11,
  }

  const commonLayer = {
    source: sourceId,
    'source-layer': layer,
    layout: {},
  }

  // push line layer so that we can control line width
  layers.push({
    ...commonLayer,
    id: `SOIL-WASHINGTON-line-${index}`,
    type: 'line',
    paint: {
      'line-color': COLOR,
      'line-width': 2,
    },
  })

  // push polygon layer
  layers.push({
    ...commonLayer,
    id: `${SOIL_WASHINGTON_DATA_LAYER}-${index}`,
    type: 'fill',
    paint: {
      'fill-color': 'rgba(0, 0, 0, 0)',
      'fill-outline-color': COLOR,
    },
  })

  // push polygon layer
  layers.push({
    ...commonLayer,
    id: `${SOIL_WASHINGTON_LABEL_LAYER}-${index}`,
    type: 'symbol',
    layout: {
      'text-field': [
        'format',
        // split the string by ',' and get the first part
        [
          'slice',
          ['get', 'RasterT__4'],
          0,
          [
            'case',
            ['==', ['index-of', ', ', ['get', 'RasterT__4']], -1],
            ['length', ['get', 'RasterT__4']],
            ['index-of', ', ', ['get', 'RasterT__4']],
          ],
        ],
        {},
        '\n',
        {},
        // split the string by ',' and get the second part
        [
          'case',
          ['==', ['index-of', ', ', ['get', 'RasterT__4']], -1],
          '',
          [
            'slice',
            ['get', 'RasterT__4'],
            ['+', ['index-of', ', ', ['get', 'RasterT__4']], 2],
            ['length', ['get', 'RasterT__4']],
          ],
        ],
        { 'font-scale': 0.8 },
      ] as any,
      'text-size': 12,
      'text-justify': 'auto' as any,
      'symbol-placement': 'point',
      /** in ems */
      'text-offset': [0, 0.6],
    },
    paint: {
      'text-color': TEXT_COLOR,
      'text-halo-color': TEXT_HALO_COLOR,
      'text-halo-blur': 2,
      'text-halo-width': 2,
    },
  })
})

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  sources,
  layers,
}
