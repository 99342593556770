import * as React from 'react'

import {
  Icon,
  IconButton,
  List,
  ListItemText,
  MenuItem,
  Tooltip,
} from '@mui/material'

import {
  selectAvailableProducts,
  selectAvailableProductsByGroup,
} from '../../data/selectAvailableProducts'
import { selectProductGroupsById } from '../../data/selectListMapLayerDefGroups'
import { useRedux } from '../../hooks/useRedux'
import i18n, { keys } from '../../i18n'
import { DataLayerMenuGroupItem } from '../../legend/DataLayerMenuGroupItem'
import DataLayerMenuItem from '../../legend/DataLayerMenuItem'
import ElevationLayerToggle from '../../postgis/ElevationLayer/ElevationLayerToggle'
import LayerDrawerBackgroundRaster from '../../postgis/LayerDrawer/LayerDrawerBackgroundRaster'
import SoilLayerToggle from '../../postgis/SoilLayer/SoilLayerToggle'
import { updatePreferences } from '../../redux/preferences/redux'
import { ViewAs } from '../../ProductSettings/UI/ViewAs'
import { Coverage } from '../../ProductSettings/UI/Coverage'
import {
  setFocusedBlockId,
  setFocusedPoint,
  setFocusedPolygon,
  setStatsZonesToolActive,
} from '../../postgis/actions'
import { MapLayerDefData } from '../../data/types'
import { addArrayValues, toggleArrayValue } from '../../util/toggleArrayValue'

import * as userSelection from '../../data/userSelectionRedux'
import { selectSelectedLegendProductId } from '../../data/selectSelectedLegendProductId'
import { selectSelectedProductIds } from '../../data/selectSelectedProductIds'

export function LayerDrawerToggle() {
  const [state, dispatch] = useRedux()
  const { preferences } = state

  const { products } = selectAvailableProducts(state)

  const toggleLayerDrawer = (event: any) => {
    event.preventDefault()

    if (
      preferences.showSidebarRight &&
      preferences.sidebarTabRight === 'layers'
    ) {
      dispatch(updatePreferences({ showSidebarRight: false }))
    } else {
      dispatch(
        updatePreferences({ showSidebarRight: true, sidebarTabRight: 'layers' })
      )
    }
  }

  return (
    <Tooltip title={i18n.t(keys.legend.changeActiveProductTooltip)}>
      <IconButton
        id="dataLayerMenuButton"
        onClick={toggleLayerDrawer}
        style={{ position: 'relative' }}
        size="large"
      >
        <Icon>layers</Icon>
        <span
          style={{
            position: 'absolute',
            zIndex: 1,
            right: 0,
            fontSize: '1rem',
          }}
        >
          {products.length}
        </span>
      </IconButton>
    </Tooltip>
  )
}

export default function LayerDrawer() {
  const [state, dispatch] = useRedux()
  const products = selectAvailableProductsByGroup(state)
  const productGroupsById = selectProductGroupsById(state)

  const selectedLegendProductId = selectSelectedLegendProductId(state)
  const selectedProductIds = selectSelectedProductIds(state)

  const handleLayerToggle = (product: MapLayerDefData) => {
    dispatch(
      userSelection.update({
        selectedProductIds: toggleArrayValue(selectedProductIds, product.id),
        selectedLegendProductId: selectedLegendProductId ?? product.id,
      })
    )
    closePopups()
  }

  const handleLegendSelect = (product: MapLayerDefData) => {
    if (product.mapSourceDef.type !== 'point') {
      dispatch(setStatsZonesToolActive(false))
      dispatch(
        userSelection.update({
          selectedProductIds: [product.id],
          selectedLegendProductId: product.id,
        })
      )
    } else {
      dispatch(
        userSelection.update({
          selectedProductIds: addArrayValues(selectedProductIds, [product.id]),
          selectedLegendProductId: product.id,
        })
      )
    }
  }

  const handleExclusiveLayerSelect = (product: MapLayerDefData) => {
    if (product.mapSourceDef.type !== 'point') {
      dispatch(setStatsZonesToolActive(false))
      dispatch(
        userSelection.update({
          selectedProductIds: [product.id],
          selectedLegendProductId: product.id,
        })
      )
    } else {
      dispatch(
        userSelection.update({
          selectedProductIds: [product.id],
          selectedLegendProductId: product.id,
        })
      )
    }
  }

  const closePopups = () => {
    dispatch(setFocusedPoint(null))
    dispatch(setFocusedPolygon(null))
    dispatch(setFocusedBlockId(undefined))
  }

  const menuItems = Object.keys(products).map((groupId) => {
    const group = productGroupsById[groupId]

    if (!!group) {
      return (
        <DataLayerMenuGroupItem group={group} products={products[groupId]} />
      )
    }

    const [product] = products[groupId]
    return (
      <DataLayerMenuItem
        product={product}
        key={product.id}
        onLayerToggled={handleLayerToggle}
        onLayerLegendSelect={handleLegendSelect}
        onExclusiveLayerSelect={handleExclusiveLayerSelect}
      />
    )
  })

  return (
    <List dense>
      {/* wrap each section in a div to push previous stickies out of the way. */}
      <MenuItem divider disabled>
        <ListItemText
          style={{ paddingLeft: 0 }}
          primary={i18n.t(keys.legend.selectActiveLayerMenuTitle)}
        />
        <ListItemText
          style={{ paddingRight: 0, textAlign: 'right' }}
          primary={i18n.t(keys.legend.colorLegend)}
        />
      </MenuItem>
      {menuItems}
      <LayerDrawerBackgroundRaster />
      <hr />
      <SoilLayerToggle />
      <ElevationLayerToggle />
      <hr />
      <ViewAs />
      <Coverage />
    </List>
  )
}
