import { selectMapLayerDefsById } from '../../../data/selectListMapSourceDefs'
import { RateMapContextState } from '../editor/RateMapContext'
import { RootStore } from '../../../redux/types'
import { createSelector } from 'reselect'

const getExternalState = (_: RateMapContextState, externalState: RootStore) =>
  externalState

export const selectRateMapLayerDefsById = createSelector(
  getExternalState,
  (externalstate) => selectMapLayerDefsById(externalstate)
)
