import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import * as React from 'react'
import i18n, { keys } from '../../../../../../../i18n'
import { isEmpty } from 'lodash'
import { ParcelFilterInfo } from '../../../../../../../postgis/types'
import { selectProductsForOrg } from '../../../../../../../data/selectListMapSourceDefs'
import { useRedux } from '../../../../../../../hooks/useRedux'

interface Props {
  parcelFilterInfo: ParcelFilterInfo
  onSelect: (products: string[]) => void
  onReset: () => void
  disabled?: boolean
}

const CLEAR_FILTER = 'CLEAR_FILTER'

const ProductFilter = ({
  parcelFilterInfo,
  onSelect,
  onReset,
  disabled,
}: Props) => {
  const [state] = useRedux()
  const products = selectProductsForOrg(state)
  const selectedValues = React.useMemo(
    () => (parcelFilterInfo?.products ?? []) as string[],
    [parcelFilterInfo.products]
  )

  const handleSelectionChange = React.useCallback(
    (event: SelectChangeEvent<string[]>) => {
      let selection = event.target.value
      if (typeof selection === 'string') {
        selection = selection.split(', ')
      }
      if (selection.includes(CLEAR_FILTER)) {
        return
      }

      onSelect(selection)
    },
    [onSelect]
  )

  const resetSelection = React.useCallback(() => {
    onReset()
  }, [onReset])

  return (
    <ListItem>
      <FormControl fullWidth>
        <InputLabel>{i18n.t(keys.productsText)}</InputLabel>

        <Select
          disabled={disabled || products.length === 0}
          multiple
          value={selectedValues}
          onChange={handleSelectionChange}
          renderValue={(selected: any[]) =>
            products
              .filter(({ id }) => selected.includes(id))
              .map(({ name }) => name)
              .join(', ')
          }
        >
          <MenuItem
            value={CLEAR_FILTER}
            disabled={disabled || isEmpty(selectedValues)}
          >
            <ListItemText
              primary={i18n.t(keys.forms.parcelFilter.resetSelection)}
              onClick={resetSelection}
            />
          </MenuItem>
          {products.map(({ id: value, name }) => (
            <MenuItem key={value} value={value}>
              <Checkbox checked={selectedValues.includes(value)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ListItem>
  )
}

export default ProductFilter
