function buildTranslationKeys<T>(translationKeys: T, prefix = ''): T {
  const newObj: any = {}

  // tslint:disable-next-line:forin
  for (const key in translationKeys) {
    const newPrefix = prefix === '' ? key : `${prefix}.${key}`
    if (typeof translationKeys[key] === 'string') {
      newObj[key] = newPrefix
    } else {
      newObj[key] = buildTranslationKeys(translationKeys[key], newPrefix)
    }
  }

  return newObj as T
}

export const keys = buildTranslationKeys({
  notificationsTitle: '',
  notificationsManage: '',
  notifications: {
    'order|confirmation': {
      subscribeText: '',
      subscriptionDescription: '',
    },
    'target-delivery|flight-notice': {
      subscribeText: '',
      subscriptionDescription: '',
    },
    'delivery|flight-complete': {
      subscribeText: '',
      subscriptionDescription: '',
    },
    'delivery|data-available': {
      subscribeText: '',
      subscriptionDescription: '',
    },
  },
  theme: { light: '', dark: '', themePicker: '' },
  selectDate: '',
  forSelectedParcels: '',
  forOtherParcels: '',
  productsText: '',
  beta: '',
  betaMessage: '',
  errors: {
    somethingWentWrong: '',
    tapToReload: '',
    reportAnIssue: '',
  },
  user: {
    user: '',
    firstName: '',
    lastName: '',
    password: '',
    organization: '',
    confirmPassword: '',
    email: '',
    switchOrganization: '',
    role: '',
  },
  focusedBlockDialog: {
    fieldInformation: '',
    name: '',
    area: '',
    varietals: '',
    rootstock: '',
    trellisType: '',
    EVImean: '',
    EVIstdev: '',
    canopyArea: '',
    CWCmean: '',
    undetectedVines: '',
    totalVines: '',
    rowSpacing: '',
    vineSpacing: '',
  },
  soilLayer: {
    soilInformation: '',
    layerToggle: '',
  },
  elevationLayer: {
    layerToggle: '',
  },
  forms: {
    // don't re-name this key. It's part of the server validation contract.
    notNull: '',
    confirmation: '',
    password: {
      lowercase: '',
      uppercase: '',
      number: '',
      symbol: '',
      length: '',
    },
    resetPassword: {
      title: '',
      submit: '',
    },
    login: {
      login: '',
      loginError: '',
      passwordRequired: '',
      emailRequired: '',
      notChromeWarning: '',
      emailOrPasswordIncorrect: '',
    },
    forgotPassword: {
      title: '',
      submit: '',
      forgotMessage: '',
      requestedMessage: '',
    },
    invalidToken: '',
    // don't re-name this key. It's part of the server validation contract.
    isUnique: '',
    done: '',
    parcelFilter: {
      advancedFilters: '',
      deliveriesFor: '',
      selectDelivery: '',
      filter: '',
      search: '',
      varietal: '',
      fromDate: '',
      toDate: '',
      noDate: '',
      today: '',
      sort: '',
      sortAlpha: '',
      sortTime: '',
      resetSelection: '',
      rootstock: '',
      trellisType: '',
      noVarietals: '',
      noRootstocks: '',
      noTrellises: '',
    },
  },
  colorScheme: {
    chooseColorSchemeButton: '',
    chooseColorScheme: '',
    revertToDefaults: '',
    redYellowGreen: '',
    recommended: '',
    recommendedEVI: '',
    allBlues: '',
    redYellowBlue: '',
    cyanToRed: '',
    showHealthyAsGreen: '',
    showSymptomaticAsRed: '',
    showPresentAsGreen: '',
    relativeThermal: '',
    missingVines: '',
    pureVineDefault: '',
    ceviDefault: '',
    legacy: '',
    redleafDefault: '',
  },
  legend: {
    selectActiveLayerMenuTitle: '',
    changeActiveProductTooltip: '',
    changeColorSchemeTooltip: '',
    scaleSwitcherTooltip: '',
    filterTooltip: '',
    clearFilter: '',
    isolateColorTooltip: '',
    colorLegend: '',
    noLegend: '',
    noDataSelected: '',
    scale: {
      absolute: '',
      relative: '',
    },
  },
  logout: '',
  logoutConfirm: '',
  serverError: '',
  cursorLon: '',
  cursorLat: '',
  generic: {
    any: '',
    alertConst: '',
    back: '',
    cancel: '',
    clear: '',
    close: '',
    confirm: '',
    continue: '',
    copied: '',
    copy: '',
    create: '',
    created: '',
    csv: '',
    shapefile: '',
    geojson: '',
    delete: '',
    duplicate: '',
    download: '',
    downloadFormat: '',
    edit: '',
    expires: '',
    export: '',
    help: '',
    high: '',
    loading: '',
    low: '',
    na: '',
    next: '',
    no: '',
    noThanks: '',
    noData: '',
    none: '',
    ok: '',
    refresh: '',
    remove: '',
    reset: '',
    retry: '',
    save: '',
    selectAll: '',
    update: '',
    view: '',
    xlsx: '',
    pdf: '',
    yes: '',
    unavailable: '',
    name: '',
    observations: '',
    average: '',
    successLevels: {
      none: '',
      low: '',
      fair: '',
      good: '',
      excellent: '',
    },
    learnMore: '',
    noItems: '',
    on: '',
    off: '',
    move: '',
  },
  attachments: {
    attachments: '',
    chooseFile: '',
    fileLimitWarning: '',
  },
  gis: {
    zone: '',
    area: '',
    canopyArea: '',
    undetectedVine: '',
  },
  toggleStats: '',
  products: {
    leafroll_high: '',
    leafroll_low: '',
  },
  productNames: {
    EVI_POINT: '',
    VIGOR_ZONE: '',
    PURE_VIGOR_ZONE: '',
    TRUE_COLOR: '',
    GRAY_SCALE: '',
    LEAFROLL: '',
    LEAFROLL_RASTER: '',
    WATER_INDEX: '',
    EVI_LEGACY_RELATIVE: '',
    EVI_LEGACY: '',
    CIR: '',
    RELATIVE_THERMAL_ZONE: '',
    RELATIVE_THERMAL_RASTER: '',
    CANOPY_GAP: '',
    MISSING_VINE: '',
    FD: '',
    RED_LEAF: '',
    RED_LEAF_RASTER: '',
  },
  productDescriptions: {
    EVI_POINT: '',
    VIGOR_ZONE: '',
    PURE_VIGOR_ZONE: '',
    EVI_LEGACY_RELATIVE: '',
    EVI_LEGACY: '',
    CANOPY_GAP: '',
    TRUE_COLOR: '',
    CIR: '',
    GRAY_SCALE: '',
    LEAFROLL: '',
    LEAFROLL_RASTER: '',
    WATER_INDEX: '',
    TEMPERATURE_ZONE: '',
    RELATIVE_THERMAL_ZONE: '',
    RELATIVE_THERMAL_RASTER: '',
    MISSING_VINE: '',
    FD: '',
    RED_LEAF: '',
    RED_LEAF_RASTER: '',
  },
  valueNames: {
    EVI: '',
    TRUE_COLOR: '',
    GRAY_SCALE: '',
    LR_Score: '',
    CWC: '',
    CIR: '',
    Thermal: '',
    CanopyGap: '',
    MV_Class: '',
    FD: '',
    RedMean: '',
    PercentRed: '',
    PercentLR: '',
    Area: '',
    RowID: '',
    PlantID: '',
  },
  valueAcronyms: {
    EVI: '',
    TRUE_COLOR: '',
    GRAY_SCALE: '',
    LR_Score: '',
    CWC: '',
    CIR: '',
    Thermal: '',
    CanopyGap: '',
    MV_Class: '',
    FD: '',
    RedMean: '',
    PercentRed: '',
    PercentLR: '',
  },
  map: {
    map: '',
    selectAField: '',
    allParcels: '',
    someParcels: '',
    resetBearing: '',
    mapSettings: '',
  },
  customLayers: {
    editLayers: '',
  },
  parcelDrawer: {
    ungrouped: '',
    unknownList: '',
    mostRecentDate: '',
    noDataForCurrentDate: '',
    selectParcels: '',
    noResults: '',
    unavailableForSelectedDate: '',
  },
  notes: {
    failedToLoad: '',
    failedToSave: '',
    failedToDelete: '',
    showOnMap: '',
    pinColor: '',
    filter: {
      search: '',
      fromDate: '',
      toDate: '',
      allTemplates: '',
      allProjects: '',
    },
    general: {
      placeholder: '',
    },
    editLocationSnackbar: '',
    noteSaved: '',
    noteDelete: '',
    undo: '',
    notesPopup: {
      save: '',
      note: '',
      location: {
        location: '',
        currentLocation: '',
        pointOnMap: '',
        areaOnMap: '',
      },
      deleteConfirmTitle: '',
      deleteConfirmMessage: '',
      saveLocation: '',
      updateLocation: '',
      noteType: '',
      createAnother: '',
      editLocation: '',
      currentLocationAgo: {
        unavailable: '',
        lastUpdated: '',
      },
      newNote: '',
      editNoteType: '',
      selectLocation: '',
      pointInstruction: '',
      polygonInstruction: '',
      jumpToCurrentLocation: '',
    },
    createdBy: '',
    updatedBy: '',
    export: '',
    fileName: '',
    fileNameValidation: '',
    exportBoxTitle: '',
    noNotes: '',
    downloadImagesTitle: '',
    downloadImagesDescription: '',
    deleteImageTitle: '',
    deleteImageDescription: '',
    deleteImagesTitle: '',
    deleteImagesDescription: '',
    file: '',
    files: '',
    Files: '',
    projects: '',
  },
  layerDrawer: {
    fieldBoundaries: '',
    fieldLabels: '',
    notes: '',
    toggleScaleBar: '',
    toggleCursorLocation: '',
    backgroundRaster: '',
    numberOfGroups: '',
    value: '',
    area: '',
    downloadDialog: {
      title: '',
      description: '',
      downloadPNG: '',
      printMap: '',
      exportMapHeading: '',
    },
  },
  userPosition: {
    mapToolbarTooltip: '',
    error: {
      missingPermission: '',
      positionNotAvailable: '',
      unknown: '',
    },
  },
  preferences: '',
  clearSettings: '',
  clearSettingsDescription: '',
  darkMode: '',
  fieldsTab: '',
  rateMapTab: '',
  notesTab: '',
  colorsTab: '',
  layersTab: '',
  settingsTab: '',
  zoomToSelection: '',
  demo: '',
  noteForm: {
    failedToLoad: '',
    template: '',
    templateTitle: '',
    reservedNames: {
      pinpoint: {
        title: '',
        button: '',
        double: '',
        horizontalAccuracy: '',
        numSV: '',
        altitude: '',
        hmsl: '',
        fixType: '',
        date: '',
        label: '',
      },
      note: '',
      __VV__GENERAL__TEMPLATE__: '',
      __VV_PINPOINT_CAPTURE__: '',
      __VV__VINEYARD_PRODUCTION_INSPECTION__: '',
      __VV__PRODUCTION_STATUS__: '',
      __VV__PRODUCING__: '',
      __VV__NONPRODUCING__: '',
      __VV__ROOTSTOCK__: '',
      __VV__MISSING__: '',
      __VV__NA__: '',
      __VV__IRRIGATION_SUPPLY__: '',
      __VV__PRIMARY__: '',
      __VV__SECONDARY__: '',
      __VV_NONE__: '',
      __VV__VIRUS_STATUS__: '',
      __VV__POWDERY_MILDEW__: '',
      __VV__DOWNY_MILDEW__: '',
      __VV__BOTRYTIS__: '',
      __VV__PIERCES__: '',
      __VV__ESCA__: '',
      __VV__LEAFROLL__: '',
      __VV__RED_BLOTCH__: '',
      __VV__DISEASE_INSPECITON__: '',
      __VV__CLUSTER_COUNT__: '',
      __VV__SHATTER_PRESENCE__: '',
      __VV__CLUSTERS_INFECTED__: '',
      __VV__DISEASE_OBSERVED__: '',
      __VV__SAMPLE_TAKEN__: '',
      __VV__CANOPY_SYMPTOMATIC__: '',
      __VV__SHATTER__: '',
      __VV_PERCENTAGE__: '',
      __VV_BLACK_ROT__: '',
      __VV_LEAF_SPOT__: '',
      __VV_CLUSTER_WEIGHT__: '',
    },
    errors: {
      errorDeleting: '',
      errorSaving: '',
      templateHasErrors: '',
      atLeastOneField: '',
      mustDefineOptions: '',
      formTitleRequired: '',
      fieldTitleRequired: '',
      fieldTitleMustBeUnique: '',
      duplicateFormName: '',
    },
    fieldTypes: {
      text: '',
      number: '',
      dropdown: '',
      checkbox: '',
      checkboxes: '',
    },
    manage: {
      title: '',
      info: '',
      templateNameFilter: '',
      columns: { templateName: '', createdDate: '', createdBy: '' },
      newTemplate: '',
      dialogs: {
        delete: { title: '', message: '' },
      },
    },

    addOption: '',
    titlePlaceholder: '',
    newTemplate: '',
    addField: '',
    addNew: '',
    formName: '',
    propertyName: '',
    propertyType: '',
    propertyEnum: '',
    propertyEnumOptions: '',
    toggleAdmin: '',
    newForm: '',
    newProperty: '',
    showAsMenu: '',
    deleteProperty: '',
    addProperty: '',
    cantDeleteInUse: '',
    note: '',
    date: '',
    text: '',
    number: '',
    truefalse: '',
    choices: '',
    formNameError: '',
    propertyNameError: '',
    formAndPropertyError: '',
    createNote: '',
  },
  preview: '',
  areYouSure: '',
  confirmDelete: '',
  save: '',
  delete: '',
  formType: '',
  dataDownload: {
    buttonText: '',
  },
  reports: {
    singular: '',
    plural: '',
    chooseDataProducts: '',
    includeStats: '',
    dataProducts: '',
    noProducts: '',
    reportOptions: '',
    includeBlockReports: '',
    showBlockBoundaries: '',
    title: '',
    print: '',
    preparedBy: '',
    preparedByOn: '',
    flightDate: '',
    whatsInside: '',
    client: '',
    ranch: '',
    vineSpacing: '',
    dataProduct: '',
    blockArea: '',
    detectedVines: '',
    undetectedVines: '',
    areaNotInUse: '',
    rows: '',
    meanEVI: '',
    includeRelative: '',
    pages: '',
    flightInformation: '',
    blockInformation: '',
    groupPage: '',
    individualPages: '',
    EVImean: '',
    EVIstdev: '',
    EVIcov: '',
    PercentGaps: '',
  },
  visualization: {
    visualization: '',
    primary: '',
    secondary: '',

    absolute: '',
    relative: '',
    threshold: '',
    coverage: '',
    coverageMin: '',
    coverageMax: '',

    numberOfGroups: '',
    groupBy: '',
    equalAreas: '',
    valueRanges: '',

    opacity: '',
    filter: '',

    filterProperty: '',
  },
  units: {
    unitSystemPicker: '',
    areaPicker: '',
    areaLabel: '',
    unitSystem: {
      metric: '',
      imperial: '',
    },
    area: {
      hectare: '',
      acre: '',
    },
    lengthPicker: '',
    lengthLabel: '',
    length: {
      meter: '',
      foot: '',
    },
    perimiterLabel: '',
    plantCountLabel: '',
    countLabel: '',
  },

  language: {
    languagePicker: '',
  },

  statistics: {
    statisticsPicker: '',
    values: '',
    percentages: '',
  },

  stats: {
    historic: '',
    current: '',
    organization: '',
    group: '',
    block: '',
    date: '',
    mean: '',
    stDev: '',
    total: '',
    undetected: '',
    coverage: '',
    totalWithUndetected: '',
    missing: '',
    present: '',
    download: '',
    headerTitle: '',
    classes: {
      Missing: '',
      0: '',
      Present: '',
      1: '',
      Dead: '',
      2: '',
    },
    usage: '',
    usageTooltip: '',
    coefficientOfVariation: '',
    coefficientOfVariationTooltip: '',
    acres: '',
    hectares: '',
    average: '',
    currentSelection: '',
    trendTooltip: '',
  },
  measure: '',
  statsZones: '',
  drawPolygon: '',
  drawLine: '',
  clearAll: '',
  download: '',
  copyUrl: '',
  copyFailed: '',
  copyFailedDetail: '',
  copySuccess: '',
  openInAvenza: '',
  reprocessDownload: '',
  reprocessDownloadAll: '',
  downloadPending: '',
  downloadError: '',
  allFiles: '',
  requestDownload: '',
  requestDownloadAll: '',
  confirmRequestDownload: '',
  confirmRequestDownloadMessage: '',
  confirmReprocessDownload: '',
  confirmReprocessDownloadMessage: '',
  downloadRequested: '',
  downloadRequestedCheckBack: '',
  downloadRequestError: '',
  downloadRequestErrorTitle: '',
  dotSize: '',
  small: '',
  medium: '',
  large: '',
  extraLarge: '',
  manageOrganization: '',
  removeUser: '',
  uninviteUser: '',
  resendInvite: '',
  addNewUser: '',
  add: '',
  confirmUpdateUser: '',
  confirmUpdateUserTitle: '',
  confirmUpdateUserSuccess: '',
  confirmRemoveUser: '',
  confirmRemoveUserTitle: '',
  confirmRemoveUserSuccess: '',
  confirmUpdateSelf: '',
  confirmUpdateSelfTitle: '',
  confirmRemoveSelf: '',
  confirmRemoveSelfTitle: '',
  manageOrgNotPermitted: '',
  invitedBy: '',
  confirmUninviteUser: '',
  confirmUninviteUserTitle: '',
  confirmUninviteUserSuccess: '',
  resend: '',
  revoke: '',
  confirmInviteResend: '',
  confirmInviteResendTitle: '',
  confirmInviteResendSuccess: '',
  signup: '',
  inviteInvalid: '',
  continueWith: '',
  inviteMessage: '',
  join: '',
  joinWithOther: '',
  emailInUse: '',
  emailInvalid: '',
  signupError: '',
  inviteConflict: '',
  inviteForEmailAlreadyExists: '',
  userAlreadyBelongsToOrg: '',
  invitation: '',
  joinedOrgSuccessTitle: '',
  joinedOrgSuccess: '',
  accepted: '',
  expired: '',
  wrongInviteEmail: '',
  switchAcount: '',
  toAcceptInvite: '',
  mustBeSignedIn: '',
  signUpToAccept: '',
  invitationPending: '',
  invitationExpired: '',
  editName: '',
  members: '',
  fields: '',
  groupDownloadByRequest: '',
  groupDownloadToRequest: '',
  groupDownloadMayTakeAMinute: '',
  groupDownloadOnceGenerated: '',
  groupDownloadOnceGeneratedPost: '',
  speedTracker: '',
  showSpeedTracker: '',
  gps: '',
  showLatLon: '',
  prescribedSpeed: '',
  currentSpeed: '',
  soundWhenChangingZones: '',
  GPSLocation: '',
  adjustMapPosition: '',
  adjustCurrentGPSMapPosition: '',
  moveTheMapToSetCorrectLocation: '',
  offset: '',
  userLocation: '',
  cursorLocation: '',
  distance: '',
  geoPdfDownload: '',
  geoPdfDownloadByRequest: '',
  productGuide: '',
  appGuide: '',
  faq: '',
  contactUs: '',
  name: '',
  requestedBy: '',
  actions: '',
  status: '',
  downloads: '',
  dateRequested: '',
  deleteDownload: '',
  deleteDownloadConfirm: '',
  filename: '',
  groupName: '',
  flightDate: '',
  blockByBlockAverage: '',
  'manage-subscription': '',
  paySubscription: '',
  productPriceDescription: '',
  noSubscriptions: '',
  renewsOn: '',
  invoiceNotSent: '',
  month: {
    one: '',
    other: '',
  },
  year: {
    one: '',
    other: '',
  },
  groupDownloadsFilename: '',
  multipleDownloads: '',
  rowsPerPage: '',
  tableRowsPerPage: '',
  nextPage: '',
  previousPage: '',
  filenames: {
    'all-files': {
      zip: '',
    },
  },
  filters: '',
  all: '',
  readyForDownload: '',
  inProgress: '',
  complete: '',
  pending: '',
  processing: '',
  error: '',
  unprocessed: '',
  downloadType: '',
  geoPdf: '',
  rawData: '',
  request: '',
  selectType: '',
  groupsAndDates: '',
  settings: '',
  reviewAndRequest: '',
  selectGroupsAndDates: '',
  groups: '',
  allGroups: '',
  editGroupAccess: '',
  dates: '',
  geoPdfSettings: '',
  product: '',
  duplicatesFoundTitle: '',
  duplicatesFoundDetail: '',
  skip: '',
  replace: '',
  skipAll: '',
  replaceAll: '',
  rawDataDownloadSettings: '',
  file: '',
  noFilesAffectedTitle: '',
  noFilesAffectedDetail: '',
  colorProfile: '',
  filesCount: '',
  downloadTableInstruction: '',
  numberOfFiles: '',
  expirationDate: '',
  filterBy: '',
  viewAs: '',
  popOutStats: '',
  dockStats: '',
  range: '',
  downloadChartDataTitle: '',
  closeStatWindow: '',
  weightedAvgWithError: '',
  historicChartConfig: {
    xAxisLabel: '',
    yAxisLabel: '',
    meanLabel: '',
    stdevLabel: '',
    weightLabel: '',
    mean: '',
    stdev: '',
    weight: '',
  },
  downloadOutputTypeLabel: '',
  downloadMergeGroupsLabel: '',
  downloadBlockByBlockLabel: '',
  downloadBothLabel: '',
  pauseAllJobs: '',
  unpauseAllJobs: '',
  mobileAdvertisementTitle: '',
  mobileAdvertisementText: '',
  samplePlanDashboard: '',
  samplePlanNameColumnLabel: '',
  samplePlanDateColumnLabel: '',
  samplePlanFormColumnLabel: '',
  samplePlanBlocksColumnLabel: '',
  samplePlanSamplesColumnLabel: '',
  samplePlanLastUpdateColumnLabel: '',
  samplePlanStatusColumnLabel: '',
  samplePlanStatusPendingLabel: '',
  samplePlanStatusInProgressLabel: '',
  samplePlanStatusCompleteLabel: '',
  samplePlanCreateNewLabel: '',
  samplePlanMoreMenuMarkAsCompleteLabel: '',
  samplePlanBlockIdColumnLabel: '',
  samplePlanBlockLocationColumnLabel: '',
  samplePlanBlockSampleCountColumnLabel: '',
  samplePlanDownloadPlanLabel: '',
  samplePlanDownloadPlanFormTitle: '',
  samplePlanDownloadPlanFormDescLabel: '',
  samplePlanNewLabel: '',
  samplePlanNewStep1Label: '',
  samplePlanNewStep2Label: '',
  samplePlanNewStep3Label: '',
  samplePlanNewStep4Label: '',
  samplePlanNewStep5Label: '',
  samplePlanNewStep6Label: '',
  samplePlanNewNameLabel: '',
  samplePlanNewNameTipLabel: '',
  samplePlanNewSampleTypeLabel: '',
  samplePlanNewStatisticalMethodLabel: '',
  samplePlanNewBlockSelectionLabel: '',
  samplePlanNewSameForAllLabel: '',
  samplePlanNewMarginOfErrorLabel: '',
  samplePlanNewAvgMarginOfErrorLabel: '',
  samplePlanNewConfidenceLevelLabel: '',
  samplePlanNewMarginOfErrorTooltip: '',
  samplePlanNewDivergenceTooltip: '',
  samplePlanNewZonesPerBlockLabel: '',
  samplePlanNewZonesPerBlockErrorLabel: '',
  samplePlanNewSelectFormTemplateLabel: '',
  samplePlanNewCreateFormTemplateLabel: '',
  samplePlanNewProjectInstructionsLabel: '',
  samplePlanNewFormsLabel: '',
  samplePlanNewSampleNumberPerBlock: '',
  samplePlanNewSameForAll: '',
  samplePlanNewGenerateLabel: '',
  samplePlanNewFormTemplatePlaceholderLabel: '',
  samplePlanNewChooseMethod: '',
  samplePlanNewSampleTypePlaceholderLabel: '',
  samplePlanNewSampleFieldPlaceholderLabel: '',
  samplePlanNewSampleDataSourcePlaceholderLabel: '',
  samplePlanResultCheckBoxContentTrue: '',
  samplePlanResultCheckBoxContentFalse: '',
  writeHere: '',
  next: '',
  confirm: '',
  back: '',
  sampleResultBlockLabel: '',
  sampleResultSamplerLabel: '',
  sampleResultCompletionDateLabel: '',
  sampleResultCompletionHourLabel: '',
  samplePlanDownloadTitleLabel: '',
  samplePlanDownloadDescLabel: '',
  samplePlanDownloadCsv: '',
  samplePlanDownloadGeoJson: '',
  samplePlanReportTitle: '',
  samplePlanNoSamplePlans: '',
  search: '',
  results: '',
  seeResults: '',
  filterIconTooltip: '',
  orderIconTooltip: '',
  completedBy: '',
  allLocations: '',
  allBlocks: '',
  samplePlanMap: '',
  projectInstructions: '',
  samplePlanProjectInstructionsPlaceholder: '',
  notesProjectInstructionsPlaceholder: '',
  marginOfError: '',
  divergence: '',
  sample: '',
  moreInfo: '',
  unableToPrintMapTitle: '',
  unableToPrintMapMessage: '',
  createProjectLabel: '',
  editProjectLabel: '',
  deleteProjectLabel: '',
  deleteProjectPromptMessage1: '',
  deleteProjectPromptMessage2: '',
  deleteProjectSuccessMessage: '',
  deleteProjectFailedMessage: '',
  deleteSelectedNotesPromptLabel: '',
  deleteSelectedNotesPromptMessage: '',
  deleteNotesSuccessMessage: '',
  deleteNotesFailedMessage: '',
  moveAllProjectNotesLabel: '',
  moveAllProjectNotesPromptLabel: '',
  moveAllProjectNotesPromptMessage: '',
  moveAllProjectNotesSuccessMessage: '',
  moveAllProjectNotesFailedMessage: '',
  moveNotesModalTitle: '',
  moveNotesModalDescription: '',
  selectNotesLabel: '',
  selectAllNotesLabel: '',
  makeProjectPrivateLabel: '',
  projectLabel: '',
  projectNameLabel: '',
  projectNamePlaceholder: '',
  projectInstructionsLabel: '',
  projectInstructionsPlaceholder: '',
  projectCreateNewLabel: '',
  samplePlanMissingDatasourcesWarning: '',
  samplePlanAllFlightDates: '',
  samplePlanDuplicate: '',
  samplePlanDuplicateConfirmation: '',
  samplePlanRename: '',
  samplePlanDelete: '',
  samplePlanDeleteConfirmation: '',
  sampleTemplate: '',
  samplePlanVinesPerRow: '',
  projectsToExport: '',
  tools: '',
  rateMap: '',
  rateMapDescription: '',
  samplePlanDescription: '',
  rateMapNameColumnLabel: '',
  rateMapDateColumnLabel: '',
  rateMapBlockColumnLabel: '',
  rateMapAmendmentTypeColumnLabel: '',
  rateMapEditColumnButtonLabel: '',
  newRateMap: '',
  rateMapEmptyLabel: '',
  rateMapCreateCustomZones: '',
  rateMapDelete: '',
  rateMapDeleteConfirmation: '',
  rateMapDuplicate: '',
  rateMapDuplicateConfirmation: '',
  rateMapDownloadLabel: '',
  rateMapDownloadBlockSelectionLabel: '',
  rateMapDownloadFormatLabel: '',
  noAvailableDates: '',
  rateMapMapColor: '',
  rateMapRateOfApplication: '',
  rateMapTotalAmountPerZone: '',
  rateMapVigorZoneTypeTitle: '',
  rateMapVigorZoneTypeDescription: '',
  rateMapSetUpAmendmentTitle: '',
  rateMapSetUpAmendmentDescription: '',
  rateMapFinalOutputTitle: '',
  rateMapFinalOutputDescription: '',
  rateMapSupplimentalDataLayersTitle: '',
  rateMapSupplimentalDataLayersDescription: '',
  rateMapAmendmentTypeTitle: '',
  rateMapUnitTypeTitle: '',
  rateMapUnitCostTitle: '',
  rateMapTotalApplicationAmountTitle: '',
  rateMapCostTitle: '',
  rateMapAddAmendmentModalTitle: '',
  rateMapDeleteAmendmentModalTitle: '',
  rateMapDeleteAmendmentModalDescription: '',
  rateMapAddAmendmentNameLabel: '',
  rateMapSaveModalTitle: '',
  rateMapAbsoluteZones: '',
  rateMapRelativeZones: '',
  rateMapRate: '',
  rateMapAmount: '',
  rateMapAmendmentDuplicateError: '',
  rateMapDeleteAmendmentModalAffectedMaps: '',
  rateMapDeleteAmendmentModalAffectedMapsSingular: '',
  rateMapDeleteAmendmentModalAffectedMapsPlural: '',
  rateMapInvalidGeometryWarning: '',
  rateMapMoreOptions: '',
  rateMapRectangle: '',
  rateMapPolygon: '',
  rateMapSnapping: '',
  rateMapLimitToBounds: '',
  rateMapCreateNewZone: '',
  rateMapConfirmAllZones: '',
  rateMapPropertyName: '',
  rateMapSupplementalDataLayersTitle: '',
  rateMapSupplementalDataLayersDescription: '',
  rateMapNoSupplementalDataLayers: '',
  rateMapLimitToBoundsTooltip: '',
  rateMapSnappingTooltip: '',
  rateMapPolygonTooltip: '',
  rateMapRectangleTooltip: '',
  filterListByMapView: '',
})
;(window as any).translations = keys

export type Translations = typeof keys
