import { Stack, styled, Typography } from '@mui/material'
import * as React from 'react'
import i18n, { keys } from '../../../i18n'
import { NewSamplePlanButton } from './NewSamplePlanButton'
import { ReactComponent as SamplePlanIcon } from '../../SamplePlan/assets/sample_plan_icon.svg'

const Container = styled(Stack)({
  backgroundColor: 'rgb(58, 58, 58)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '300px',
  fontSize: 24,
  rowGap: '16px',
})

const EmptyViewText = styled(Typography)({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: '0.5px',
})

export const SamplePlanEmptyView = () => {
  return (
    <Container direction="column">
      <SamplePlanIcon />
      <EmptyViewText>{i18n.t(keys.samplePlanNoSamplePlans)}</EmptyViewText>
      <NewSamplePlanButton variant="contained" />
    </Container>
  )
}
