import { createSelector } from 'reselect'
import { selectSelectedLegendProductId } from '../../data/selectSelectedLegendProductId'
import { selectSelectedMapLayersByLayerDefId } from '../../data/selectSelectedMapLayersByLayerDefId'

export const selectHistoricalTabEnabled = createSelector(
  [selectSelectedMapLayersByLayerDefId, selectSelectedLegendProductId],
  (selectedMapLayersById, selectedLegendProductId) =>
    selectedMapLayersById[selectedLegendProductId]?.mapLayerDef
      ?.historicalChartCalculationType !== undefined &&
    selectedMapLayersById[selectedLegendProductId]?.mapLayerDef
      ?.historicalChartCalculationType !== null &&
    selectedMapLayersById[selectedLegendProductId]?.mapLayerDef
      ?.historicalChartCalculationType !== ''
)
