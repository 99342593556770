import { IMapActionGenerator } from './types/MapActionTypes'
import { PointNoteActionGenerator } from './click-generators/PointNoteActionGenerator'
import { NoActionGenerator } from './click-generators/NoActionGenerator'
import { GlDrawActionGenerator } from './click-generators/GlDrawActionGenerator'
import { BlockActionGenerator } from './click-generators/BlockActionGenerator'
import { AreaNoteActionGenerator } from './click-generators/AreaNoteActionGenerator'
import { PointActionGenerator } from './click-generators/PointActionGenerator'
import { MultiPolygonActionGenerator } from './click-generators/MultiPolygonActionGenerator'
import { SoilActionGenerator } from './click-generators/SoilActionGenerator'
import { PolygonActionGenerator } from './click-generators/PolygonActionGenerator'
import { BlockByBlockActionGenerator } from './click-generators/BlockByBlockActionGenerator'
import { PolygonByColourActionGenerator } from './click-generators/PolygonByColourActionGenerator'
import { MapboxGL } from '../../MapboxGL'
import { IMapActionFactoryParams } from './types/MapActionFactoryParams'
import { EnterNoteGenerator } from './enter-generators/EnterNoteGenerator'
import { LeaveGenerator } from './leave-generators/LeaveGenerator'
import { PointNoteClusterActionGenerator } from './click-generators/PointNoteClusterActionGenerator'

export interface IMapActionFactory {
  /**
   * Registers the action generator with the factory. Action generator define the actions taken when a map event occurs.
   * All action generators need to implement the {@link IMapActionGenerator}.
   * See {@link PointNoteActionGenerator} for an example implementation.
   * @param actionGenerator The action generator to be registe10red with the factory.
   */
  register(actionGenerator: IMapActionGenerator): void

  /**
   * Resolves the first action to qualify for the event by running the associated execute function.
   * The priority of the action run is determined by the {@link Priority}.
   * @param params Parameters needed to produce
   */
  resolveFirst(params: IMapActionFactoryParams): void
}

export class MapActionFactory implements IMapActionFactory {
  private registeredGenerators: Record<string, IMapActionGenerator> = {}

  constructor(defaultRegisteredActions: IMapActionGenerator[]) {
    for (const action of defaultRegisteredActions) {
      this.register(action)
    }
  }

  register(actionGenerator: IMapActionGenerator): void {
    if (this.registeredGenerators[actionGenerator.key]) {
      throw new Error('Action has already been registered.')
    }
    this.registeredGenerators[actionGenerator.key] = actionGenerator
  }

  resolveFirst(params: IMapActionFactoryParams): void {
    const { event, map } = params
    // Get the set of features from Mapbox.
    const features = map.queryRenderedFeatures(
      event.point
    ) as MapboxGL.MapboxGeoJSONFeature[]

    // get the generators and sort by priority. This ensures the first generator
    const generators = Object.values(this.registeredGenerators).sort(
      (a, b) => a.priority - b.priority
    )

    for (const generator of generators) {
      const actions = generator.generateActionsFromQualifyingFeatures({
        ...params,
        features,
      })

      if (actions && actions.length !== 0) {
        actions[0].execute()

        // Return after executing first action.
        return
      }
    }
  }
}

// Export the default
export const defaultClickActionFactory = new MapActionFactory([
  new PointNoteClusterActionGenerator(),
  new PointNoteActionGenerator(),
  new NoActionGenerator(),
  new GlDrawActionGenerator(),
  new BlockActionGenerator(),
  new AreaNoteActionGenerator(),
  new PointActionGenerator(),
  new PolygonActionGenerator(),
  new MultiPolygonActionGenerator(),
  new SoilActionGenerator(),
  new BlockByBlockActionGenerator(),
  new PolygonByColourActionGenerator(),
])

export const defaultMouseEnterActionFactory = new MapActionFactory([
  new EnterNoteGenerator(),
])

export const defaultMouseLeaveActionFactory = new MapActionFactory([
  new LeaveGenerator(),
])
