import * as React from 'react'
import { selectMapLayerDefsById } from '../../../data/selectListMapSourceDefs'
import { selectPreferredLanguage } from '../../../data/selectPreferredLanguage'
import { useRedux } from '../../../hooks/useRedux'
import { selectProductStats } from '../../../ProductStats/selectors/selectProductStats'
import {
  selectBestUnitsByProductId,
  selectCalculateStats,
  selectConvertedSelectedColorProfiles,
} from '../../../ProductStats/selectors/stats'
import { toStopData } from '../../../ProductStats/toStopData'
import { ZoneRow } from '../editor/sidebar-options/config/RateMapConfig'
import { useRateMapContext } from '../editor/RateMapContext'
import areaFromGeometry from '@turf/area'
import area from './../../../util/units/area'
import { useRateMapVigorZoneFeatures } from './useRateMapVigorZoneFeatures'
import { feature, Geometry } from '@turf/helpers'
import { VisualizationMode } from '../../../ProductSettings/store/types'
import { selectRateMapVigorZoneProductId } from '../selectors/selectRateMapVigorZoneProductId'
import { selectRateMapCustomZoneRatesByZoneId } from '../selectors/selectRateMapCustomZoneRatesByZoneId'

export const useGenerateAmendmentZones = () => {
  const [state] = useRedux()
  const rateMapState = useRateMapContext()

  const ratesByZoneId = selectRateMapCustomZoneRatesByZoneId(rateMapState)
  const { rateMap } = rateMapState
  const lang = selectPreferredLanguage(state)
  const productId = selectRateMapVigorZoneProductId(state)
  const productStats = selectProductStats(state)[productId]
  const stats = selectCalculateStats(state)
  const selectedColorProfiles = selectConvertedSelectedColorProfiles(state)
  const productSelectedColorProfiles = selectedColorProfiles?.[productId]
  const productDef = selectMapLayerDefsById(state)[productId]
  const preferredUnitSystem = state.preferences.preferredUnitSystem
  const unit = selectBestUnitsByProductId(state)[productId]

  const stops = React.useMemo(() => {
    const colorProfile =
      productSelectedColorProfiles &&
      (productSelectedColorProfiles[
        (rateMap?.mode ?? 'absolute') as VisualizationMode
      ] ||
        productSelectedColorProfiles.absolute ||
        productSelectedColorProfiles.relative ||
        productSelectedColorProfiles.threshold)

    const { stops } = toStopData({
      lang,
      unit,
      stats: productStats,
      preferredUnitSystem,
      colorProfile: colorProfile as any,
      layerDef: productDef,
    })

    return stops
  }, [
    productStats,
    lang,
    preferredUnitSystem,
    unit,
    productDef,
    productSelectedColorProfiles,
    rateMap?.mode,
  ])

  const { getCustomZoneAdjustedVigorZonesByStopIndex } =
    useRateMapVigorZoneFeatures()

  const customZoneAdjustedVigorZonesByStopIndex = React.useMemo(() => {
    return getCustomZoneAdjustedVigorZonesByStopIndex(
      rateMap?.RateMapCustomZones ?? []
    )
  }, [getCustomZoneAdjustedVigorZonesByStopIndex, rateMap?.RateMapCustomZones])

  const zones = React.useMemo<ZoneRow[]>(() => {
    const vigorZoneRows = stops
      .map((stop, index) => {
        const customZoneAdjustedVigorZonesForStopIndex =
          customZoneAdjustedVigorZonesByStopIndex?.[index]

        let adjustedSize = 0

        if (customZoneAdjustedVigorZonesForStopIndex) {
          const sizeSum = customZoneAdjustedVigorZonesForStopIndex.reduce(
            (sum, zone) =>
              sum +
              (zone?.geometry
                ? areaFromGeometry(feature(zone.geometry as Geometry))
                : 0),
            0
          )
          adjustedSize =
            preferredUnitSystem === 'metric'
              ? area.convert(
                  sizeSum ?? 0,
                  area.units.squareMeter,
                  area.units.hectare,
                  'hectare'
                ).value
              : area.convert(
                  sizeSum ?? 0,
                  area.units.squareMeter,
                  area.units.acre,
                  'acre'
                ).value
        }

        if (rateMap?.RateMapCustomZones.length === 0) {
          adjustedSize = stop.size
        }

        return {
          stopIndex: index,
          index,
          zoneId: `${stop.key}`,
          size: adjustedSize,
          color: stop.color as string,
          value: stop.value,
        }
      })
      .filter((s) => s.size > 0)
      .map((row, index) => ({
        ...row,
        amount: (rateMap?.amendmentZoneRates?.[index] ?? 0) * row.size,
        rateOfApplication: rateMap?.amendmentZoneRates?.[index] ?? 0,
      }))

    const customZones =
      rateMap?.RateMapCustomZones.filter((zone) => !zone.deletedAt) ?? []

    if (customZones.length === 0) {
      return vigorZoneRows
    }

    const customZoneRows = customZones.map((zone, index) => {
      const geometryArea = zone.geometry ? areaFromGeometry(zone.geometry) : 0

      const size =
        preferredUnitSystem === 'metric'
          ? area.convert(
              geometryArea ?? 0,
              area.units.squareMeter,
              area.units.hectare,
              'hectare'
            ).value
          : area.convert(
              geometryArea ?? 0,
              area.units.squareMeter,
              area.units.acre,
              'acre'
            ).value

      const lastStopIndex = stops.length + 1

      return {
        stopIndex: undefined,
        index: lastStopIndex + index,
        zoneId: zone.id,
        size: size,
        color: zone.color,
        value: undefined,
        amount: (ratesByZoneId[zone.id]?.rate ?? 0) * size,
        rateOfApplication: ratesByZoneId[zone.id]?.rate ?? 0,
        isCustomZone: true,
      } as ZoneRow
    })

    return [...vigorZoneRows, ...customZoneRows]
  }, [
    stops,
    rateMap?.RateMapCustomZones,
    rateMap?.amendmentZoneRates,
    customZoneAdjustedVigorZonesByStopIndex,
    preferredUnitSystem,
    ratesByZoneId,
  ])

  return [zones, stats.status === 'pending'] as [ZoneRow[], boolean]
}
