import { Button } from '@mui/material'
import * as React from 'react'
import { IconButton, Menu, Tooltip } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { MRT_Row, MRT_RowData, MRT_TableInstance } from 'material-react-table'

export interface ActionItemProps<T extends MRT_RowData> {
  closeMenu: () => void
  row: MRT_Row<T>
  staticRowIndex?: number
  table: MRT_TableInstance<T>
}

interface ActionsCellProps<T extends MRT_RowData> {
  row: MRT_Row<T>
  table: MRT_TableInstance<T>
  editTitle: string
  menuItems: (props: ActionItemProps<T>) => React.ReactNode[]
}

const ActionsCell = <T extends MRT_RowData>({
  row,
  table,
  editTitle,
  menuItems,
}: ActionsCellProps<T>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => setAnchorEl(null)

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Button
        onClick={(e) => {
          e.stopPropagation()
          row.original.edit()
        }}
        size="small"
        color="primary"
        variant="contained"
        sx={{ px: 2 }}
      >
        {editTitle}
      </Button>
      <Tooltip title="More options">
        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {menuItems({ row, table, closeMenu: handleClose })}
      </Menu>
    </div>
  )
}

export default ActionsCell
