import * as React from 'react'

type Timer = ReturnType<typeof setTimeout>

const useThrottle = <T, U>(func: (args: U) => T, delay = 500) => {
  const isThrottling = React.useRef<boolean>()
  const timer = React.useRef<Timer>()
  React.useEffect(() => {
    return () => {
      if (!timer.current) return
      clearTimeout(timer.current)
    }
  }, [])

  const throttleFunction = (args: U) => {
    if (isThrottling.current) {
      return
    }
    isThrottling.current = true
    setTimeout(() => {
      func(args)
      isThrottling.current = false
    }, delay)
  }

  return throttleFunction
}

export { useThrottle }
