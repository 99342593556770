import * as React from 'react'
import { useRedux } from '../../hooks/useRedux'
import { selectStatsChartData } from '../selectors/selectStatsChartData'
import ChartJS from '../../UI/ChartJS/ChartJS'
import { Theme } from '@mui/material'

function StatsChart({ isMobile, theme }: { isMobile: boolean; theme: Theme }) {
  const [state] = useRedux()
  const prepareChartData = selectStatsChartData(state)

  const chart = React.useMemo(
    () => prepareChartData?.(theme, isMobile),
    [prepareChartData, theme, isMobile]
  )

  if (chart == null) {
    return null
  }

  return (
    <ChartJS
      key={isMobile ? 'mobile' : 'browser'}
      type="bar"
      data={chart.chartData}
      options={chart.chartOptions}
    />
  )
}

export { StatsChart }
