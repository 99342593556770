import { RateMapContextState } from '../editor/RateMapContext'
import { createSelector } from 'reselect'

const getRateMap = (state: RateMapContextState) => state.rateMap

export const selectRateMapWithoutZoneRates = createSelector(
  getRateMap,
  (rateMap) => {
    if (!rateMap) {
      return
    }

    const {
      amendmentZoneRates,
      amendmentTypeId,
      unitCost,
      unitTypeId,
      RateMapCustomZones,
      RateMapAdditionalLayers,
      RateMapBlockSelections,
      RateMapAmendmentType,
      RateMapUnitType,
      ...restRateMap
    } = rateMap

    return restRateMap
  }
)
