import { createSelector } from 'reselect'
import { RateMapContextState } from '../editor/RateMapContext'

const getRateMapAdditionalLayers = (state: RateMapContextState) =>
  state.rateMap?.RateMapAdditionalLayers

export const selectRateMapAdditionalLayers = createSelector(
  getRateMapAdditionalLayers,
  (additionalLayers) => additionalLayers?.filter((raml) => raml.enabled)
)

export const selectRateMapAdditionalLayerIds = createSelector(
  selectRateMapAdditionalLayers,
  (rateMapAdditionalLayers) =>
    rateMapAdditionalLayers?.map((raml) => raml.layerId) ?? []
)
