import { createSelector } from 'reselect'
import { selectMapLayerDefsById } from '../../data/selectListMapSourceDefs'
import { selectPreferredLanguage } from '../../data/selectPreferredLanguage'
import { selectSelectedLegendProductId } from '../../data/selectSelectedLegendProductId'
import { selectActiveProductSettings } from '../../ProductSettings/store/selectors/selectActiveProductSettings'
import { toChartStopData } from '../toStopData'
import { selectProductStats } from './selectProductStats'
import {
  selectBestUnitsByProductId,
  selectConvertedSelectedColorProfiles,
} from './stats'
import {
  configureChartData,
  createStatsChartOptions,
} from '../ChartJs/chartOptions'

export const selectActiveProductVisualization = createSelector(
  [selectActiveProductSettings],
  (settings) => settings.visualization
)

export const selectStatsChartData = createSelector(
  [
    selectActiveProductVisualization,
    selectSelectedLegendProductId,
    selectConvertedSelectedColorProfiles,
    selectPreferredLanguage,
    (state) => state.preferences.preferredUnitSystem,
    selectMapLayerDefsById,
    selectProductStats,
    (state) => state.preferences.preferredStatistics,
    selectBestUnitsByProductId,
    (state) => state.preferences.statsWindowMinimized,
  ],
  (
    visualization,
    productId,
    selectedColorProfilesByProductId,
    lang,
    preferredUnitSystem,
    mapLayerDefsById,
    productStatsById,
    selectedStatisticsType,
    bestUnitsByProductId,
    statsWindowMinimized
  ) => {
    const selectedColorProfiles = selectedColorProfilesByProductId[productId]
    const colorProfile = selectedColorProfiles?.[visualization!]

    const productDef = mapLayerDefsById[productId]
    const productStats = productStatsById[productId]

    const unit = bestUnitsByProductId[productId]

    if (!productStats || !colorProfile) {
      return null
    }

    const {
      stops: data,
      labelX,
      labelY,
      useIntegers,
      unit: unitLabel,
    } = toChartStopData({
      preferredUnitSystem,
      unit,
      layerDef: productDef,
      colorProfile: colorProfile as any,
      stats: productStats,
      lang,
    })

    const lengthOrArea = ['area', 'length'].includes(productStats.weightType!)

    const minimumFractionDigits = lengthOrArea ? 1 : undefined
    const maximumFractionDigits = lengthOrArea ? 1 : undefined

    const { chartData, percents } = configureChartData(data)

    return (theme: any, isMobile: boolean) => {
      console.log({ statsWindowMinimized, isMobile })
      const chartOptions = createStatsChartOptions({
        theme,
        useIntegers,
        labelY,
        labelX,
        selectedStatisticsType,
        percents,
        minimumFractionDigits,
        maximumFractionDigits,
        unitLabel,
        statsWindowMinimized: statsWindowMinimized || isMobile,
      })

      return { chartData, chartOptions }
    }
  }
)
