import { mergeCoalesce } from '../util/coalesce'
import { initialPreferencesState } from './preferences/redux'
import { RootStore } from './types'
// import { RootStore } from './types'

// type Migration = (state: any) => RootStore // to help with authoring the migrations
type Migration = (state: any) => any

interface Migrations {
  [key: number]: Migration
}

export const migrations: Migrations = {
  // TEMPLATE
  /*
   * Migration <timestamp> `e.g. yyyymmddhhmmss`
   * ADDS <key-path>
   * CHANGES <key-path>:
   *   <old-type> -> <new-type>
   * REMOVES <key-path>
   */
  // <timestamp>: (state) => {
  //   //make changes
  //   return {
  //     ...state,
  //     // changes
  //   }
  // }
  // TEMPLATE END

  /*
   * Migration 20220112162600
   * REMOVES
   *   - state.userSelection.selectedFlightDate
   *   - state.userSelection.selectedGroupId
   *   - state.userSelection.selectedGroupName
   *   - state.userSelection.selectedParcelIds
   */
  20220112162600: (state) =>
    state?.userSelection
      ? {
          ...state,
          userSelection: {
            ...state.userSelection,
            selectedFlightDate: undefined,
            selectedGroupId: undefined,
            selectedGroupName: undefined,
            selectedParcelIds: undefined,
          },
        }
      : state,

  /*
   * Migration 20220112162600
   * REMOVES
   *   - state.userSelection.selectedGroupName
   */
  20220131192200: (state) =>
    state?.reports
      ? {
          ...state,
          reports: {
            ...state.reports,
            selectedGroupName: undefined,
          },
        }
      : state,

  /*
   * Migration 20220304111300
   * Consolidates preferences into one reducer:
   *
   */
  20220304111300: (state) => {
    const fromApp: any = {}

    if (state?.app) {
      fromApp.theme = state.app.isDarkModeEnabled
        ? state.app.isDarkModeEnabled
          ? 'dark'
          : 'light'
        : undefined
      fromApp.preferredUnitSystem = state.app.preferredUnitSystem
      fromApp.preferredLanguage = state.app.preferredLanguage
      fromApp.preferredStatistics = state.app.preferredStatistics
    }

    const fromPostgis: any = {}

    if (state?.postgis) {
      fromPostgis.showScale = state.postgis.isScaleBarEnabled
      fromPostgis.showFieldBoundaries = state.postgis.showFieldBoundaries
      fromPostgis.showFieldLabels = state.postgis.showFieldLabels
      fromPostgis.showSoilLayer = state.postgis.soilLayerEnabled
      fromPostgis.showElevationLayer = state.postgis.elevationLayerEnabled
    }

    return {
      ...state,
      preferences: mergeCoalesce(fromApp, fromPostgis, initialPreferencesState),
    }
  },
  20230911118000: (state: RootStore): RootStore => {
    return {
      ...state,
      ProductSettings: {
        ...(state.ProductSettings ?? {}),
        userProductSettings: Object.entries(
          state.ProductSettings?.userProductSettings ?? {}
        ).reduce((acc, [key, settings]) => {
          acc[key] = {
            ...settings,
            coverage: {},
          }

          return acc
        }, {}),
      },
    }
  },
  20241205150000: (state: RootStore): RootStore => {
    return {
      ...state,
      preferences: {
        ...state.preferences,
        statsWindowTab: 'current',
      },
    }
  },
}
