import { Divider, MenuItem, Menu } from "@mui/material"
import * as React from "react"
import { selectSelectedLegendProductId } from "../../data/selectSelectedLegendProductId"
import { useRedux } from "../../hooks/useRedux"
import i18n, { keys } from "../../i18n"
import { selectActiveProductSettings } from "../../ProductSettings/store/selectors/selectActiveProductSettings"
import { DownloadTableOptions, downloadCSV, downloadXLSX } from "../../util/table"
import { selectProductStats } from "../selectors/selectProductStats"
import { selectStatsData } from "../selectors/selectStatsData"
import { selectConvertedSelectedColorProfiles } from "../selectors/stats"
import { selectHistoricStatsData } from '../selectors/selectHistoricStatsData';
import { selectHistoricalTabEnabled } from '../selectors/selectHistoricalTabEnabled';
import { useFeatureFlag } from "../../hooks/useFeatureFlag"

interface DownloadStatsProps {
    open: boolean
    anchorEl: null | HTMLElement
    onClose: ()  => void
}

export const DownloadStatsMenu = ({open, onClose, anchorEl}: DownloadStatsProps) => {
    const [state] = useRedux()
  
    const settings = selectActiveProductSettings(state)
    const productId = selectSelectedLegendProductId(state)
    const selectedColorProfiles =
      selectConvertedSelectedColorProfiles(state)[productId]
  
    const colorProfile = selectedColorProfiles?.[settings.visualization!]

    const productStats = selectProductStats(state)[productId]
  
    const statsData = selectStatsData(state)
    const historicStatsData = selectHistoricStatsData(state)
    const historicDataDownloadEnabled = selectHistoricalTabEnabled(state) 
    const {featureEnabled} = useFeatureFlag({ featureFlagId: 'historical-stats' })
  
    if (!productStats || !colorProfile) {
      return null
    }

    const andClose = (fn: (options: DownloadTableOptions) => void, options: DownloadTableOptions) => {
        fn(options)
        onClose()
    }
  
    return (
        <Menu open={open} 
            anchorEl={anchorEl}
            onClose={() => onClose()}>
            <MenuItem disabled={!statsData} onClick={() => andClose(downloadCSV, statsData!)}>
                {i18n.t(keys.generic.export)} {i18n.t(keys.generic.csv)}
            </MenuItem>
            <MenuItem disabled={!statsData} onClick={() => andClose(downloadXLSX, statsData!)}>
                {i18n.t(keys.generic.export)} {i18n.t(keys.generic.xlsx)}
            </MenuItem>
            { historicDataDownloadEnabled && featureEnabled ? [
                <Divider/>,
                <MenuItem disabled={!historicStatsData} onClick={() => andClose(downloadCSV, historicStatsData!)}>
                    {i18n.t(keys.generic.export)} {i18n.t(keys.stats.historic)} {i18n.t(keys.generic.csv)}
                </MenuItem>,
                <MenuItem disabled={!historicStatsData} onClick={() => andClose(downloadXLSX, historicStatsData!)}>
                    {i18n.t(keys.generic.export)} {i18n.t(keys.stats.historic)} {i18n.t(keys.generic.xlsx)}
                </MenuItem>
            ] : []
            }  
        </Menu>
    )

}