import { RateMapContextState } from '../editor/RateMapContext'
import { createSelector } from 'reselect'

const getRateMapGroupBy = (state: RateMapContextState) =>
  state.rateMap?.modeConfiguration?.classifyBy

export const selectRateMapGroupBy = createSelector(
  getRateMapGroupBy,
  (groupBy) => {
    return groupBy
  }
)
