import * as React from 'react'
import { Stack, Button, styled, Icon } from '@mui/material'
import { RateMapCustomZone } from '../../../../../graphql/types'
import { useRateMapContext } from '../../RateMapContext'
import { v4 as uuidv4 } from 'uuid'
import { color } from '../../../../../util/color'
import i18n, { keys } from '../../../../../i18n'

// Note: limited to 50 colors (10 gradients with 5 steps each)
const multigradient = color.generateMultiGradient(
  [
    '#EE82EE',
    '#FF0000',
    '#FFFF00',
    '#008000',
    '#0000FF',
    '#4B0082',
    '#FFFF00',
    '#008000',
    '#EE82EE',
    '#0000FF',
    '#FF0000',
  ],
  5
)

interface Props {
  confirmAllZones: () => void
  pendingZones: RateMapCustomZone[] | null
  cancelDrawing: () => void
}

const CustomZoneCommitContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  padding: 0,
  borderRadius: '8px',
  width: '600px',
}))

const CustomZoneButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: '8px',
  margin: 1,
  '&.cancel-button:hover': {
    backgroundColor: '#ff0000',
  },
}))

const CustomIcon = styled(Icon)(({ theme }) => ({
  fontSize: '16px !important',
  marginLeft: '4px',
}))

export const CustomZoneCommitOptions: React.FC<Props> = ({
  confirmAllZones,
  pendingZones,
  cancelDrawing,
}) => {
  const {
    rateMap,
    setModifiedRateMap,
    setIsCustomZoneMode,
    selectedZoneType,
    selectedCustomZone,
    setSelectedCustomZone,
  } = useRateMapContext()

  const handleConfirmAllZones = React.useCallback(() => {
    confirmAllZones()
    setSelectedCustomZone(undefined)
    setIsCustomZoneMode(false)
  }, [confirmAllZones, setIsCustomZoneMode, setSelectedCustomZone])

  const addNewZone = React.useCallback(() => {
    if (!rateMap || !setModifiedRateMap) {
      return
    }
    const indexColor =
      multigradient[(pendingZones ?? rateMap?.RateMapCustomZones)?.length ?? 0]
    const newZone: RateMapCustomZone = {
      id: uuidv4(),
      rateMapId: rateMap.id,
      customZoneTypeId: selectedZoneType ?? '',
      color: indexColor,
    }
    setSelectedCustomZone(newZone)
  }, [
    rateMap,
    selectedZoneType,
    setModifiedRateMap,
    setSelectedCustomZone,
    pendingZones,
  ])

  const cancel = React.useCallback(() => {
    cancelDrawing()

    setIsCustomZoneMode(false)
    setSelectedCustomZone(undefined)
  }, [cancelDrawing, setIsCustomZoneMode, setSelectedCustomZone, rateMap])

  return (
    <CustomZoneCommitContainer>
      <CustomZoneButton
        disabled={!!selectedCustomZone}
        variant={!!selectedCustomZone ? 'outlined' : 'contained'}
        onClick={addNewZone}
      >
        {i18n.t(keys.rateMapCreateNewZone)}
        <CustomIcon>add</CustomIcon>
      </CustomZoneButton>
      <CustomZoneButton
        disabled={!!selectedCustomZone}
        variant="contained"
        onClick={handleConfirmAllZones}
      >
        {i18n.t(keys.rateMapConfirmAllZones)}
        <CustomIcon>check</CustomIcon>
      </CustomZoneButton>
      <CustomZoneButton
        sx={{ marginLeft: '12px' }}
        disabled={!!selectedCustomZone}
        variant="contained"
        onClick={cancel}
        className="cancel-button"
      >
        {i18n.t(keys.generic.cancel)}
        <CustomIcon>cancel</CustomIcon>
      </CustomZoneButton>
    </CustomZoneCommitContainer>
  )
}
