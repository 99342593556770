import { WeightedAverageWithError } from '../../admin/MapLayerDef/types/HistoricalChartCalculationType'
import { HistoricDeliveryParcelStats } from './selectHistoricParcelData'
import { createSelector } from 'reselect'
import {
  selectHistoricalCalculationProperties,
  selectHistoricalCalculationType,
} from './selectHistoricalTabCalculationProperties'
import { sortByFlightDate } from './historicDataHelper'

interface CalculatedChartData {
  labels: string[]
  data: {
    y: number
    yMin?: number
    yMax?: number
    parcels: string[]
    showWarningIndicator?: boolean
  }[]
}

export const selectHistoricalCalculation = createSelector(
  [selectHistoricalCalculationProperties, selectHistoricalCalculationType],
  (
    properties,
    type
  ): ((
    deliveryParcelMetaData: HistoricDeliveryParcelStats[]
  ) => CalculatedChartData) => {
    switch (type) {
      case 'weightedAvgWithError':
        return getCalculateWeightedAverageWithErrorFunc(
          properties as WeightedAverageWithError
        )
      default:
        return defaultCalculationFunc
    }
  }
)

const getCalculateWeightedAverageWithErrorFunc = (
  properties: WeightedAverageWithError
): ((
  deliveryParcelMetaData: HistoricDeliveryParcelStats[]
) => CalculatedChartData) => {
  return (deliveryParcelMetaData: HistoricDeliveryParcelStats[]) => {
    const sortedByFlightDate = sortByFlightDate(deliveryParcelMetaData)

    const labels = Array.from(sortedByFlightDate.keys()).map((d) => d)
    const flightGroupings = Array.from(sortedByFlightDate.values())

    const weightedAverages = flightGroupings.reduce(
      (weightedAverages, flightParcelSet) => {
        weightedAverages.push(
          weightedAverage(
            flightParcelSet,
            (d) => d?.[properties.weight] ?? 0,
            (d) => d?.[properties.mean] ?? 0
          )
        )
        return weightedAverages
      },
      new Array<number>()
    )

    const weightedStdDevs = Array.from(sortedByFlightDate.values()).reduce(
      (weightedStdDevs, flightParcelSet, index) => {
        weightedStdDevs.push(
          combinedWeightedStandardDeviation(
            flightParcelSet,
            weightedAverages[index],
            (d) => d?.[properties.weight] ?? 0,
            (d) => d?.[properties.stdev] ?? 0,
            (d) => d?.[properties.mean] ?? 0
          )
        )
        return weightedStdDevs
      },
      new Array<number>()
    )

    // Find the maximum count of parcels for all flight groups.
    const parcelCount = Math.max(...flightGroupings.map((fg) => fg.length))
    return {
      labels,
      data: weightedAverages.map((a, i) => ({
        y: a,
        yMin: a - weightedStdDevs[i],
        yMax: a + weightedStdDevs[i],
        // compare flight parcel count to max parcel count.
        // If less than the max, a warning should be shown.
        parcels: Array.from(
          new Set(flightGroupings[i].map((fg) => fg.parcelId.toString()))
        ),
        showWarningIndicator: flightGroupings[i].length < parcelCount,
      })),
    }
  }
}

const defaultCalculationFunc = (
  _deliveryParcelMetaData: HistoricDeliveryParcelStats[]
): CalculatedChartData => {
  return {
    labels: [],
    data: [],
  }
}

/**
 * Based on weighted standard deviation formula (https://www.itl.nist.gov/div898/software/dataplot/refman2/ch2/weigmean.pdf)
 * @param dataSet The set of data of the generic type.
 * @param getWeight A function that gets the weight from the generic type.
 * @param getValue A function that gets the value from the generic type.
 * @returns A weighted mean for the dataSet.
 */
const weightedAverage = <T>(
  dataSet: T[],
  getWeight: (data: T) => number,
  getValue: (data: T) => number
) => {
  const { numeratorSum, denominatorSum } = dataSet.reduce(
    (result, data) => {
      result.numeratorSum =
        result.numeratorSum + getWeight(data) * getValue(data)
      result.denominatorSum = result.denominatorSum + getWeight(data)
      return result
    },
    { numeratorSum: 0, denominatorSum: 0 }
  )

  return numeratorSum / denominatorSum
}

/**
 * Based on combined variance formula (https://www.emathzone.com/tutorials/basic-statistics/combined-variance.html)
 * @param dataSet The set of data of the generic type.
 * @param weightedAverage The weighted average for the data set.
 * @param getWeight A function that gets the weight from the generic type.
 * @param getStdDev A function that gets the stand deviation from the generic type.
 * @param getMean A function that gets the mean from the generic type.
 * @returns A weighted standard deviation for the data set.
 */
const combinedWeightedStandardDeviation = <T>(
  dataSet: T[],
  weightedAverage: number,
  getWeight: (data: T) => number,
  getStdDev: (data: T) => number,
  getMean: (data: T) => number
) => {
  const { numeratorSum, weightSum } = dataSet.reduce(
    (result, data) => {
      result.numeratorSum =
        result.numeratorSum +
        getWeight(data) *
          (Math.pow(getStdDev(data), 2) +
            Math.pow(getMean(data) - weightedAverage, 2))
      result.weightSum = result.weightSum + getWeight(data)
      return result
    },
    { numeratorSum: 0, weightSum: 0 }
  )

  return Math.sqrt(numeratorSum / weightSum)
}
