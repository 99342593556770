import React from 'react'
import { selectSelectedMapSources } from '../../../data/selectSelectedMapSources'
import { useRedux } from '../../../hooks/useRedux'
import { pureVineZonesLayerID, vigorZonesLayerID } from '../constants'
import { selectConvertedSelectedColorProfiles } from '../../../ProductStats/selectors/stats'
import { RateMapCustomZone } from '../../../graphql/types'
import {
  Feature,
  feature,
  MultiPolygon,
  Polygon,
  Properties,
} from '@turf/helpers'
import area from './../../../util/units/area'
import { area as geometryArea } from '@turf/turf'
import getMapSourceFeatures from '../utils/getRateMapFeatures'
import { getStopIndex } from '../../../ProductStats/calculateStats/calcStats'
import useAsync from '../../../hooks/useAsync'
import * as drawHelpers from '../../../map/draw/util/drawHelpers'
import { selectDeliveryParcels } from '../../../data/selectDelivery'
import { useRateMapContext } from '../editor/RateMapContext'
import { VisualizationMode } from '../../../ProductSettings/store/types'
import { selectRateMapVigorZoneProductId } from '../selectors/selectRateMapVigorZoneProductId'
import { selectRateMapMode } from '../selectors/selectRateMapMode'

const MIN_AREA_M2 = 0.25

const eviPropertyName = 'EVI'
const areaPropertyName = 'Area'
const areaM2PropertyName = 'Area_m2'

export const useRateMapVigorZoneFeatures = () => {
  const [state] = useRedux()
  const rateMapContextState = useRateMapContext()
  const selectedMapSources = selectSelectedMapSources(state)
  const productId = selectRateMapVigorZoneProductId(state)

  const mode = selectRateMapMode(rateMapContextState)
  const selectedColorProfiles =
    selectConvertedSelectedColorProfiles(state)[productId]

  const rateMapMapSources = React.useMemo(() => {
    const pureVineZoneSources = selectedMapSources?.[pureVineZonesLayerID]

    // only load pure vine zone sources if they exist
    if (pureVineZoneSources) {
      return pureVineZoneSources
    }

    const vigorZoneSources = selectedMapSources?.[vigorZonesLayerID] ?? []
    return vigorZoneSources
  }, [selectedMapSources])

  const [featuresByParcelAsync] = useAsync(async () => {
    return getMapSourceFeatures(rateMapMapSources)
  }, [rateMapMapSources])

  const featuresByParcel = React.useMemo(() => {
    return featuresByParcelAsync?.result ?? {}
  }, [featuresByParcelAsync?.result])

  const parcelData = selectDeliveryParcels(state)

  const parcelGeometries = React.useMemo(() => {
    if (!parcelData) {
      return {}
    }
    return parcelData.reduce((acc, parcel) => {
      acc[parcel.parcelId] = feature(parcel.geometry) as Feature<
        Polygon | MultiPolygon
      >
      return acc
    }, {} as Record<string, Feature<Polygon | MultiPolygon, Properties> | null | undefined>)
  }, [parcelData])

  const getRateMapZoneFeaturesByParcelIdAndStopIndex = React.useCallback(() => {
    const colorProfile =
      selectedColorProfiles &&
      (selectedColorProfiles[(mode ?? 'absolute') as VisualizationMode] ||
        selectedColorProfiles.absolute ||
        selectedColorProfiles.relative ||
        selectedColorProfiles.threshold)

    const dataStops = colorProfile?.dataStops?.map(([stop]) => stop) ?? []

    const filteredFeaturesByParcelIdAndStopIndex: Record<
      string,
      Record<string, Feature<Polygon | MultiPolygon, Properties>[]>
    > = {}
    for (const [parcelId, featureCollection] of Object.entries(
      featuresByParcel
    )) {
      filteredFeaturesByParcelIdAndStopIndex[parcelId] =
        filteredFeaturesByParcelIdAndStopIndex[parcelId] ?? {}

      for (const feature of featureCollection.features) {
        const stopIndex = getStopIndex(
          { value: feature.properties?.[eviPropertyName] },
          dataStops as number[],
          'value'
        )

        filteredFeaturesByParcelIdAndStopIndex[parcelId][stopIndex] =
          filteredFeaturesByParcelIdAndStopIndex[parcelId][stopIndex] ?? []
        filteredFeaturesByParcelIdAndStopIndex[parcelId][stopIndex].push(
          feature as Feature<Polygon | MultiPolygon, Properties>
        )
      }
    }

    return filteredFeaturesByParcelIdAndStopIndex
  }, [featuresByParcel, selectedColorProfiles, mode])

  const getRateMapZoneFeaturesByStopIndex = React.useCallback(() => {
    const featuresByParcelIdAndStopIndex =
      getRateMapZoneFeaturesByParcelIdAndStopIndex()
    const featuresByStopIndex: Record<
      string,
      Feature<Polygon | MultiPolygon, Properties>[]
    > = {}
    Object.values(featuresByParcelIdAndStopIndex).forEach((features) => {
      Object.entries(features).forEach(([stopIndex, stopFeatures]) => {
        if (!featuresByStopIndex[stopIndex]) {
          featuresByStopIndex[stopIndex] = []
        }
        featuresByStopIndex[stopIndex].push(...stopFeatures)
      })
    })
    return featuresByStopIndex
  }, [getRateMapZoneFeaturesByParcelIdAndStopIndex])

  const getCustomZoneAdjustedAdjustedVigorZonesByParcelIdAndStopIndex =
    React.useCallback(
      (rateMapCustomZones: RateMapCustomZone[]) => {
        const filteredCustomZones = rateMapCustomZones
          .filter((zone) => !zone.deletedAt || !zone.geometry)
          .map((zone) =>
            feature({
              type: 'Polygon',
              coordinates: zone!.geometry!.coordinates,
            })
          ) as Feature<Polygon | MultiPolygon>[]

        if (filteredCustomZones.length === 0) {
          return getRateMapZoneFeaturesByStopIndex()
        }

        const vigorZonesByParcelIdAndStopIndex =
          getRateMapZoneFeaturesByParcelIdAndStopIndex()

        const flattenedVigorZones = Object.entries(
          vigorZonesByParcelIdAndStopIndex
        ).flatMap(([parcelId, features]) =>
          Object.entries(features).map(([stopIndex, stopFeatures]) => ({
            parcelId,
            stopIndex,
            features: stopFeatures,
          }))
        )

        // union all the custom zones
        const mergedCustomZones =
          filteredCustomZones.length > 1
            ? drawHelpers.union(...filteredCustomZones)
            : filteredCustomZones?.[0] ?? null

        if (!mergedCustomZones) {
          return vigorZonesByParcelIdAndStopIndex
        }

        if (flattenedVigorZones.length === 0) {
          return undefined
        }

        const areaByParcelAndStop = flattenedVigorZones.reduce(
          (acc, { parcelId, stopIndex, features }) => {
            const stopVigorZones = features.reduce((acc, f) => {
              const diff = drawHelpers.difference(
                feature(f.geometry as Polygon, f.properties),
                mergedCustomZones
              )
              const updatedFeature = {
                ...f,
              }
              if (!!diff) {
                const newArea = geometryArea(diff)
                if (newArea < MIN_AREA_M2) {
                  return acc
                }
                updatedFeature.properties = {
                  ...updatedFeature.properties,
                  [`${areaM2PropertyName}`]: newArea,
                  [`${areaPropertyName}`]: area.convert(
                    geometryArea(diff),
                    area.units.squareMeter,
                    area.units.hectare,
                    'hectare'
                  ).value,
                }
                updatedFeature.geometry = diff.geometry
                acc.push(updatedFeature)
              }
              return acc
            }, [] as Feature<Polygon | MultiPolygon, Properties>[])

            if (!acc[parcelId]) {
              acc[parcelId] = {}
            }

            acc[parcelId][stopIndex] = stopVigorZones
            return acc
          },
          {} as Record<
            string,
            Record<string, Feature<Polygon | MultiPolygon, Properties>[]>
          >
        )

        return areaByParcelAndStop
      },
      [
        getRateMapZoneFeaturesByParcelIdAndStopIndex,
        getRateMapZoneFeaturesByStopIndex,
      ]
    )

  const getCustomZoneAdjustedVigorZonesByStopIndex = React.useCallback(
    (rateMapCustomZones: RateMapCustomZone[]) => {
      const filteredCustomZones = rateMapCustomZones
        .filter((zone) => !zone.deletedAt || !zone.geometry)
        .map((zone) =>
          feature({
            type: 'Polygon',
            coordinates: zone!.geometry!.coordinates,
          })
        ) as Feature<Polygon | MultiPolygon>[]

      const vigorZonesByStopIndex = getRateMapZoneFeaturesByStopIndex()
      const vigorZonesList = Object.entries(vigorZonesByStopIndex)

      if (vigorZonesList.length === 0) {
        return undefined
      }

      // union all the custom zones
      const mergedCustomZones =
        filteredCustomZones.length > 1
          ? drawHelpers.union(...filteredCustomZones)
          : filteredCustomZones?.[0] ?? null

      if (!mergedCustomZones) {
        return vigorZonesByStopIndex
      }

      // get the difference between the each vigor zones stop and the merged custom zones
      const areaByStop = vigorZonesList.reduce((acc, [stopIndex, features]) => {
        const adjustedVigorZones = features.reduce((acc, f) => {
          const diff = drawHelpers.difference(
            feature(f.geometry as Polygon),
            mergedCustomZones
          )
          const updatedFeature = {
            ...f,
          }

          if (!!diff) {
            const newArea = geometryArea(diff)
            if (newArea < MIN_AREA_M2) {
              return acc
            }
            updatedFeature.properties = {
              ...updatedFeature.properties,
              [`${areaM2PropertyName}`]: newArea,
              [`${areaPropertyName}`]: area.convert(
                geometryArea(diff),
                area.units.squareMeter,
                area.units.hectare,
                'hectare'
              ).value,
            }
            updatedFeature.geometry = diff.geometry
            acc.push(updatedFeature)
          }
          return acc
        }, [] as Feature<Polygon | MultiPolygon, Properties>[])

        if (!adjustedVigorZones) {
          return acc
        }

        acc[stopIndex] = adjustedVigorZones
        return acc
      }, {} as Record<string, Feature<Polygon | MultiPolygon, Properties>[]>)

      return areaByStop
    },
    [getRateMapZoneFeaturesByStopIndex]
  )

  return {
    getRateMapZoneFeaturesByParcelIdAndStopIndex,
    getRateMapZoneFeaturesByStopIndex,
    getCustomZoneAdjustedAdjustedVigorZonesByParcelIdAndStopIndex,
    getCustomZoneAdjustedVigorZonesByStopIndex,
    parcelGeometries,
    rateMapMapSources,
  }
}
