import * as React from 'react'
import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import i18n, { keys } from '../../../i18n'
import { SamplePlanNew } from './SamplePlanNew/SamplePlanNew'
import { url, urls } from '../../../appNavigation/urls'
import { useHistory } from 'react-router-dom'

interface Props {
  variant: 'contained' | 'outlined' | undefined
}

export const NewSamplePlanButton = ({ variant }: Props) => {
  const [newSamplePlanDialogOpen, setNewSamplePlanDialogOpen] =
    React.useState(false)
  const history = useHistory()
  return (
    <>
      <Button
        variant={variant}
        onClick={() => setNewSamplePlanDialogOpen(true)}
        startIcon={<Add />}
      >
        {i18n.t(keys.samplePlanCreateNewLabel)}
      </Button>
      <SamplePlanNew
        open={newSamplePlanDialogOpen}
        onClose={(samplePlanId?: string) => {
          setNewSamplePlanDialogOpen(false)
          // navigate to the new sample plan
          if (!!samplePlanId) {
            history.push(url(urls.samplePlan, { samplePlanId: samplePlanId }))
          }
        }}
      />
    </>
  )
}
