import gql from 'graphql-tag'
import { createAsyncSelector } from '../../AsyncSelector/createAsyncSelector'
import { selectParcels } from '../../data/selectOrgMapData'
import { query } from '../../graphql/client'
import { RootStore } from '../../redux/types'
import { selectSelectedLegendProductId } from '../../data/selectSelectedLegendProductId'
import { selectMapLayerDefsById } from '../../data/selectListMapSourceDefs'
import { selectSelectedParcelIds } from '../../data/selectSelectedParcelIds'

interface MapSourceDefFilename {
  filenames: { filename: string }[]
}

interface HistoricDeliveryParcelData {
  deliveryParcelFile: [
    {
      deliveryId: string
      parcelId: number
      filename: string
      flightDate: string
      metaData: {
        meta: {
          deliveryId: string
          parcelId: number
          targetDate: string
          EVIstdev: number
          EVImean: number
          DV: number
          UDV: number
          vvAcres: number
        }
      }
    }
  ]
}

export interface HistoricDeliveryParcelStats {
  deliveryId: string
  parcelId: number
  flightDate: string
  EVIstdev: number
  EVImean: number
  DV: number
  UDV: number
  vvAcres: number
}

const getDeliveryParcelFilenameByMapSourceDefId = gql`
  query FETCH_FILENAME_WITH_MAP_SOURCE_DEF_ID($mapSourceDefId: uuid!) {
    filenames: MapSourceDefFilename(
      where: { mapSourceDefId: { _eq: $mapSourceDefId } }
    ) {
      filename
    }
  }
`

const getHistoricDeliveryParcelData = gql`
  query FETCH_HISTORIC_STATS_DATA($parcelIds: [Int!], $filename: citext) {
    deliveryParcelFile: DeliveryParcelFile(
      where: { parcelId: { _in: $parcelIds }, filename: { _eq: $filename } }
    ) {
      deliveryId
      parcelId
      filename
      flightDate
      metaData: DeliveryParcel {
        meta
      }
    }
  }
`

export const {
  selector: selectHistoricParcelData,
  refresh: refreshHistoricParcelData,
} = createAsyncSelector({
  resource: 'me.organization.delivery.historicData',
  inputs: {
    parcels: (state: RootStore) => selectParcels(state),
    selectedParcelIds: (state: RootStore) => selectSelectedParcelIds(state),
    selectedLegendProductId: (state: RootStore) =>
      selectSelectedLegendProductId(state),
    selectedMapLayerDefsById: (state: RootStore) =>
      selectMapLayerDefsById(state),
  },
  fetcher: async ({
    parcels,
    selectedParcelIds,
    selectedLegendProductId,
    selectedMapLayerDefsById,
  }): Promise<HistoricDeliveryParcelStats[]> => {
    if (
      !parcels ||
      !selectedParcelIds ||
      !selectedLegendProductId ||
      !selectedMapLayerDefsById
    ) {
      return []
    }
    const selectedParcels = parcels.filter(({ id }) =>
      selectedParcelIds.has(id)
    )
    const mapSourceDefId =
      selectedMapLayerDefsById[selectedLegendProductId].mapSourceDef.id

    const { filenames } = await query<MapSourceDefFilename>({
      query: getDeliveryParcelFilenameByMapSourceDefId,
      variables: { mapSourceDefId },
    })

    const data = await query<HistoricDeliveryParcelData>({
      query: getHistoricDeliveryParcelData,
      variables: {
        parcelIds: selectedParcels.map((p) => p.id),
        filename: filenames[0].filename,
      },
    })

    if (!data) {
      return []
    }

    return data.deliveryParcelFile.map(
      ({
        deliveryId,
        parcelId,
        flightDate,
        metaData: {
          meta: { EVIstdev, EVImean, DV, UDV, vvAcres },
        },
      }) => {
        return {
          deliveryId,
          parcelId,
          flightDate,
          EVIstdev,
          EVImean,
          DV,
          UDV,
          vvAcres,
        }
      }
    )
  },
})
