import { createSelector } from 'reselect'
import { RootStore } from '../../redux/types'
import {
  getNotesFilter,
  getSelectedProjects,
  selectFilteredNotes,
  selectNotesById,
  selectProjectsById,
} from '../../notes/selectors'

import { selectOrganization } from '../../data/selectOrganization'

import notesToGeoJSON from '../../notes/notesToGeoJSON'

import { NoteWithTemplate } from '../../vvapi/models'
import { COLORS } from '../../noteForms/NoteTemplateForm'

export const NOTE_MARKERS_POINTS = 'NOTE-MARKERS-POINTS'
export const NOTE_HALO_COLOUR = '#FFF'
export const FOCUSED_NOTE_MARKERS_POINTS = 'FOCUSED_NOTE_MARKERS_POINTS'
export const NOTE_MARKERS_POLYGONS = 'NOTE-MARKERS-POLYGONS'

const getEditingNoteId = (state: RootStore) => state.notes.editingNoteId
const getFocusedNoteId = (state: RootStore) => state.notes.focusedNoteId

const colorsWithClusterWeightColor = [...COLORS, '#AA61F2']

export const getNotesLayer = createSelector(
  selectNotesById,
  getEditingNoteId,
  getFocusedNoteId,
  getNotesFilter,
  selectFilteredNotes,
  selectOrganization,
  selectProjectsById,
  getSelectedProjects,
  selectNotesById,
  (
    notes = {},
    editingNoteId,
    focusedNoteId,
    _notesFilters,
    filteredNotes,
    organization,
    _projectsById = {},
    selectedProjectIds,
    notesById
  ) => {
    const sources = {}
    const layers: any[] = []
    if (!organization) {
      return { sources, layers }
    }
    const filtNotes: NoteWithTemplate[] = []
    const projectIdsSet = new Set(selectedProjectIds)

    for (const noteId of filteredNotes) {
      if (
        notesById[noteId] &&
        projectIdsSet.has(notesById[noteId].projectId ?? 'general')
      ) {
        filtNotes.push(notesById[noteId])
      }
    }

    const notesGeoJson = notesToGeoJSON(filtNotes)
    const highlightedNoteId = editingNoteId ?? focusedNoteId

    if (highlightedNoteId) {
      const focusedNote = notes[highlightedNoteId]

      const focusedNoteGeoJson = notesToGeoJSON([focusedNote])

      sources[FOCUSED_NOTE_MARKERS_POINTS] = {
        data: focusedNoteGeoJson,
        type: 'geojson',
        attribution: '&copy; VineView',
      }

      layers.push({
        type: 'circle',
        paint: {
          'circle-color': NOTE_HALO_COLOUR,
          'circle-blur': 1,
          'circle-radius': 30,
          'circle-translate': [0, -12],
        },
        id: FOCUSED_NOTE_MARKERS_POINTS,
        source: FOCUSED_NOTE_MARKERS_POINTS,
      })
    }

    sources[NOTE_MARKERS_POINTS] = {
      data: notesGeoJson,
      type: 'geojson',
      attribution: '&copy; VineView',
      cluster: true,
      clusterMaxZoom: 16, // Max zoom to cluster points on
      clusterRadius: 30, // Radius of each cluster when clustering points (defaults to 50)
      clusterProperties: {
        ...colorsWithClusterWeightColor.reduce((acc, cur) => {
          acc[cur] = ['+', ['case', ['==', ['get', 'color'], cur], 1, 0]]
          return acc
        }, {} as Record<string, any>),
      },
    }

    layers.push({
      id: `${NOTE_MARKERS_POINTS}_unclustered_layer`,
      type: 'symbol',
      source: NOTE_MARKERS_POINTS,
      filter: ['!', ['has', 'point_count']],
      layout: {
        'text-field': String.fromCharCode(0xe3c9),
        'text-font': ['Material Icons Regular'],
        'text-size': 12,
        'text-line-height': 1,
        'text-offset': [0, -0.9],
        'text-padding': 0,
        'text-allow-overlap': true,
        'icon-optional': false,
        'icon-allow-overlap': true,
        'icon-size': 0.6,
        'icon-offset': [0, -2],
        'icon-anchor': 'bottom',
        'icon-image': 'marker',
        'text-anchor': 'bottom',
        'symbol-sort-key': ['to-number', ['get', 'priority']],
      },
      paint: {
        'text-translate-anchor': 'viewport',
        'text-color': '#FFF',
        'icon-color': ['get', 'color'],
        'text-halo-blur': 0,
        'text-halo-width': 2.5,
        'icon-halo-color': '#FFF',
        'icon-halo-width': 2,
      },
    })

    const getNoOtherColors = (color: string) => {
      return colorsWithClusterWeightColor.reduce((acc, curr) => {
        if (curr === color) {
          return acc
        }

        acc.push(['==', ['get', curr], 0])
        return acc
      }, [] as any[])
    }
    const colorPickingExpression = [
      'case',
      ['all', ...colorsWithClusterWeightColor.map((c) => ['>', ['get', c], 0])],
      '#8C8A88',
      ...colorsWithClusterWeightColor.reduce((acc, curr) => {
        acc.push(
          ...[['all', ['>', ['get', curr], 0], ...getNoOtherColors(curr)], curr]
        )
        return acc
      }, [] as any[]),
      '#8C8A88',
    ]

    layers.push({
      id: `${NOTE_MARKERS_POINTS}_clustered_layer`,
      source: NOTE_MARKERS_POINTS,
      type: 'symbol',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': ['step', ['get', 'point_count'], 14, 10000, 12],
        'text-anchor': 'bottom',
        'text-offset': [
          'step',
          ['get', 'point_count'],
          ['literal', [0, -1]],
          100,
          ['literal', [0, -1.5]],
          10000,
          ['literal', [0, -1.8]],
        ],
        'icon-optional': false,
        'icon-allow-overlap': true,
        'text-optional': false,
        'icon-size': ['step', ['get', 'point_count'], 0.8, 100, 1, 10000, 1.1],
        'icon-offset': [0, -2],
        'icon-anchor': 'bottom',
        'icon-image': 'marker',
      },
      paint: {
        'text-color': '#FFF',
        'icon-color': colorPickingExpression,
        'icon-halo-color': '#FFF',
        'icon-halo-width': 3,
      },
    })

    // uncomment to debug icon placement
    // layers.push({
    //   id: `${NOTE_MARKERS_POINTS}_clustered_layer_circle`,
    //   source: NOTE_MARKERS_POINTS,
    //   type: 'circle',
    //   filter: ['has', 'point_count'],
    //   paint: {
    //     'circle-color': 'red',
    //     'circle-radius': [
    //       'step',
    //       ['get', 'point_count'],
    //       10,
    //       1000,
    //       15,
    //       2000,
    //       20,
    //     ],
    //   },
    // })

    return { sources, layers }
  }
)
