import * as React from 'react'
import AsyncSelectorStatusOverlay from '../../../../AsyncSelector/AsyncSelectorStatusOverlay'
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { useRedux } from '../../../../hooks/useRedux'
import {
  DeliveryGroupDate,
  selectDateMenuDataWithFilter,
  selectOrgMapData,
} from '../../../../data/selectOrgMapData'
import { deliveryFilterFetcher } from '../../../../data/selectDeliveryFilter'
import i18n, { keys } from '../../../../i18n'
import { selectDrawerOption } from '../../../../map-editor/redux/selectors/selectDrawerOptions'
import { MapEditorToolType } from '../../../../redux/map-editor-pages/redux'
import { getFiltersInfo } from '../../../../data/selectParcelFilterWhere'
import { ParcelFilterInfo } from '../../../../postgis/types'
import { selectOrganizationId } from '../../../../data/selectOrganizationId'
import useAsync from '../../../../hooks/useAsync'

interface Props {
  selectedFlightDate: string
  onSelectedFlightDateChange: (flightDate: string) => void
  page: MapEditorToolType
  blockSelectorId: string
  selectedParcelIds: Set<string>
  disabled?: boolean
  dateFilter: (deliveryGroupDate: DeliveryGroupDate) => boolean
}

const FlightDateSelector = ({
  selectedFlightDate,
  onSelectedFlightDateChange,
  page,
  blockSelectorId,
  selectedParcelIds,
  disabled,
  dateFilter,
}: Props) => {
  const [state] = useRedux()
  const organizationId = selectOrganizationId(state)
  const dates = selectDateMenuDataWithFilter(state, dateFilter)
  const deliveryFlightDatesSelector = selectOrgMapData(state)
  const option = selectDrawerOption(state, page, blockSelectorId)
  const parcelFiltersWhere = React.useMemo(
    () =>
      getFiltersInfo(
        option?.config?.parcelFilter?.metaFilters ??
          ({} as ParcelFilterInfo['metaFilters']),
        option?.config?.parcelFilter?.maxDate,
        option?.config?.parcelFilter?.minDate,
        option?.config?.parcelFilter?.products
      ),
    [option?.config?.parcelFilter]
  )

  const [deliveryFilterRequest] = useAsync(async () => {
    return deliveryFilterFetcher({
      organizationId,
      parcelFiltersWhere,
      selectedParcelIds,
    })
  }, [option?.config?.parcelFilter, parcelFiltersWhere])

  const handleChange = (
    event: SelectChangeEvent<any>,
    child: React.ReactNode
  ) => {
    onSelectedFlightDateChange(event.target.value)
  }
  return (
    <div>
      <AsyncSelectorStatusOverlay
        requests={[deliveryFlightDatesSelector, deliveryFilterRequest]}
      >
        <Select
          disabled={
            disabled ||
            (dates.selectedDates.length === 0 &&
              dates.otherDates.length === 0 &&
              dates.filteredDates.length === 0)
          }
          value={selectedFlightDate ?? 'select-date'}
          onChange={handleChange}
        >
          {dates.selectedDates.length > 0 && [
            <MenuItem disabled key="for-selected">
              <Typography variant="subtitle1" align="left">
                {i18n.t(keys.forSelectedParcels)}
              </Typography>
            </MenuItem>,
            <hr key="for-selected-separator" />,
          ]}
          {dates.selectedDates.map((flightDate) => (
            <MenuItem key={flightDate} value={flightDate}>
              <Typography variant="subtitle1" align="left">
                {flightDate}
              </Typography>
            </MenuItem>
          ))}
          {dates.otherDates.length > 0 && [
            <MenuItem disabled key="for-other-parcels">
              <Typography variant="subtitle1" align="left">
                {i18n.t(keys.forOtherParcels)}
              </Typography>
            </MenuItem>,
            <hr key="for-other-separator" />,
          ]}
          {dates.otherDates.map((flightDate) => (
            <MenuItem key={flightDate} value={flightDate}>
              <Typography variant="subtitle1" align="left">
                {flightDate}
              </Typography>
            </MenuItem>
          ))}
          {dates.filteredDates.length > 0 && [
            <MenuItem disabled key="unavailable-parcels">
              <Typography variant="subtitle1" align="left">
                {i18n.t(keys.generic.unavailable)}
              </Typography>
            </MenuItem>,
            <hr key="for-other-separator" />,
          ]}
          {dates.filteredDates.map((flightDate) => (
            <MenuItem disabled key={flightDate} value={flightDate}>
              <Typography variant="subtitle1" align="left">
                {flightDate}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </AsyncSelectorStatusOverlay>
    </div>
  )
}

export default FlightDateSelector
