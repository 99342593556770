import { createSelector } from 'reselect'

import {
  createValueStatsCalculator,
  ValueStats,
} from '../ProductStats/calculateStats/StatsCalculator'
import { store } from '../redux/createStore'
import { RootStore } from '../redux/types'
import handleError from '../util/handleError'
import { createCancellable } from './cancellable'
import { refreshAsyncSelector, updateAsyncSelector } from './reducer'

interface Input {
  (state: RootStore): any
}

interface Inputs {
  [key: string]: Input
}

interface Fetcher<Args extends Record<string, any>> {
  (args: Args, skip: () => void): Promise<any>
}

type Returns<Funcs extends Inputs> = {
  [K in keyof Funcs]: ReturnType<Funcs[K]>
}

interface AsyncSelectorOptions<I extends Inputs, F extends Fetcher<any>> {
  resource: string
  inputs?: I
  fetcher: F
}

export interface Fetched<D> {
  inputs?: any
  status: 'pending' | 'resolved' | 'rejected' | 'none'
  pendingAt?: Date
  resolvedAt?: Date
  rejectedAt?: Date
  data?: UnwrapPromise<D>
  error?: any
  resolvedStats?: ValueStats
  rejectedStats?: ValueStats
}

interface Cached<D> extends Fetched<D> {
  cacheId: number
}

interface ResourceStats {
  rejected: ReturnType<typeof createValueStatsCalculator>
  resolved: ReturnType<typeof createValueStatsCalculator>
}

const resources = new Map<string, ResourceStats>()

function assertResourceDoesNotExist(resource: string) {
  if (resources.has(resource)) {
    throw new Error(`AsyncSelector '${resource}' already exists.`)
  }

  resources.set(resource, {
    rejected: createValueStatsCalculator(),
    resolved: createValueStatsCalculator(),
  })
}

function assertInputsAreFunction(inputs: Record<string, any>) {
  for (const [key, func] of Object.entries(inputs)) {
    if (typeof func !== 'function') {
      throw new Error(`AsyncSelector inputs['${key}'] is not a function.`)
    }
  }
}

/**
 * This self updating reducer updates when any of its inputs change.
 * You can also manually refresh the data by calling the refresh method.
 */
export const createAsyncSelector = <
  I extends Inputs,
  F extends Fetcher<Returns<I>>
>({
  resource,
  inputs,
  fetcher,
}: AsyncSelectorOptions<I, F>) => {
  assertResourceDoesNotExist(resource)
  if (inputs) {
    assertInputsAreFunction(inputs)
  }

  const stats = resources.get(resource)!

  const DEFAULT: Fetched<ReturnType<F>> = {
    status: 'none',
    inputs: {},
  }

  const getCacheId = (state: RootStore) => {
    const res = (state.AsyncSelector[resource] || DEFAULT) as Cached<
      ReturnType<F>
    >

    return res.cacheId
  }

  let lastInputs = {} as Returns<I>
  const getInputs = (state: RootStore) => {
    let changed = false

    const ins = {} as Returns<I>
    if (inputs) {
      for (const [key, func] of Object.entries(inputs)) {
        ins[key as keyof I] = func(state)

        if (ins[key] !== lastInputs[key]) {
          changed = true
        }
      }
    }

    if (changed) {
      lastInputs = ins
    }

    return lastInputs
  }

  const getData = (state: RootStore) =>
    (state.AsyncSelector[resource] || DEFAULT) as Fetched<ReturnType<F>>

  const update = (result: Partial<Cached<ReturnType<F>>>) => {
    store.dispatch(updateAsyncSelector({ resource, result: result as any }))
  }

  let clearDataOnPending = false

  const refresh = (hard = false) => {
    clearDataOnPending = hard
    store.dispatch(refreshAsyncSelector({ resource }))
  }

  let cancellable = createCancellable(update)
  let skipped = false
  const skip = () => {
    skipped = true
    cancellable.cancel()
  }

  const fetchSelector = createSelector([getInputs, getCacheId], (args) => {
    cancellable.cancel()
    cancellable = createCancellable(update)
    skipped = false
    const pendingAt = new Date()
    const pending: Partial<Cached<ReturnType<F>>> = {
      pendingAt,
      status: 'pending',
      resolvedAt: undefined,
      rejectedAt: undefined,
      error: undefined,
      inputs: args,
    }

    if (clearDataOnPending) {
      pending.data = undefined
    }
    clearDataOnPending = false

    cancellable.callback(pending)

    fetcher(args, skip).then(
      (data) => {
        const resolvedAt = new Date()

        if (!skipped) {
          stats.resolved.update([
            {
              value: resolvedAt.valueOf() - pendingAt.valueOf(),
            },
          ])
        }

        cancellable.callback({
          data,
          resolvedAt,
          status: 'resolved',
          resolvedStats: stats.resolved.digest(),
          inputs: args,
        })
      },
      (error) => {
        const rejectedAt = new Date()

        stats.rejected.update([
          {
            value: rejectedAt.valueOf() - pendingAt.valueOf(),
          },
        ])

        handleError(error)
        cancellable.callback({
          error,
          rejectedAt,
          status: 'rejected',
          data: undefined,
          rejectedStats: stats.rejected.digest(),
          inputs: args,
        })
      }
    )
  })

  const selector = createSelector(getData, fetchSelector, (data) => data)

  return {
    refresh,
    selector,
  }
}

export type AsyncSelector = ReturnType<typeof createAsyncSelector>
