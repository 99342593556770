import * as React from 'react'

import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'

import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  Grid,
  Icon,
  styled,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { useRedux } from '../../hooks/useRedux'
import i18n, { keys } from '../../i18n'
import { updatePreferences } from '../../redux/preferences/redux'
import { classnames } from '../../util/classnames'
import { selectCalculateStats } from '../selectors/stats'
import './StatsWindow.scss'
import { toggleStats } from '../../colorScheme/actions'
import { StatsHistory } from './StatsHistory'
import DownloadIcon from '@mui/icons-material/Download'
import { DownloadStatsMenu } from './DownloadStatsMenu'
import { IconButton } from '@mui/material'
import { StatsChart } from './StatsChart'
import { ValueStatsBar } from './ValueStatsBar'
import { selectSelectedLegendProductId } from '../../data/selectSelectedLegendProductId'
import { selectMapLayerDefsById } from '../../data/selectListMapSourceDefs'
import { selectHistoricalTabEnabled } from '../selectors/selectHistoricalTabEnabled'
import { selectHistoricParcelData } from '../selectors/selectHistoricParcelData'
import { useFeatureFlag } from '../../hooks/useFeatureFlag'
import { KeyboardArrowUp, KeyboardArrowLeft } from '@mui/icons-material'

interface CollapseGridProps {
  isMobile: boolean
}

const CollapseGrid = styled(Grid)<CollapseGridProps>`
  display: flex;
  margin-left: 8px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '8px')};
`

interface CollapseButtonProps {
  valueStatusBarCollapsed: boolean
}

const HorizontalCollapseButton = styled(Button)<CollapseButtonProps>`
  padding: 0;
  min-width: 6px;
  transform: translate(4px, 0px)
    rotateY(${(props) => (props.valueStatusBarCollapsed ? '180deg' : '0deg')});
  transition: transform 0.3s;
`

const VerticalCollapseButton = styled(Button)<CollapseButtonProps>`
  padding: 0;
  min-width: 6px;
  transform: translate(0px, 9px)
    rotateX(${(props) => (props.valueStatusBarCollapsed ? '180deg' : '0deg')});
  transition: transform 0.3s;
`

const HorizontalCollapseIcon = styled(KeyboardArrowLeft)(({ theme }) => ({
  width: '24px',
  color: theme.palette.text.primary,
}))

const VerticalCollapseIcon = styled(KeyboardArrowUp)(({ theme }) => ({
  width: '24px',
  color: theme.palette.text.primary,
}))

export function StatsWindow() {
  const [state, dispatch] = useRedux()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { statsWindowTab, statsWindowDocked } = state.preferences
  const selectedTab = React.useMemo(() => {
    if (
      (statsWindowTab !== 'historic' && statsWindowTab !== 'current') ||
      !statsWindowTab
    ) {
      return 'current'
    }
    return statsWindowTab
  }, [statsWindowTab])
  const getHistoricalParcelData = selectHistoricParcelData(state)
  const getHistoricalTabEnabled = selectHistoricalTabEnabled(state)
  const calculateStats = selectCalculateStats(state)
  const [downloadAnchorEl, setDownloadAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false)

  const productId = selectSelectedLegendProductId(state)
  const productDef = selectMapLayerDefsById(state)[productId]

  const [valueStatusBarCollapsed, setValueStatusBarCollapsed] =
    React.useState(false)
  const [valueStatusBarVisible, setValueStatusBarVisible] = React.useState(true)

  const toggleDocked = () => {
    dispatch(updatePreferences({ statsWindowDocked: !statsWindowDocked }))
  }

  const toggleStatsWindow = () => {
    dispatch(toggleStats({}))
  }

  const setTab = (tab: any) => {
    dispatch(updatePreferences({ statsWindowTab: tab }))
  }

  const onDownloadOptionClicked = (event: React.MouseEvent<HTMLElement>) => {
    setDownloadAnchorEl(event.currentTarget)
    setDownloadDialogOpen(true)
  }

  const closeDownloadMenu = () => {
    setDownloadAnchorEl(null)
    setDownloadDialogOpen(false)
  }

  const { featureEnabled } = useFeatureFlag({
    featureFlagId: 'historical-stats',
  })

  React.useEffect(() => {
    if (getHistoricalTabEnabled && featureEnabled) {
      return
    }
    setTab('current')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHistoricalTabEnabled, featureEnabled])

  return (
    <div className={classnames('StatsWindow', selectedTab)}>
      <AsyncSelectorStatusOverlay
        requests={[calculateStats, getHistoricalParcelData]}
      >
        <div>
          <Grid
            className="StatsWindow-Bar"
            container
            wrap="nowrap"
            alignItems="flex-center"
            justifyContent="space-between"
            id="draggable-dialog-title"
            style={statsWindowDocked ? {} : { cursor: 'move' }}
          >
            <Grid item xs="auto">
              <Typography
                className="StatsWindow-Title"
                variant="subtitle1"
                noWrap
              >
                {productDef?.name}
              </Typography>
            </Grid>
            <Grid item justifySelf="flex-start" alignSelf="flex-start">
              <div className="StatsWindow-Tabs">
                <Tabs
                  style={{ minHeight: 0 }}
                  value={selectedTab}
                  onChange={(event: any, selectedTab: any) =>
                    setTab(selectedTab)
                  }
                  centered
                  indicatorColor="primary"
                  textColor="primary"
                >
                  {getHistoricalTabEnabled && featureEnabled ? (
                    <Tab
                      className="StatsWindowTab"
                      value="current"
                      label={i18n.t(keys.stats.current)}
                    />
                  ) : null}
                  {getHistoricalTabEnabled && featureEnabled ? (
                    <Tab
                      className="StatsWindowTab"
                      value="historic"
                      label={i18n.t(keys.stats.historic)}
                    />
                  ) : null}
                </Tabs>
              </div>
            </Grid>
            <Grid item justifySelf="flex-end">
              <Grid alignItems="flex-end" justifyContent="flex-end">
                <IconButton
                  className="StatsWindow-Download"
                  onClick={onDownloadOptionClicked}
                  disableRipple
                >
                  <Tooltip
                    title={i18n.t(keys.downloadChartDataTitle)}
                    placement="top"
                  >
                    <DownloadIcon />
                  </Tooltip>
                </IconButton>
                {!isMobile ? (
                  <Tooltip
                    title={i18n.t(
                      statsWindowDocked ? keys.popOutStats : keys.dockStats
                    )}
                    placement="top"
                  >
                    <Checkbox
                      className="StatsWindow-Dock"
                      checked={statsWindowDocked}
                      onClick={toggleDocked}
                      color="default"
                      disableRipple
                      icon={<Icon className="pin-icon">push_pin</Icon>}
                      checkedIcon={
                        <Icon className="pin-icon icon-crossed">push_pin</Icon>
                      }
                    />
                  </Tooltip>
                ) : null}
                <Tooltip title={i18n.t(keys.closeStatWindow)} placement="top">
                  <Checkbox
                    className="StatsWindow-Close"
                    checked={true}
                    onClick={toggleStatsWindow}
                    color="default"
                    disableRipple
                    icon={<Icon style={{ color: 'var(--color)' }}>close</Icon>}
                    checkedIcon={
                      <Icon style={{ color: 'var(--color)' }}>close</Icon>
                    }
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Divider orientation="horizontal" variant="middle" />
        <div className="StatsWindow-Current">
          <Grid
            container
            alignContent="space-around"
            justifyContent="space-around"
            direction={isMobile ? 'column' : 'row'}
            wrap="nowrap"
          >
            <Grid
              className={classnames(['hidden', !valueStatusBarVisible])}
              item
            >
              <Collapse
                in={!valueStatusBarCollapsed}
                orientation={isMobile ? 'vertical' : 'horizontal'}
                style={{
                  // Fixes issue with collapse not liking the switch between vertical and horizontal
                  width: valueStatusBarCollapsed && !isMobile ? 0 : '100%',
                  height: valueStatusBarCollapsed && isMobile ? 0 : '100%',
                  transition: 'width 0.3s, height 0.3s',
                }}
                timeout={0}
              >
                <ValueStatsBar
                  isMobile={isMobile}
                  setValueStatusBarVisible={setValueStatusBarVisible}
                />
              </Collapse>
            </Grid>
            <CollapseGrid
              isMobile={isMobile}
              direction={isMobile ? 'row' : 'column'}
              item
              className={classnames(['hidden', !valueStatusBarVisible])}
            >
              <Divider
                orientation={isMobile ? 'horizontal' : 'vertical'}
                style={isMobile ? { paddingTop: '5px' } : {}}
                variant="middle"
                flexItem
                sx={{
                  flexGrow: 1,
                }}
              />
              {isMobile ? (
                <VerticalCollapseButton
                  valueStatusBarCollapsed={valueStatusBarCollapsed}
                  onClick={() =>
                    setValueStatusBarCollapsed(!valueStatusBarCollapsed)
                  }
                >
                  <VerticalCollapseIcon />
                </VerticalCollapseButton>
              ) : (
                <HorizontalCollapseButton
                  valueStatusBarCollapsed={valueStatusBarCollapsed}
                  onClick={() =>
                    setValueStatusBarCollapsed(!valueStatusBarCollapsed)
                  }
                >
                  <HorizontalCollapseIcon />
                </HorizontalCollapseButton>
              )}
              <Divider
                orientation={isMobile ? 'horizontal' : 'vertical'}
                style={isMobile ? { paddingTop: '5px' } : {}}
                variant="middle"
                flexItem
                sx={{
                  flexGrow: 1,
                }}
              />
            </CollapseGrid>
            <Grid
              item
              xs={valueStatusBarVisible && !valueStatusBarCollapsed ? 8.4 : 12}
              padding={1}
            >
              <StatsChart isMobile={isMobile} theme={theme} />
            </Grid>
          </Grid>
        </div>
        <div className="StatsWindow-Historic">
          <StatsHistory isMobile={isMobile} theme={theme} />
        </div>
        <DownloadStatsMenu
          open={downloadDialogOpen}
          onClose={closeDownloadMenu}
          anchorEl={downloadAnchorEl}
        />
      </AsyncSelectorStatusOverlay>
    </div>
  )
}
