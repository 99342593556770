import * as React from 'react'
import { MapEditor } from '../../../map-editor/MapEditor'
import { pageType } from '../redux/redux'
import { useHistory } from 'react-router-dom'

import {
  HeaderOption,
  MapOption,
  DrawerTabOption,
} from '../../../map-editor/types'
import { LibraryAddCheck, Polyline } from '@mui/icons-material'
import i18n, { keys } from '../../../i18n'
import UserMenuButton from '../../../users/UserMenuButton'
import { Button, Icon, Stack } from '@mui/material'
import FlightDateSelector from './header-options/FlightDateSelector'
import {
  RateMapHeaderConfiguration,
  RateMapMapConfiguration,
  RateMapDrawerConfiguration,
} from '../types'
import BlockSelector from './sidebar-options/block-selection/BlockSelector'
import { ReduxContextProvider, useRedux } from '../../../hooks/useRedux'

import {
  selectParcelsById,
  selectParcelsWithFlightDate,
} from '../../../data/selectOrgMapData'

import { RateMapConfig } from './sidebar-options/config/RateMapConfig'
import { RateMapProvider, useRateMapContext } from './RateMapContext'
import { MapContextProvider, useMap } from '../../../map/withMap'

import getBoundsFromParcels from '../../../postgis/getBoundsFromParcels'
import { selectDrawerStatus } from '../../../map-editor/redux/selectors/selectDrawerStatus'

import { selectRateMapProductSettings } from '../selectors/selectRateMapProductSettings'
import { selectRateMapUserSelection } from '../selectors/selectRateMapUserSelection'
import RateMapLegend from './map-options/legend/RateMapLegend'
import { ReactComponent as TractorIcon } from '../assets/tractor.svg'
import { CustomZoneOptions } from './map-options/custom-zones/CustomZoneOptions'
import { CustomZoneCommitOptions } from './map-options/custom-zones/CustomZoneCommitOptions'
import { pureVineZonesLayerID, vigorZonesLayerID } from '../constants'
import { useDrawCustomRateMapZones } from '../hooks/useDrawCustomRateMapZones'
import { MapDrawContextProvider } from '../../../map/draw/MapDrawContext'
import {
  adjustZoneLayerPositions,
  CustomZoneStyles,
} from './map-options/custom-zones/CustomZoneStyles'

// header options
const backButtonId = 'back-button'
const userOptionsId = 'user-options'
const flightSelectorId = 'flight-selection'

// map options
const mapLegendId = 'map-legend'
const customZoneOptionsId = 'custom-zone-options'
const customZoneCommitOptionsId = 'custom-zone-commit-options'
const customZoneLayerOptionsId = 'custom-zone-layer-options'
const customZoneBorderId = 'custom-zone-border'

// sidebar tab options
const blockSelectionOptionsId = 'block-selection-options'
const amendmentOptionsId = 'amendment-options'
const op1 = 'op1'
const op2 = 'op2'
const op3 = 'op3'

const RateMapEditorInner = () => {
  const { map } = useMap()
  const [state] = useRedux()
  const history = useHistory()
  const {
    isCustomZoneMode,
    selectedZoneType,
    setSelectedZoneType,
    snappingEnabled,
    setSnappingEnabled,
    limitToBounds,
    setLimitToBounds,
    hasInvalidGeometry,
  } = useRateMapContext()

  const [, pendingZones, confirmAllZones, cancelDrawing] =
    useDrawCustomRateMapZones(isCustomZoneMode)

  const { rateMap, setModifiedRateMap, isDirty } = useRateMapContext()

  const { leftDrawerOpen, rightDrawerOpen } = selectDrawerStatus(
    state,
    'rateMap'
  )
  const parcels = selectParcelsById(state)

  const selectedParcelIds = React.useMemo(() => {
    if (!rateMap?.RateMapBlockSelections) {
      return new Set([])
    }
    return new Set(
      rateMap.RateMapBlockSelections.filter((block) => block.enabled).map(
        (block) => block.parcelId
      )
    )
  }, [rateMap?.RateMapBlockSelections])

  const selectedFlightDate = React.useMemo(() => {
    if (!rateMap) {
      return ''
    }
    return rateMap.flightDate
  }, [rateMap])

  React.useEffect(() => {
    const selectedParcels = Array.from(selectedParcelIds).map(
      (parcelId) => parcels[parcelId]
    )
    try {
      const parcelsBounds = getBoundsFromParcels(selectedParcels)

      map?.fitBounds(parcelsBounds as any, {
        // if a sidebar is open add extra padding so they don't cover the data.
        padding: {
          top: 100,
          bottom: 100,
          left: leftDrawerOpen ? 400 : 100,
          right: rightDrawerOpen ? 400 : 100,
        },
        duration: 0,
      })
    } catch (e) {
      console.warn('Could not fit selected parcels')
    }
  }, [parcels, selectedParcelIds, map, leftDrawerOpen, rightDrawerOpen])

  const rateMapParcelOptions = selectParcelsWithFlightDate(
    state,
    selectedFlightDate
  )

  const onBack = React.useCallback(() => {
    history.goBack()
  }, [history])

  const defaultHeaderOptions = React.useMemo<
    Record<string, HeaderOption<RateMapHeaderConfiguration>>
  >(() => {
    return {
      [backButtonId]: {
        id: backButtonId,
        position: 'left',
        index: 0,
        active: true,
      },
      [userOptionsId]: {
        id: userOptionsId,
        position: 'right',
        index: 0,
        active: true,
      },
      [flightSelectorId]: {
        id: flightSelectorId,
        position: 'left',
        index: 1,
        active: true,
      },
    }
  }, [])

  const defaultMapOptions = React.useMemo<
    Record<string, MapOption<RateMapMapConfiguration>>
  >(() => {
    return {
      [mapLegendId]: {
        id: mapLegendId,
        position: 'bottom-center',
        active: true,
      },
      [customZoneOptionsId]: {
        id: customZoneOptionsId,
        position: 'top-center',
        active: true,
      },
      [customZoneLayerOptionsId]: {
        id: customZoneLayerOptionsId,
        position: 'self',
        active: false,
      },
      [customZoneCommitOptionsId]: {
        id: customZoneCommitOptionsId,
        position: 'top-center',
        active: true,
      },
      [customZoneBorderId]: {
        id: customZoneBorderId,
        position: 'fill',
        active: true,
      },
    }
  }, [])

  const defaultDrawerOptions = React.useMemo<
    Record<string, DrawerTabOption<RateMapDrawerConfiguration>>
  >(() => {
    return {
      [blockSelectionOptionsId]: {
        id: blockSelectionOptionsId,
        position: 'left-side-bar',
        index: 0,
        active: true,
      },
      [op1]: {
        id: amendmentOptionsId,
        position: 'left-side-bar',
        index: 1,
        active: true,
      },
      [amendmentOptionsId]: {
        id: amendmentOptionsId,
        position: 'right-side-bar',
        index: 0,
        active: true,
      },
      [op2]: {
        id: amendmentOptionsId,
        position: 'right-side-bar',
        index: 1,
        active: true,
      },
      [op3]: {
        id: amendmentOptionsId,
        position: 'right-side-bar',
        index: 2,
        active: true,
      },
    }
  }, [])

  const defaultDrawerStatus = {
    rightDrawerOpen: true,
    leftDrawerOpen: true,
    rightDrawerTabIndex: 0,
    leftDrawerTabIndex: 0,
  }

  const onSelectBlocks = React.useCallback(
    (parcelIds: string[], flightDate: string) => {
      setModifiedRateMap?.((prev) => {
        // initialize the blocks if nothing has been saved yet
        if (!prev && rateMap) {
          return {
            ...rateMap,
            flightDate: flightDate ?? rateMap.flightDate,
            RateMapBlockSelections: rateMapParcelOptions.map((parcel) => {
              return {
                rateMapId: rateMap.id,
                parcelId: parcel.id,
                enabled: parcelIds.includes(parcel.id),
              }
            }),
          }
        }

        if (!rateMap) {
          return prev
        }
        return {
          ...rateMap,
          flightDate,
          RateMapBlockSelections: rateMap.RateMapBlockSelections.map(
            (block) => {
              return {
                ...block,
                enabled: parcelIds.includes(block.parcelId),
              }
            }
          ),
        }
      })
    },
    [rateMap, setModifiedRateMap, rateMapParcelOptions]
  )

  const onFlightDateChanged = React.useCallback(
    (flightDate: string) => {
      setModifiedRateMap?.((prev) => {
        if (!prev && rateMap) {
          return {
            ...rateMap,
            flightDate,
            RateMapBlockSelections: rateMapParcelOptions.map((parcel) => {
              return {
                rateMapId: rateMap.id,
                parcelId: parcel.id,
                enabled: selectedParcelIds.has(parcel.id),
              }
            }),
          }
        }

        if (!rateMap) {
          return prev
        }
        return {
          ...rateMap,
          flightDate,
        }
      })
    },
    [rateMap, setModifiedRateMap, rateMapParcelOptions, selectedParcelIds]
  )

  const headerComponents = React.useMemo(() => {
    return {
      [backButtonId]: () => (
        <Button
          style={{ minWidth: 0, textTransform: 'none', color: '#fff' }}
          onClick={onBack}
        >
          <Icon>keyboard_arrow_left</Icon> {i18n.t(keys.back)}
        </Button>
      ),
      [userOptionsId]: () => <UserMenuButton />,
      [flightSelectorId]: () => (
        <FlightDateSelector
          page={pageType}
          selectedFlightDate={selectedFlightDate ?? ''}
          onSelectedFlightDateChange={onFlightDateChanged}
          blockSelectorId={blockSelectionOptionsId}
          selectedParcelIds={selectedParcelIds}
          disabled={isCustomZoneMode}
          dateFilter={(gd) => {
            return gd.mapLayerDefIds.every(
              (id) => id !== pureVineZonesLayerID && id !== vigorZonesLayerID
            )
          }}
        />
      ),
    }
  }, [
    onBack,
    onFlightDateChanged,
    selectedFlightDate,
    selectedParcelIds,
    isCustomZoneMode,
  ])

  const mapComponents = React.useMemo(() => {
    return {
      [mapLegendId]: () => {
        return isCustomZoneMode ? null : <RateMapLegend />
      },
      [customZoneOptionsId]: () =>
        isCustomZoneMode ? (
          <Stack direction="row" sx={{ marginTop: 6 }}>
            <CustomZoneOptions
              selectedZoneType={selectedZoneType}
              setSelectedZoneType={setSelectedZoneType}
              snappingEnabled={snappingEnabled}
              setSnappingEnabled={setSnappingEnabled}
              limitToBounds={limitToBounds}
              setLimitToBounds={setLimitToBounds}
              hasInvalidGeometry={hasInvalidGeometry}
            />
          </Stack>
        ) : null,
      [customZoneCommitOptionsId]: () =>
        isCustomZoneMode ? (
          <Stack direction="row" spacing={2} sx={{ marginTop: 1 }}>
            <CustomZoneCommitOptions
              confirmAllZones={confirmAllZones}
              pendingZones={pendingZones}
              cancelDrawing={cancelDrawing}
            />
          </Stack>
        ) : null,

      [customZoneBorderId]: () =>
        isCustomZoneMode ? (
          <Stack
            sx={{
              border: 'var(--logo) solid 4px',
              height: '100%',
              width: '100%',
            }}
          ></Stack>
        ) : null,
    }
  }, [
    isCustomZoneMode,
    selectedZoneType,
    setSelectedZoneType,
    snappingEnabled,
    setSnappingEnabled,
    limitToBounds,
    setLimitToBounds,
    hasInvalidGeometry,
    pendingZones,
    confirmAllZones,
    cancelDrawing,
  ])

  const drawerTabComponents = React.useMemo(() => {
    return {
      [blockSelectionOptionsId]: {
        icon: () => {
          return <LibraryAddCheck />
        },
        component: (option: DrawerTabOption<RateMapDrawerConfiguration>) => {
          return (
            <BlockSelector
              {...option}
              page={pageType}
              selectedParcelIds={selectedParcelIds}
              selectedFlightDate={selectedFlightDate ?? ''}
              onSelect={onSelectBlocks}
              disableUnavailableGroups={true}
            />
          )
        },
      },
      [amendmentOptionsId]: {
        icon: () => {
          return <Polyline />
        },
        component: () => {
          return <RateMapConfig />
        },
      },
    }
  }, [selectedFlightDate, onSelectBlocks, selectedParcelIds])

  React.useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        // eslint-disable-next-line no-restricted-globals
        const res = confirm()

        if (res.toString() === 'false') {
          e.preventDefault()
        }
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isDirty])

  return (
    <MapEditor
      pageType={pageType}
      pageIcon={<TractorIcon />}
      defaultDrawerStatus={defaultDrawerStatus}
      defaultHeaderOptions={defaultHeaderOptions}
      defaultMapOptions={defaultMapOptions}
      defaultDrawerTabOptions={defaultDrawerOptions}
      defaultMapSettings={{}}
      headerComponents={headerComponents}
      mapOverlayComponents={mapComponents}
      drawerTabComponents={drawerTabComponents}
    />
  )
}

const RateMapEditorWithRedux = () => {
  const rateMapContextState = useRateMapContext()

  const [externalState] = useRedux()
  const rateMapProductSettings = selectRateMapProductSettings(
    rateMapContextState,
    externalState
  )

  const rateMapUserSelection = selectRateMapUserSelection(
    rateMapContextState,
    externalState
  )

  return (
    <ReduxContextProvider
      stateOverrides={{
        userSelection: rateMapUserSelection,
        ProductSettings: {
          userProductSettings: rateMapProductSettings,
        },
      }}
    >
      <RateMapEditorInner />
    </ReduxContextProvider>
  )
}

export const RateMapEditor = () => (
  <MapContextProvider>
    <MapDrawContextProvider
      defaultStyle={CustomZoneStyles}
      setCustomLayerPositions={adjustZoneLayerPositions}
    >
      <RateMapProvider>
        <RateMapEditorWithRedux />
      </RateMapProvider>
    </MapDrawContextProvider>
  </MapContextProvider>
)
