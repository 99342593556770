import * as React from 'react'
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { ParcelFilterInfo } from '../../../../../../../postgis/types'
import i18n, { keys } from '../../../../../../../i18n'
import { isEmpty } from 'lodash'

const CLEAR_FILTER = 'CLEAR_FILTER'

interface Props {
  filterProperty: keyof ParcelFilterInfo['metaFilters']
  parcelFilterInfo: ParcelFilterInfo
  availableValues: string[]
  inputLabel: string
  noAvailableValuesLabel: string
  onSelect: (filterProperty: string, values: string[]) => void
  onReset: (filterProperty: string) => void
  disabled?: boolean
}

const MetaDataFilter = ({
  filterProperty,
  parcelFilterInfo,
  availableValues,
  inputLabel,
  noAvailableValuesLabel,
  onSelect,
  onReset,
  disabled,
}: Props) => {
  const selectedValues = React.useMemo(
    () => (parcelFilterInfo?.metaFilters?.[filterProperty] ?? []) as string[],
    [filterProperty, parcelFilterInfo.metaFilters]
  )

  const handleSelectionChange = React.useCallback(
    (event: SelectChangeEvent<string[]>) => {
      let selection = event.target.value
      if (typeof selection === 'string') {
        selection = selection.split(', ')
      }
      if (selection.includes(CLEAR_FILTER)) {
        return
      }

      onSelect(filterProperty, selection)
    },
    [filterProperty, onSelect]
  )

  const resetSelection = React.useCallback(() => {
    onReset(filterProperty)
  }, [filterProperty, onReset])

  return (
    <ListItem>
      <FormControl fullWidth>
        <InputLabel>{inputLabel}</InputLabel>
        <Select
          disabled={disabled || availableValues.length === 0}
          multiple
          value={
            availableValues.length === 0
              ? [noAvailableValuesLabel]
              : selectedValues
          }
          onChange={handleSelectionChange}
          renderValue={(selected: any[]) => selected.join(', ')}
        >
          <MenuItem
            value={CLEAR_FILTER}
            disabled={disabled || isEmpty(selectedValues)}
          >
            <ListItemText
              primary={i18n.t(keys.forms.parcelFilter.resetSelection)}
              onClick={resetSelection}
            />
          </MenuItem>

          {availableValues.map((value) => (
            <MenuItem key={value} value={value}>
              <Checkbox checked={selectedValues.includes(value)} />
              <ListItemText primary={value} />
            </MenuItem>
          ))}
          {availableValues.length === 0 && (
            <MenuItem
              disabled
              key={noAvailableValuesLabel}
              value={noAvailableValuesLabel}
            >
              <ListItemText primary={noAvailableValuesLabel} />
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </ListItem>
  )
}

export default MetaDataFilter
