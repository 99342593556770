const waitForLoaded = (
  condition: () => boolean,
  effect: () => void,
  interval: number = 100
) => {
  const retryInterval = setInterval(() => {
    if (condition()) {
      clearInterval(retryInterval)
      effect()
    }
  }, interval)

  return () => clearInterval(retryInterval)
}

export default waitForLoaded
