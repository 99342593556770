import * as React from 'react'
import {
  Stack,
  TableCell,
  Table as MuiTable,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  IconButton,
  Divider,
  Button,
  TableContainer,
  Backdrop,
  CircularProgress,
  Slide,
} from '@mui/material'
import i18n, { keys } from '../../../i18n'
import { classnames } from '../../../util/classnames'
import {
  ChevronLeft,
  ChevronRight,
  LocationSearching,
  PictureAsPdf,
} from '@mui/icons-material'
import bbox from '@turf/bbox'
import { SamplePlanMoreMenu } from '../SamplePlanMoreMenu'
import { SamplePlanFormChip } from '../SamplePlanFormChip'
import { SamplePlanStatusText } from '../SamplePlanStatusText'
import { url, urls } from '../../../appNavigation/urls'
import { useHistory } from 'react-router-dom'
import { LngLatBoundsLike } from 'mapbox-gl'
import { SamplePlanBlockDetailContent } from './SamplePlanBlockDetailContent'
import { useSamplePlanMapContext } from '../Context/SamplePlanMapContext'
import { Sample } from '../../../graphql/types'
import { setFocusedPoint } from '../../../postgis/actions'
import { useRedux } from '../../../hooks/useRedux'

interface Props {
  sidePanelOpen?: boolean
  setSidePanelOpen: (open: boolean) => void
  toggleDownloadPlan: () => void
}

const BlockHeaderTableCell = ({ headerLabel }: { headerLabel: string }) => {
  return (
    <TableCell className="sample-plan-block-header-cell">
      <Typography variant="overline">{headerLabel}</Typography>
    </TableCell>
  )
}

const BlockBodyTableCell = ({ children }: { children: React.ReactNode }) => {
  return (
    <TableCell className="sample-plan-block-body-cell">{children}</TableCell>
  )
}

const timeout = { appear: 0, enter: 500, exit: 200 }

export const SamplePlanDetailSidePanel = ({
  sidePanelOpen,
  setSidePanelOpen,
  toggleDownloadPlan,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, dispatch] = useRedux()
  const history = useHistory()
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [sampleInfoOpen, setSampleInfoOpen] = React.useState(false)
  const {
    state: { samplePlan, selectedSamplePlanBlocks },
    dispatch: samplePlanMapDispatch,
    setBlockBounds,
    refreshSamplePlan,
    isLoading,
  } = useSamplePlanMapContext()

  const onClickSamplePlanBlockRow = async (
    samplePlanBlockId: string,
    showSampleInfo?: boolean
  ) => {
    if (samplePlanBlockId !== selectedSamplePlanBlocks?.[0]?.id) {
      samplePlanMapDispatch({
        type: 'SET_SELECTED_SAMPLE_PLAN_BLOCKS',
        selectedSamplePlanBlockIds: [samplePlanBlockId],
      })
      setSampleInfoOpen(!!showSampleInfo)
      samplePlanMapDispatch({
        type: 'SET_SELECTED_SAMPLE_PLAN_SAMPLES',
        selectedSamplePlanSampleIds:
          samplePlan?.Samples?.filter(
            (s) => s?.samplePlanBlockId === samplePlanBlockId
          )?.map((s) => s.id) ?? [],
      })
      samplePlanMapDispatch({
        type: 'SET_HIGHLIGHTED_SAMPLE',
        sampleId: null,
      })
    } else {
      if (showSampleInfo) {
        setSampleInfoOpen(!!showSampleInfo)
      } else {
        samplePlanMapDispatch({
          type: 'SET_SELECTED_SAMPLE_PLAN_BLOCKS',
          selectedSamplePlanBlockIds: [],
        })
        samplePlanMapDispatch({
          type: 'SET_SELECTED_SAMPLE_PLAN_SAMPLES',
          selectedSamplePlanSampleIds: [],
        })
        samplePlanMapDispatch({
          type: 'SET_HIGHLIGHTED_SAMPLE',
          sampleId: null,
        })
      }
    }
  }

  const onClickSamplePlanBlockLocationButton = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    flyToSamplePlanBlock(e.currentTarget.id)
  }

  const flyToSamplePlanBlock = (id: string) => {
    const samplePlanParcelGeometry = samplePlan?.SamplePlanBlocks?.find(
      (spb) => spb.id === id
    )?.Parcel.geometry
    if (!!samplePlanParcelGeometry) {
      setBlockBounds(bbox(samplePlanParcelGeometry) as LngLatBoundsLike)
    }
  }

  const navigateToResults = () => {
    history.push(
      url(urls.samplePlanResults, { samplePlanId: samplePlan?.id }),
      {
        fromMap: true,
      }
    )
  }

  const refresh = async () => {
    refreshSamplePlan()
  }

  const completedSamples = React.useMemo(() => {
    return samplePlan?.Samples?.filter((s) => s.completedAt)
  }, [samplePlan?.Samples])

  const samplesByBlock = React.useMemo(() => {
    return samplePlan?.Samples?.reduce((acc, s) => {
      if (!acc[s.samplePlanBlockId]) {
        acc[s.samplePlanBlockId] = []
      }

      acc[s.samplePlanBlockId].push(s)

      return acc
    }, {} as Record<string, Sample[]>)
  }, [samplePlan?.Samples])

  const completedSamplesByBlock = React.useMemo(() => {
    return Object.entries(samplesByBlock ?? {}).reduce(
      (acc, [blockId, samples]) => {
        acc[blockId] = samples.filter((s) => s.completedAt)

        return acc
      },
      {} as Record<string, Sample[]>
    )
  }, [samplesByBlock])

  const samplePlanTemplate = React.useMemo(() => {
    return {
      ...samplePlan?.NoteForm,
    }
  }, [samplePlan?.NoteForm])

  return (
    <Stack
      className={classnames('sample-plan-detail-side-panel', [
        'closed',
        !sidePanelOpen,
      ])}
    >
      <IconButton
        disableRipple
        className={classnames('collapse-arrow', ['closed', !sidePanelOpen])}
        size="small"
        onClick={() => setSidePanelOpen(!sidePanelOpen)}
      >
        <ChevronLeft />
      </IconButton>
      <Slide direction="right" timeout={timeout} in={sidePanelOpen}>
        <Stack
          style={{ overflow: 'hidden', width: '100%', height: '100%' }}
          ref={containerRef}
        >
          <Slide
            className="sample-plan-detail-section"
            direction="right"
            timeout={timeout}
            in={!sampleInfoOpen && sidePanelOpen}
            container={containerRef.current}
            style={{ zIndex: 2 }}
          >
            <Stack direction="column">
              <Stack
                className="sample-plan-detail-info-panel"
                direction="column"
                spacing={1}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    sx={{ fontWeight: 600 }}
                    variant="h6"
                    align="center"
                  >
                    {samplePlan?.name}
                  </Typography>
                  <SamplePlanMoreMenu
                    samplePlanId={samplePlan?.id ?? ''}
                    samplePlanName={samplePlan?.name ?? ''}
                    refresh={refresh}
                    onDelete={() => history.push(url(urls.samplePlans))}
                    onDuplicate={(duplicateSamplePlanId) => {
                      dispatch({ type: 'setEditingSampleId', sampleId: null })
                      dispatch(setFocusedPoint(null))
                      history.push(
                        url(urls.samplePlan, {
                          samplePlanId: duplicateSamplePlanId,
                        })
                      )
                    }}
                  />
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Typography>
                    {i18n.toDateShort(samplePlan?.createdAt ?? '-')}
                  </Typography>
                  <Divider
                    sx={{ mx: '5px' }}
                    orientation="vertical"
                    variant="fullWidth"
                    flexItem
                  />
                  <SamplePlanFormChip
                    templateName={i18n.t(
                      `noteForm.reservedNames.${samplePlan?.NoteForm?.name}`,
                      { defaultValue: samplePlan?.templateName ?? '' }
                    )}
                    noteFormColor={samplePlanTemplate?.pinColor}
                  />
                  <Divider
                    sx={{ mx: '5px' }}
                    orientation="vertical"
                    variant="fullWidth"
                    flexItem
                  />
                  <SamplePlanStatusText
                    isPending={samplePlan?.isPending}
                    completedSamples={completedSamples?.length}
                    totalSamples={samplePlan?.Samples?.length}
                  />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={10}>
                  <Stack direction="column">
                    <Typography fontWeight={400}>
                      {`${i18n.t(keys.samplePlanLastUpdateColumnLabel)}:`}
                    </Typography>
                    <Typography fontWeight={700}>
                      {`${i18n.toDateShort(samplePlan?.updatedAt ?? '-')}`}
                    </Typography>
                  </Stack>
                  <Stack direction="column">
                    <Typography fontWeight={400}>
                      {`${i18n.t(keys.samplePlanBlockLocationColumnLabel)}:`}
                    </Typography>
                    <Typography fontWeight={700}>
                      {`${
                        samplePlan?.SamplePlanBlocks?.[0]?.Parcel
                          ?.OrganizationGroup?.name ?? '-'
                      }`}
                    </Typography>
                  </Stack>
                </Stack>

                <Divider
                  sx={{ my: '15px' }}
                  orientation="horizontal"
                  variant="fullWidth"
                  flexItem
                />
                <Stack direction="row" spacing={1}>
                  <Button
                    title={i18n.t(keys.results)}
                    disabled={samplePlan?.isPending}
                    variant="contained"
                    onClick={navigateToResults}
                    sx={{ width: '50%' }}
                  >
                    {i18n.t(keys.results)}
                  </Button>
                  <Button
                    title={i18n.t(keys.samplePlanDownloadPlanLabel)}
                    variant="outlined"
                    onClick={toggleDownloadPlan}
                    sx={{ width: '50%' }}
                    startIcon={<PictureAsPdf />}
                  >
                    {i18n.t(keys.samplePlanDownloadPlanLabel)}
                  </Button>
                </Stack>
              </Stack>
              <TableContainer sx={{ maxHeight: 'calc(100vh - 285px)' }}>
                <MuiTable stickyHeader>
                  <TableHead>
                    <TableRow>
                      <BlockHeaderTableCell
                        headerLabel={i18n.t(keys.samplePlanBlockIdColumnLabel)}
                      />
                      <BlockHeaderTableCell
                        headerLabel={i18n.t(
                          keys.samplePlanBlockSampleCountColumnLabel
                        )}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {samplePlan?.SamplePlanBlocks?.map((spb) => (
                      <TableRow
                        key={spb.id}
                        id={spb.id}
                        className={classnames('sample-plan-block-row', [
                          'selected',
                          spb.id === selectedSamplePlanBlocks?.[0]?.id,
                        ])}
                        onClick={async (e) =>
                          await onClickSamplePlanBlockRow(e.currentTarget.id)
                        }
                      >
                        <BlockBodyTableCell>
                          <Typography>{spb.Parcel.name}</Typography>
                        </BlockBodyTableCell>
                        <BlockBodyTableCell>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography>
                              {completedSamplesByBlock?.[spb.id]?.length ?? '-'}
                              /{samplesByBlock?.[spb.id]?.length ?? '-'}
                            </Typography>
                            <Stack
                              direction="row"
                              spacing={0.5}
                              alignItems="center"
                            >
                              <IconButton
                                id={spb.id}
                                onClick={onClickSamplePlanBlockLocationButton}
                              >
                                <LocationSearching
                                  className={classnames(
                                    'sample-plan-block-location-icon',
                                    [
                                      'selected',
                                      spb.id ===
                                        selectedSamplePlanBlocks?.[0]?.id,
                                    ]
                                  )}
                                />
                              </IconButton>
                              <IconButton
                                className={classnames(
                                  'sample-plan-block-open-button',
                                  [
                                    'selected',
                                    spb.id ===
                                      selectedSamplePlanBlocks?.[0]?.id,
                                  ]
                                )}
                                id={spb.id}
                                onClick={async (e) => {
                                  e.stopPropagation()
                                  await onClickSamplePlanBlockRow(
                                    e.currentTarget.id,
                                    true
                                  )
                                }}
                                size="small"
                              >
                                <ChevronRight />
                              </IconButton>
                            </Stack>
                          </Stack>
                        </BlockBodyTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </MuiTable>
              </TableContainer>
            </Stack>
          </Slide>
          <Slide
            className="sample-plan-detail-section"
            direction="right"
            timeout={timeout}
            in={sampleInfoOpen && sidePanelOpen}
            container={containerRef.current}
            style={{ zIndex: 1 }}
          >
            <div>
              <SamplePlanBlockDetailContent
                onClose={() => setSampleInfoOpen(false)}
              />
            </div>
          </Slide>
        </Stack>
      </Slide>
      <Backdrop
        open={isLoading}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress />
      </Backdrop>
    </Stack>
  )
}
