import * as React from 'react'

import {
  Checkbox,
  Icon,
  ListItemText,
  MenuItem,
  Radio,
  Tooltip,
} from '@mui/material'
import { selectInactiveProductIds } from '../data/selectAvailableProductIds'
import { selectIsAdmin } from '../data/selectMe'
import { selectSelectedLegendProductId } from '../data/selectSelectedLegendProductId'
import { selectSelectedProductIds } from '../data/selectSelectedProductIds'
import { MapLayerDefData } from '../data/types'

import { useRedux } from '../hooks/useRedux'

interface Props {
  product: MapLayerDefData
  onLayerToggled: (product: MapLayerDefData) => void
  onLayerLegendSelect?: (product: MapLayerDefData) => void
  onExclusiveLayerSelect: (product: MapLayerDefData) => void
}

const DataLayerMenuItem = ({
  product,
  onLayerToggled,
  onLayerLegendSelect,
  onExclusiveLayerSelect,
}: Props) => {
  const [state] = useRedux()
  const isAdmin = selectIsAdmin(state)
  const selectedLegendProductId = selectSelectedLegendProductId(state)
  const selectedProductIds = selectSelectedProductIds(state)
  const inactiveProducts = selectInactiveProductIds(state)

  const { id, name } = product

  const handleExclusiveLayerSelect = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onExclusiveLayerSelect(product)
  }

  const handleLayerToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onLayerToggled(product)
  }

  const handleLegendSelect = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (onLayerLegendSelect) {
      onLayerLegendSelect(product)
    }
  }

  return (
    <MenuItem onClick={handleExclusiveLayerSelect}>
      <Checkbox
        checked={selectedProductIds.includes(id)}
        onClick={handleLayerToggle}
        color="primary"
      />
      <ListItemText primary={name} style={{ whiteSpace: 'break-spaces' }} />
      {isAdmin && inactiveProducts.has(id) && (
        <Tooltip
          title="Layer is inactive"
          style={{
            position: 'absolute',
            left: 0,
            opacity: 0.7,
            transform: 'scale(0.7)',
          }}
        >
          <Icon fontSize="inherit">visibility_off</Icon>
        </Tooltip>
      )}
      {onLayerLegendSelect && (
        <Radio
          checked={selectedLegendProductId === id}
          onClick={handleLegendSelect}
          color="primary"
        />
      )}
    </MenuItem>
  )
}

export default DataLayerMenuItem
