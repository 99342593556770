import { createSelector } from 'reselect'
import {
  createAsyncSelector,
  Fetched,
} from '../../../../AsyncSelector/createAsyncSelector'
import * as api from '../../../../graphql/api'
import { client, gql } from '../../../../graphql/client'
import {
  GQLListResult,
  GQLQueryResult,
} from '../../../../graphql/createGQLResource'
import {
  DeliveryProcGroup,
  DeliveryProcGroupPackage,
  Job,
} from '../../../../graphql/types'
import { RootStore } from '../../../../redux/types'
import filterTruthy from '../../../../util/filterTruthy'
import { indexMultiArrayMappedKey } from '../../../../util/indexArray'
import { objectToString } from '../../../../util/objectToString'

interface DeliveryProcGroupWithCommentAggregate extends DeliveryProcGroup {
  commentAggregate: {
    aggregate: {
      count: number
    }
  }
  Watchers: {
    User: {
      id: number
      email: string
      firstName: string
      lastName: string
    }
  }[]
}

export interface DeliveryProcGroupWithNeighborsAndJobStatuses
  extends DeliveryProcGroupWithCommentAggregate,
    DeliveryProcGroupWithStatuses {
  nextProcGroup?: { procGroup: string }
  prevProcGroup?: { procGroup: string }
}

const {
  selector: selectGetDeliveryProcGroup,
  refresh: refreshGetDeliveryProcGroup,
} = createAsyncSelector({
  resource: 'GetDeliveryProcGroup',
  inputs: {
    deliveryId: (state: RootStore) => state.router.params.deliveryId,
    procGroup: (state: RootStore) => state.router.params.procGroup,
  },
  fetcher: async ({ deliveryId, procGroup }) => {
    if (!deliveryId || !procGroup) {
      return
    }

    const {
      deliveryProcGroup,
      nextProcGroups: [nextProcGroup],
      prevProcGroups: [prevProcGroup],
    } = await client.request<{
      deliveryProcGroup: DeliveryProcGroupWithCommentAggregate
      nextProcGroups: Pick<DeliveryProcGroup, 'procGroup'>[]
      prevProcGroups: Pick<DeliveryProcGroup, 'procGroup'>[]
    }>({
      query: gql`
        query DELIVERY_PROC_GROUP($deliveryId: uuid!, $procGroup: String!) {
          deliveryProcGroup: Delivery_ProcGroup_by_pk(
            deliveryId: $deliveryId
            procGroup: $procGroup
          ) {
            deliveryId
            procGroup
            organizationId
            procGroup
            flightDate
            groupName
            location
            qaStatus
            Delivery {
              comment
              TargetDelivery {
                date
              }
              Organization {
                name
              }
              Order {
                comment
              }
            }
            ReferenceSetupJob {
              id
              taskName
              isPending
              Status {
                status
              }
            }
            ReferenceUpdateJob {
              id
              taskName
              isPending
              Status {
                status
              }
            }
            MatchFilterJob {
              id
              taskName
              isPending
              Status {
                status
              }
            }
            LineDrawingJob {
              id
              taskName
              isPending
              Status {
                status
              }
            }
            UploadJob {
              id
              taskName
              isPending
              Status {
                status
              }
            }
            Watchers {
              User {
                id
                email
                firstName
                lastName
              }
            }
            Assignee {
              id
              email
              firstName
              lastName
            }
            HistoricalProcGroups(
              where: {
                deliveryId: { _neq: $deliveryId }
                KatoLineQAJob: { LatestJobAttempt: {} }
              }
            ) {
              deliveryId
              procGroup
              flightDate
              KatoLineQAJob {
                LatestJobAttempt {
                  output
                }
              }
            }
            KatoLineQAJob {
              taskName
              id
              isPending
              Status {
                status
              }
              LatestJobAttempt {
                attempt
                status
                output
                worker
              }
            }
            Packages {
              package
              Job {
                taskName
                isPending
                id
                Status {
                  status
                }
                LatestJobAttempt {
                  output
                  startedAt
                  finishedAt
                }
              }
            }
          }

          nextProcGroups: Delivery_ProcGroup(
            where: {
              procGroup: { _gt: $procGroup }
              deliveryId: { _eq: $deliveryId }
            }
            limit: 1
            order_by: { procGroup: asc }
          ) {
            procGroup
          }
          prevProcGroups: Delivery_ProcGroup(
            where: {
              procGroup: { _lt: $procGroup }
              deliveryId: { _eq: $deliveryId }
            }
            limit: 1
            order_by: { procGroup: desc }
          ) {
            procGroup
          }
        }
      `,
      variables: { deliveryId, procGroup },
    })

    return { ...deliveryProcGroup, nextProcGroup, prevProcGroup }
  },
})

const selectDeliveryProcGroup = createSelector(
  selectGetDeliveryProcGroup,
  (deliveryProcGroupSelector) => {
    if (!deliveryProcGroupSelector.data) {
      return undefined
    }

    return {
      ...deliveryProcGroupSelector.data,
      rowMaskStatus: getStatusAndId(
        deliveryProcGroupSelector.data.LineDrawingJob
      ),
      referenceSetupStatus: getStatusAndId(
        deliveryProcGroupSelector.data.ReferenceSetupJob
      ),
      matchFilterStatus: getStatusAndId(
        deliveryProcGroupSelector.data.MatchFilterJob
      ),
      katoStatus: getStatusAndId(deliveryProcGroupSelector.data.KatoLineQAJob),
      referenceUpdateStatus: getStatusAndId(
        deliveryProcGroupSelector.data.ReferenceUpdateJob
      ),
      finalProductsStatuses: deliveryProcGroupSelector.data.Packages?.map(
        (pack) => getStatusAndId(pack.Job)
      ),

      uploadStatus: getStatusAndId(deliveryProcGroupSelector.data.UploadJob),
    } as DeliveryProcGroupWithNeighborsAndJobStatuses
  }
)

const fetchDeliveryProcGroups = async ({
  deliveryId,
}: {
  deliveryId: string
}) => {
  if (!deliveryId) {
    return []
  }

  return api.deliveryProcGroup.list({
    where: {
      deliveryId: { _eq: deliveryId },
      TargetDelivery_geojsons: {},
    },
    returning: `{
      procGroup
      groupName
      deliveryId
      organizationId
      flightDate
      qaStatus
      Assignee {
        email
        firstName
        lastName
      }
      ReferenceSetupJob {
        taskName
        isPending
        id
        Status {
          status
        }
        LatestJobAttempt {  output }
      }
      MatchFilterJob {
        taskName
        isPending
        id
        Status {
          status
        }
        LatestJobAttempt {  output }
      }
      ReferenceUpdateJob {
        taskName
        isPending
        id
        Status {
          status
        }
        LatestJobAttempt {  output }
      }
      LineDrawingJob {
        taskName
        isPending
        id
        Status {
          status
        }
        LatestJobAttempt {  output }
      }
      KatoLineQAJob { 
        taskName
        isPending
        id
        Status {
          status
        }
        LatestJobAttempt { output } 
      }
      UploadJob { 
        taskName
        isPending
        id
        Status {
          status
        }
        LatestJobAttempt { output, startedAt, finishedAt  } 
      }
      Packages {
        package
        Job {
          taskName
          isPending
          id  
          Status {
            status
          }
          LatestJobAttempt {  output, startedAt, finishedAt }  
        }
      }
      commentAggregate:Delivery_ProcGroup_Comments_aggregate {
        aggregate {
          count
        }
      }
    }`,
  })
}

const {
  selector: selectGetDeliveryProcGroups,
  refresh: refreshGetDeliveryProcGroups,
} = createAsyncSelector({
  resource: 'DeliveryProcGroups',
  inputs: {
    deliveryId: (state: RootStore) => state.router.params.deliveryId,
  },
  fetcher: fetchDeliveryProcGroups,
})

const sortProcGroups = (procGroups: DeliveryProcGroupWithStatuses[] = []) =>
  indexMultiArrayMappedKey(
    procGroups.sort((ja, jb) => {
      return (ja.procGroup ?? '').localeCompare(jb.procGroup ?? '', undefined, {
        numeric: true,
      })
    }) || EMPTY_KATO_JOBS,
    (j) => j.groupName ?? ''
  )

export type DeliveryProcGroupWithStatuses =
  DeliveryProcGroupWithCommentAggregate & {
    matchFilterStatus: JobStatusAndId
    katoStatus: JobStatusAndId
    rowMaskStatus: JobStatusAndId
    referenceSetupStatus: JobStatusAndId
    uploadStatus: JobStatusAndId
    referenceUpdateStatus: JobStatusAndId
    finalProductsStatuses: JobStatusAndId[]
  }

const EMPTY_KATO_JOBS: DeliveryProcGroupWithStatuses[] = []

export interface JobStatusAndId {
  status: string
  id?: string
  startedAt?: Date
  finishedAt?: Date
  taskName?: string
  output?: string[]
}
export const getStatusAndId = (
  job?: GQLQueryResult<Job | undefined>,
  noJobText = 'not-applicable'
): JobStatusAndId => {
  if (!job) {
    return { status: noJobText }
  }

  if (job.Status?.status) {
    return {
      taskName: job.taskName,
      status: job.Status!.status!,
      startedAt: job.LatestJobAttempt?.startedAt
        ? new Date(job.LatestJobAttempt.startedAt)
        : undefined,
      finishedAt: job.LatestJobAttempt?.finishedAt
        ? new Date(job.LatestJobAttempt.finishedAt)
        : undefined,
      id: job.id,
      output: job.LatestJobAttempt
        ? objectToString(job.LatestJobAttempt!.output ?? {})
        : undefined,
    }
  }

  return { status: 'unknown' }
}

const selectDeliveryProcGroupsWithStatus = createSelector(
  selectGetDeliveryProcGroups,
  (
    deliveryProcGroups: Fetched<
      Promise<GQLListResult<GQLQueryResult<DeliveryProcGroup>[]>>
    >
  ) => {
    return (
      deliveryProcGroups.data?.data?.map(populateDeliveryProcGroupStatuses) ??
      EMPTY_KATO_JOBS
    )
  }
)

const populateDeliveryProcGroupStatuses = (
  deliveryProcGroup: DeliveryProcGroupWithCommentAggregate
): DeliveryProcGroupWithStatuses => {
  const matchFilterStatus = getStatusAndId(deliveryProcGroup.MatchFilterJob)
  const katoStatus = getStatusAndId(deliveryProcGroup.KatoLineQAJob)
  const rowMaskStatus = getStatusAndId(deliveryProcGroup.LineDrawingJob)
  const referenceSetupStatus = getStatusAndId(
    deliveryProcGroup.ReferenceSetupJob
  )
  const referenceUpdateStatus = getStatusAndId(
    deliveryProcGroup.ReferenceUpdateJob
  )
  const uploadStatus = getStatusAndId(deliveryProcGroup.UploadJob, 'un-queued')
  let finalProductsStatuses: JobStatusAndId[] = [{ status: 'not-applicable' }]
  const packages = deliveryProcGroup.Packages as DeliveryProcGroupPackage[]

  if (packages.length > 0) {
    finalProductsStatuses = packages
      .filter(filterTruthy)
      .map((dpp) => getStatusAndId(dpp.Job))
  }

  return {
    ...deliveryProcGroup,
    matchFilterStatus,
    katoStatus,
    rowMaskStatus,
    uploadStatus,
    finalProductsStatuses,
    referenceSetupStatus,
    referenceUpdateStatus,
  }
}

export const selectDeliveryProcGroupCounts = createSelector(
  selectDeliveryProcGroupsWithStatus,
  (deliveryProcGroups) => {
    const counts: Record<RowMaskTabs, number> = {
      all: 0,
      missing: 0,
      'row-mask': 0,
      'canopy-mfp': 0,
      kato: 0,
      'final-products': 0,
      complete: 0,
      error: 0,
    }

    for (const dp of deliveryProcGroups) {
      counts['all'] += 1
      if ((dp.Packages?.length ?? 0) === 0) {
        counts['missing'] += 1
      } else {
        if (
          dp.rowMaskStatus.status === 'queued' ||
          dp.rowMaskStatus.status === 'running' ||
          dp.rowMaskStatus.status === 'pending'
        ) {
          counts['row-mask'] += 1
        }
        if (
          dp.matchFilterStatus.status === 'queued' ||
          dp.matchFilterStatus.status === 'running' ||
          dp.matchFilterStatus.status === 'pending'
        ) {
          counts['canopy-mfp'] += 1
        }
        if (
          (dp.katoStatus.status === 'running' ||
            dp.katoStatus.status === 'pending' ||
            dp.katoStatus.status === 'queued') &&
          dp.rowMaskStatus.status === 'complete'
        ) {
          counts['kato'] += 1
        }
        if (
          dp.finalProductsStatuses.some(
            ({ status }) =>
              status === 'running' ||
              status === 'pending' ||
              status === 'queued'
          ) &&
          ((dp.rowMaskStatus.status === 'complete' &&
            dp.katoStatus.status === 'complete') ||
            dp.rowMaskStatus.status === 'not-applicable')
        ) {
          counts['final-products'] += 1
        }

        if (
          dp.finalProductsStatuses.every(({ status }) => status === 'complete')
        ) {
          counts['complete'] += 1
        }
        if (
          dp.rowMaskStatus.status === 'error' ||
          dp.katoStatus.status === 'error' ||
          dp.finalProductsStatuses.some(({ status }) => status === 'error')
        ) {
          counts['error'] += 1
        }
      }
    }

    return counts
  }
)

export type RowMaskTabs =
  | 'all'
  | 'row-mask'
  | 'canopy-mfp'
  | 'kato'
  | 'final-products'
  | 'complete'
  | 'error'
  | 'missing'

const selectAllDeliveryProcGroups = (tab: RowMaskTabs, flightDate: string) =>
  createSelector(selectDeliveryProcGroupsWithStatus, (deliveryProcGroups) => {
    if (tab === 'all' && flightDate === 'all') {
      return sortProcGroups(deliveryProcGroups)
    }

    return sortProcGroups(
      deliveryProcGroups.filter((dp) => {
        if (flightDate !== 'all') {
          if (dp.flightDate !== flightDate) {
            return false
          }
        }
        if (tab !== 'all') {
          if (tab === 'row-mask') {
            if (
              dp.rowMaskStatus.status !== 'queued' &&
              dp.rowMaskStatus.status !== 'running' &&
              dp.rowMaskStatus.status !== 'pending'
            ) {
              return false
            }
          }
          if (tab === 'canopy-mfp') {
            if (
              dp.matchFilterStatus.status !== 'queued' &&
              dp.matchFilterStatus.status !== 'running' &&
              dp.matchFilterStatus.status !== 'pending'
            ) {
              return false
            }
          }
          if (tab === 'kato') {
            return (
              (dp.katoStatus.status === 'running' ||
                dp.katoStatus.status === 'pending' ||
                dp.katoStatus.status === 'queued') &&
              dp.rowMaskStatus.status === 'complete'
            )
          }
          if (tab === 'final-products') {
            return (
              dp.finalProductsStatuses.some(
                ({ status }) =>
                  status === 'running' ||
                  status === 'pending' ||
                  status === 'queued'
              ) &&
              ((dp.rowMaskStatus.status === 'complete' &&
                dp.katoStatus.status === 'complete') ||
                dp.rowMaskStatus.status === 'not-applicable')
            )
          }
          if (tab === 'complete') {
            return dp.finalProductsStatuses.every(
              ({ status }) => status === 'complete'
            )
          }
          if (tab === 'error') {
            return (
              dp.rowMaskStatus.status === 'error' ||
              dp.katoStatus.status === 'error' ||
              dp.finalProductsStatuses.some(({ status }) => status === 'error')
            )
          }
          if (tab === 'missing') {
            return dp.Packages?.length === 0
          }

          return true
        }

        return true
      })
    )
  })

const selectRowMaskDeliveryProcGroups = createSelector(
  selectDeliveryProcGroupsWithStatus,
  (deliveryProcGroups) => {
    return sortProcGroups(
      deliveryProcGroups.filter(
        (dp) =>
          dp.rowMaskStatus.status === 'pending' ||
          dp.rowMaskStatus.status === 'running'
      )
    )
  }
)

const selectKatoDeliveryProcGroups = createSelector(
  selectDeliveryProcGroupsWithStatus,
  (deliveryProcGroups) => {
    return sortProcGroups(
      deliveryProcGroups.filter(
        (dp) =>
          dp.katoStatus.status === 'pending' ||
          dp.katoStatus.status === 'running'
      )
    )
  }
)

const selectFinalProductsDeliveryProcGroups = createSelector(
  selectDeliveryProcGroupsWithStatus,
  (deliveryProcGroups) => {
    return sortProcGroups(
      deliveryProcGroups.filter(
        (dp) =>
          dp.katoStatus.status === 'pending' ||
          dp.katoStatus.status === 'running'
      )
    )
  }
)

const selectCompleteDeliveryProcGroups = createSelector(
  selectDeliveryProcGroupsWithStatus,
  (deliveryProcGroups) => {
    return sortProcGroups(
      deliveryProcGroups.filter((dp) =>
        dp.finalProductsStatuses.every(({ status }) => status === 'complete')
      )
    )
  }
)

const selectErroredDeliveryProcGroups = createSelector(
  selectDeliveryProcGroupsWithStatus,
  (deliveryProcGroups) => {
    return sortProcGroups(
      deliveryProcGroups.filter(
        (dp) =>
          dp.rowMaskStatus.status === 'error' ||
          dp.katoStatus.status === 'error' ||
          dp.finalProductsStatuses.some(({ status }) => status === 'error')
      )
    )
  }
)

export {
  selectGetDeliveryProcGroup,
  refreshGetDeliveryProcGroup,
  selectDeliveryProcGroup,
  selectGetDeliveryProcGroups,
  refreshGetDeliveryProcGroups,
  selectAllDeliveryProcGroups,
  selectRowMaskDeliveryProcGroups,
  selectKatoDeliveryProcGroups,
  selectFinalProductsDeliveryProcGroups,
  selectCompleteDeliveryProcGroups,
  selectErroredDeliveryProcGroups,
}
