import * as React from 'react'

import { Button, ListItem, Typography } from '@mui/material'

import { ParcelFilterInfo } from '../../../../../../postgis/types'
import { ExpandButton } from '../../../../../../UI/Expand/ExpandButton'
import i18n, { keys } from '../../../../../../i18n'
import DatePickerYMD from '../../../../../../admin/mapdata/utils/DatePickerYMD'
import { NameAndValue } from '../../../../../../admin/mapdata/types'
import {
  parcelFilterStatsRootstock,
  parcelFilterStatsTrellis,
  parcelFilterStatsVarietals,
} from '../../../../../../data/selectOrgMapData'
import MetaDataFilter from './advanced-filters/MetaDataFilter'
import { useRedux } from '../../../../../../hooks/useRedux'
import { MapEditorActionTypes } from '../../../../../../map-editor/redux/types'
import { MapEditorToolType } from '../../../../../../redux/map-editor-pages/redux'
import { selectDrawerOptions } from '../../../../../../map-editor/redux/selectors/selectDrawerOptions'
import ProductFilter from './advanced-filters/ProductFilter'

interface Props {
  id: string
  page: MapEditorToolType
  parcelFilterInfo: ParcelFilterInfo
  disabled?: boolean
}

const AdvancedBlockFilter = ({
  id,
  page,
  parcelFilterInfo,
  disabled,
}: Props) => {
  const [state, dispatch] = useRedux()
  const drawerOptions = selectDrawerOptions(state, page)
  const { advancedFilterOpen, minDate, maxDate } = parcelFilterInfo
  const toggleFilters = () => {
    dispatch({
      type: MapEditorActionTypes.SET_DRAWER_OPTIONS,
      page: page,
      payload: {
        drawerOptions: {
          ...drawerOptions,
          [id]: {
            ...drawerOptions[id],
            config: {
              ...drawerOptions[id].config,
              parcelFilter: {
                ...drawerOptions[id].config?.parcelFilter,
                advancedFilterOpen: !advancedFilterOpen,
              },
            },
          },
        },
      },
    })
  }
  const handleDateFilters = ({ name, value }: NameAndValue) => {
    dispatch({
      type: MapEditorActionTypes.SET_DRAWER_OPTIONS,
      page: page,
      payload: {
        drawerOptions: {
          ...drawerOptions,
          [id]: {
            ...drawerOptions[id],
            config: {
              ...drawerOptions[id].config,
              parcelFilter: {
                ...drawerOptions[id].config?.parcelFilter,
                [name]: value,
              },
            },
          },
        },
      },
    })
  }
  const handleReset = () => {
    dispatch({
      type: MapEditorActionTypes.SET_DRAWER_OPTIONS,
      page: page,
      payload: {
        drawerOptions: {
          ...drawerOptions,
          [id]: {
            ...drawerOptions[id],
            config: {
              ...drawerOptions[id].config,
              parcelFilter: {
                ...drawerOptions[id].config?.parcelFilter,
                minDate: undefined,
                maxDate: undefined,
                products: [],
                metaFilters: {},
              },
            },
          },
        },
      },
    })
  }

  const handleProductFilterChange = (productIds: string[]) => {
    dispatch({
      type: MapEditorActionTypes.SET_DRAWER_OPTIONS,
      page: page,
      payload: {
        drawerOptions: {
          ...drawerOptions,
          [id]: {
            ...drawerOptions[id],
            config: {
              ...drawerOptions[id].config,
              parcelFilter: {
                ...drawerOptions[id].config?.parcelFilter,
                products: productIds,
              },
            },
          },
        },
      },
    })
  }

  const handleResetProductFilter = () => {
    dispatch({
      type: MapEditorActionTypes.SET_DRAWER_OPTIONS,
      page: page,
      payload: {
        drawerOptions: {
          ...drawerOptions,
          [id]: {
            ...drawerOptions[id],
            config: {
              ...drawerOptions[id].config,
              parcelFilter: {
                ...drawerOptions[id].config?.parcelFilter,
                products: [],
              },
            },
          },
        },
      },
    })
  }

  const handleResetMetaData = (filterProperty: string) => {
    dispatch({
      type: MapEditorActionTypes.SET_DRAWER_OPTIONS,
      page: page,
      payload: {
        drawerOptions: {
          ...drawerOptions,
          [id]: {
            ...drawerOptions[id],
            config: {
              ...drawerOptions[id].config,
              parcelFilter: {
                ...drawerOptions[id].config?.parcelFilter,
                metaFilters: {
                  ...drawerOptions[id].config?.parcelFilter?.metaFilters,
                  [filterProperty]: [],
                },
              },
            },
          },
        },
      },
    })
  }

  const handleMetaDataFilterChange = (
    filterProperty: string,
    values: string[]
  ) => {
    dispatch({
      type: MapEditorActionTypes.SET_DRAWER_OPTIONS,
      page: page,
      payload: {
        drawerOptions: {
          ...drawerOptions,
          [id]: {
            ...drawerOptions[id],
            config: {
              ...drawerOptions[id].config,
              parcelFilter: {
                ...drawerOptions[id].config?.parcelFilter,
                metaFilters: {
                  ...drawerOptions[id].config?.parcelFilter?.metaFilters,
                  [filterProperty]: values,
                },
              },
            },
          },
        },
      },
    })
  }

  const trellisFilterStats = parcelFilterStatsTrellis(state)
  const rootstockFilterStats = parcelFilterStatsRootstock(state)
  const varietalFilterStats = parcelFilterStatsVarietals(state)
  return (
    <div className="AdvancedFilters">
      <ListItem
        button
        disableGutters
        className="AdvancedFilters__toggle"
        dense
        onClick={toggleFilters}
        disableRipple
        selected={advancedFilterOpen}
        disabled={disabled}
      >
        <ExpandButton
          expanded={advancedFilterOpen}
          style={{ padding: 0 }}
          onClick={toggleFilters}
        />
        <Typography variant="caption" style={{ fontSize: 14 }}>
          {i18n.t(keys.forms.parcelFilter.advancedFilters)}
        </Typography>
      </ListItem>
      {advancedFilterOpen && (
        <div className="AdvancedFilters__body">
          <ListItem>
            <DatePickerYMD
              sx={{ width: '100%' }}
              label={i18n.t(keys.forms.parcelFilter.fromDate)}
              name="minDate"
              value={minDate}
              disabled={disabled}
              onChange={(nameAndValue) =>
                handleDateFilters({
                  ...nameAndValue,
                  value: nameAndValue.value,
                })
              }
            />
          </ListItem>
          <ListItem>
            <DatePickerYMD
              sx={{ width: '100%' }}
              label={i18n.t(keys.forms.parcelFilter.toDate)}
              name="maxDate"
              value={maxDate}
              disabled={disabled}
              onChange={(nameAndValue) =>
                handleDateFilters({
                  ...nameAndValue,
                  value: nameAndValue.value,
                })
              }
            />
          </ListItem>
          <ProductFilter
            parcelFilterInfo={parcelFilterInfo}
            onSelect={handleProductFilterChange}
            onReset={handleResetProductFilter}
            disabled={disabled}
          />
          <MetaDataFilter
            filterProperty="varietals"
            availableValues={varietalFilterStats}
            inputLabel={i18n.t(keys.forms.parcelFilter.varietal)}
            noAvailableValuesLabel={i18n.t(keys.forms.parcelFilter.noVarietals)}
            parcelFilterInfo={parcelFilterInfo}
            onSelect={handleMetaDataFilterChange}
            onReset={handleResetMetaData}
            disabled={disabled}
          />
          <MetaDataFilter
            filterProperty="rootstock"
            availableValues={rootstockFilterStats}
            inputLabel={i18n.t(keys.forms.parcelFilter.rootstock)}
            noAvailableValuesLabel={i18n.t(
              keys.forms.parcelFilter.noRootstocks
            )}
            parcelFilterInfo={parcelFilterInfo}
            onSelect={handleMetaDataFilterChange}
            onReset={handleResetMetaData}
            disabled={disabled}
          />
          <MetaDataFilter
            filterProperty="trellises"
            availableValues={trellisFilterStats}
            inputLabel={i18n.t(keys.forms.parcelFilter.trellisType)}
            noAvailableValuesLabel={i18n.t(keys.forms.parcelFilter.noTrellises)}
            parcelFilterInfo={parcelFilterInfo}
            onSelect={handleMetaDataFilterChange}
            onReset={handleResetMetaData}
            disabled={disabled}
          />
          <ListItem>
            <Button fullWidth onClick={handleReset} disabled={disabled}>
              {i18n.t(keys.generic.reset)}
            </Button>
          </ListItem>
        </div>
      )}
      <ListItem disabled divider />
    </div>
  )
}

export default AdvancedBlockFilter
