import * as React from 'react'
import { Sample, SamplePlan, SamplePlanBlock } from '../../../graphql/types'
import ReportSection from '../../../reports/page/ReportSection'
import { Box, Stack, Typography } from '@mui/material'
import i18n, { keys } from '../../../i18n'
import { SamplePlanMapImage } from './SamplePlanMapImage'

const SAMPLE_PLAN_PARCEL_MAP = 'SAMPLE-PLAN-PARCEL-MAP'

interface Props {
  samplePlanBlock: SamplePlanBlock
  bounds: number[][]
  layersAndSources: {
    sources: Record<string, any>
    layers: mapboxgl.Layer[]
  }
  orgName?: string
  samplePlan?: SamplePlan | null
  onStyleLoaded?: ((map: mapboxgl.Map) => Promise<void | void[]>) | undefined
  pageSamples: Sample[]
  blockPageNumber: number
  blockPageTotal: number
  samplesPerPage: number
}

export const SummaryPlanBlockReport = ({
  orgName,
  samplePlan,
  samplePlanBlock,
  bounds,
  layersAndSources,
  onStyleLoaded,
  pageSamples,
  blockPageNumber,
  blockPageTotal,
  samplesPerPage,
}: Props) => {
  return (
    <ReportSection key={samplePlanBlock.id}>
      <section id="ReportSummary">
        <Stack className="summary-container" direction="row">
          <Stack direction="column" spacing={2} width="312px" height="100%">
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography className="title">
                {samplePlanBlock.Parcel.name}
              </Typography>
              <Typography className="block-info-title">
                {`(${blockPageNumber + 1} / ${blockPageTotal})`}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              spacing={4}
              justifyContent="space-between"
              height="100%"
            >
              <Stack direction="column">
                <Stack>
                  <Typography className="block-info-title">
                    {i18n.t(keys.generic.name)}
                  </Typography>
                  <hr className="name-line" />
                </Stack>
              </Stack>
              <Stack flexWrap="nowrap" direction="row" height="100%">
                <Stack direction="column" flex={4} height="100%" spacing={1.75}>
                  <Typography className="block-info-title">ID</Typography>
                  {pageSamples?.map((sample, index) => {
                    return (
                      <Stack direction="row">
                        <Typography className="sample-info-index">
                          {`${index + samplesPerPage * blockPageNumber + 1}`}
                        </Typography>
                        {sample.rowID !== null && (
                          <Typography className="sample-info">
                            {`, R${sample.rowID}P${sample.plantIndex}`}
                          </Typography>
                        )}
                      </Stack>
                    )
                  })}
                </Stack>
                <Box className="observation-line" />
                <Stack direction="column" flex={12}>
                  <Stack>
                    <Typography className="block-info-title">
                      {i18n.t(keys.generic.observations)}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="column" flexGrow={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ padding: '8px' }}
            >
              <Stack direction="column">
                <Typography className="block-info-title">
                  {i18n.t(keys.user.organization)}
                </Typography>
                <Typography className="info">{orgName}</Typography>
              </Stack>
              <Stack direction="column">
                <Typography className="block-info-title">
                  {i18n.t(keys.samplePlanBlockLocationColumnLabel)}
                </Typography>
                <Typography className="info">
                  {samplePlanBlock?.Parcel.OrganizationGroup?.name}
                </Typography>
              </Stack>
              <Stack direction="column">
                <Typography className="block-info-title">
                  {i18n.t(keys.samplePlanDateColumnLabel)}
                </Typography>
                <Typography className="info">
                  {i18n.toDateShort(samplePlan?.createdAt ?? '')}
                </Typography>
              </Stack>
            </Stack>
            <SamplePlanMapImage
              key={`${SAMPLE_PLAN_PARCEL_MAP}-${samplePlanBlock.id}`}
              style="mapbox://styles/vineview/cjwhzylct0lhe1dqxys0x3i2v" // eslint-disable-line react/style-prop-object
              bounds={bounds}
              layers={layersAndSources}
              onStyleLoaded={onStyleLoaded}
              constainerStyle={{ height: '100%' }}
            />
          </Stack>
        </Stack>
      </section>
    </ReportSection>
  )
}
