import applyNamespace from 'redux-ts-helpers/lib/applyNamespace'
import createAction from 'redux-ts-helpers/lib/createAction'
import createReducer from 'redux-ts-helpers/lib/createReducer'
import { LanguageSelection } from '../../app/types'
import i18n from '../../i18n'
import { UnitSystem } from '../../util/units/unitSystem'
import { PreferencesState } from './types'

const NAMESPACE = 'preferences'
export const LOCAL_STORAGE_VV_PREFERENCES = 'vv.redux.preferences'

// Actions
export const constants = applyNamespace(NAMESPACE, {
  updatePreferences: 0,
})

export const updatePreferences = createAction<Partial<PreferencesState>>(
  constants.updatePreferences
)

const preferredLanguage = !!navigator.language.match(/en|fr/)
  ? (navigator.language as LanguageSelection)
  : 'en'

const preferredUnitSystem = ['en-US', 'my'].includes(navigator.language)
  ? UnitSystem.Imperial
  : UnitSystem.Metric

export let initialPreferencesState: PreferencesState = {
  preferredLanguage,
  preferredStatistics: 'values',
  preferredUnitSystem,
  showElevationLayer: false,
  showFieldBoundaries: true,
  showFieldLabels: true,
  showCursorLocation: false,
  showNotes: false,
  showScale: true,
  showSoilLayer: false,
  showSpeedTracker: false,
  showSidebarLeft: true,
  showSidebarRight: false,
  sidebarTabLeft: 'fields',
  sidebarTabRight: 'layers',
  isSpeedTrackerSoundEnabled: false,
  showGPS: false,
  gpsOffset: {
    lng: 0,
    lat: 0,
  },
  statsFormat: 'value',
  theme: 'dark',

  statsWindowMinimized: false,
  statsWindowDocked: true,
  statsWindowTab: 'current',
  statsWindowPosition: {
    x: 0,
    y: 0,
  },
  lastUpdated: 0,
}

if (typeof localStorage !== 'undefined') {
  try {
    initialPreferencesState = {
      ...initialPreferencesState,
      ...JSON.parse(localStorage.getItem(LOCAL_STORAGE_VV_PREFERENCES) ?? '{}'),
    }
  } catch (e) {
    // empty
  }
}

i18n.changeLanguage(initialPreferencesState.preferredLanguage)

export const reducer = createReducer(initialPreferencesState, {
  [constants.updatePreferences]: (
    state,
    { payload }: ReturnType<typeof updatePreferences>
  ) => {
    const newState = {
      ...state,
      ...payload,
      lastUpdated: Date.now(),
    }
    i18n.changeLanguage(newState.preferredLanguage)

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(
        LOCAL_STORAGE_VV_PREFERENCES,
        JSON.stringify(newState)
      )
    }

    return newState
  },
})
