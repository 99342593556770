import { createSelector } from 'reselect'
import { RootStore } from '../../redux/types'
import californiaMapboxSoil from './california-mapbox-soil'
import washingtonMapboxSoil from './washington-mapbox-soil'

const getSoilLayersEnabled = (state: RootStore) =>
  state.preferences.showSoilLayer

const DEFAULT_SOIL = { sources: {}, layers: [] }

export const getSoilLayers = createSelector(
  getSoilLayersEnabled,
  (soilLayerEnabled) => {
    if (soilLayerEnabled) {
      return {
        sources: {
          ...californiaMapboxSoil.sources,
          ...washingtonMapboxSoil.sources,
        },
        layers: [
          ...californiaMapboxSoil.layers,
          ...washingtonMapboxSoil.layers,
        ],
      }
    }

    return DEFAULT_SOIL
  }
)
