import {
  IMapActionGenerator,
  Priority,
  IMapAction,
} from '../types/MapActionTypes'
import { MapboxGL } from '../../../MapboxGL'
import { IMapActionGeneratorParams } from '../types/MapActionGeneratorParams'
import { NOTE_MARKERS_POINTS } from '../../../../postgis/selectors/NotesLayerSelector'
import { GeoJSONSource } from 'mapbox-gl'

export class PointNoteClusterActionGenerator implements IMapActionGenerator {
  key = 'point-note-cluster'
  priority = Priority.PointNote

  generateActionsFromQualifyingFeatures({
    dispatch,
    features,
    map,
  }: IMapActionGeneratorParams): IMapAction[] {
    // Find the qualifying features.
    const qualifiers = features.filter(
      (f: MapboxGL.MapboxGeoJSONFeature) =>
        f.layer.id === `${NOTE_MARKERS_POINTS}_clustered_layer` &&
        f.properties?.cluster_id !== undefined &&
        f.geometry.type === 'Point'
    )

    // Return array of functions that can execute the action for each of the qualifying features.
    return qualifiers.map((q) => ({
      key: this.key,
      priority: this.priority,
      execute: () => {
        ;(map.getSource(q.source) as GeoJSONSource).getClusterExpansionZoom(
          q.properties!.cluster_id,
          (err, zoom) => {
            if (!err) {
              if (q.geometry.type === 'Point') {
                map.easeTo({
                  center: q.geometry.coordinates as [number, number],
                  zoom,
                })
              }
            }
          }
        )
      },
    }))
  }
}
