import { createSelector } from 'reselect'
import { selectAvailableProductIds } from '../../../data/selectAvailableProductIds'
import { pureVineZonesLayerID, vigorZonesLayerID } from '../constants'

export const selectRateMapVigorZoneProductId = createSelector(
  selectAvailableProductIds,
  (availableProductIds) => {
    if (availableProductIds.some((id) => id === pureVineZonesLayerID)) {
      return pureVineZonesLayerID
    } else {
      return vigorZonesLayerID
    }
  }
)
