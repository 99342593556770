import { Utils } from 'mapbox-gl-draw-snap-mode'
import { RectangleMode } from '@ookla/mapbox-gl-draw-rectangle'
import { Feature, Polygon } from '@turf/turf'
import booleanIntersects from '@turf/boolean-intersects'
import { difference } from '../util/drawHelpers'

const doubleClickZoom = {
  enable: (ctx: any) => {
    setTimeout(() => {
      // First check we've got a map and some context.
      if (
        !ctx.map ||
        !ctx.map.doubleClickZoom ||
        !ctx._ctx ||
        !ctx._ctx.store ||
        !ctx._ctx.store.getInitialConfigValue
      )
        return
      // Now check initial state wasn't false (we leave it disabled if so)
      if (!ctx._ctx.store.getInitialConfigValue('doubleClickZoom')) return
      ctx.map.doubleClickZoom.enable()
    }, 0)
  },
  disable(ctx: any) {
    setTimeout(() => {
      if (!ctx.map || !ctx.map.doubleClickZoom) return
      // Always disable here, as it's necessary in some cases.
      ctx.map.doubleClickZoom.disable()
    }, 0)
  },
}

const SnapRectangleMode = {
  ...RectangleMode,
}

SnapRectangleMode.onSetup = function (options: any) {
  const rectangle = this.newFeature({
    type: 'Feature',
    properties: {
      isRectangle: true,
    },
    geometry: {
      type: 'Polygon',
      coordinates: [[]],
    },
  })
  this.addFeature(rectangle)
  this.clearSelectedFeatures()
  doubleClickZoom.disable(this)
  this.updateUIClasses({ mouse: 'add' })
  this.setActionableState({
    trash: true,
  })

  const selectedFeatures = this.getSelected()

  const [snapList, vertices] = Utils.createSnapList(
    this.map,
    this._ctx.api,
    rectangle
  )

  const state: any = {
    map: this.map,
    rectangle,
    vertices,
    snapList,
    selectedFeatures,
  }

  state.options = Object.assign(this._ctx.options, {
    overlap: true,
  })

  const moveendCallback = () => {
    const [snapList, vertices] = Utils.createSnapList(
      this.map,
      this._ctx.api,
      rectangle
    )
    state.vertices = vertices
    state.snapList = snapList
  }
  // for removing listener later on close
  state['moveendCallback'] = moveendCallback

  const optionsChangedCallBack = (options: any) => {
    state.options = options
  }

  // for removing listener later on close
  state['optionsChangedCallBack'] = optionsChangedCallBack

  this.map.on('moveend', moveendCallback)
  this.map.on('draw.snap.options_changed', optionsChangedCallBack)

  return state
}

// Whenever a user clicks on the map, Draw will call `onClick`
SnapRectangleMode.onClick = function (state: any, e: any) {
  const { lng, lat } = Utils.snap(state, e)

  // if state.startPoint exist, means its second click
  //change to  simple_select mode
  if (
    state.startPoint &&
    state.startPoint[0] !== e.lngLat.lng &&
    state.startPoint[1] !== e.lngLat.lat
  ) {
    this.updateUIClasses({ mouse: 'pointer' })
    state.endPoint = [lng, lat]
    this.changeMode('simple_select', { featuresId: state.rectangle.id })
  }
  // on first click, save clicked point coords as starting for  rectangle
  const startPoint = [lng, lat]
  state.startPoint = startPoint
}

SnapRectangleMode.onMouseMove = function (state: any, e: any) {
  const { lng, lat } = Utils.snap(state, e)

  state.snappedLng = lng
  state.snappedLat = lat

  if (
    state.lastVertex &&
    state.lastVertex[0] === lng &&
    state.lastVertex[1] === lat
  ) {
    this.updateUIClasses({ mouse: 'pointer' })

    // cursor options:
    // ADD: "add"
    // DRAG: "drag"
    // MOVE: "move"
    // NONE: "none"
    // POINTER: "pointer"
  } else {
    this.updateUIClasses({ mouse: 'add' })
  }

  // if startPoint, update the feature coordinates, using the bounding box concept
  // we are simply using the startingPoint coordinates and the current Mouse Position
  // coordinates to calculate the bounding box on the fly, which will be our rectangle
  if (state.startPoint) {
    state.rectangle.updateCoordinate(
      '0.0',
      state.startPoint[0],
      state.startPoint[1]
    ) //minX, minY - the starting point
    state.rectangle.updateCoordinate('0.1', lng, state.startPoint[1]) // maxX, minY
    state.rectangle.updateCoordinate('0.2', lng, lat) // maxX, maxY
    state.rectangle.updateCoordinate('0.3', state.startPoint[0], lat) // minX,maxY
    state.rectangle.updateCoordinate(
      '0.4',
      state.startPoint[0],
      state.startPoint[1]
    ) //minX,minY - ending point (equals to starting point)
  }
}

SnapRectangleMode.onStop = function (state: any) {
  // remove moveemd callback
  this.map.off('moveend', state.moveendCallback)
  this.map.off('draw.snap.options_changed', state.optionsChangedCallBAck)

  var userRectangle = state.rectangle
  if (state.options.overlap) {
    RectangleMode.onStop.call(this, state)
    return
  }
  // if overlap is false, mutate rectangle so it doesnt overlap with existing ones
  // get all editable features to check for intersections
  var features = this._ctx.store.getAll()

  try {
    var edited = userRectangle
    features.forEach((feature: Feature<Polygon>) => {
      if (userRectangle.id === feature.id) return
      if (!booleanIntersects(feature, edited)) return
      edited = difference(edited, feature)
    })
    state.rectangle.coordinates =
      edited.coordinates || edited.geometry.coordinates
  } catch (err) {
    // cancel this rectangle if a difference cannot be calculated
    RectangleMode.onStop.call(this, state)
    this.deleteFeature([state.rectangle.id], { silent: true })
    return
  }

  // monkeypatch so RectangleMode.onStop doesn't error
  var rc = state.rectangle.removeCoordinate
  state.rectangle.removeCoordinate = () => {}
  // This relies on the the state of SnapRectangleMode being similar to DrawRectangle
  SnapRectangleMode.onStop.call(this, state)
  state.rectangle.removeCoordinate = rc.bind(state.rectangle)
}

export default SnapRectangleMode
