import {
  List,
  ListItem,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import AsyncSelectorStatusOverlay from '../../../../../AsyncSelector/AsyncSelectorStatusOverlay'
import { selectFilteredGroupsFromParcelIds } from '../../../../../data/selectFilteredGroups'
import {
  selectAvailableGroupIdsFromFlightDateParameter,
  selectOrgMapData,
  selectParcelsWithFlightDate,
} from '../../../../../data/selectOrgMapData'
import { filteredParcelFetcher } from '../../../../../data/selectParcelFilter'
import { useRedux } from '../../../../../hooks/useRedux'
import * as React from 'react'
import i18n, { keys } from '../../../../../i18n'
import { DrawerTabOption } from '../../../../../map-editor/types'
import { ParcelFilterInfo } from '../../../../../postgis/types'
import { MapEditorToolType } from '../../../../../redux/map-editor-pages/redux'
import BlockFilter from './filters/BlockFilter'
import { selectOrganizationId } from '../../../../../data/selectOrganizationId'
import useAsync from '../../../../../hooks/useAsync'
import { getFiltersInfo } from '../../../../../data/selectParcelFilterWhere'
import AdvancedBlockFilter from './filters/AdvancedBlockFilter'
import { getFilteredParcels } from '../../../../../data/selectFilteredParcels'
import { selectDrawerOption } from '../../../../../map-editor/redux/selectors/selectDrawerOptions'
import BlockGroupListItem from './BlockGroupListItem'
import { useRateMapContext } from '../../RateMapContext'
import { setIntersect } from '../../../../../util/setIntersect'

export interface BlockSelectionConfig {
  parcelFilter: ParcelFilterInfo
  expandedGroupIds: string[] | undefined
}

interface Props extends DrawerTabOption<BlockSelectionConfig> {
  page: MapEditorToolType
  selectedFlightDate: string
  selectedParcelIds: Set<string>
  onSelect: (parcelIds: string[], flightDate?: string) => void
  disableUnavailableGroups?: boolean
}

const MainContainer = styled(Stack)(() => ({
  width: '100%',
  height: '100%',
}))

const BlockSelectorContentContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: theme.spacing(1),
  overflow: 'auto',
}))
const BlockSelector = ({
  id,
  page,
  selectedFlightDate,
  selectedParcelIds,
  onSelect,
  disableUnavailableGroups = false,
}: Props) => {
  const [state] = useRedux()
  const organizationId = selectOrganizationId(state)
  const OrgMapDataRequest = selectOrgMapData(state)
  const parcels = selectParcelsWithFlightDate(state, selectedFlightDate)
  const option = selectDrawerOption(state, page, id)
  const { isCustomZoneMode } = useRateMapContext()
  const parcelFiltersWhere = React.useMemo(
    () =>
      getFiltersInfo(
        option?.config?.parcelFilter?.metaFilters ??
          ({} as ParcelFilterInfo['metaFilters']),
        option?.config?.parcelFilter?.maxDate,
        option?.config?.parcelFilter?.minDate,
        option?.config?.parcelFilter?.products
      ),
    [option?.config?.parcelFilter]
  )
  const [parcelFilterRequest] = useAsync(async () => {
    return filteredParcelFetcher({
      organizationId,
      parcelFiltersWhere,
    })
  }, [option?.config?.parcelFilter, parcelFiltersWhere])

  const [filteredParcelsRequest] = useAsync(async () => {
    return getFilteredParcels(
      parcels,
      option?.config?.parcelFilter ?? ({} as ParcelFilterInfo),
      {
        ...parcelFilterRequest,
        data: parcelFilterRequest.result,
      }
    )
  }, [option?.config?.parcelFilter, parcelFilterRequest?.result, parcels])

  const filteredParcels = React.useMemo(() => {
    return filteredParcelsRequest.result ?? []
  }, [filteredParcelsRequest?.result])

  const groups = selectFilteredGroupsFromParcelIds(state, filteredParcels)

  const availableGroupIds = selectAvailableGroupIdsFromFlightDateParameter(
    state,
    selectedFlightDate
  )

  const availableGroupData = React.useMemo(() => {
    return groups.filter(({ id }) => availableGroupIds.includes(id))
  }, [groups, availableGroupIds])

  const unavailableGroupData = React.useMemo(() => {
    return groups.filter(({ id }) => !availableGroupIds.includes(id))
  }, [groups, availableGroupIds])

  React.useEffect(() => {
    // when availability changes, only keep selected parcels that are still available
    const availableGroupParcelIds = new Set(
      availableGroupData.flatMap((g) => g.parcels).map((p) => p.id)
    )

    const newSelectedParcelIds = setIntersect(
      selectedParcelIds,
      availableGroupParcelIds
    )

    if (
      Array.from(selectedParcelIds).every((id) => newSelectedParcelIds.has(id))
    ) {
      return
    }

    onSelect(Array.from(newSelectedParcelIds), selectedFlightDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableGroupData])

  const availableGroups = React.useMemo(
    () =>
      availableGroupData.map((group) => (
        <BlockGroupListItem
          key={group.id}
          id={id}
          page={page}
          group={group}
          selectedFlightDate={selectedFlightDate}
          selectedParcelIds={selectedParcelIds}
          filteredParcels={filteredParcels}
          onSelect={onSelect}
          disabled={isCustomZoneMode}
        />
      )),
    [
      availableGroupData,
      id,
      page,
      selectedFlightDate,
      selectedParcelIds,
      filteredParcels,
      onSelect,
      isCustomZoneMode,
    ]
  )

  const unavailableGroups = React.useMemo(
    () =>
      unavailableGroupData.map((group) => (
        <BlockGroupListItem
          key={group.id}
          id={id}
          page={page}
          group={group}
          selectedFlightDate={selectedFlightDate}
          selectedParcelIds={selectedParcelIds}
          filteredParcels={filteredParcels}
          onSelect={onSelect}
          disabled={disableUnavailableGroups}
        />
      )),
    [
      unavailableGroupData,
      id,
      page,
      selectedFlightDate,
      selectedParcelIds,
      filteredParcels,
      onSelect,
      disableUnavailableGroups,
    ]
  )

  return (
    <MainContainer
      direction="column"
      style={{ opacity: isCustomZoneMode ? 0.3 : 1 }}
    >
      <Tabs
        value={'fields'}
        textColor="primary"
        indicatorColor="primary"
        variant="fullWidth"
        centered
      >
        <Tab
          style={{ minWidth: 0 }}
          value="fields"
          label={i18n.t(keys.fieldsTab)}
          disabled={isCustomZoneMode}
        />
      </Tabs>
      <BlockSelectorContentContainer>
        <AsyncSelectorStatusOverlay
          requests={OrgMapDataRequest}
          hideChildrenWhenLoading
          style={{
            width: '100%',
          }}
        >
          <List dense>
            <BlockFilter
              id={id}
              parcelFilterInfo={option?.config?.parcelFilter}
              page={page}
              disabled={isCustomZoneMode}
            />
            <AdvancedBlockFilter
              id={id}
              page={page}
              parcelFilterInfo={
                option?.config?.parcelFilter ?? ({} as ParcelFilterInfo)
              }
              disabled={isCustomZoneMode}
            />
            <ListItem disabled>
              <Typography variant="subtitle1">
                {i18n.t(keys.parcelDrawer.selectParcels)}
              </Typography>
            </ListItem>
            <AsyncSelectorStatusOverlay
              hideChildrenWhenLoading
              requests={[OrgMapDataRequest, parcelFilterRequest]}
            >
              {availableGroups}
              {unavailableGroups.length > 0 && selectedFlightDate && (
                <ListItem disabled>
                  <Typography variant="subtitle1">
                    {i18n.t(keys.parcelDrawer.unavailableForSelectedDate)}
                  </Typography>
                </ListItem>
              )}
              {unavailableGroups}
              {groups.length === 0 && (
                <ListItem disabled>
                  <Typography variant="caption">
                    {i18n.t(keys.parcelDrawer.noResults)}
                  </Typography>
                </ListItem>
              )}
            </AsyncSelectorStatusOverlay>
          </List>
        </AsyncSelectorStatusOverlay>
      </BlockSelectorContentContainer>
    </MainContainer>
  )
}

export default BlockSelector
