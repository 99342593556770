import { useRedux } from '../../hooks/useRedux'
import { historicChartPlugin } from '../ChartJs/plugins/historicChartPlugin'
import {
  updateChartMinMax,
  updateSpanByDataPoints,
  shiftByDataPoint,
  getIndexOfSelectedFlightDate,
  updateSelectedFlightIndex,
  updateSpan,
} from '../ChartJs/util/pluginHelpers'
import { Theme } from '@mui/material'
import * as React from 'react'
import { Chart } from 'react-chartjs-2'
import { selectHistoricalChartData } from '../selectors/selectHistoricalChartData'
import { BarWithErrorBarsController } from 'chartjs-chart-error-bars'
import { getSelectedFlightDate } from '../../data/userSelectionRedux'
import { useThrottle } from '../../hooks/useThrottle'
import { ChartOptions } from 'chart.js'

export const StatsHistory = ({
  isMobile,
  theme,
}: {
  isMobile: boolean
  theme: Theme
}) => {
  const [state] = useRedux()
  const prepareChartData = selectHistoricalChartData(state)
  const selectedFlightDate = getSelectedFlightDate(state)
  const historicChart = React.useMemo(
    () => prepareChartData?.(theme, isMobile),
    [prepareChartData, theme, isMobile]
  )

  const chartRef = React.useRef<any>()
  const lastDrag = React.useRef(0)
  const mouseDown = React.useRef(false)

  const [span] = React.useState(8)
  const [isGrabbed, setIsGrabbed] = React.useState(false)

  const scroll = (e: WheelEvent) => {
    const delta = e.deltaX + e.deltaY
    if (Math.abs(delta) <= 0) {
      return
    }
    const chart = chartRef.current
    if (!chart) {
      return
    }

    const { min, max } = shiftByDataPoint(
      chart,
      delta > 0 ? 'right' : 'left',
      span
    )
    updateChartMinMax(chart, min, max)
    chart.update()
  }
  const throttleWheel = useThrottle(scroll, 175)

  const drag = (position: number) => {
    if (!mouseDown.current) {
      return
    }
    const touchDelta = lastDrag.current - position
    if (Math.abs(touchDelta) <= 17) {
      return
    }
    const chart = chartRef.current
    if (!chart) {
      return
    }

    const { min, max } = shiftByDataPoint(
      chart,
      touchDelta > 0 ? 'right' : 'left',
      span
    )
    updateChartMinMax(chart, min, max)
    chart.update()
    lastDrag.current = position
  }
  const throttleDrag = useThrottle(drag, 40)

  const scrollWithKey = (e: React.KeyboardEvent<HTMLCanvasElement>) => {
    const scrollDirection =
      e.key === 'ArrowRight' ? 'right' : e.key === 'ArrowLeft' ? 'left' : null
    if (!scrollDirection) {
      return
    }
    const chart = chartRef.current
    if (!chart) {
      return
    }

    const { min, max } = shiftByDataPoint(chart, scrollDirection, span)
    updateChartMinMax(chart, min, max)
    chart.update()
  }
  const throttleArrowkey = useThrottle(scrollWithKey, 25)

  const onMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    e.preventDefault()
    lastDrag.current = e.screenX
    mouseDown.current = true
    setIsGrabbed(true)
  }

  const onMouseStop = (e: React.MouseEvent<HTMLCanvasElement>) => {
    lastDrag.current = 0
    mouseDown.current = false
    setIsGrabbed(false)
  }

  const onTouchStart = (e: React.TouchEvent<HTMLCanvasElement>) => {
    lastDrag.current = e.touches[0].screenX
    mouseDown.current = true
  }

  const onTouchEnd = (e: React.TouchEvent<HTMLCanvasElement>) => {
    lastDrag.current = 0
    mouseDown.current = false
  }

  const onMouseWheel = React.useCallback(
    (e: WheelEvent) => {
      e.stopPropagation()
      e.preventDefault()
      throttleWheel(e)
    },
    [throttleWheel]
  )

  React.useEffect(() => {
    const chartCanvas = chartRef?.current?.canvas
    if (chartCanvas) {
      chartCanvas.addEventListener('wheel', onMouseWheel)
    }

    return () => {
      if (chartCanvas) {
        chartCanvas.removeEventListener('wheel', onMouseWheel)
      }
    }
  }, [chartRef, onMouseWheel])

  React.useEffect(() => {
    const chart = chartRef.current
    if (!chart) {
      return
    }

    const flightDateIndex = getIndexOfSelectedFlightDate(
      chart,
      selectedFlightDate ?? ''
    )
    const { min, max } = updateSpanByDataPoints(chart, span, flightDateIndex)
    updateSelectedFlightIndex(chart, flightDateIndex)
    updateSpan(chart, span)
    updateChartMinMax(chart, min, max)
    chart.update()
  }, [span, historicChart, selectedFlightDate])

  if (historicChart == null) {
    return null
  }

  return (
    <Chart
      ref={chartRef}
      type={BarWithErrorBarsController.id}
      options={historicChart.chartOptions as ChartOptions}
      data={historicChart.chartData}
      plugins={[historicChartPlugin]}
      onMouseDown={onMouseDown}
      onMouseMove={(e: any) => throttleDrag(e.screenX)}
      onMouseUp={onMouseStop}
      onMouseLeave={onMouseStop}
      onTouchStart={onTouchStart}
      onTouchMove={(e: any) => throttleDrag(e.touches[0].screenX)}
      onTouchEnd={onTouchEnd}
      onKeyDown={(e: any) => throttleArrowkey(e)}
      tabIndex={0}
      style={{ padding: '0px 5px', cursor: isGrabbed ? 'grabbing' : 'grab' }}
    />
  )
}
