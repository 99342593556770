import * as React from 'react'
import { selectMapLayerDefsById } from '../../data/selectListMapSourceDefs'
import { selectSelectedDeliveryParcelsArea } from '../../data/selectSelectedDeliveryParcelsArea'
import { selectSelectedLegendProductId } from '../../data/selectSelectedLegendProductId'
import { useRedux } from '../../hooks/useRedux'
import i18n, { keys } from '../../i18n'
import abbreviateNumber from '../../util/abbreviateNumber'
import { formatNum } from '../../util/formatNumber'
import { selectConversionsByProductId } from '../selectors/selectConversionsByProductId'
import { selectProductStats } from '../selectors/selectProductStats'
import { selectTrendStats } from '../selectors/selectTrendStats'
import { StatsTable } from './StatsTable'

interface Props {
  setValueStatusBarVisible: React.Dispatch<React.SetStateAction<boolean>>
  isMobile: boolean
}

function getTotalPlants(unitType: string, count: number) {
  if (unitType === 'count') {
    return abbreviateNumber(count, 1)
  }

  return i18n.t(keys.generic.na)
}

function getUsagePlantsCount(
  count: number,
  countMissing: number,
  unitType: string
) {
  if (unitType === 'count') {
    return `${formatNum(
      Math.floor((count / (count + countMissing)) * 100),
      false,
      0,
      1
    )}%`
  }

  return i18n.t(keys.generic.na)
}

function ValueStatsBar({ setValueStatusBarVisible, isMobile }: Props) {
  const [state] = useRedux()
  const productId = selectSelectedLegendProductId(state)
  const productStats = selectProductStats(state)[productId]
  const convert = selectConversionsByProductId(state)[productId]
  const productDef = selectMapLayerDefsById(state)[productId]
  const selectedParcelsArea = selectSelectedDeliveryParcelsArea(state)
  const trendStats = selectTrendStats(state)

  React.useEffect(() => {
    setValueStatusBarVisible(
      productStats &&
        productDef &&
        productDef.dataProperty &&
        productDef.dataProperty.type === 'value'
    )
  }, [productId, productStats, productDef])

  if (
    !productStats ||
    !productDef ||
    !productDef.dataProperty ||
    productDef.dataProperty.type !== 'value'
  ) {
    return null
  }

  const hideUsageAndVariance = productDef.name === 'PureVine™ Change Detection'

  const { combined, weightType, valueType } = productStats

  const totalSize = combined.data.stats.size + combined.coverage.size

  const totalPlantCount = getTotalPlants(weightType!, totalSize)
  const usagePlantsCount = getUsagePlantsCount(
    totalSize,
    combined.noData.size,
    weightType!
  )
  const totalArea = `${formatNum(selectedParcelsArea.value, false, 1, 1)} ${
    selectedParcelsArea.symbol
  }`

  const coefficientOfVariation =
    productDef.dataProperty.measurementType === 'absolute'
      ? `${formatNum((combined.data.stats.cv ?? 0) * 100, false, 0, 1)}%`
      : 'N/A'

  let mean = combined.data.stats.mean!
  let stDev = combined.data.stats.stdev!

  if (convert && (valueType === 'area' || valueType === 'length')) {
    mean = convert(mean)
    stDev = convert(stDev)
  }

  return (
    <StatsTable
      isMobile={isMobile}
      hideUsageAndVariance={hideUsageAndVariance}
      plantCount={{
        value: totalPlantCount,
        label: i18n.t(keys.units.plantCountLabel),
        type: 'small',
      }}
      area={{
        value: totalArea,
        label: i18n.t(keys.units.areaLabel),
        type: 'small',
      }}
      mean={{
        value: formatNum(mean, false, 2),
        label: i18n.t(keys.stats.mean),
        trend: trendStats?.meanTrend,
        type: 'large',
      }}
      stdev={{
        value: formatNum(stDev, false, 2),
        label: i18n.t(keys.stats.stDev),
        trend: trendStats?.stdevTrend,
        invert: true,
        type: 'large',
      }}
      usage={{
        value: usagePlantsCount,
        label: i18n.t(keys.stats.usage),
        tooltip: i18n.t(keys.stats.usageTooltip),
        type: 'small',
      }}
      variance={{
        value: coefficientOfVariation,
        label: i18n.t(keys.stats.coefficientOfVariation),
        tooltip: i18n.t(keys.stats.coefficientOfVariationTooltip),
        type: 'small',
      }}
    />
  )
}

export { ValueStatsBar }
