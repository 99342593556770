import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import * as React from 'react'
import { usePrevious } from '../hooks/usePrevious'
import { Field, NoteFormV2 } from '../vvapi/models'
import i18n, { keys } from '../i18n'
import { omit } from 'lodash'
import { setWithout } from '../util/setWithout'
import { isGQLError } from '../graphql/GQLError'
import { showFeedback } from '../redux/notifications/redux'
import { useRedux } from '../hooks/useRedux'

export const COLORS = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#FFC107',
  '#FF9800',
] as const

type Color = typeof COLORS[number]

type FieldsAction =
  | { type: 'addField' }
  | { type: 'updateField'; fieldIndex: number; field: Partial<Field> }
  | { type: 'removeField'; fieldIndex: number }
  | { type: 'addOption'; fieldIndex: number }
  | {
      type: 'updateOption'
      fieldIndex: number
      optionIndex: number
      value: string
    }
  | { type: 'removeOption'; fieldIndex: number; optionIndex: number }
  | { type: 'reset' }
  | { type: 'set'; fields: Field[]; title: string; pinColor: Color }
  | { type: 'setTitle'; title: string }
  | { type: 'setPinColor'; color: Color }
  | {
      type: 'setValidations'
      emptyFields: Set<string>
      duplicateFields: Set<string>
      noOptions: Set<string>
      noFields: boolean
    }
  | { type: 'setDuplicateFormName'; duplicateFormName: boolean }

interface FieldsState {
  formTitle: string
  pinColor: Color
  fields: Field[]
  emptyFields: Set<string>
  duplicateFields: Set<string>
  noOptions: Set<string>
  noFields: boolean
  duplicateFormName: boolean
}

interface Props {
  open: boolean
  initialTemplate?: NoteFormV2
  onCancel: () => void
  onSubmit: (noteForm: Omit<NoteFormV2, 'id'>, id?: string) => Promise<void>
}

const EMPTY_STATE: FieldsState = {
  formTitle: '',
  pinColor: '#2196F3',
  fields: [],
  emptyFields: new Set(),
  duplicateFields: new Set(),
  noOptions: new Set(),
  noFields: false,
  duplicateFormName: false,
}
const FIELD_TYPES = ['text', 'number', 'dropdown', 'checkbox', 'checkboxes']

const fieldsReducer = (state: FieldsState, action: FieldsAction) => {
  if (action.type === 'addField') {
    const { fields } = state

    return {
      ...state,
      fields: [...fields, { title: '', type: 'text' as Field['type'] }],
      noFields: false,
    }
  }
  if (action.type === 'updateField') {
    const { fields } = state

    fields[action.fieldIndex] = {
      ...fields[action.fieldIndex],
      ...action.field,
    }

    return {
      ...state,
      fields,
      emptyFields: setWithout(state.emptyFields, `field_${action.fieldIndex}`),
      duplicateFields: setWithout(
        state.duplicateFields,
        `field_${action.fieldIndex}`
      ),
    }
  }
  if (action.type === 'removeField') {
    const { fields } = state

    fields.splice(action.fieldIndex, 1)

    return {
      ...state,
      fields,
    }
  }
  if (action.type === 'addOption') {
    const { fields } = state
    fields[action.fieldIndex].options = [
      ...(fields[action.fieldIndex].options ?? []),
      '',
    ]

    return {
      ...state,
      fields: [...fields],
      noOptions: setWithout(state.noOptions, `field_${action.fieldIndex}`),
    }
  }
  if (action.type === 'updateOption') {
    const { fields } = state

    fields[action.fieldIndex]!.options![action.optionIndex] = action.value

    return {
      ...state,
      fields,
      emptyFields: setWithout(
        state.emptyFields,
        `field_${action.fieldIndex}_option_${action.optionIndex}`
      ),
    }
  }
  if (action.type === 'removeOption') {
    const { fields } = state

    fields[action.fieldIndex].options!.splice(action.optionIndex)
    return {
      ...state,
      fields,
    }
  }

  if (action.type === 'set') {
    return {
      ...state,
      fields: action.fields,
      formTitle: action.title,
      pinColor: action.pinColor,
    }
  }

  if (action.type === 'setTitle') {
    return {
      ...state,
      formTitle: action.title,
      emptyFields: setWithout(state.emptyFields, 'form_title'),
      duplicateFormName: false,
    }
  }

  if (action.type === 'setPinColor') {
    return {
      ...state,
      pinColor: action.color,
    }
  }

  if (action.type === 'setValidations') {
    return { ...state, ...omit(action, 'type') }
  }

  if (action.type === 'setDuplicateFormName') {
    return { ...state, duplicateFormName: action.duplicateFormName }
  }

  if (action.type === 'reset') {
    return { ...EMPTY_STATE }
  }

  return state
}

export const NoteTemplateForm = ({
  open,
  onCancel,
  onSubmit,
  initialTemplate,
}: Props) => {
  const theme = useTheme()
  const [, appDispatch] = useRedux()

  const [isDirty, setIsDirty] = React.useState(false)

  const dialogContentRef = React.useRef<HTMLElement>()

  const [state, dispatch] = React.useReducer(fieldsReducer, {
    ...EMPTY_STATE,
    fields: initialTemplate?.fields ?? EMPTY_STATE.fields,
  })

  const prevState = usePrevious(state)

  React.useEffect(() => {
    if (initialTemplate) {
      dispatch({
        type: 'set',
        fields: initialTemplate.fields,
        title: initialTemplate.name,
        pinColor: initialTemplate.pinColor as Color,
      })
    }
  }, [initialTemplate])

  React.useEffect(() => {
    if (prevState) {
      if (
        Object.keys(prevState.fields).length < Object.keys(state.fields).length
      ) {
        if (dialogContentRef.current) {
          dialogContentRef.current.scrollTo({
            top: dialogContentRef.current.scrollHeight,
            behavior: 'smooth',
          })
        }
      }
    }

    setIsDirty(
      JSON.stringify(initialTemplate?.fields) !== JSON.stringify(state)
    )
  }, [state])

  const renderOptions = (field: Field, fieldIndex: number) => {
    return (
      <Stack spacing={0.5}>
        {Object.values(field.options ?? {}).map((option, optionIndex) => {
          const optionKey = `field_${fieldIndex}_option_${optionIndex}`
          return (
            <TextField
              required
              error={
                state.emptyFields.has(optionKey) ||
                state.duplicateFields.has(optionKey)
              }
              autoComplete="off"
              autoFocus
              onChange={(ev) =>
                dispatch({
                  type: 'updateOption',
                  fieldIndex,
                  optionIndex,
                  value: ev.target.value,
                })
              }
              key={optionKey}
              value={option}
              helperText={
                state.emptyFields.has(optionKey)
                  ? 'Option value is required'
                  : state.duplicateFields.has(optionKey)
                  ? 'Options must be unique'
                  : undefined
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() =>
                        dispatch({
                          type: 'removeOption',
                          fieldIndex,
                          optionIndex,
                        })
                      }
                    >
                      <Icon>clear</Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )
        })}
        <Button
          onClick={() => dispatch({ type: 'addOption', fieldIndex })}
          variant="text"
        >
          {i18n.t(keys.noteForm.addOption)}
        </Button>
      </Stack>
    )
  }

  const renderOption = (field: Field, fieldIndex: number) => {
    switch (field.type) {
      case 'dropdown':
      case 'checkboxes':
        return renderOptions(field, fieldIndex)
      default:
        return null
    }
  }

  const validate = async () => {
    const emptyFields = new Set<string>()
    const noOptions = new Set<string>()
    const duplicateFields = new Set<string>()

    if (!state.formTitle?.trim()) {
      emptyFields.add('form_title')
    }

    const noFields = Object.keys(state.fields).length === 0

    const fieldNameSet = new Set<string>()

    for (const [fieldId, field] of Object.entries(state.fields)) {
      if (!field.title?.trim()) {
        emptyFields.add(`field_${fieldId}`)
      } else {
        if (fieldNameSet.has(field.title)) {
          duplicateFields.add(`field_${fieldId}`)
        }
        fieldNameSet.add(field.title)
      }

      if (
        (field.type === 'checkboxes' || field.type === 'dropdown') &&
        (!field.options || Object.keys(field.options).length === 0)
      ) {
        noOptions.add(`field_${fieldId}`)
      }
      const optionsSet = new Set<string>()
      for (const [optionId, option] of Object.entries(field.options ?? {})) {
        if (!option?.trim()) {
          emptyFields.add(`field_${fieldId}_option_${optionId}`)
        } else {
          if (optionsSet.has(option)) {
            duplicateFields.add(`field_${fieldId}_option_${optionId}`)
          }
          optionsSet.add(option)
        }
      }
    }

    dispatch({
      type: 'setValidations',
      emptyFields,
      noFields,
      noOptions,
      duplicateFields,
    })

    if (
      emptyFields.size === 0 &&
      noOptions.size === 0 &&
      !noFields &&
      duplicateFields.size === 0
    ) {
      try {
        await onSubmit(
          {
            name: state.formTitle!,
            fields: state.fields,
            pinColor: state.pinColor,
          },
          initialTemplate?.id
        )

        dispatch({ type: 'reset' })
      } catch (e) {
        if (isGQLError(e)) {
          if (
            e.errors.some(
              (e) =>
                e.message ===
                'Uniqueness violation. duplicate key value violates unique constraint "NoteForm_organizationId_name_key"'
            )
          ) {
            dispatch({ type: 'setDuplicateFormName', duplicateFormName: true })
          }
        } else {
          console.error(e)

          appDispatch(
            showFeedback({
              message: i18n.t(keys.noteForm.errors.errorSaving),
              severity: 'error',
            })
          )
        }
      }
    }
  }

  const handleCancel = () => {
    onCancel()

    dispatch({ type: 'reset' })
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Stack spacing={1}>
          <Typography variant="h6">
            {i18n.t(keys.noteForm.newTemplate)}
          </Typography>
          <TextField
            required
            error={
              state.emptyFields.has('form_title') || state.duplicateFormName
            }
            autoComplete="off"
            autoFocus
            variant="outlined"
            placeholder={i18n.t(keys.noteForm.titlePlaceholder)}
            value={state.formTitle ?? null}
            onChange={(ev) =>
              dispatch({ type: 'setTitle', title: ev.target.value })
            }
            helperText={
              state.emptyFields.has('form_title')
                ? i18n.t(keys.noteForm.errors.formTitleRequired)
                : state.duplicateFormName
                ? i18n.t(keys.noteForm.errors.duplicateFormName)
                : undefined
            }
          />
          <Typography>{i18n.t(keys.notes.pinColor)}</Typography>
          <Stack direction="row" justifyContent="stretch">
            {COLORS.map((c) => (
              <Box
                onClick={() => dispatch({ type: 'setPinColor', color: c })}
                sx={{
                  cursor: 'pointer',
                  background: c,
                  height: 30,
                  width: '100%',
                  borderStyle: 'solid',
                  borderWidth: 2,
                  borderColor:
                    state.pinColor === c
                      ? theme.palette.getContrastText(
                          theme.palette.background.paper
                        )
                      : c,
                  boxSizing: 'border-box',
                }}
              />
            ))}
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent
        dividers={true}
        sx={{
          mx: 2.5,
          borderTop: 'none',
          borderRadius: `${theme.shape.borderRadius}px`,
          background: theme.palette.background.default,
        }}
        ref={dialogContentRef}
      >
        <Stack spacing={1} sx={{ height: '100%' }}>
          {state.noFields ? (
            <Typography variant="caption" color="error">
              {i18n.t(keys.noteForm.errors.atLeastOneField)}
            </Typography>
          ) : null}
          <Stack spacing={1}>
            {Object.values(state.fields).map((field, fieldIndex) => {
              return (
                <Card elevation={0} key={fieldIndex}>
                  <CardContent>
                    <Grid container spacing={1} sx={{ px: 1 }}>
                      <Grid xs={6} sm={8}>
                        <TextField
                          required
                          error={
                            state.emptyFields.has(`field_${fieldIndex}`) ||
                            state.duplicateFields.has(`field_${fieldIndex}`)
                          }
                          autoComplete="off"
                          autoFocus
                          placeholder={i18n.t(keys.noteForm.titlePlaceholder)}
                          onChange={(ev) =>
                            dispatch({
                              type: 'updateField',
                              fieldIndex,
                              field: {
                                title: ev.target.value as Field['title'],
                              },
                            })
                          }
                          value={field.title ?? null}
                          sx={{ marginTop: 1 }}
                          variant="standard"
                          fullWidth
                          helperText={
                            state.emptyFields.has(`field_${fieldIndex}`)
                              ? i18n.t(keys.noteForm.errors.fieldTitleRequired)
                              : state.duplicateFields.has(`field_${fieldIndex}`)
                              ? i18n.t(
                                  keys.noteForm.errors.fieldTitleMustBeUnique
                                )
                              : undefined
                          }
                        />
                      </Grid>
                      <Grid xs={6} sm={4}>
                        <Select
                          onChange={(ev) =>
                            dispatch({
                              type: 'updateField',
                              fieldIndex,
                              field: {
                                type: ev.target.value as Field['type'],
                              },
                            })
                          }
                          value={field.type}
                          fullWidth
                          variant="outlined"
                          size="small"
                          sx={{
                            m: 1,
                            minWidth: 120,
                            background: theme.palette.background.default,
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                          }}
                        >
                          {FIELD_TYPES.map((fieldType) => (
                            <MenuItem key={fieldType} value={fieldType}>
                              {i18n.t(`noteForm.fieldTypes.${fieldType}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid xs={8}>{renderOption(field, fieldIndex)}</Grid>
                      {state.noOptions.has(`field_${fieldIndex}`) ? (
                        <Grid xs={12}>
                          <Typography variant="caption" color="error">
                            {i18n.t(keys.noteForm.errors.mustDefineOptions)}
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'end' }}>
                    <IconButton
                      onClick={() =>
                        dispatch({ type: 'removeField', fieldIndex })
                      }
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </CardActions>
                </Card>
              )
            })}
            <Button
              onClick={() => dispatch({ type: 'addField' })}
              variant="outlined"
            >
              <Stack direction="row" spacing={1}>
                <Icon>add_circle_outline</Icon>
                <Typography>{i18n.t(keys.noteForm.addField)}</Typography>
              </Stack>
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack width="100%">
          {(state.noOptions.size > 0 ||
            state.duplicateFields.size > 0 ||
            state.emptyFields.size > 0 ||
            state.duplicateFormName ||
            state.noFields) && (
            <Typography sx={{ p: 1 }} variant="caption" color="error">
              {i18n.t(keys.noteForm.errors.templateHasErrors)}
            </Typography>
          )}

          <Stack sx={{ width: '100%' }} spacing={1}>
            <Stack
              direction="row"
              justifyItems="stretch"
              sx={{ width: '100%' }}
              spacing={1}
            >
              <Button
                onClick={handleCancel}
                variant="contained"
                fullWidth
                sx={{
                  color: theme.palette.getContrastText(
                    theme.palette.background.default
                  ),
                  background: theme.palette.background.default,
                  '&:hover': {
                    color: theme.palette.getContrastText(
                      theme.palette.secondary.main
                    ),
                  },
                }}
                disableElevation
                color="secondary"
              >
                {i18n.t(keys.generic.cancel)}
              </Button>
              <Button
                disabled={!isDirty}
                variant="outlined"
                color="primary"
                fullWidth
                onClick={validate}
              >
                {i18n.t(keys.forms.done)}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
