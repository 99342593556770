import * as Sentry from '@sentry/browser'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ReduxContextProvider } from './hooks/useRedux'
import './index.scss'
import { store } from './redux/createStore'
import './util/disableZoom.scss'
import '@fortawesome/fontawesome-free/css/all.css'
import { Chart, registerables } from 'chart.js'
import { BarWithErrorBar, BarWithErrorBarsController } from 'chartjs-chart-error-bars'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// https://github.com/facebook/create-react-app/issues/11773#issuecomment-995720144
// This may be fixed if we update react and react-dom or CRA fixes the above issue
window.process = { ...(window.process ?? {}), browser: true } as any

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'development',
    release: process.env.REACT_APP_SENTRY_RELEASE ?? '',
    ignoreErrors: [/^Error 40.+/],
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === 'xhr') {
        return null
      }

      return breadcrumb
    },
  })
}

// Initialize chart JS
Chart.register(...registerables, 
  ChartDataLabels,
  BarWithErrorBar,
  BarWithErrorBarsController,
  annotationPlugin
)

function renderApp() {
  // Importing this strange way is needed for hot loading.
  // tslint:disable-next-line:variable-name
  const App = require('./app/App').default

  ReactDOM.render(
    <Provider store={store}>
      <ReduxContextProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Router>
            <App />
          </Router>
        </LocalizationProvider>
      </ReduxContextProvider>
    </Provider>,
    document.getElementById('root')
  )
}

async function init() {
  renderApp()
}

// tslint:disable-next-line: no-string-literal
if (window['cordova']) {
  // wait for cordova to initialize before initializing the VineView app
  document.addEventListener('deviceready', init, false)
} else {
  init()
}

if (navigator.storage?.persist) {
  navigator.storage.persist()
}

if (module.hot) {
  module.hot.accept('./app/App', renderApp)
}
