import { RateMapContextState } from '../editor/RateMapContext'
import { createDeepEqualitySelector } from './createDeepEqualitySelector'

const getRateMapMode = (state: RateMapContextState) => state.rateMap?.mode

export const selectRateMapMode = createDeepEqualitySelector(
  getRateMapMode,
  (mode) => {
    return mode
  }
)
