import {
  MRT_Row,
  MRT_ActionMenuItem as ActionMenuItem,
  MRT_TableInstance,
} from 'material-react-table'
import * as React from 'react'
import { Check, ContentCopy, Delete } from '@mui/icons-material'
import i18n, { keys } from '../../../i18n'
import { SamplePlanRow } from './types/SamplePlanRow'

interface Props {
  closeMenu: () => void
  row: MRT_Row<SamplePlanRow>
  staticRowIndex?: number
  table: MRT_TableInstance<SamplePlanRow>
}

export const SamplePlanActionMenuItems = ({ table, row }: Props) => {
  const onMarkComplete = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    row.original.markComplete()
  }

  const onDuplicate = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    row.original.duplicate()
  }

  const onDelete = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    row.original.delete()
  }

  return [
    <ActionMenuItem
      icon={<Check />}
      key="mark-complete"
      label={i18n.t(keys.samplePlanMoreMenuMarkAsCompleteLabel)}
      onClick={onMarkComplete}
      table={table}
    />,
    <ActionMenuItem
      icon={<ContentCopy />}
      key="duplicate"
      label={i18n.t(keys.generic.duplicate)}
      onClick={onDuplicate}
      table={table}
    />,
    <ActionMenuItem
      icon={<Delete />}
      key="delete"
      label={i18n.t(keys.generic.delete)}
      onClick={onDelete}
      table={table}
    />,
  ]
}
