import { HistoricDeliveryParcelStats } from './selectHistoricParcelData'

export const sortByFlightDate = (
  historicParcelData: HistoricDeliveryParcelStats[]
) => {
  return historicParcelData
    ?.filter((d) => d.flightDate) // Remove instances without flight dates.
    .sort(
      (a, b) =>
        new Date(a.flightDate).getTime() - new Date(b.flightDate).getTime()
    )
    .reduce((parcelByFlightDate, parcelData) => {
      const { flightDate: flightDate } = parcelData
      if (parcelByFlightDate.has(flightDate)) {
        parcelByFlightDate.get(flightDate)?.push(parcelData)
        return parcelByFlightDate
      }
      parcelByFlightDate.set(flightDate, [parcelData])
      return parcelByFlightDate
    }, new Map<string, HistoricDeliveryParcelStats[]>())
}
