import './SamplePlanDashboard.scss'

import { Stack, styled } from '@mui/material'
import * as React from 'react'
import Page from '../../../app/Page'
import { url, urls } from '../../../appNavigation/urls'
import i18n, { keys } from '../../../i18n'

import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from 'material-react-table'
import { ConfirmationModal } from '../../../app/ConfirmationModal/ConfirmationModal'
import { DuplicateModal } from '../../../app/DuplicateModal/DuplicateModal'
import { selectOrganizationId } from '../../../data/selectOrganizationId'
import { SamplePlan } from '../../../graphql/types'
import useAsync from '../../../hooks/useAsync'
import { useRedux } from '../../../hooks/useRedux'
import { useHistory } from '../../../hooks/useRouter'
import ActionsCell from '../../../UI/MaterialReactTable/ActionsCell'
import { useDefaultTableConfig } from '../../../UI/MaterialReactTable/useDefaultTableConfig'
import {
  deleteSamplePlan,
  duplicateSamplePlan,
  getFilterOptions,
  getSamplePlans,
  markAsComplete,
} from '../queries'
import { SamplePlanFormChip } from '../SamplePlanFormChip'
import { SamplePlanStatusText } from '../SamplePlanStatusText'
import { SamplePlanActionMenuItems } from './SamplePlanActionMenuItems'
import { SamplePlanEmptyView } from './SamplePlanEmptyView'
import { SamplePlanToolBarOptions } from './SamplePlanToolBarOptions'
import { SamplePlanRow } from './types/SamplePlanRow'

const TableContainer = styled(Stack)({
  margin: '30px 128px',
})

export const SamplePlanDashboard = () => {
  const [state] = useRedux()
  const organizationId = selectOrganizationId(state)

  //table state
  const [columnFilters, setColumnFilters] =
    React.useState<MRT_ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [sorting, setSorting] = React.useState<MRT_SortingState>([])
  const [pagination, setPagination] = React.useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })
  const [rowCount, setRowCount] = React.useState(0)

  const history = useHistory()

  const [samplePlanRequest, refreshSamplePlanRequest] = useAsync(async () => {
    const { SamplePlans, totalRowCount } = await getSamplePlans({
      organizationId,
      columnFilters,
      globalFilter,
      sorting,
      pagination,
    })
    setRowCount(totalRowCount)
    return SamplePlans
  }, [
    organizationId,
    columnFilters,
    globalFilter,
    sorting,
    pagination.pageIndex,
    pagination.pageSize,
  ])

  const [filterOptions] = useAsync(async () => {
    return await getFilterOptions({ organizationId })
  }, [organizationId])

  const parcelFilterOptions = React.useMemo(() => {
    if (!filterOptions.result) return {}
    return filterOptions.result.parcelOptions
  }, [filterOptions.result])

  const groupFilterOptions = React.useMemo(() => {
    if (!filterOptions.result) return {}
    return filterOptions.result.groupOptions
  }, [filterOptions.result])

  const formFilterOptions = React.useMemo(() => {
    if (!filterOptions.result) return {}
    return filterOptions.result.formOptions.map((form) => ({
      value: form.value,
      label: i18n.t(`noteForm.reservedNames.${form.label}`, {
        defaultValue: form.label ?? '',
      }),
    }))
  }, [filterOptions.result])

  const samplePlanData = React.useMemo(
    () => (samplePlanRequest.result ? samplePlanRequest.result : []),
    [samplePlanRequest.result]
  )

  const [deleteCandidate, setDeleteCandidate] = React.useState<SamplePlan>()

  const [duplicateCandidate, setDuplicateCandidate] =
    React.useState<SamplePlan>()

  const handleDelete = async () => {
    if (!deleteCandidate) {
      return
    }
    await deleteSamplePlan(deleteCandidate.id)
    refreshSamplePlanRequest()
    setDeleteCandidate(undefined)
  }

  const handleDuplicate = async (value: string) => {
    if (!duplicateCandidate) {
      return
    }
    const duplicate = await duplicateSamplePlan(duplicateCandidate.id, value)
    history.push(url(urls.samplePlan, { samplePlanId: duplicate.id }))
  }

  const samplePlanRows = React.useMemo<SamplePlanRow[]>(() => {
    return samplePlanData.map((sp) => ({
      id: sp.id,
      name: sp.name,
      blockGroup:
        sp.SamplePlanBlocks?.map((spb) => spb.Parcel.OrganizationGroup?.name)
          .filter(
            (ogn, i, organizationGroups) =>
              organizationGroups.indexOf(ogn) === i
          )
          .join(', ') ?? '-',
      createdAt: sp.createdAt,
      formType: sp.NoteForm?.name ?? sp.templateName ?? '-',
      formColor: sp.NoteForm?.pinColor,
      blocks:
        sp.SamplePlanBlocks && sp.SamplePlanBlocks.length > 0
          ? sp.SamplePlanBlocks?.sort((a, b) =>
              a?.Parcel?.name.localeCompare(b?.Parcel?.name)
            ).reduce(
              (acc, spb, i) =>
                acc +
                `${spb?.Parcel?.name}` +
                (i < (sp?.SamplePlanBlocks?.length ?? 0) - 1 ? ' | ' : ''),
              ''
            )
          : '-',
      samples:
        sp.totalSamples?.aggregate.count && sp.totalSamples?.aggregate.count > 0
          ? `${sp.completedSamples?.aggregate?.count ?? '-'}/${
              sp.totalSamples?.aggregate.count
            }`
          : '-',
      updatedAt: sp.updatedAt,
      totalSamples: sp.totalSamples?.aggregate.count,
      completedSamples: sp.completedSamples?.aggregate?.count,
      isPending: sp.isPending,
      markComplete: async () => {
        await markAsComplete(sp.id)
        refreshSamplePlanRequest()
      },
      edit: () => {
        history.push(url(urls.samplePlanResults, { samplePlanId: sp.id }))
      },
      duplicate: async () => {
        setDuplicateCandidate(sp)
      },
      delete: async () => {
        setDeleteCandidate(sp)
      },
    }))
  }, [history, refreshSamplePlanRequest, samplePlanData])

  const columns = React.useMemo<MRT_ColumnDef<SamplePlanRow>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header: i18n.t(keys.samplePlanNameColumnLabel),
        size: 130,
      },
      {
        accessorKey: 'blockGroup',
        header: i18n.t(keys.samplePlanBlockLocationColumnLabel),
        filterSelectOptions: groupFilterOptions,
        filterVariant: 'multi-select',
        size: 130,
      },
      {
        accessorKey: 'createdAt',
        header: i18n.t(keys.samplePlanDateColumnLabel),
        filterVariant: 'date-range',
        Cell: ({ row }) => i18n.toDateShort(row.original.createdAt),
        size: 130,
      },
      {
        accessorKey: 'formType',
        header: i18n.t(keys.samplePlanFormColumnLabel),
        filterSelectOptions: formFilterOptions,
        filterVariant: 'multi-select',
        Cell: ({ row }) => (
          <SamplePlanFormChip
            templateName={i18n.t(
              `noteForm.reservedNames.${row.original.formType}`,
              {
                defaultValue: row.original.formType ?? '',
              }
            )}
            noteFormColor={row.original.formColor}
          />
        ),
        size: 130,
      },
      {
        accessorKey: 'blocks',
        header: i18n.t(keys.samplePlanBlocksColumnLabel),
        filterVariant: 'multi-select',
        filterSelectOptions: parcelFilterOptions,
        size: 130,
      },
      {
        accessorKey: 'samples',
        header: i18n.t(keys.samplePlanSamplesColumnLabel),
        size: 130,
      },
      {
        accessorKey: 'updatedAt',
        header: i18n.t(keys.samplePlanLastUpdateColumnLabel),
        filterVariant: 'date-range',
        Cell: ({ row }) => i18n.toDateShort(row.original.updatedAt),
        size: 130,
      },
      {
        accessorKey: 'status',
        header: i18n.t(keys.samplePlanStatusColumnLabel),
        filterVariant: 'multi-select',
        filterSelectOptions: [
          {
            value: 'in-progress',
            label: i18n.t(keys.samplePlanStatusInProgressLabel),
          },
          {
            value: 'complete',
            label: i18n.t(keys.samplePlanStatusCompleteLabel),
          },
        ],
        Cell: ({ row }) => (
          <SamplePlanStatusText
            isPending={row.original.isPending}
            totalSamples={row.original.totalSamples}
            completedSamples={row.original.completedSamples}
          />
        ),
        size: 130,
      },
      {
        id: 'actions',
        header: 'Actions',
        Cell: ({ row, table }) => (
          <ActionsCell
            row={row}
            table={table}
            editTitle={i18n.t(keys.results)}
            menuItems={SamplePlanActionMenuItems}
          />
        ),
        enableSorting: false,
        enableFiltering: false,
        size: 130,
      },
    ] as MRT_ColumnDef<SamplePlanRow>[]
  }, [formFilterOptions, groupFilterOptions, parcelFilterOptions])

  const table = useDefaultTableConfig({
    status: samplePlanRequest.status,
    columns,
    data: samplePlanRows,
    requiredTableConfig: {
      renderEmptyRowsFallback: SamplePlanEmptyView,
      rowCount,
      state: {
        columnFilters,
        globalFilter,
        isLoading: samplePlanRequest.status === 'pending',
        pagination,
        showAlertBanner: samplePlanRequest.status === 'rejected',
        sorting,
      },
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
    },
    extendedTableConfig: {
      renderTopToolbarCustomActions: SamplePlanToolBarOptions,
      renderRowActionMenuItems: SamplePlanActionMenuItems,
      muiTableBodyRowProps: ({ row }) => ({
        onClick: () =>
          history.push(url(urls.samplePlan, { samplePlanId: row.id })),
        sx: { cursor: 'pointer' },
      }),
      muiTopToolbarProps: {
        sx: {
          zIndex: 2,
        },
      },
    },
  })

  return (
    <Page
      title={i18n.t(keys.samplePlanDashboard)}
      backTo={url(urls.mapView)}
      backToTitle={i18n.t(keys.map.map)}
    >
      <TableContainer>
        <MaterialReactTable table={table} />
      </TableContainer>
      <ConfirmationModal
        open={!!deleteCandidate}
        onConfirm={handleDelete}
        onCancel={() => setDeleteCandidate(undefined)}
        title={i18n.t(keys.samplePlanDelete)}
        message={i18n.t(keys.samplePlanDeleteConfirmation)}
        dangerMode
      />
      <DuplicateModal
        open={!!duplicateCandidate}
        onConfirm={handleDuplicate}
        onCancel={() => setDuplicateCandidate(undefined)}
        title={i18n.t(keys.samplePlanDuplicate)}
        message={i18n.t(keys.samplePlanDuplicateConfirmation)}
        defaultValue={`${duplicateCandidate?.name} - Copy`}
      />
    </Page>
  )
}
