import {
  MRT_ColumnDef,
  MRT_RowData,
  MRT_TableOptions,
  useMaterialReactTable,
} from 'material-react-table'
import i18n, { keys } from '../../i18n'
import { useRedux } from '../../hooks/useRedux'
import { selectPreferredLanguage } from '../../data/selectPreferredLanguage'
import { MRT_Localization_FR } from 'material-react-table/locales/fr'
import { MRT_Localization_EN } from 'material-react-table/locales/en'
import { darken, useTheme } from '@mui/material'

interface Input<TRowData extends MRT_RowData> {
  status: 'pending' | 'resolved' | 'rejected'
  columns: MRT_ColumnDef<TRowData>[]
  data: TRowData[]
  requiredTableConfig: Pick<
    MRT_TableOptions<TRowData>,
    | 'renderEmptyRowsFallback'
    | 'rowCount'
    | 'state'
    | 'onColumnFiltersChange'
    | 'onGlobalFilterChange'
    | 'onPaginationChange'
    | 'onSortingChange'
  >
  extendedTableConfig?: Exclude<
    Partial<MRT_TableOptions<TRowData>>,
    | 'renderEmptyRowsFallback'
    | 'rowCount'
    | 'state'
    | 'onColumnFiltersChange'
    | 'onGlobalFilterChange'
    | 'onPaginationChange'
    | 'onSortingChange'
  >
}

export const useDefaultTableConfig = <TRowData extends MRT_RowData>({
  status,
  columns,
  data,
  requiredTableConfig,
  extendedTableConfig,
}: Input<TRowData>) => {
  const [state] = useRedux()
  const preferredLanguage = selectPreferredLanguage(state)
  const theme = useTheme()

  const table = useMaterialReactTable<TRowData>({
    columns,
    data,
    enableRowActions: false,
    enableColumnActions: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableSorting: true,
    getRowId: (row) => row.id,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: false,
    muiToolbarAlertBannerProps:
      status === 'rejected'
        ? {
            color: 'error',
            children: i18n.t(keys.errors.somethingWentWrong),
          }
        : undefined,

    initialState: {
      showGlobalFilter: true,
    },
    localization:
      preferredLanguage === 'fr' ? MRT_Localization_FR : MRT_Localization_EN,
    positionActionsColumn: 'last',
    muiTableHeadCellProps: {
      sx: {
        '& .MuiTableSortLabel-icon': {
          opacity: 0,
          visibility: 'hidden',
          transition: 'opacity 0.2s, visibility 0.2s',
        },
        '&:hover .MuiTableSortLabel-icon': {
          opacity: 1,
          visibility: 'visible',
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: 14,
      },
    },
    muiTableBodyProps: {
      sx: {
        '& tr:nth-of-type(odd) > td': {
          backgroundColor: darken(theme.palette.background.paper, 0.2),
        },
      },
    },
    ...requiredTableConfig,
    ...extendedTableConfig,
  })

  return table
}
