import { createSelector } from 'reselect'
import { selectRateMapFlightDate } from './selectRateMapFlightDate'
import { selectRateMapSelectedParcelIds } from './selectRateMapSelectedParcelIds'
import { RateMapContextState } from '../editor/RateMapContext'
import {
  ParcelData,
  selectParcelByIdWithFlightDate,
} from '../../../data/selectOrgMapData'
import { RootStore } from '../../../redux/types'
import { selectOrganizationId } from '../../../data/selectOrganizationId'
import { selectRateMapSelectedProductIds } from './selectRateMapSelectedProductIds'
import { selectRateMapCustomZoneLayers } from './selectRateMapCustomZoneLayers'

const getExternalState = (_: RateMapContextState, externalState: RootStore) =>
  externalState

const selectRateMapOrganizationId = createSelector(
  getExternalState,
  (externalstate) => selectOrganizationId(externalstate)
)

const EMPTY_PARCELS: Record<string, ParcelData> = {}

const selectRateMapParcelsById = createSelector(
  getExternalState,
  selectRateMapFlightDate,
  (externalstate, flightDate) =>
    flightDate
      ? selectParcelByIdWithFlightDate(externalstate, flightDate)
      : EMPTY_PARCELS
)

const getIsCustomZoneMode = (state: RateMapContextState) =>
  state.isCustomZoneMode

export const selectRateMapUserSelection = createSelector(
  selectRateMapFlightDate,
  selectRateMapSelectedParcelIds,
  selectRateMapOrganizationId,
  selectRateMapParcelsById,
  selectRateMapSelectedProductIds,
  getIsCustomZoneMode,
  selectRateMapCustomZoneLayers,
  (
    flightDate,
    selectedParcelIds,
    organizationId,
    parcelsById,
    selectedProductIds,
    isCustomZoneMode,
    customZoneLayers
  ) => {
    const selectedGroupId = selectedParcelIds?.[0]
      ? parcelsById[selectedParcelIds[0]]?.groupId
      : undefined

    return {
      selectedFlightDate: flightDate,
      selectedGroupId,
      selectedParcelIds,
      selectedProductIds,
      overriddenParcelBorderColor: isCustomZoneMode ? 'white' : undefined,
      overriddenParcelBorderOpacity: isCustomZoneMode ? [[1, 1]] : undefined,
      overriddenParcelBorderWidth: 2.5,
      selectedOrganizationId: organizationId,
      selectedLegendProductId: selectedProductIds[0],
      extraLayers: customZoneLayers,
    }
  }
)
