import {
  setFocusedBlockId,
  setFocusedPoint,
  setFocusedPolygon,
} from '../../../../postgis/actions'
import {
  IMapActionGenerator,
  Priority,
  IMapAction,
} from '../types/MapActionTypes'
import { MapboxGL } from '../../../MapboxGL'
import { SOIL_CALIFORNIA_LABEL_LAYER } from '../../../../postgis/SoilLayer/california-mapbox-soil'
import { FocusedSoilProperties } from '../../../../postgis/types'
import { setFocusedSoilLayer } from '../../../../postgis/actions'
import { IMapActionGeneratorParams } from '../types/MapActionGeneratorParams'
import { SOIL_WASHINGTON_LABEL_LAYER } from '../../../../postgis/SoilLayer/washington-mapbox-soil'

export class SoilActionGenerator implements IMapActionGenerator {
  key = 'soil'
  priority = Priority.Soil

  generateActionsFromQualifyingFeatures({
    dispatch,
    event,
    features,
  }: IMapActionGeneratorParams): IMapAction[] {
    // Find the qualifying features.
    const qualifiers = features.filter(
      (f: MapboxGL.MapboxGeoJSONFeature) =>
        f.layer.id.startsWith(SOIL_CALIFORNIA_LABEL_LAYER) ||
        f.layer.id.startsWith(SOIL_WASHINGTON_LABEL_LAYER)
    )

    // Return array of functions that can execute the action for each of the qualifying features.
    return qualifiers.map((q) => ({
      key: this.key,
      priority: this.priority,
      execute: () => {
        const clickedPoint = {
          type: 'Point' as 'Point',
          coordinates: [event.lngLat.lng, event.lngLat.lat],
        }
        const alternativeSoilProperties = q?.properties?.RasterT__4?.split(', ')
        const properties = {
          vv_desc: q?.properties?.vv_desc ?? alternativeSoilProperties?.[0],
          vv_slope:
            q?.properties?.vv_slope ??
            alternativeSoilProperties?.slice(1)?.join(' '),
          descr: q?.properties?.descr ?? alternativeSoilProperties?.[0],
          URL: q?.properties?.URL,
        } as FocusedSoilProperties

        dispatch(setFocusedPoint(null))
        dispatch(setFocusedPolygon(null))
        dispatch(setFocusedBlockId(undefined))
        dispatch(
          setFocusedSoilLayer({
            properties,
            type: 'Feature',
            geometry: clickedPoint,
          })
        )
      },
    }))
  }
}
