import * as React from 'react'
import './StatsTable.scss'
import { Divider, Grid, styled, Tooltip, Typography } from '@mui/material'
import { TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material'
import i18n, { keys } from '../../i18n'
import { useFeatureFlag } from '../../hooks/useFeatureFlag'
import { useRedux } from '../../hooks/useRedux'

interface StatsTableProps {
  isMobile: boolean
  plantCount: StatsTableItemProp
  area: StatsTableItemProp
  mean: StatsTableItemProp
  stdev: StatsTableItemProp
  usage: StatsTableItemProp
  variance: StatsTableItemProp
  hideUsageAndVariance?: boolean
}

interface StatsTableGridProp {
  isMobile: boolean
}

const StatsTableGrid = styled(Grid)<StatsTableGridProp>`
  padding: 6px 0px 12px 10px;
  height: 100%;
  max-width: ${({ isMobile }) => (isMobile ? '100%' : '180px')};
  max-height: ${({ isMobile }) => (isMobile ? '100%' : '180px')};
`

// tslint:disable-next-line: function-name
export function StatsTable({
  isMobile,
  plantCount,
  area,
  mean,
  stdev,
  usage,
  variance,
  hideUsageAndVariance,
}: StatsTableProps) {
  const [wideSize, setWideSize] = React.useState(12)
  const [halfWideSize, setHalfWideSize] = React.useState(6)

  React.useEffect(() => {
    const wideSize = isMobile ? 6 : 12
    setWideSize(wideSize)
    setHalfWideSize(wideSize / 2)
  }, [isMobile])

  return (
    <StatsTableGrid isMobile={isMobile} container spacing={0}>
      <Grid item xs={wideSize}>
        {StatsTableItem(mean)}
      </Grid>
      <Grid item xs={wideSize}>
        {StatsTableItem(stdev)}
      </Grid>
      <Grid item xs={12} style={{ padding: '5px 20px' }}>
        <Divider orientation="horizontal" flexItem />
      </Grid>
      <Grid item xs={halfWideSize}>
        {StatsTableItem(plantCount)}
      </Grid>
      <Grid item xs={halfWideSize}>
        {StatsTableItem(usage)}
      </Grid>

      {!hideUsageAndVariance ? (
        <Grid item xs={halfWideSize}>
          {StatsTableItem(area)}
        </Grid>
      ) : null}
      {!hideUsageAndVariance ? (
        <Grid item xs={halfWideSize}>
          {StatsTableItem(variance)}
        </Grid>
      ) : null}
    </StatsTableGrid>
  )
}

interface StatsTableItemProp {
  value: string
  label: string
  tooltip?: string
  type: 'small' | 'large'
  trend?: number
  invert?: boolean
}

function StatsTableItem({
  value,
  label,
  tooltip,
  trend,
  invert,
  type,
}: StatsTableItemProp) {
  const [state] = useRedux()
  const labelColour = getTrendLabelColour(trend ?? 0, invert)
  const { featureEnabled } = useFeatureFlag({
    featureFlagId: 'historical-stats',
  })
  const isStatsZoneToolActive = state.postgis.isStatsZonesToolActive

  return (
    <Grid container className="StatsTableItem" spacing={0}>
      <Grid item xs={12}>
        <Typography
          style={{
            fontWeight: `${type === 'large' ? 'bolder' : 'lighter'}`,
            fontSize: `${type === 'large' ? '10px' : '9px'}`,
            lineHeight: 1.3,
            color: 'lightgray',
          }}
          variant="overline"
        >
          {label}
        </Typography>
      </Grid>
      <Grid
        className="StatsInnerGrid"
        item
        xs={12}
        direction="row"
        wrap="nowrap"
      >
        <Tooltip
          title={tooltip ?? ''}
          disableHoverListener={!tooltip}
          placement="right"
        >
          <Typography
            className="StatLabel"
            style={{
              fontWeight: `${type === 'large' ? 'bolder' : 'lighter'}`,
              fontSize: `${type === 'large' ? '16px' : '15px'}`,
              lineHeight: 1.3,
            }}
            variant={type === 'large' ? 'body1' : 'body2'}
          >
            {value}
          </Typography>
        </Tooltip>
        {trend !== undefined && !isStatsZoneToolActive && featureEnabled ? (
          <Tooltip
            title={i18n
              .t(keys.stats.trendTooltip)
              .replace('{{stat}}', label.toLocaleLowerCase())}
            placement="right"
          >
            <div className="TrendItem">
              {trend > 0 ? (
                <TrendingUp sx={{ color: labelColour }} />
              ) : trend === 0 ? (
                <TrendingFlat sx={{ color: labelColour }} />
              ) : (
                <TrendingDown sx={{ color: labelColour }} />
              )}
              <Typography
                className="TrendLabel"
                color={labelColour}
                sx={{ fontWeight: 'bolder' }}
              >
                {`${trend > 0 ? '+' : ''}${trend ? trend : ''}`}
              </Typography>
            </div>
          </Tooltip>
        ) : null}
      </Grid>
    </Grid>
  )
}

const getTrendLabelColour = (
  trend: number,
  invert?: boolean
): '#00FF7F' | 'grey' | 'red' => {
  if (invert) {
    return trend < 0 ? '#00FF7F' : trend === 0 ? 'grey' : 'red'
  }
  return trend > 0 ? '#00FF7F' : trend === 0 ? 'grey' : 'red'
}
