import { Info } from '@mui/icons-material'
import {
  Box,
  Divider,
  Stack,
  styled,
  TableContainer,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import * as React from 'react'
import RateMapSelect from '../../../components/RateMapSelect'
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_Table as Table,
  useMaterialReactTable,
} from 'material-react-table'
import { selectOrganizationId } from '../../../../../data/selectOrganizationId'
import { useRedux } from '../../../../../hooks/useRedux'
import { selectPreferredLanguage } from '../../../../../data/selectPreferredLanguage'
import { useRateMapContext } from '../../RateMapContext'
import { RateMapNumberInput } from '../../../components/RateMapNumberInput'
import { AddAmendmentDialog } from './AddAmendmentDialog'
import {
  addAmendment,
  deleteAmendment,
  getRatemapsByAmendment,
} from '../../../queries'
import AsyncSelectorStatusOverlay from '../../../../../AsyncSelector/AsyncSelectorStatusOverlay'
import { ZoneRow } from './RateMapConfig'
import i18n, { keys } from '../../../../../i18n'
import { MRT_Localization_FR } from 'material-react-table/locales/fr'
import { MRT_Localization_EN } from 'material-react-table/locales/en'
import { DeleteAmendmentDialog } from './DeleteAmendmentDialog'
import { selectRateMapCustomZoneRatesByZoneId } from '../../../selectors/selectRateMapCustomZoneRatesByZoneId'

const Circle = styled(Box)<{ circleColor: string; selected?: boolean }>(
  ({ circleColor, selected }) => ({
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    backgroundColor: circleColor,
    cursor: 'pointer',
    border: selected ? '3px solid white' : 'null',
  })
)

export const AmendmentConfig = ({
  zones,
  zonesLoading,
}: {
  zones: ZoneRow[]
  zonesLoading: boolean
}) => {
  const theme = useTheme()

  const [state] = useRedux()

  const [addAmendmentDialogOpen, setAddAmendmentDialogOpen] =
    React.useState(false)

  const [deleteAmendmentDialogOpen, setDeleteAmendmentDialogOpen] =
    React.useState(false)

  const [amendmentToDelete, setAmendmentToDelete] = React.useState<{
    id: string
    label: string
    affectedRateMaps: number
  } | null>(null)

  const rateMapState = useRateMapContext()

  const {
    rateMap,
    setModifiedRateMap,
    setSelectedZoneIndex,
    refresh,
    unitTypes,
    amendmentTypes,
    selectedZoneIndex,
    setSelectedCustomZoneId,
  } = rateMapState

  const ratesByZoneId = selectRateMapCustomZoneRatesByZoneId(rateMapState)

  const setSelectedZone = React.useCallback(
    (zoneIndex?: number, customZoneId?: string) => {
      setSelectedZoneIndex?.(zoneIndex)
      setSelectedCustomZoneId?.(customZoneId)
    },
    [setSelectedCustomZoneId, setSelectedZoneIndex]
  )

  const language = selectPreferredLanguage(state)
  const locale = language.toLocaleLowerCase()
  const organizationId = selectOrganizationId(state)
  const preferredLanguage = selectPreferredLanguage(state)
  const preferredUnitSystem = state.preferences.preferredUnitSystem
  const currencySymbol = preferredUnitSystem === 'imperial' ? '$' : '€'

  const numberFormatter = new Intl.NumberFormat(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const unitOptions = Object.entries(unitTypes).map(([id, type]) => ({
    value: id,
    label:
      type.name[preferredUnitSystem]?.[preferredLanguage] ??
      type.name.imperial?.en ??
      '',
    description:
      type.description[preferredUnitSystem]?.[preferredLanguage] ??
      type.description.imperial?.en ??
      '',
  }))

  const rowSelection = React.useMemo(() => {
    return zones.reduce((acc, row) => {
      acc[row.zoneId] = selectedZoneIndex === row.index

      return acc
    }, {} as MRT_RowSelectionState)
  }, [zones, selectedZoneIndex])

  const handleChangeAmendment = React.useCallback(
    (val: string | null) => {
      if (!val) {
        return
      }

      if (!rateMap) {
        return
      }
      setModifiedRateMap?.({
        ...rateMap,
        amendmentTypeId: val,
      })
    },
    [setModifiedRateMap, rateMap]
  )

  const handleChangeUnit = React.useCallback(
    (val: string | null) => {
      if (!val) {
        return
      }

      if (!rateMap) {
        return
      }

      setModifiedRateMap?.({
        ...rateMap,
        unitTypeId: val,
      })
    },
    [setModifiedRateMap, rateMap]
  )

  const handleChangeUnitCost = React.useCallback(
    (val: number | null) => {
      if (!rateMap) {
        return
      }

      setModifiedRateMap?.({
        ...rateMap,
        unitCost: val,
      })
    },
    [setModifiedRateMap, rateMap]
  )

  // const debouncedChangeUnitCost = useDebounce(handleChangeUnitCost, 500)

  const columns = React.useMemo<MRT_ColumnDef<ZoneRow>[]>(
    () => [
      {
        header: i18n.t(keys.rateMapMapColor),
        accessorKey: 'color',
        size: preferredLanguage === 'en' ? 1 : 1.3,
        minSize: preferredLanguage === 'en' ? 1 : 1.3,
        Cell: ({ row }) => {
          const isSelected = row.getIsSelected()
          return (
            <Circle
              selected={isSelected}
              circleColor={row.original.color}
              onClick={() => {
                if (row.original.isCustomZone) {
                  setSelectedZone(
                    isSelected ? undefined : row.original.index,
                    isSelected ? undefined : row.original.zoneId
                  )
                } else {
                  setSelectedZone(
                    isSelected ? undefined : row.original.stopIndex
                  )
                }
              }}
            />
          )
        },
      },
      {
        header: `${i18n.t(keys.rateMapRateOfApplication)} (${
          unitTypes[rateMap?.unitTypeId ?? '']?.name[preferredUnitSystem]?.[
            preferredLanguage
          ]
        })`,
        accessorKey: 'rateOfApplication',
        Cell: ({ row }) => (
          <RateMapNumberInput
            value={row.original.rateOfApplication}
            locale={locale}
            onChange={(value) => {
              if (!rateMap) {
                return
              }
              if (!row.original.isCustomZone) {
                const newValue = [...(rateMap?.amendmentZoneRates ?? [])]
                newValue[row.index] = Number.isNaN(value)
                  ? 0
                  : (value as number)
                setModifiedRateMap?.({
                  ...rateMap,
                  amendmentZoneRates: newValue,
                })
              } else {
                setModifiedRateMap?.({
                  ...rateMap,
                  RateMapCustomZoneRates: Object.values({
                    ...ratesByZoneId,
                    [row.original.zoneId]: {
                      rateMapId: rateMap.id,
                      customZoneId: row.original.zoneId,
                      rate: value ?? 0,
                    },
                  }),
                })
              }
            }}
          />
        ),
        size: 2,
        minSize: 2,
      },
      {
        header: preferredUnitSystem === 'metric' ? 'Hectares' : 'Acres',
        accessorKey: 'size',
        accessorFn: (row) => {
          return numberFormatter.format(row.size)
        },
        size: preferredUnitSystem === 'metric' ? 1.4 : 1,
        minSize: preferredUnitSystem === 'metric' ? 1.4 : 1,
      },
      {
        accessorKey: 'amount',
        header: i18n.t(keys.rateMapTotalAmountPerZone),
        accessorFn: (row) => {
          return `${numberFormatter.format(row.amount)} ${
            unitTypes[rateMap?.unitTypeId!]?.weightUnit[preferredUnitSystem]
          }`
        },
        size: 1.5,
        minSize: 1.5,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      unitTypes,
      rateMap,
      preferredLanguage,
      preferredUnitSystem,
      setSelectedZone,
      setModifiedRateMap,
    ]
  )

  const table = useMaterialReactTable({
    state: { rowSelection },
    layoutMode: 'grid',
    initialState: { density: 'compact' },
    columns,
    data: zones,
    mrtTheme: () => ({
      baseBackgroundColor: '#00000000', //change default background color
    }),

    getRowId: (originalRow) => originalRow.zoneId,

    muiTableBodyRowProps: {
      hover: false,

      sx: {
        backgroundColor: 'transparent',
        '&.Mui-selected': {
          borderRadius: '8px',
          '& td:after': {
            background: 'transparent !important',
          },
        },
      },
    },
    muiTableProps: {
      sx: {
        border: 'none',
        backgroundColor: 'transparent',
        caption: {
          captionSide: 'top',
        },
      },
    },

    muiTableHeadRowProps: {
      sx: {
        height: 'auto',
      },
    },

    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content-Wrapper': {
          whiteSpace: 'wrap !important',
          letterSpacing: '0.4px',
        },
        padding: theme.spacing(1),
        boxShadow: 'none',
        border: 'none',
        backgroundColor: 'transparent',
        textTransform: 'uppercase',
        fontWeight: 400,
        fontSize: '12px',
        letterSpacing: '0.4px',
        textWrap: 'wrap',
        wordBreak: 'break-word',
        whiteSpace: 'wrap',
        textOverflow: 'clip',
        verticalAlign: 'bottom',
        justifyContent: 'end',
      },
    },

    muiTableBodyProps: {
      sx: {
        boxShadow: 'none',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        backgroundColor: 'transparent',
        border: 'none',
        textWrap: 'wrap',
        wordBreak: 'break-word',
        whiteSpace: 'wrap',
        textOverflow: 'clip',
        fontWeight: 400,
        fontSize: '14px',
        letterSpacing: '0.25px',
      },
    },

    columnFilterDisplayMode: 'popover',
    enableBatchRowSelection: false,

    enableDensityToggle: false,
    enableFilters: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableTableFooter: false,

    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    localization:
      preferredLanguage === 'fr' ? MRT_Localization_FR : MRT_Localization_EN,
  })

  const handleAddAmendment = async (amendmentName: string) => {
    if (!organizationId) {
      return
    }

    await addAmendment(amendmentName, organizationId)
    refresh('amendmentTypes')
    setAddAmendmentDialogOpen(false)
  }

  const handleDeleteAmendment = async (amendmentId: string) => {
    if (!organizationId) {
      return
    }
    await deleteAmendment(amendmentId)
    refresh('amendmentTypes')
    setDeleteAmendmentDialogOpen(false)
  }

  const handleGetRatemapsByAmendment = async (amendmentId: string) => {
    if (!organizationId) {
      return 0
    }
    const count = await getRatemapsByAmendment(amendmentId)
    return count
  }

  React.useEffect(() => {
    if (!deleteAmendmentDialogOpen) {
      setAmendmentToDelete(null)
    }
  }, [deleteAmendmentDialogOpen])

  return (
    <>
      <Stack gap={1} sx={{ width: '100%', overflow: 'hidden' }}>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '19.2px',
              letterSpacing: '-3.5%',
            }}
          >
            {i18n.t(keys.rateMapSetUpAmendmentTitle)}
          </Typography>
          <Tooltip title={i18n.t(keys.rateMapSetUpAmendmentDescription)}>
            <Info sx={{ fontSize: '14px' }} />
          </Tooltip>
        </Stack>
        <Stack
          direction="row"
          gap={1}
          justifyContent="stretch"
          sx={{ width: '100%' }}
        >
          <RateMapSelect
            id="select-amendment-type"
            onChange={(ev, value) => handleChangeAmendment(value)}
            selectedValue={rateMap?.amendmentTypeId ?? null}
            label={i18n.t(keys.rateMapAmendmentTypeTitle)}
            options={Object.values(amendmentTypes).map((type) => ({
              value: type.id,
              label: type.name[preferredLanguage] ?? type.name.en ?? '',
              organizationId: type.organizationId,
            }))}
            onAddOption={() => setAddAmendmentDialogOpen(true)}
            onDeleteOption={async (value, label) => {
              const count = await handleGetRatemapsByAmendment(value)
              setAmendmentToDelete({
                id: value,
                label: label,
                affectedRateMaps: count,
              })
              setDeleteAmendmentDialogOpen(true)
            }}
          />
          <RateMapSelect
            id="select-unit-type"
            onChange={(ev, value) => handleChangeUnit(value)}
            selectedValue={rateMap?.unitTypeId ?? null}
            label={i18n.t(keys.rateMapUnitTypeTitle)}
            options={unitOptions}
          />
          {rateMap && (
            <RateMapNumberInput
              currency
              locale={locale}
              id="select-unit-cost"
              label={i18n.t(keys.rateMapUnitCostTitle)}
              value={rateMap.unitCost}
              onChange={(value) => handleChangeUnitCost(value)}
              startAdornment={currencySymbol}
            />
          )}
        </Stack>
        <Divider />
        <AsyncSelectorStatusOverlay requests={[]} isLoading={zonesLoading}>
          <TableContainer>
            <Table table={table} />
          </TableContainer>
        </AsyncSelectorStatusOverlay>
      </Stack>
      <AddAmendmentDialog
        open={addAmendmentDialogOpen}
        onCancel={() => setAddAmendmentDialogOpen(false)}
        onSave={handleAddAmendment}
      />
      <DeleteAmendmentDialog
        open={deleteAmendmentDialogOpen}
        onCancel={() => {
          setDeleteAmendmentDialogOpen(false)
        }}
        onDelete={handleDeleteAmendment}
        affectedRateMaps={amendmentToDelete?.affectedRateMaps ?? 0}
        amendmentId={amendmentToDelete?.id!}
        amendmentLabel={amendmentToDelete?.label!}
      />
    </>
  )
}
