import { RateMapContextState } from '../editor/RateMapContext'
import { createDeepEqualitySelector } from './createDeepEqualitySelector'

const EMPTY_PARCEL_IDS: string[] = []

const getRateMapBlockSelections = (state: RateMapContextState) =>
  state.rateMap?.RateMapBlockSelections

export const selectRateMapSelectedParcelIds = createDeepEqualitySelector(
  getRateMapBlockSelections,
  (blockSelections) => {
    const parcelIds = blockSelections
      ?.filter((block) => block.enabled)
      .map((block) => block.parcelId)

    if (!parcelIds || parcelIds.length === 0) {
      return EMPTY_PARCEL_IDS
    }
    return parcelIds
  }
)
