import './ProcGroupsTable.scss'

import * as React from 'react'
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'

import {
  Badge,
  Box,
  Chip,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  tabClasses,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/styles'

import { Column } from '../../../../admin/UI/Column'
import { Row } from '../../../../admin/UI/Row'
import { url, urls } from '../../../../appNavigation/urls'
import AsyncSelectorStatusOverlay from '../../../../AsyncSelector/AsyncSelectorStatusOverlay'
import * as api from '../../../../graphql/api'
import {
  DeliveryProcGroup,
  DeliveryProcGroupPackage,
} from '../../../../graphql/types'
import { useRedux } from '../../../../hooks/useRedux'
import EnhancedTableToolbar from '../../../../UI/EnhancedTable/EnhancedTableToolbar'
import { ExpandButton } from '../../../../UI/Expand/ExpandButton'
import LinkChild from '../../../../UI/LinkChild'
import { SelectBox, selectionOf } from '../../../../UI/SelectBox/SelectBox'
import { setDifference } from '../../../../util/setDifference'
import { unionSet } from '../../../../util/unionSet'
import {
  ListFlightsData,
  refreshListFlights,
  selectListFlights,
} from '../../Flight/selectListFlight'
import { addPackagesToProcGroup } from './addPackagesToProcGroup'
import {
  AddPackageToProcGroupDialog,
  DeliveryProcGroupPackages,
} from './AddPackageToProcGroupDialog'
import {
  DeliveryProcGroupWithStatuses,
  refreshGetDeliveryProcGroups,
  RowMaskTabs,
  selectAllDeliveryProcGroups,
  selectDeliveryProcGroupCounts,
  selectGetDeliveryProcGroups,
} from './deliveryProcGroupSelectors'

import { useIsLoading } from '../../../../hooks/useIsLoading'
import { selectMe } from '../../../../data/selectMe'
import { DeliveryProcGroupAssignee } from './DeliveryProcGroupAssignee'
import { postJson } from '../../../../vvapi/apiResource/createApiResource'
import { DeliveryProcGroupAssignDialog } from './DeliveryProcGroupAssignDialog'
import { TaskAlt, Unpublished, HighlightOff } from '@mui/icons-material'
import { MenuButton } from '../../../../UI/MenuButton'
import { ProcGroupJobs } from './ProcGroupJobs'

import {
  RequeueJobType,
  RequeueProcGroupsDialog,
} from './RequeueProcGroupsDialog'
import { queueDeliveryProcGroupJob } from './queueDeliveryProcGroupJob'

const TABS: RowMaskTabs[] = [
  'all',
  'row-mask',
  'canopy-mfp',
  'kato',
  'final-products',
  'complete',
  'error',
  'missing',
]

const BadgeTab = styled(Tab)(() => ({
  [`&.${tabClasses.root}`]: {
    padding: 8,
    minWidth: 50,
    minHeight: 20,
    paddingRight: 18,
    paddingBottom: 10,
  },
}))

const SmallIcon = styled(Icon)({
  fontSize: 16,
})

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: -3,
    right: -8,
    padding: '0 4px',
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  [`&.kato .MuiBadge-badge`]: {
    background: theme.palette.info.main,
  },
  [`&.missing .MuiBadge-badge`]: {
    background: theme.palette.warning.dark,
  },
  [`&.error .MuiBadge-badge`]: {
    background: theme.palette.error.dark,
  },
  [`&.complete .MuiBadge-badge`]: {
    background: theme.palette.success.dark,
  },
}))

export const ProcGroupsTable = () => {
  const [state] = useRedux()
  const theme = useTheme()

  const [selection, setSelection] = React.useState<Record<string, Set<string>>>(
    {}
  )

  const [assignDialogOpen, setAssignDialogOpen] = React.useState<boolean>(false)
  const [requeueDialogType, setRequeueDialogType] = React.useState<
    RequeueJobType | undefined
  >(undefined)

  const { load, isLoading } = useIsLoading<void>()
  const [addPackageProcGroup, setAddPackageProcGroup] = React.useState<
    | Pick<
        DeliveryProcGroup,
        | 'deliveryId'
        | 'flightDate'
        | 'procGroup'
        | 'Packages'
        | 'organizationId'
      >
    | undefined
  >(undefined)

  const { status = 'all', flight } = useParams<{
    status: RowMaskTabs
    flight: string
  }>()
  const { deliveryId } = state.router.params
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()

  const user = selectMe(state)

  const deliveryProcGroupsSelector = selectGetDeliveryProcGroups(state)
  const deliveryProcGroups = selectAllDeliveryProcGroups(
    (status ?? 'all') as RowMaskTabs,
    flight ?? 'all'
  )(state)
  const counts = selectDeliveryProcGroupCounts(state)
  const flightDateSelector = selectListFlights(state)

  const flightDates = flightDateSelector.data ?? ([] as ListFlightsData[])

  const getSelectedProcGroups = () => {
    return Object.values(selection).reduce((acc, curr) => {
      return [...acc, ...Array.from(curr)]
    }, [] as string[])
  }

  const selectedCount = getSelectedProcGroups().length

  const handleAssignProcGroup = async (
    procGroup: DeliveryProcGroup,
    assignee?: number
  ) => {
    if (!procGroup) {
      return
    }

    if (!assignee) {
      await postJson(
        `/api/v3/delivery-procgroup/${procGroup.deliveryId}/${procGroup.procGroup}/unassign`
      )
    } else {
      await postJson(
        `/api/v3/delivery-procgroup/${procGroup.deliveryId}/${procGroup.procGroup}/assign/${assignee}`
      )
    }

    handleRefresh()
  }

  const handleBulkAssignProcGroup = async (assignee?: number) => {
    const procGroups: string[] = []

    for (const groupSelections of Object.values(selection)) {
      groupSelections.forEach((procGroup) => {
        procGroups.push(procGroup)
      })
    }

    if (deliveryId === undefined) {
      return
    }

    if (!assignee) {
      await postJson(`/api/v3/delivery-procgroup/unassign-bulk`, {
        body: { deliveryId, procGroups },
      })
    } else {
      await postJson(`/api/v3/delivery-procgroup/assign-bulk/${assignee}`, {
        body: { deliveryId, procGroups },
      })
    }

    handleRefresh()
    setAssignDialogOpen(false)
  }

  const renderTable = (
    deliveryProcGroups: Record<string, DeliveryProcGroupWithStatuses[]>,
    type?: string
  ) => {
    const searchParams = new URLSearchParams(location.search)

    const expandedGroups = new Set(
      (searchParams.get('expanded-groups') ?? '').split('_')
    )

    const groups = Object.keys(deliveryProcGroups)

    const allSelection = getAllSelection()

    return groups.length > 0 ? (
      <Table className="table">
        <TableHead>
          <TableCell>
            <SelectBox
              selection={allSelection}
              onClick={(ev) => {
                ev.stopPropagation()
                toggleAllSelection()
              }}
            />
            Property
          </TableCell>
          <TableCell>Proc Group</TableCell>
          <TableCell>Packages</TableCell>
          <TableCell>Flight Date</TableCell>
          <TableCell colSpan={2}>Jobs</TableCell>
          <TableCell>Assignee</TableCell>
          <TableCell>Reviewed</TableCell>
          <TableCell />
        </TableHead>
        <TableBody>
          {groups.map((group) => {
            const groupProcGroups = deliveryProcGroups[group]
            const groupExpanded = expandedGroups.has(group)
            const groupSelection = selectionOf(
              selection[group]?.size ?? 0,
              groupProcGroups.length
            )

            return (
              <>
                <TableRow
                  key={group}
                  className="group"
                  onClick={() => handleExpandGroup(group)}
                >
                  <TableCell colSpan={9}>
                    <Row>
                      <SelectBox
                        selection={groupSelection}
                        onClick={(ev) => {
                          ev.stopPropagation()
                          toggleGroupSelection(group)
                        }}
                      />
                      <ExpandButton expanded={!!groupExpanded} />
                      <Typography>{group}</Typography>
                    </Row>
                  </TableCell>
                </TableRow>
                {groupExpanded &&
                  groupProcGroups?.map((deliveryProcGroup) => {
                    const {
                      deliveryId,
                      organizationId,
                      procGroup,
                      flightDate,
                      Packages,
                      commentAggregate: {
                        aggregate: { count: commentCount },
                      },
                      qaStatus,
                      UploadJob,
                    } = deliveryProcGroup

                    return (
                      <TableRow
                        key={`${deliveryId}_${procGroup}`}
                        className={
                          (Packages?.length ?? 0) === 0 ? 'missing' : undefined
                        }
                      >
                        <TableCell>
                          {commentCount > 0 ? (
                            <Box sx={{ padding: 2 }}>
                              <Tooltip title="Has Comments">
                                <Badge
                                  badgeContent={commentCount}
                                  color="warning"
                                >
                                  <Icon>chat</Icon>
                                </Badge>
                              </Tooltip>
                            </Box>
                          ) : null}
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            toggleProcGroupSelection(group, procGroup!)
                          }
                        >
                          <SelectBox
                            selection={
                              !!procGroup && selection[group]?.has(procGroup)
                                ? 'selected'
                                : 'unselected'
                            }
                          />
                          {procGroup}
                        </TableCell>
                        <TableCell>
                          {renderPackages(Packages ?? [], {
                            deliveryId: deliveryId!,
                            procGroup: procGroup!,
                            flightDate: flightDate!,
                            Packages: Packages!,
                            organizationId: organizationId!,
                          })}
                        </TableCell>
                        <TableCell>{flightDate}</TableCell>
                        <TableCell colSpan={2}>
                          <Stack sx={{ width: 60 }}>
                            <ProcGroupJobs
                              deliveryProcGroup={deliveryProcGroup}
                              compact
                            />
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <DeliveryProcGroupAssignee
                            procGroup={deliveryProcGroup}
                            onAssign={(assignee) =>
                              handleAssignProcGroup(deliveryProcGroup, assignee)
                            }
                            compact
                          />
                        </TableCell>
                        <TableCell>
                          <Stack direction="row" alignItems="center">
                            <Typography variant="caption">
                              {qaStatus !== 'not-reviewed'
                                ? `Reviewed ${qaStatus}`
                                : UploadJob?.Status?.status !== 'complete'
                                ? 'Pending Upload'
                                : 'Requires Review'}
                            </Typography>
                            {qaStatus === 'complete' ? (
                              <TaskAlt color="success" sx={{ mx: 0.3 }} />
                            ) : qaStatus === 'failed' ? (
                              <HighlightOff color="error" sx={{ mx: 0.3 }} />
                            ) : (
                              <Unpublished
                                color={
                                  UploadJob?.Status?.status !== 'complete'
                                    ? 'disabled'
                                    : undefined
                                }
                                sx={{ mx: 0.3 }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                        <TableCell>
                          {!!deliveryId && !!procGroup && (
                            <Tooltip title="Upload Proc Group">
                              <IconButton
                                onClick={() =>
                                  queueUpload({
                                    deliveryId,
                                    procGroup,
                                  })
                                }
                                size="large"
                              >
                                <Icon>upload</Icon>
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title="View Proc Group Details">
                            <LinkChild
                              to={url(
                                urls.deliveryProcGroup,
                                {
                                  deliveryId,
                                  procGroup,
                                },
                                {}
                              )}
                            >
                              <IconButton size="large">
                                <Icon>arrow_forward</Icon>
                              </IconButton>
                            </LinkChild>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </>
            )
          })}
        </TableBody>
      </Table>
    ) : (
      <Column
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Typography>
          {`This delivery does not currently have any ${
            type ? `${type} ` : ''
          }proc groups.`}
        </Typography>
      </Column>
    )
  }

  const renderPackages = (
    packages: DeliveryProcGroupPackage[],
    deliveryProcGroup: Pick<
      DeliveryProcGroup,
      'deliveryId' | 'flightDate' | 'procGroup' | 'Packages' | 'organizationId'
    >
  ) => {
    if (!packages || packages.length === 0) {
      return (
        <Chip
          sx={{ color: theme.palette.text.primary }}
          color="warning"
          onDelete={
            user?.roles.includes('admin')
              ? () => setAddPackageProcGroup(deliveryProcGroup)
              : undefined
          }
          deleteIcon={
            <Tooltip title="Add Package(s)">
              <Icon sx={{ color: theme.palette.text.primary }}>add</Icon>
            </Tooltip>
          }
          label="No Packages"
        />
      )
    }

    return (
      <Stack direction={{ sm: 'column', md: 'column', lg: 'row' }} spacing={1}>
        {(packages as DeliveryProcGroupPackage[])
          .filter((p) => p !== undefined)
          .map((p) => (
            <Chip label={p!.package} />
          ))}
      </Stack>
    )
  }

  const getAllSelection = () => {
    const allProcGroupsCount = Object.values(deliveryProcGroups).reduce(
      (acc, curr) => {
        return acc + curr.length
      },
      0
    )

    const selectedProcGroupsCount = getSelectedProcGroups().length

    return selectionOf(selectedProcGroupsCount, allProcGroupsCount)
  }

  const toggleAllSelection = () => {
    const selectedCount = getSelectedProcGroups().length
    if (selectedCount > 0) {
      setSelection({})
    } else {
      setSelection(
        Object.entries(deliveryProcGroups).reduce((acc, [key, value]) => {
          return {
            ...acc,
            [key]: new Set(
              value.filter((pg) => !!pg.procGroup).map((pg) => pg.procGroup!)
            ),
          }
        }, {} as Record<string, Set<string>>)
      )
    }
  }

  const toggleGroupSelection = (group: string) => {
    const groupSet = selection[group] || new Set()

    if (groupSet.size > 0) {
      setSelection({
        ...selection,
        [group]: new Set(),
      })
    } else {
      setSelection({
        ...selection,
        [group]: new Set(
          deliveryProcGroups[group].map((dpg) => dpg.procGroup!) ?? []
        ),
      })
    }
  }

  const toggleProcGroupSelection = (group: string, procGroup: string) => {
    const groupSet = selection[group] || new Set()

    if (groupSet.has(procGroup)) {
      setSelection({
        ...selection,
        [group]: setDifference(groupSet, new Set([procGroup])),
      })
    } else {
      setSelection({
        ...selection,
        [group]: unionSet(groupSet, new Set([procGroup])),
      })
    }
  }

  const queueReprocessMaskSelected = async (requeueDownstream: boolean) => {
    await load(async () => {
      if (deliveryId === undefined) {
        return
      }
      const selectedProcGroups = getSelectedProcGroups()

      for (const procGroup of selectedProcGroups) {
        await queueDeliveryProcGroupJob(
          {
            deliveryId,
            procGroup,
          },
          'row-mask',
          requeueDownstream
        )
      }

      refresh()
    })
  }

  const queueReprocessCanopyMFPSelected = async (
    requeueDownstream: boolean
  ) => {
    await load(async () => {
      if (deliveryId === undefined) {
        return
      }
      const selectedProcGroups = getSelectedProcGroups()

      for (const procGroup of selectedProcGroups) {
        await queueDeliveryProcGroupJob(
          {
            deliveryId,
            procGroup,
          },
          'canopy-mfp',
          requeueDownstream
        )
      }

      refresh()
    })
  }

  const queueReferenceSetupSelected = async (requeueDownstream: boolean) => {
    await load(async () => {
      if (deliveryId === undefined) {
        return
      }
      const selectedProcGroups = getSelectedProcGroups()

      for (const procGroup of selectedProcGroups) {
        await queueDeliveryProcGroupJob(
          {
            deliveryId,
            procGroup,
          },
          'reference-setup',
          requeueDownstream
        )
      }

      refresh()
    })
  }

  const queueReferenceUpdateSelected = async (requeueDownstream: boolean) => {
    await load(async () => {
      if (deliveryId === undefined) {
        return
      }
      const selectedProcGroups = getSelectedProcGroups()

      for (const procGroup of selectedProcGroups) {
        await queueDeliveryProcGroupJob(
          {
            deliveryId,
            procGroup,
          },
          'reference-update',
          requeueDownstream
        )
      }

      refresh()
    })
  }

  const queueReprocessFinalProductsSelected = async () => {
    await load(async () => {
      if (deliveryId === undefined) {
        return
      }
      const selectedProcGroups = getSelectedProcGroups()

      for (const procGroup of selectedProcGroups) {
        await queueDeliveryProcGroupJob(
          {
            deliveryId,
            procGroup,
          },
          'final-products'
        )
      }

      refresh()
    })
  }

  const downloadMissingProcGroups = async () => {
    await load(async () => {
      if (deliveryId === undefined) {
        return
      }

      await api.delivery.downloadMissingGeoJSON({ deliveryId })
    })
  }

  const queueUploadSelected = async () => {
    await load(async () => {
      if (deliveryId === undefined) {
        return
      }
      const selectedProcGroups = getSelectedProcGroups()

      for (const procGroup of selectedProcGroups) {
        await queueDeliveryProcGroupJob({ deliveryId, procGroup }, 'upload')
      }

      refresh()
    })
  }

  const queueUpload = async (deliveryProcGroup: {
    deliveryId: string
    procGroup: string
  }) => {
    await load(async () => {
      await queueDeliveryProcGroupJob(deliveryProcGroup, 'upload')

      refresh()
    })
  }

  const setTab = (status: string) => {
    history.replace({
      pathname: url({ url: match.path }, { ...match.params, status }),
      search: location.search,
    })
  }

  const handleFlightChange = (ev: SelectChangeEvent) => {
    const flight = ev.target.value

    history.replace({
      pathname: url({ url: match.path }, { ...match.params, flight }),
      search: location.search,
    })
  }

  const handleExpandGroup = (group: string) => {
    const searchParams = new URLSearchParams(location.search)
    const selectedGroupSearch = searchParams.get('expanded-groups')

    const expandedGroups = new Set(selectedGroupSearch?.split('_') ?? [])

    const newExpandedGroups = new Set(expandedGroups)
    if (newExpandedGroups.has(group)) {
      newExpandedGroups.delete(group)
    } else {
      newExpandedGroups.add(group)
    }

    if (newExpandedGroups.size === 0) {
      searchParams.delete('expanded-groups')
    } else {
      searchParams.set(
        'expanded-groups',
        Array.from(newExpandedGroups).join('_')
      )
    }

    history.replace({
      search: searchParams.toString(),
    })
  }

  const handleRefresh = () => {
    refresh()
  }

  const refresh = () => {
    refreshListFlights()
    refreshGetDeliveryProcGroups()
  }

  const handleCancelAddProcGroupPackage = () => {
    setAddPackageProcGroup(undefined)
  }

  const handleSubmitAddProcGroupPackage = async (
    deliveryProcGroupPackages: DeliveryProcGroupPackages
  ) => {
    await addPackagesToProcGroup(deliveryProcGroupPackages)
    setAddPackageProcGroup(undefined)
    refresh()
  }

  const handleCancelProcGroupAssignDialog = () => {
    setAssignDialogOpen(false)
  }

  const handleAssignSelected = async () => {
    setAssignDialogOpen(true)
  }

  const handleSubmitRequeueDialog = async (
    jobType: RequeueJobType,
    requeueDownstream = false
  ) => {
    switch (jobType) {
      case 'Row Mask':
        await queueReprocessMaskSelected(requeueDownstream)
        break
      case 'Canopy MFP':
        await queueReprocessCanopyMFPSelected(requeueDownstream)
        break
      case 'Reference Setup':
        await queueReferenceSetupSelected(requeueDownstream)
        break
      case 'Reference Update':
        await queueReferenceUpdateSelected(requeueDownstream)
        break
      case 'Final Products':
        await queueReprocessFinalProductsSelected()
        break
    }

    setRequeueDialogType(undefined)
  }

  return (
    <>
      <RequeueProcGroupsDialog
        open={Boolean(requeueDialogType)}
        jobType={requeueDialogType}
        procGroupCount={selectedCount}
        onCancel={() => setRequeueDialogType(undefined)}
        onSubmit={handleSubmitRequeueDialog}
      />
      <DeliveryProcGroupAssignDialog
        open={assignDialogOpen}
        onCancel={handleCancelProcGroupAssignDialog}
        onAssign={handleBulkAssignProcGroup}
      />
      <Paper className="Paper ProcGroupsTable">
        <EnhancedTableToolbar
          style={{ paddingLeft: 0, paddingRight: 0 }}
          title={`Proc Groups`}
          numSelected={selectedCount}
          onClickRefresh={handleRefresh}
          actions={() => (
            <Stack direction="row" spacing={1}>
              <Tooltip title="Download Missing Proc Groups GeoJSON">
                <IconButton
                  disabled={counts['missing'] === 0}
                  onClick={() => downloadMissingProcGroups()}
                  size="large"
                >
                  <Badge
                    badgeContent={
                      <Icon fontSize="small" color="action">
                        download
                      </Icon>
                    }
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  >
                    <Icon color="action">hide_image</Icon>
                  </Badge>
                </IconButton>
              </Tooltip>

              <MenuButton
                sx={{ p: 1 }}
                size="small"
                title="actions"
                variant="outlined"
                disabled={selectedCount < 1}
                color="inherit"
              >
                <MenuItem
                  disabled={selectedCount < 1}
                  onClick={() => setRequeueDialogType('Row Mask')}
                >
                  <ListItemIcon>
                    <Badge
                      badgeContent={<SmallIcon>replay_circle_filled</SmallIcon>}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                    >
                      <SmallIcon>blur_on</SmallIcon>
                    </Badge>
                  </ListItemIcon>
                  <ListItemText>Reprocess Mask</ListItemText>
                </MenuItem>
                <MenuItem
                  disabled={selectedCount < 1}
                  onClick={() => setRequeueDialogType('Canopy MFP')}
                >
                  <ListItemIcon>
                    <Badge
                      badgeContent={<SmallIcon>replay_circle_filled</SmallIcon>}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                    >
                      <SmallIcon>texture</SmallIcon>
                    </Badge>
                  </ListItemIcon>
                  <ListItemText>Reprocess Canopy MFP</ListItemText>
                </MenuItem>
                <MenuItem
                  disabled={selectedCount < 1}
                  onClick={() => setRequeueDialogType('Reference Setup')}
                >
                  <ListItemIcon>
                    <Badge
                      badgeContent={
                        <SmallIcon color="action">
                          replay_circle_filled
                        </SmallIcon>
                      }
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                    >
                      <SmallIcon color="action">folder_zip</SmallIcon>
                    </Badge>
                  </ListItemIcon>
                  <ListItemText>Requeue Reference Setup</ListItemText>
                </MenuItem>
                <MenuItem
                  disabled={selectedCount < 1}
                  onClick={() => setRequeueDialogType('Reference Update')}
                >
                  <ListItemIcon>
                    <Badge
                      badgeContent={
                        <SmallIcon color="action">
                          replay_circle_filled
                        </SmallIcon>
                      }
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                    >
                      <SmallIcon color="action">play_arrow</SmallIcon>
                    </Badge>
                  </ListItemIcon>
                  <ListItemText>Requeue Reference Update</ListItemText>
                </MenuItem>
                <MenuItem
                  disabled={selectedCount < 1}
                  onClick={() => setRequeueDialogType('Final Products')}
                >
                  <ListItemIcon>
                    <Badge
                      badgeContent={
                        <SmallIcon color="action">
                          replay_circle_filled
                        </SmallIcon>
                      }
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                    >
                      <SmallIcon color="action">category</SmallIcon>
                    </Badge>
                  </ListItemIcon>
                  <ListItemText>Reprocess Final Products</ListItemText>
                </MenuItem>
                <MenuItem
                  disabled={selectedCount < 1}
                  onClick={() => queueUploadSelected()}
                >
                  <ListItemIcon>
                    <SmallIcon>upload</SmallIcon>
                  </ListItemIcon>
                  <ListItemText>Upload Selected</ListItemText>
                </MenuItem>
                <MenuItem
                  disabled={selectedCount < 1}
                  onClick={handleAssignSelected}
                >
                  <ListItemIcon>
                    <SmallIcon>person_add</SmallIcon>
                  </ListItemIcon>
                  <ListItemText>Assign Selected Proc Groups</ListItemText>
                </MenuItem>
              </MenuButton>
            </Stack>
          )}
        />
        <AsyncSelectorStatusOverlay
          overlayStyle={{ paddingTop: 25, alignItems: 'flex-start' }}
          style={{ minHeight: 300 }}
          requests={deliveryProcGroupsSelector}
          isLoading={isLoading}
        >
          <AddPackageToProcGroupDialog
            open={!!addPackageProcGroup}
            deliveryProcGroup={addPackageProcGroup!}
            onCancel={handleCancelAddProcGroupPackage}
            onSubmit={handleSubmitAddProcGroupPackage}
          />
          <Column>
            <Column style={{ width: 250, alignSelf: 'flex-end' }}>
              <Typography>Flight Date:</Typography>
              <Select
                onChange={handleFlightChange}
                value={flight}
                style={{ width: '100%' }}
              >
                <MenuItem key="all" value="all">
                  All Flights
                </MenuItem>
                {flightDates?.map(({ date }) => (
                  <MenuItem key={date} value={date}>
                    {date}
                  </MenuItem>
                ))}
              </Select>
            </Column>

            <Tabs value={status}>
              {TABS.map((tab) => (
                <BadgeTab
                  label={
                    <StyledBadge
                      className={tab}
                      badgeContent={counts[tab]}
                      color="info"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                    >
                      {tab}
                    </StyledBadge>
                  }
                  key={tab}
                  value={tab}
                  onClick={() => setTab(tab)}
                />
              ))}
            </Tabs>
            {renderTable(deliveryProcGroups, status)}
          </Column>
        </AsyncSelectorStatusOverlay>
      </Paper>
    </>
  )
}
