import { createSelector } from 'reselect'
import { RootStore } from '../redux/types'
import { selectAvailableProductIds } from './selectAvailableProductIds'
import { selectMapLayerDefs } from './selectListMapSourceDefs'

const getSelectedProductIds = (state: RootStore) => {
  return state.userSelection.selectedProductIds
}

export const selectSelectedProductIds = createSelector(
  [getSelectedProductIds, selectAvailableProductIds, selectMapLayerDefs],
  (selectedProductIds, availableProductIds, mapLayerDefs) => {
    return selectedProductIds
      ? selectedProductIds.filter(
          (id) =>
            availableProductIds.includes(id) ||
            availableProductIds.includes(id.replace('-block-by-block', ''))
        )
      : mapLayerDefs
          .filter(
            ({ id, mapSourceDef: { type } }) =>
              type !== 'raster-background' && availableProductIds.includes(id)
          )
          .sort((a, b) => a.order - b.order)
          .map(({ id }) => id)
          .slice(0, 1)
  }
)
