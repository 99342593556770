import * as React from 'react'
import {
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  styled,
  Divider,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from '@mui/material'
import { Warning } from '@mui/icons-material'
import i18n, { keys } from '../../../../../i18n'

interface Props {
  selectedZoneType: string | null
  setSelectedZoneType: (value: string | null) => void
  snappingEnabled: boolean
  setSnappingEnabled: (value: boolean) => void
  limitToBounds: boolean
  setLimitToBounds: (value: boolean) => void
  hasInvalidGeometry: boolean
}

const CustomZoneOptionContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  padding: 0,
  borderRadius: '8px',
  backgroundColor: theme.palette.background.default,
  maxWidth: '550px',
  minWidth: '500px',
}))

const WarningContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 1,
  pointerEvents: 'auto',
}))

const CustomZoneForm = styled(FormControl)(({ theme }) => ({
  pointerEvents: 'auto',
}))

const OptionDivider = styled(Divider)(({ theme }) => ({
  margin: '0',
  padding: '0',
  alignSelf: 'stretch',
}))

const CustomZoneMainContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  padding: 0,
  pointerEvents: 'none',
}))

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
})

export const CustomZoneOptions: React.FC<Props> = ({
  selectedZoneType,
  setSelectedZoneType,
  snappingEnabled,
  setSnappingEnabled,
  limitToBounds,
  setLimitToBounds,
  hasInvalidGeometry,
}) => {
  return (
    <CustomZoneMainContainer direction="row">
      <CustomZoneOptionContainer sx={{ paddingLeft: 1 }}>
        <CustomZoneForm>
          <RadioGroup
            sx={{ flexWrap: 'nowrap' }}
            value={selectedZoneType}
            onChange={(e) => setSelectedZoneType(e.target.value)}
            row
          >
            <Tooltip title={i18n.t(keys.rateMapRectangleTooltip)}>
              <FormControlLabel
                value="rectangle"
                control={<Radio />}
                label={i18n.t(keys.rateMapRectangle)}
              />
            </Tooltip>
            <Tooltip title={i18n.t(keys.rateMapPolygonTooltip)}>
              <FormControlLabel
                value="polygon"
                control={<Radio />}
                label={i18n.t(keys.rateMapPolygon)}
              />
            </Tooltip>
          </RadioGroup>
        </CustomZoneForm>
        {hasInvalidGeometry ? (
          <WarningContainer sx={{ marginRight: 1 }}>
            <Tooltip title={i18n.t(keys.rateMapInvalidGeometryWarning)}>
              <Warning color="warning" />
            </Tooltip>
          </WarningContainer>
        ) : null}
        <OptionDivider
          flexItem
          orientation="vertical"
          sx={{ marginRight: 2 }}
        />
        <CustomZoneForm
          sx={{
            flexDirection: 'row',
          }}
        >
          <CustomWidthTooltip title={i18n.t(keys.rateMapSnappingTooltip)}>
            <FormControlLabel
              label={i18n.t(keys.rateMapSnapping)}
              control={
                <Switch
                  checked={snappingEnabled}
                  onChange={() => setSnappingEnabled(!snappingEnabled)}
                />
              }
            />
          </CustomWidthTooltip>
          <CustomWidthTooltip title={i18n.t(keys.rateMapLimitToBoundsTooltip)}>
            <FormControlLabel
              label={i18n.t(keys.rateMapLimitToBounds)}
              control={
                <Switch
                  checked={limitToBounds}
                  onChange={() => setLimitToBounds(!limitToBounds)}
                />
              }
            />
          </CustomWidthTooltip>
        </CustomZoneForm>
      </CustomZoneOptionContainer>
    </CustomZoneMainContainer>
  )
}
