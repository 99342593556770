import { PureComponent } from 'react'

import { setPosition } from '../../postgis/actions'
import { connect } from '../../redux/connect'
import { AppDispatchProps } from '../../redux/types'
import withMap, { WithMap } from '../withMap'
import { BoundsData } from '../../postgis/types'
function makeSquare(center: mapboxgl.LngLat, distance: number) {
  const halfDistance = distance / 2

  return [
    // west, south
    [center.lng - halfDistance, center.lat - halfDistance],
    // east, north
    [center.lng + halfDistance, center.lat + halfDistance],
  ]
}

class MapMoveHandler extends PureComponent<WithMap & AppDispatchProps> {
  componentDidMount() {
    this.props.map.on('moveend', this.handleMapMoveEvent)
  }

  componentWillUnmount() {
    this.props.map.off('moveend', this.handleMapMoveEvent)
  }

  render() {
    return null
  }

  handleMapMoveEvent = () => {
    // Make a square around the center of the bounds using the smallest
    // dimension. This is to restore the map viewport to something similar
    // regardless of the devices aspect ratio.
    const bounds = this.props.map.getBounds()
    const dX = Math.abs(bounds.getEast() - bounds.getWest())
    const dY = Math.abs(bounds.getSouth() - bounds.getNorth())
    const center = bounds.getCenter()
    const squareBounds =
      dX < dY ? makeSquare(center, dX) : makeSquare(center, dY)

    const actualBounds = [
      [bounds.getWest(), bounds.getSouth()],
      [bounds.getEast(), bounds.getNorth()],
    ]

    const boundsData: BoundsData = {
      squareBounds,
      actualBounds,
    }

    this.props.dispatch(setPosition(boundsData))
  }
}

export default connect<{}, {}, AppDispatchProps>()(withMap(MapMoveHandler))
