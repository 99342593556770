import { ChartOptions } from 'chart.js'
import { createSelector } from 'reselect'
import { selectHistoricParcelData } from './selectHistoricParcelData'
import { selectHistoricalCalculation } from './selectHistoricalCalculation'
import { createHistoricChartOptions } from '../ChartJs/chartOptions'
import { selectConvertedSelectedColorProfiles } from './stats'
import { selectSelectedLegendProductId } from '../../data/selectSelectedLegendProductId'
import { selectActiveProductVisualization } from './selectStatsChartData'
import { selectPreferredLanguage } from '../../data/selectPreferredLanguage'
import {
  selectHistoricalCalculationProperties,
  selectHistoricalCalculationType,
} from './selectHistoricalTabCalculationProperties'

interface HistoricChartData {
  chartData: {
    labels: string[]
    datasets: {
      backgroundColor: string[]
      data: {
        y: number
        yMin?: number
        yMax?: number
        showWarningIndicator?: boolean
      }[]
    }[]
  }
  chartOptions?:
    | ChartOptions
    | { errorBarColor: string; errorBarWhiskerColor: string }
}

export const selectHistoricalChartData = createSelector(
  [
    selectHistoricParcelData,
    selectHistoricalCalculation,
    selectHistoricalCalculationType,
    selectHistoricalCalculationProperties,
    (state) => state.preferences.statsWindowMinimized,
    selectSelectedLegendProductId,
    selectConvertedSelectedColorProfiles,
    selectActiveProductVisualization,
    selectPreferredLanguage,
  ],
  (
    { data: deliveryParcelMetaData },
    historicalCalculation,
    historicalCalculationType,
    historicalCalculationProperties,
    statsWindowMinimized,
    productId,
    selectedColorProfilesByProductId,
    visualization,
    preferredLanguage
  ): ((theme: any, isMobile: boolean) => HistoricChartData) => {
    if (!deliveryParcelMetaData) {
      return () => ({
        chartData: {
          labels: [],
          datasets: [],
        },
        chartOptions: undefined,
      })
    }

    const selectedColorProfiles = selectedColorProfilesByProductId[productId]
    const colorProfile = selectedColorProfiles?.[visualization!]

    const { labels, data } = historicalCalculation(deliveryParcelMetaData)

    const colours = data.map((data) => {
      return (
        [...(colorProfile?.dataStops ?? [])]
          .reverse()
          .find((d) => (d[0] as number) <= data.y)?.[1] ??
        colorProfile?.dataStops[0][1] ??
        ''
      )
    })

    return (theme: any, isMobile: boolean) => {
      const chartOptions = createHistoricChartOptions({
        theme,
        statsWindowMinimized: statsWindowMinimized || isMobile,
        preferredLanguage,
        historicalCalculationType,
        historicalCalculationProperties,
      })

      return {
        chartData: {
          labels,
          datasets: [
            {
              backgroundColor: colours ?? [],
              data,
            },
          ],
        },
        chartOptions,
      }
    }
  }
)
