import * as React from 'react'
import { PureComponent } from 'react'
import { default as Button } from '@mui/material/Button'
import { Grid } from '@mui/material'
import { withStyles, WithStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import i18n, { keys } from '../../i18n'
import MapPopup from '../../map/controls/MapPopup'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { setFocusedSoilLayer } from '../actions'

class FocusedSoilPopup extends PureComponent<
  ReduxProps & AppDispatchProps & WithStyles<typeof styles>
> {
  render() {
    const { focusedSoilLayer, classes } = this.props

    let content = ''
    let coordinates: number[] = []
    let url = null
    if (focusedSoilLayer) {
      content = `${focusedSoilLayer.properties.vv_desc} ${
        focusedSoilLayer?.properties?.vv_slope ?? ''
      }`
      url = focusedSoilLayer.properties.URL
      content = content.replace('percent', '%')

      coordinates = focusedSoilLayer.geometry.coordinates
    }

    return (
      <MapPopup
        open={!!content && coordinates.length > 0}
        coordinates={coordinates}
        onClose={this.onClose}
      >
        <Grid
          className="FocusedSoilPopup"
          container
          direction="column"
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            className={this.props.classes.listItem}
            style={{ display: 'grid', height: '49px' }}
          >
            <span style={{ fontSize: '1rem', textAlign: 'center' }}>
              {i18n.t(keys.soilLayer.soilInformation)}
            </span>
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.listItem}>
              <Grid item xs={12}>
                <span style={{ textAlign: 'left' }}>{content}</span>
              </Grid>
            </Grid>
          </Grid>
          {url && (
            <Grid item xs={12}>
              <Grid container className={classes.listItem}>
                <Grid item xs={12}>
                  <a
                    style={{
                      textOverflow: 'initial',
                      overflow: 'hidden',
                      wordBreak: 'break-all',
                    }}
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {url}
                  </a>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            style={{
              textAlign: 'center',
              paddingTop: 16,
            }}
          >
            <Button className={classes.button} onClick={this.handleClose}>
              {i18n.t(keys.generic.close)}
            </Button>
          </Grid>
        </Grid>
      </MapPopup>
    )
  }

  handleClose = () => {
    this.props.dispatch(setFocusedSoilLayer(null))
  }

  onClose = () => {
    return false
  }
}

const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      borderBottom: `1px solid ${theme.palette.background.paper}`,
      padding: 10,
      display: 'flex',
    },
    button: {
      color: `${theme.palette.text.primary}`,
    },
  })

const mapState = (state: RootStore) => ({
  focusedSoilLayer: state.postgis.focusedSoilLayer,
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withStyles(styles)(FocusedSoilPopup)
)
