import {
  Feature,
  multiPolygon,
  MultiPolygon,
  polygon,
  Polygon,
} from '@turf/helpers'
import * as polyclip from 'polyclip-ts'

const intersection = (...features: Feature<Polygon | MultiPolygon>[]) => {
  if (features.length < 2) {
    throw new Error('Must provide at least two features to intersect')
  }
  const intersection = polyclip.intersection(
    features[0].geometry.coordinates as polyclip.Geom,
    ...features.slice(1).map((f) => f.geometry.coordinates as polyclip.Geom)
  )

  if (!intersection) {
    return null
  }
  if (intersection.length === 1) {
    return polygon(intersection[0])
  }
  return multiPolygon(intersection)
}

const union = (...features: Feature<Polygon | MultiPolygon>[]) => {
  if (features.length < 2) {
    throw new Error('Must provide at least two features to union')
  }
  const union = polyclip.union(
    features[0].geometry.coordinates as polyclip.Geom,
    ...features.slice(1).map((f) => f.geometry.coordinates as polyclip.Geom)
  )

  if (!union) {
    return null
  }
  if (union.length === 1) {
    return polygon(union[0])
  }
  return multiPolygon(union)
}

const difference = (...features: Feature<Polygon | MultiPolygon>[]) => {
  if (features.length < 2) {
    throw new Error('Must provide at least two feature to subtract')
  }
  const difference = polyclip.difference(
    features[0].geometry.coordinates as polyclip.Geom,
    ...features.slice(1).map((f) => f.geometry.coordinates as polyclip.Geom)
  )

  if (!difference) {
    return null
  }
  if (difference.length === 1) {
    return polygon(difference[0])
  }
  return multiPolygon(difference)
}

export { intersection, union, difference }
