import { createSelector } from 'reselect'
import { selectRateMapClasses } from './selectRateMapClasses'
import { selectRateMapGroupBy } from './selectRateMapGroupBy'
import { getSelectedZoneIndex } from './getSelectedZoneIndex'
import { selectRateMapMode } from './selectRateMapMode'
import { pureVineZonesLayerID, vigorZonesLayerID } from '../constants'
import { VisualizationMode } from '../../../ProductSettings/store/types'
import { selectRateMapAdditionalLayers } from './selectRateMapAdditionalLayers'
import { selectRateMapLayerDefsById } from './selectRateMapLayerDefsById'
import { selectCustomZones } from './selectCustomZones'

export const selectRateMapProductSettings = createSelector(
  selectRateMapClasses,
  selectRateMapGroupBy,
  selectRateMapMode,
  selectRateMapAdditionalLayers,
  selectRateMapLayerDefsById,
  getSelectedZoneIndex,
  selectCustomZones,
  (
    rateMapClasses,
    rateMapGroupBy,
    rateMapMode,
    additionalLayers,
    mapLayerDefsById,
    selectedZoneIndex,
    customZones
  ) => {
    const additionalProducts =
      additionalLayers?.reduce((acc, layer) => {
        if (
          mapLayerDefsById[layer.layerId].mapSourceDef.type === 'raster' ||
          mapLayerDefsById[layer.layerId].mapSourceDef.type ===
            'raster-background'
        ) {
          acc[layer.layerId] = {
            opacity: 0.5,
          }
        }

        return acc
      }, {} as Record<string, any>) ?? {}
    const visibility = (
      (customZones?.length ?? 0) > 0 && selectedZoneIndex ? 'none' : 'visible'
    ) as typeof additionalProducts['visibility']
    return {
      [pureVineZonesLayerID]: {
        visualization: rateMapMode as VisualizationMode | undefined,
        groupBy: rateMapGroupBy as 'value-ranges' | 'equal-areas' | undefined,
        numberOfGroups: rateMapClasses,
        isolatedStops:
          selectedZoneIndex !== undefined ? [selectedZoneIndex] : [],
        visibility,
      },
      [vigorZonesLayerID]: {
        visualization: rateMapMode as VisualizationMode | undefined,
        groupBy: rateMapGroupBy as 'value-ranges' | 'equal-areas' | undefined,
        numberOfGroups: rateMapClasses,
        isolatedStops:
          selectedZoneIndex !== undefined ? [selectedZoneIndex] : [],
        visibility,
      },
      ...additionalProducts,
    }
  }
)
