import { ChartOptions, ChartTypeRegistry, TooltipItem } from 'chart.js'
import {
  IHistoricalCalculationTypeProps,
  WeightedAverageWithError,
} from '../../admin/MapLayerDef/types/HistoricalChartCalculationType'
import i18n, { keys } from '../../i18n'
import { formatNum } from '../../util/formatNumber'
import { LanguageSelection } from '../../app/types'
import { shiftByDataPoint, updateChartMinMax } from './util/pluginHelpers'

interface ChartData {
  label: string
  color?: string
  size: number
}

interface ConfiguredStatData {
  percents: number[]
  chartData: {
    labels: string[]
    datasets: {
      backgroundColor: string[]
      data: number[]
    }[]
  }
}

interface HistoricChartParams {
  theme: any
  statsWindowMinimized: boolean
  preferredLanguage: LanguageSelection
  historicalCalculationType: string | null
  historicalCalculationProperties: IHistoricalCalculationTypeProps
}

function createHistoricChartOptions({
  theme,
  statsWindowMinimized,
  preferredLanguage,
  historicalCalculationType,
  historicalCalculationProperties,
}: HistoricChartParams):
  | ChartOptions
  | {
      errorBarColor: string
      errorBarWhiskerColor: string
      errorBarWhiskerRatio: number
    } {
  const { xAxisLabel, yAxisLabel, getTooltipTitle, getTooltipLabel } =
    getHistoricLabels(
      preferredLanguage,
      historicalCalculationType,
      historicalCalculationProperties
    )

  return {
    errorBarColor: theme.palette.text.primary,
    errorBarWhiskerColor: theme.palette.text.primary,
    errorBarWhiskerRatio: 0.1,
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 32,
        right: 16,
      },
    },
    color: theme.palette.text.primary,
    borderColor: theme.palette.divider,
    datasets: {
      barWithErrorBars: {
        minBarLength: 5,
        barPercentage: 0.9,
        categoryPercentage: 1.0,
      },
    },
    transitions: {
      resize: {
        animation: {
          duration: 0,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: theme.palette.text.primary,
          callback(label) {
            return formatNum(Number(label))
          },
        },
        title: {
          display: true,
          color: theme.palette.text.primary,
          text: yAxisLabel,
        },
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          color: theme.palette.divider,
          display: false,
        },
        ticks: {
          display: false, // see historic chart plugin.
        },
        title: {
          display: false,
          color: theme.palette.text.primary,
          text: xAxisLabel,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
        clamp: true,
        color: theme.palette.text.primary,
        anchor: 'end',
        align: 'top',
      },
      tooltip: {
        animation: {
          duration: 0,
        },
        callbacks: {
          title: (ctx) => getTooltipTitle(ctx),
          label: (ctx) => getTooltipLabel(ctx),
        },
      },
      historicChart: {
        barIndex: 0,
        bloomRadius: 25,
        bloomIntensity: 0.7,
        fontSize: 12,
        theme: theme,
        focusLabel: () => i18n.t(keys.stats.currentSelection),
        scrollingLabelFormat: (label) => i18n.toDateShorterName(label, true),
        onArrowClicked: (chart, direction, span) => {
          const { min, max } = shiftByDataPoint(chart, direction, span)
          updateChartMinMax(chart, min, max)
          chart.update()
        },
      },
    },
  }
}

interface IHistoricChartLabels {
  xAxisLabel: string
  yAxisLabel: string
  getTooltipTitle: (ctx: TooltipItem<keyof ChartTypeRegistry>[]) => string[]
  getTooltipLabel: (ctx: TooltipItem<keyof ChartTypeRegistry>) => string
}
const getHistoricLabels = (
  preferredLanguage: LanguageSelection,
  historicalCalculationType: string | null,
  historicalCalculationProperties: IHistoricalCalculationTypeProps
): IHistoricChartLabels => {
  switch (historicalCalculationType) {
    case 'weightedAvgWithError':
      const { xAxisLabel, yAxisLabel, meanLabel, stdevLabel } =
        historicalCalculationProperties as WeightedAverageWithError
      return {
        xAxisLabel: xAxisLabel[preferredLanguage] ?? '?',
        yAxisLabel: yAxisLabel[preferredLanguage] ?? '?',
        getTooltipTitle: (ctx) => {
          return ctx.map((tti) => i18n.toDateShortName(tti.label))
        },
        getTooltipLabel: (ctx) => {
          return `${meanLabel[preferredLanguage]}: ${formatNum(
            Number(ctx.parsed.y)
          )}, ${stdevLabel[preferredLanguage]}: ±${formatNum(
            Number(ctx.parsed.yMax) - Number(ctx.parsed.y)
          )}`
        },
      }
    default:
      return {
        xAxisLabel: '?',
        yAxisLabel: '?',
        getTooltipTitle: (ctx) => {
          return ctx.map((tti) => i18n.toDateShortName(tti.label))
        },
        getTooltipLabel: (ctx) => {
          return `Mean: ${formatNum(
            Number(ctx.parsed.y)
          )}, Std Dev: ±${formatNum(
            Number(ctx.parsed.yMax) - Number(ctx.parsed.y)
          )}`
        },
      }
  }
}

interface StatsChartParams {
  theme: any
  useIntegers: boolean
  labelY: string
  labelX: string
  selectedStatisticsType: string
  percents: number[]
  minimumFractionDigits: number | undefined
  maximumFractionDigits: number | undefined
  unitLabel: string
  statsWindowMinimized: boolean
}

function createStatsChartOptions({
  theme,
  useIntegers,
  labelY,
  labelX,
  selectedStatisticsType,
  percents,
  minimumFractionDigits,
  maximumFractionDigits,
  unitLabel,
  statsWindowMinimized,
}: StatsChartParams): ChartOptions {
  return {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 42,
        right: 16,
        bottom: 5,
      },
    },
    color: theme.palette.text.primary,
    borderColor: theme.palette.divider,
    datasets: {
      bar: {
        minBarLength: 5,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: theme.palette.text.primary,
          callback(label) {
            return formatNum(Number(label), useIntegers)
          },
        },
        title: {
          display: true,
          color: theme.palette.text.primary,
          text: labelY,
        },
        grid: {
          color: theme.palette.divider,
        },
      },
      x: {
        ticks: { display: false, color: theme.palette.text.primary },
        title: {
          display: false,
          color: theme.palette.text.primary,
          text: labelX,
        },
        grid: {
          color: theme.palette.divider,
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        clamp: true,
        color: theme.palette.text.primary,
        anchor: 'end',
        align: 'top',
        rotation: -90,

        formatter(value, ctx) {
          return selectedStatisticsType === 'percentages'
            ? `${formatNum(percents[ctx.dataIndex], false, 1, 1)}%`
            : formatNum(
                value,
                useIntegers,
                minimumFractionDigits,
                maximumFractionDigits
              )
        },
      },
      tooltip: {
        backgroundColor: '#FFFFFFF',
        animation: {
          duration: 0,
        },
        callbacks: {
          label: (ctx) => {
            const label = useIntegers
              ? formatNum(Number(ctx.parsed.y), true)
              : `${formatNum(
                  Number(ctx.parsed.y),
                  false,
                  minimumFractionDigits,
                  maximumFractionDigits
                )} ${unitLabel}`

            return `${label} | ${formatNum(
              percents[ctx.dataIndex!],
              false,
              1,
              1
            )}%`
          },
        },
      },
    },
  }
}

const configureChartData = (data: ChartData[]): ConfiguredStatData => {
  const labels: string[] = []
  const colors: string[] = []
  const values: number[] = []

  data.forEach((next) => {
    labels.push(next.label)
    colors.push(next.color!)
    values.push(next.size)
  })

  const total = values.reduce((acc, next) => acc + next, 0)
  const percents = values.map((value) => (100 * value) / total)

  const chartData = {
    labels,
    datasets: [
      {
        backgroundColor: colors,
        data: values,
      },
    ],
  }

  return {
    percents,
    chartData,
  }
}

export {
  createHistoricChartOptions,
  createStatsChartOptions,
  configureChartData,
  StatsChartParams,
  ChartData,
  ConfiguredStatData,
}
