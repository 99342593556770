import { createSelector } from 'reselect'
import { selectHistoricParcelData } from './selectHistoricParcelData'
import { getSelectedFlightDate } from '../../data/userSelectionRedux'
import { selectHistoricalCalculation } from './selectHistoricalCalculation'

interface TrendStats {
  meanTrend: number
  stdevTrend: number
}

export const selectTrendStats = createSelector(
  [
    selectHistoricParcelData,
    selectHistoricalCalculation,
    getSelectedFlightDate,
  ],
  (
    historicParcelData,
    historicalCalculation,
    selectedFlightDate
  ): TrendStats | undefined => {
    // If no historical data exists, return nothing.
    if (!historicParcelData?.data) {
      return
    }

    // Calculate historical values.
    const { labels, data } = historicalCalculation(historicParcelData.data)

    // Map the labels back to the data.
    const calculationsByFlightDates = labels.map((l, i) => {
      return {
        flightDate: l,
        mean: data[i].y,
        stdev: !data[i].yMax ? 0 : (data[i].yMax ?? 0) - data[i].y,
      }
    }, [])

    // Sort the values by their flightDate from latest to newest.
    const parcelsSortedByFlightDate = calculationsByFlightDates?.sort(
      (a, b) =>
        new Date(a.flightDate).getTime() - new Date(b.flightDate).getTime()
    )

    // Find the current flight index.
    const currentFlightIndex = parcelsSortedByFlightDate?.findIndex(
      (pbfd) => pbfd.flightDate === selectedFlightDate
    )

    // If an index is not found, return nothing.
    if (!currentFlightIndex || currentFlightIndex === -1) {
      return
    }

    // If the first flight, nothing to compare to create trend data. Return nothing.
    if (currentFlightIndex === 0) {
      return
    }

    // The diff between the current selected flight parcel aggregate and the flight before it.
    // Round to the second decimal place.
    return {
      meanTrend:
        Math.round(
          (parcelsSortedByFlightDate[currentFlightIndex].mean -
            parcelsSortedByFlightDate[currentFlightIndex - 1].mean +
            Number.EPSILON) *
            100
        ) / 100,
      stdevTrend:
        Math.round(
          (parcelsSortedByFlightDate[currentFlightIndex].stdev -
            parcelsSortedByFlightDate[currentFlightIndex - 1].stdev +
            Number.EPSILON) *
            100
        ) / 100,
    }
  }
)
