import {
  FeatureCollection,
  MultiPolygon,
  Polygon,
  Properties,
} from '@turf/helpers'
import { MapSourceData } from '../../../data/types'
import {
  getApiUrl,
  getAuthToken,
} from '../../../vvapi/apiResource/createApiResource'

const getMapSourceFeatures = async (sources: MapSourceData[]) => {
  let fcs: Record<string, FeatureCollection> = {}
  for (const source of sources) {
    const res = await fetch(
      `${getApiUrl()}/api/v3/map-source/${source.id}/product.geojson`,
      {
        headers: {
          Authorization: getAuthToken()!,
        },
      }
    )

    if (res.ok) {
      const geoJson = (await res.json()) as FeatureCollection<
        MultiPolygon | Polygon,
        Properties
      >

      fcs[source.parcelId!] = geoJson
    }
  }

  return fcs
}

export default getMapSourceFeatures
