import * as React from 'react'

import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  MenuItem,
  Stack,
  styled,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'

import { NotesFilter } from '../notes/NotesFilter'
import * as notes from '../notes/redux'
import * as selectors from '../notes/selectors'
import { useRedux } from '../hooks/useRedux'
import { NewProjectButton } from './create/NewProjectButton'
import { ProjectItem } from './ProjectItem'
import { Project } from '../graphql/types'
import DrillDownMenu from '../UI/DrillDownList/DrillDownMenu'
import {
  NoteMedia,
  NoteWithTemplate,
  OrgFeatureGeometry,
} from '../vvapi/models'
import NoteListItem from '../notes/NoteListItem'
import { Level } from '../UI/DrillDownList/types'
import i18n, { keys } from '../i18n'
import {
  deleteNotes,
  deleteProject,
  deleteMedia,
  moveGeneralProjectNotes,
  moveProjectNotes,
  deleteMediaReferences,
} from './queries'
import { refreshListNotes, refreshListProjects } from '../notes/selectors'
import {
  Delete,
  Download,
  InsertPhoto,
  KeyboardArrowRight,
  NavigateBeforeSharp,
} from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { MoveNotesModal } from './move/MoveNotesModal'
import { MoveMenuItem } from './move/MoveMenuItem'
import { SelectButton } from './select/SelectButton'
import { DeleteButton } from './select/DeleteButton'
import { MoveButton } from './select/MoveButton'
import { showFeedback } from '../redux/notifications/redux'
import { DeleteProjectModal } from './delete/DeleteProjectModal'
import { DeleteProjectNotesModal } from './delete/DeleteProjectNotesModal'
import { selectOrganizationId } from '../data/selectOrganizationId'
import { MoveProjectNotesModal } from './move/MoveProjectNotesModal'
import MediaAttachmentsModal from '../media/MediaAttachmentsModal'
import { ConfirmationModal } from '../app/ConfirmationModal/ConfirmationModal'
import MediaListItem from '../media/MediaListItem'
import { downloadBlob } from '../util/download'
import { getBlob } from '../vvapi/apiResource/createApiResource'
import { getMediaUrl } from '../media/util/getUrl'
import JSZip from 'jszip'
import { SelectAllButton } from './select/SelectAllButton'
import NoteListItemPhotos from '../notes/NoteListItemPhotos'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import { NotesFilterInfo } from '../notes/types'

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    height: '32px',
  },
}))

const FilesTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.25px;
  text-align: left;
  margin-left: 3px;
`
const FileCountText = styled(Typography)(
  ({ theme }) => `
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.25px;
  text-align: left;
  color: ${theme.palette.text.primary};
  opacity: 70%;
`
)

const StyledStack = styled(ButtonBase)`
  overflow: hidden;
  border-bottom: 1px solid #424242;
  padding-top: 4px;
  padding-bottom: 8px;
  padding-left: 27px;
  cursor: pointer;
  justify-content: flex-start;
`

interface MediaItemData {
  id: string
  media: NoteMedia
  onSelect: (id: string) => void
  onClickMedia: (mediaId: string | undefined) => void
  selected: boolean
}

interface ProjectItemData {
  id: string
  project: Project
  selected: boolean
  onSelect: (id: string) => void
  onDelete: () => void
  onMove: (newProjectId: string) => void
  moveOptions: Record<string, Project>
  filteredNoteCount?: number
  isFilteredByMapView: boolean
}

interface NoteItemData {
  id: string
  note: NoteWithTemplate
  selected: boolean
  mode: 'map' | 'select'
  onClickMedia: (mediaId: string | undefined) => void
}

const VirtualizedNoteListItem = ({
  id,
  note,
  selected,
  mode,
}: NoteItemData) => {
  return <NoteListItem key={id} note={note} selected={selected} mode={mode} />
}

const VirtualizedNoteListItemPhotos = ({
  id,
  note,
  selected,
  mode,
  onClickMedia,
}: NoteItemData) => {
  return (
    <NoteListItemPhotos
      key={id}
      note={note}
      selected={selected}
      mode={mode}
      onClickMedia={(mediaId) => onClickMedia(mediaId)}
    />
  )
}

const VirtualizedMediaListItem = ({
  id,
  media,
  onSelect,
  selected,
  onClickMedia,
}: MediaItemData) => {
  return (
    <MediaListItem
      key={id}
      media={media.media!}
      selected={selected}
      onClick={(media) => onSelect(media.id)}
      onClickMedia={(mediaId) => onClickMedia(mediaId)}
    />
  )
}
const VirtualizedProjectListItem = ({
  id,
  project,
  selected,
  onSelect,
  filteredNoteCount,
  isFilteredByMapView,
}: ProjectItemData) => {
  return (
    <ProjectItem
      key={id}
      project={project}
      selected={selected}
      onSelect={onSelect}
      style={{ paddingLeft: 8 }}
      filteredNoteCount={filteredNoteCount}
      isFilteredByMapView={isFilteredByMapView}
    />
  )
}

const ProjectOptions = ({
  project,
  onDelete,
  onMove,
  moveOptions,
}: ProjectItemData) => {
  const classes = useStyles()
  const menuItems = []
  if (project.id !== 'general') {
    menuItems.push(
      <MenuItem onClick={() => onDelete()}>
        <Stack
          className={classes.option}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Typography>{i18n.t(keys.deleteProjectLabel)}</Typography>
          <Delete />
        </Stack>
      </MenuItem>
    )
  }
  return (
    <>
      {menuItems.map((item) => item)}
      <MoveMenuItem
        currentProject={project}
        projectOptions={moveOptions}
        className={classes.option}
        moveNotesToProject={onMove}
      />
    </>
  )
}

const ProjectBackLabel = (parent: Project) => {
  return parent.name
}

export const ProjectsDrawer = () => {
  const [state, dispatch] = useRedux()
  const { featureEnabled: photosEnabled } = useFeatureFlag({
    featureFlagId: 'photos',
  })
  const theme = useTheme()
  const [displayMode, setDisplayMode] = React.useState<'media' | 'projects'>(
    'projects'
  )
  const notesFilter = state.notes.filterInfo
  const [currentLevelIndex, setCurrentLevelIndex] = React.useState(0)
  const organizationId = selectOrganizationId(state)
  const filteredNotes = selectors.selectFilteredNotes(state)
  const notesById = selectors.selectNotesById(state)
  const projectsById = selectors.selectProjectsById(state)
  const [selectedMediaId, setSelectedMediaId] = React.useState<
    string | undefined
  >()
  const [initialLoadComplete, setInitialLoadComplete] = React.useState(false)
  const isFiltered = React.useMemo(() => {
    const {
      searchTerm,
      fromDate,
      toDate,
      formType,
      projectType,
      filterNotesByMapView,
    } = notesFilter
    return (
      !!searchTerm ||
      !!formType ||
      !!fromDate ||
      !!toDate ||
      !!projectType ||
      !!filterNotesByMapView
    )
  }, [notesFilter])
  const filteredProjects = React.useMemo(() => {
    if (!isFiltered) {
      return Object.values(projectsById)
    }
    const projectIds = new Set(
      filteredNotes.map((id) => notesById[id].projectId ?? 'general')
    )
    const projs = Object.values(projectsById).filter((project) =>
      projectIds.has(project.id)
    )
    return projs
  }, [isFiltered, filteredNotes, projectsById, notesById])
  const notesByProjectId = selectors.selectNotesByProjectId(state)
  const selectedProject = state.notes.selectedProjectIds

  const handleToggleFilterByMapView = (e?: boolean) => {
    const newFilter: NotesFilterInfo = {
      ...notesFilter,
      filterNotesByMapView: e,
    }
    dispatch(notes.actions.setFilterInfo(newFilter))
  }

  const FilterNotesByMapViewCheckbox = () => (
    <StyledStack>
      <FormControlLabel
        control={
          <Checkbox
            checked={notesFilter.filterNotesByMapView}
            onChange={(e) => handleToggleFilterByMapView(e.target.checked)}
            color="primary"
          />
        }
        label={i18n.t(keys.filterListByMapView)}
        style={{
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '21px',
          letterSpacing: '0.25px',
          textAlign: 'left',
        }}
      />
    </StyledStack>
  )

  React.useEffect(() => {
    const projectIds = Object.keys(projectsById)
    if (
      selectedProject.length === 0 &&
      projectIds.length > 0 &&
      !initialLoadComplete
    ) {
      dispatch(notes.actions.setSelectedProjectIds(projectIds))
      setInitialLoadComplete(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsById])

  const notesMedia = React.useMemo(() => {
    const noteMediaMap: Record<string, NoteMedia> = {}
    for (const noteId of filteredNotes) {
      const note = notesById[noteId]
      if (note?.noteMedia && note?.noteMedia.length) {
        for (const noteMedia of note.noteMedia) {
          noteMediaMap[noteMedia.media!.id] = noteMedia
          noteMediaMap[noteMedia.media!.id].noteId = noteId
        }
      }
    }

    return Object.fromEntries(
      Object.entries(noteMediaMap).sort(
        ([, a]: [any, NoteMedia], [, b]: [any, NoteMedia]) =>
          new Date(b.media!.createdAt).getTime() -
          new Date(a.media!.createdAt).getTime()
      )
    )
  }, [filteredNotes, notesById])

  const [noteMode, setNoteMode] = React.useState<'map' | 'select'>('map')
  const [selectedNotes, setSelectedNotes] =
    React.useState<Record<string, NoteWithTemplate>>()
  const [moveNotesModalOpen, setMoveNotesModalOpen] = React.useState(false)
  const onMoveNotes = async () => {
    setMoveNotesModalOpen(false)
    setSelectedNotes({})
  }
  const selectOptionsDisabled = React.useMemo(() => {
    return Object.keys(selectedNotes ?? {}).length === 0
  }, [selectedNotes])

  const [moveProjectNotesTarget, setMoveProjectNotesTarget] = React.useState<
    string | undefined
  >()
  const [moveProjectSelection, setMoveProjectSelection] = React.useState<
    string | undefined
  >()
  const moveNotesToProject = React.useCallback(async () => {
    const currentProject = projectsById[moveProjectSelection ?? '']
    if (!currentProject) {
      return
    }

    const { data: project } =
      currentProject.id === 'general'
        ? await moveGeneralProjectNotes(organizationId, moveProjectNotesTarget)
        : await moveProjectNotes(
            currentProject.id,
            moveProjectNotesTarget ?? 'general'
          )

    if (project) {
      refreshListProjects()
      refreshListNotes()
      setMoveProjectSelection(undefined)
      setMoveProjectNotesTarget(undefined)
      dispatch(
        showFeedback({
          message: i18n.t(keys.moveAllProjectNotesSuccessMessage),
          severity: 'success',
        })
      )
    } else {
      dispatch(
        showFeedback({
          message: i18n.t(keys.moveAllProjectNotesFailedMessage),
          severity: 'error',
        })
      )
    }
  }, [
    projectsById,
    moveProjectSelection,
    organizationId,
    moveProjectNotesTarget,
    dispatch,
  ])

  const [deleteProjectSelection, setDeleteProjectSelection] = React.useState<
    string | undefined
  >()
  const onDeleteProject = React.useCallback(
    async (project: Project) => {
      if (project.id === 'general') {
        return
      }

      const result = await deleteProject(project.id)
      if (result?.projectData) {
        refreshListProjects()
        refreshListNotes()
        dispatch(
          showFeedback({
            message: i18n.t(keys.deleteProjectSuccessMessage),
            severity: 'success',
          })
        )
        setDeleteProjectSelection(undefined)
      } else {
        dispatch(
          showFeedback({
            message: i18n.t(keys.deleteProjectFailedMessage),
            severity: 'error',
          })
        )
      }
    },
    [dispatch]
  )

  const [deleteProjectNotesModalOpen, setDeleteProjectNotesModalOpen] =
    React.useState(false)
  const onDeleteSelectedNote = React.useCallback(async () => {
    const result = await deleteNotes(Object.keys(selectedNotes ?? {}))

    if (result?.data) {
      refreshListNotes()
      refreshListProjects()
      setSelectedNotes({})
      setDeleteProjectNotesModalOpen(false)
      dispatch(
        showFeedback({
          message: i18n.t(keys.deleteNotesSuccessMessage),
          severity: 'success',
        })
      )
    } else {
      dispatch(
        showFeedback({
          message: i18n.t(keys.deleteNotesFailedMessage),
          severity: 'error',
        })
      )
    }
  }, [dispatch, selectedNotes])

  const handleProjectSelected = React.useCallback(
    (id: string) => {
      const selectedSet = new Set([...state.notes.selectedProjectIds])
      if (selectedSet.has(id)) {
        selectedSet.delete(id)
      } else {
        selectedSet.add(id)
      }
      dispatch(
        notes.actions.setSelectedProjectIds(Array.from(selectedSet.values()))
      )
    },
    [state.notes.selectedProjectIds, dispatch]
  )

  const [selectedMediaIds, setSelectedMediaIds] = React.useState<Set<string>>(
    new Set()
  )

  const handleMediaSelected = React.useCallback(
    (mediaId: string) => {
      if (selectedMediaIds.has(mediaId)) {
        const newSelectedMediaIds = new Set(selectedMediaIds)
        newSelectedMediaIds.delete(mediaId)
        setSelectedMediaIds(newSelectedMediaIds)
      } else {
        setSelectedMediaIds(new Set(selectedMediaIds).add(mediaId))
      }
    },
    [selectedMediaIds, dispatch]
  )

  const [selectedNote, setSelectedNote] = React.useState<{
    pinColor: string
    templateName: string
    geometry: OrgFeatureGeometry
    id: string
  }>()

  const handleClickMedia = (mediaId: string | undefined) => {
    setSelectedMediaId(mediaId)

    if (mediaId && notesMedia[mediaId]) {
      const noteId = notesMedia[mediaId]?.noteId
      if (noteId) {
        const note = notesById[noteId]
        setSelectedNote({
          pinColor: note.pinColor,
          templateName: note.templateName,
          geometry: note.feature.geometry,
          id: note.id,
        })
      }
    } else {
      setSelectedNote(undefined)
    }
  }

  const mediaLevel: Level = React.useMemo(() => {
    return {
      id: 'media',
      ItemComponent: VirtualizedMediaListItem,
      getItems: async () => {
        return Object.values(notesMedia).reduce((acc, curr) => {
          acc.push({
            id: curr.media?.id ?? '',
            media: curr,
            onSelect: handleMediaSelected,
            selected: selectedMediaIds.has(curr.media?.id ?? ''),
            onClickMedia: handleClickMedia,
          })
          return acc
        }, [] as MediaItemData[])
      },
      getBackLabel: 'Files',
      onAscend: async () => {
        setCurrentLevelIndex(0)
      },

      // Options: ProjectOptions,
    }
  }, [notesMedia, handleMediaSelected, selectedMediaIds])

  const projectIds = React.useMemo(
    () => Object.keys(projectsById).map((id) => id),
    [projectsById]
  )
  const isAllSelected = React.useMemo(
    () => projectIds.length === state.notes.selectedProjectIds.length,
    [projectIds, state.notes.selectedProjectIds]
  )

  const handleAllProjectSelected = React.useCallback(() => {
    if (isAllSelected) {
      dispatch(notes.actions.setSelectedProjectIds([]))
    } else {
      dispatch(notes.actions.setSelectedProjectIds(projectIds))
    }
  }, [isAllSelected, projectIds, state.notes.selectedProjectIds, dispatch])

  const projectLevel: Level = React.useMemo(() => {
    return {
      id: 'projects',
      ItemComponent: VirtualizedProjectListItem,
      getItems: async () => {
        return filteredProjects.reduce((acc, curr) => {
          const filteredNoteCount = notesFilter.filterNotesByMapView
            ? filteredNotes.filter((noteId) => {
                const note = notesById[noteId]
                return (
                  note.projectId === curr.id ||
                  (curr.id === 'general' && !note.projectId)
                )
              }).length
            : undefined

          acc.push({
            id: curr.id,
            project: curr,
            selected: state.notes.selectedProjectIds.includes(curr.id),
            onSelect: handleProjectSelected,
            onDelete: () => setDeleteProjectSelection(curr.id),
            onMove: (newProjectId: string) => {
              setMoveProjectNotesTarget(newProjectId)
              setMoveProjectSelection(curr.id)
            },
            moveOptions: projectsById,
            filteredNoteCount,
            isFilteredByMapView: !!notesFilter.filterNotesByMapView,
          })
          return acc
        }, [] as ProjectItemData[])
      },
      onDescend: async () => {
        setCurrentLevelIndex(1)
      },
      Options: ProjectOptions,
    }
  }, [
    filteredProjects,
    handleProjectSelected,
    projectsById,
    state.notes.selectedProjectIds,
    notesFilter.filterNotesByMapView,
    filteredNotes,
    notesById,
  ])

  const noteLevel: Level = React.useMemo(() => {
    return {
      id: 'notes',
      ItemComponent: photosEnabled
        ? VirtualizedNoteListItemPhotos
        : VirtualizedNoteListItem,
      getBackLabel: ProjectBackLabel,
      onAscend: async () => {
        setCurrentLevelIndex(0)
        setNoteMode('map')
      },
      getItems: async (item) => {
        const parentProject = item as Project
        return filteredNotes.reduce((acc, curr) => {
          const note = notesByProjectId[parentProject.id][curr]
          if (note) {
            acc.push({
              id: note.id,
              note: note,
              selected: !!selectedNotes?.[curr],
              mode: noteMode,
              onClickMedia: handleClickMedia,
            })
          }
          return acc
        }, [] as NoteItemData[])
      },
      onSelectItem: (item) => {
        const { note } = item as NoteItemData
        if (noteMode === 'map') {
          dispatch(
            notes.actions.setEditingNoteId({
              noteId: note.id,
              zoomToNote: true,
            })
          )
          return []
        } else {
          const selectedSet = { ...selectedNotes }
          if (selectedSet[note.id]) {
            delete selectedSet[note.id]
          } else {
            selectedSet[note.id] = note
          }
          setSelectedNotes(selectedSet)
          return Object.values(selectedSet)
        }
      },
    }
  }, [notesByProjectId, filteredNotes, noteMode, dispatch, selectedNotes])

  const levels = React.useMemo(() => {
    if (displayMode === 'media') {
      return [mediaLevel]
    }

    return [projectLevel, noteLevel]
  }, [projectLevel, noteLevel, mediaLevel, displayMode])

  const [confirmMediaDownload, setConfirmMediaDownload] =
    React.useState<boolean>(false)

  const [confirmMediaDelete, setConfirmMediaDelete] =
    React.useState<boolean>(false)

  const cancelDownload = () => {
    setConfirmMediaDownload(false)
  }

  const cancelDelete = () => {
    setConfirmMediaDelete(false)
  }

  const handleDownload = async () => {
    if (selectedMediaIds) {
      if (selectedMediaIds.size === 1) {
        const mediaIdsArray = Array.from(selectedMediaIds)
        const mediaId = mediaIdsArray[0]
        const media = notesMedia[mediaId]?.media
        if (media && media.originalname) {
          const { blob } = await getBlob(getMediaUrl({ id: mediaId }))
          downloadBlob(blob, media.originalname)
          setSelectedMediaIds(new Set())
        }
      } else {
        setConfirmMediaDownload(true)
      }
    }
  }

  const handleDelete = async () => {
    setConfirmMediaDelete(true)
  }

  const finishDownload = async () => {
    const zip = new JSZip()
    const mediaIdsArray = Array.from(selectedMediaIds)

    for (const mediaId of mediaIdsArray) {
      const media = notesMedia[mediaId]?.media
      if (media && media.originalname) {
        try {
          const { blob } = await getBlob(getMediaUrl({ id: mediaId }))
          zip.file(media.originalname, blob)
        } catch (error) {
          console.error(`Error downloading file ${media.originalname} `, error)
        }
      }
    }
    zip
      .generateAsync({ type: 'blob' })
      .then((content) => {
        downloadBlob(content, 'images.zip')
        setSelectedMediaIds(new Set())
        setConfirmMediaDownload(false)
      })
      .catch((error) => {
        console.error('Error generating ZIP file: ', error)
        setConfirmMediaDownload(false)
      })
  }

  const finishDelete = async () => {
    const mediaIdsArray = Array.from(selectedMediaIds)

    await deleteMedia(mediaIdsArray)
    await deleteMediaReferences(mediaIdsArray)
    refreshListNotes()
    setSelectedMediaIds(new Set())
    setConfirmMediaDelete(false)
  }

  return (
    <>
      <Stack sx={{ overflowY: 'hidden', height: '100%' }}>
        <DrillDownMenu
          optionsComponent={
            <Stack>
              <NotesFilter
                style={{ display: noteMode === 'select' ? 'none' : 'inherit' }}
              />
              <Stack direction="column" px={2} py={1}>
                <Stack direction="row" alignItems="center" p={0} gap={1}>
                  <Button
                    color="primary"
                    variant="text"
                    style={{
                      justifyContent: 'start',
                      cursor: 'pointer',
                      display: displayMode === 'media' ? 'flex' : 'none',
                    }}
                    onClick={() => {
                      setDisplayMode('projects')
                    }}
                  >
                    <NavigateBeforeSharp
                      sx={{ color: theme.palette.text.primary }}
                    />
                    <Typography
                      fontSize={12}
                      lineHeight={2.5}
                      color={theme.palette.text.primary}
                    >
                      {i18n.t(keys.notes.projects)}
                    </Typography>
                  </Button>
                  {Object.keys(notesMedia).length > 0 && photosEnabled && (
                    <Button
                      color="primary"
                      variant="contained"
                      style={{
                        minWidth: '50%',
                        maxWidth: '70%',
                        justifyContent: 'space-between',
                        backgroundColor: '#424242',
                        cursor: 'pointer',
                        padding: '8px',
                        display:
                          displayMode === 'projects' && currentLevelIndex === 0
                            ? 'flex'
                            : 'none',
                        gap: '4px',
                      }}
                      onClick={() => {
                        setDisplayMode('media')
                      }}
                    >
                      <InsertPhoto sx={{ color: theme.palette.text.primary }} />
                      <FilesTitle>{i18n.t(keys.notes.Files)}</FilesTitle>
                      <FileCountText>
                        {Object.keys(notesMedia).length > 1000
                          ? '(1000+)'
                          : `(${Object.keys(notesMedia).length})`}
                      </FileCountText>
                      <KeyboardArrowRight
                        sx={{ color: theme.palette.text.primary }}
                      />
                    </Button>
                  )}
                  <NewProjectButton
                    type="notes"
                    style={{
                      display:
                        displayMode === 'projects' && currentLevelIndex === 0
                          ? 'flex'
                          : 'none',
                    }}
                  />

                  <SelectButton
                    noteMode={noteMode}
                    setNoteMode={setNoteMode}
                    style={{
                      display:
                        displayMode === 'projects' && currentLevelIndex === 1
                          ? 'inherit'
                          : 'none',
                    }}
                  />
                  {displayMode === 'media' && (
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      flexGrow={1}
                      sx={{ marginRight: -1 }}
                    >
                      <IconButton
                        onClick={handleDownload}
                        disabled={selectedMediaIds.size === 0}
                      >
                        <Download sx={{ color: theme.palette.text.primary }} />
                      </IconButton>
                      <IconButton
                        onClick={handleDelete}
                        disabled={selectedMediaIds.size === 0}
                      >
                        <Delete sx={{ color: '#FF0000' }} />
                      </IconButton>
                    </Box>
                  )}
                </Stack>
                <Collapse in={noteMode === 'select'} orientation="vertical">
                  <Stack direction="row" alignItems="center" py={2} spacing={2}>
                    <DeleteButton
                      onPress={() => setDeleteProjectNotesModalOpen(true)}
                      disabled={selectOptionsDisabled}
                    />
                    <MoveButton
                      onPress={() => setMoveNotesModalOpen(true)}
                      disabled={selectOptionsDisabled}
                    />
                  </Stack>
                </Collapse>
              </Stack>
              {displayMode === 'projects' &&
                (currentLevelIndex === 0 || currentLevelIndex === 1) && (
                  <FilterNotesByMapViewCheckbox />
                )}
              {displayMode === 'projects' && currentLevelIndex === 0 && (
                <SelectAllButton
                  onSelect={handleAllProjectSelected}
                  selected={isAllSelected}
                />
              )}
            </Stack>
          }
          levels={levels}
        />
      </Stack>
      <MoveNotesModal
        open={moveNotesModalOpen}
        onCancel={() => {
          setMoveNotesModalOpen(false)
        }}
        onMoveNotes={async () => {
          await onMoveNotes()
        }}
        selectedNotes={selectedNotes}
      />
      <MoveProjectNotesModal
        open={!!moveProjectSelection}
        onCancel={() => {
          setMoveProjectSelection(undefined)
          setMoveProjectNotesTarget(undefined)
        }}
        onMoveProjectNotes={moveNotesToProject}
        currentProjectName={
          projectsById[moveProjectSelection ?? '']?.name ?? ''
        }
        targetProjectName={
          projectsById[moveProjectNotesTarget ?? '']?.name ?? ''
        }
      />
      <DeleteProjectModal
        open={!!deleteProjectSelection}
        onCancel={() => setDeleteProjectSelection(undefined)}
        onProjectDelete={onDeleteProject}
        project={projectsById[deleteProjectSelection ?? '']}
      />
      <DeleteProjectNotesModal
        open={deleteProjectNotesModalOpen}
        onCancel={() => setDeleteProjectNotesModalOpen(false)}
        onProjectNotesDelete={onDeleteSelectedNote}
      />
      <MediaAttachmentsModal
        mediaFiles={notesMedia}
        selectedMediaId={selectedMediaId}
        setSelectedMediaId={handleClickMedia}
        note={{
          pinColor: selectedNote?.pinColor ?? '#2196F3',
          templateName: selectedNote?.templateName ?? '',
          id: selectedNote?.id ?? '',
        }}
        geometry={selectedNote?.geometry}
      />
      <ConfirmationModal
        open={confirmMediaDownload}
        title={i18n.t(keys.notes.downloadImagesTitle)}
        message={`${i18n.t(keys.notes.downloadImagesDescription)} ${
          selectedMediaIds.size
        } ${i18n.t(keys.notes.files)}?`}
        messageStyle={{ fontWeight: 'normal' }}
        onConfirm={finishDownload}
        onCancel={cancelDownload}
      />
      <ConfirmationModal
        open={confirmMediaDelete}
        title={
          selectedMediaIds.size === 1
            ? `${i18n.t(keys.notes.deleteImageTitle)}`
            : `${i18n.t(keys.notes.deleteImagesTitle)}`
        }
        message={
          selectedMediaIds.size === 1
            ? `${i18n.t(keys.notes.deleteImageDescription)}`
            : `${i18n.t(keys.notes.deleteImagesDescription)} ${
                selectedMediaIds.size
              } ${i18n.t(keys.notes.files)}?`
        }
        messageStyle={{ fontWeight: 'normal' }}
        dangerMode={true}
        onConfirm={finishDelete}
        onCancel={cancelDelete}
      />
    </>
  )
}
